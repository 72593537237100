import { IRoute } from 'common/common.interface'
import Settings from 'pages/settings/settings.component'
import { DashboardInProgress } from 'pages/work-in-progress/work-in-progress.component'
import Bookings from 'pages/bookings/bookings.component'
import CreateOrganisation from 'pages/organisation/create-organisation/create-organisation.component'
import Contact from 'pages/contact/contact.component'
import OrganisationDetail from 'pages/organisation/organisation-details/organisation-details.component'
import Organisation from 'pages/organisation/organisation.component'
import Shipments from 'pages/shipments/shipments.component'
import UserAccess from 'pages/user-acces/user-access.component'
import UserAccessDetail from 'pages/user-acces/user-access-detail/user-access-detail.component'
import UserAccessForm from 'pages/user-acces/user-access-form/user-access-form.component'
import Quotes from 'pages/quotes/quotes.component'
import FormBooking from 'pages/bookings/form-booking/form-booking.component'
import Experiment from 'pages/experiment/experiment.component'
import FormQuote from 'pages/quotes/form/form.component'
import CreateShipment from 'pages/shipments/form/create-shipment/create-shipment.component'
import DetailShipment from 'pages/shipments/form/detail-shipment/detail-shipment.component'
import ChargeCode from 'pages/charge-code/charge-code.component'
import ProductCategory from 'pages/product-category/product-category.component'
import HSCode from 'pages/hs-code/hs-code.component'
import Unlocodes from 'pages/unlocodes/unlocodes.component'
import ServiceRate from 'pages/service-rate/service-rate.component'
import ShippingRate from 'pages/shipping-rate/shipping-rate.component'
import CreateConsolidation from 'pages/shipments/form/create-consolidation/create-consolidation.component'
import CreateSales from 'pages/sales/create-sales/create-sales.component'
import Sales from 'pages/sales/sales.component'
import CashBank from 'pages/cash-bank/cash-bank.component'
import CashBankDetail from 'pages/cash-bank/cash-bank-details/cash-bank-details.component'
import CashBankEditAccount from 'pages/cash-bank/cash-bank-details/edit-account/account-form.component'
import Expenses from 'pages/expenses/expenses.component'
import DetailSales from 'pages/sales/details-sales/details.sales.component'
import CreateCashAndBank from 'pages/cash-bank/create-cash-and-bank/create-cash-and-bank.component'
import CreateCashAndBankInternal from 'pages/cash-bank/create-cash-and-bank/cash-and-bank-internal/create-cash-and-bank-internal.component'
import CreatePurchasesAndExpenses from 'pages/expenses/create-purchases-and-expenses/create-purchases-and-expenses.component'
import DetailConsolidation from 'pages/shipments/form/detail-consolidation/detail-consolidation.component'
import DetailCashAndBackForm from 'pages/cash-bank/cash-bank-details/details-cash-bank-form/details.sales.component'
import DetailExpenses from 'pages/expenses/details-expenses/details.expenses.component'
import { moduleCodes } from './loglines.routes.static'
import Titipan from 'pages/titipan/titipan.component'
import PaymentRequest from 'pages/titipan/payment-request/payment-request.component'
import TitipanDetails from 'pages/titipan/details/titipan-details.component'
import TopUp from 'pages/titipan/top-up/top-up.component'
import PaymentDetails from 'pages/titipan/payment-details/payment-details.component'
import ChartOfAccount from 'pages/chart-of-accounts/chart-of-accounts.component'
import CreateJurnal from 'pages/chart-of-accounts/create-jurnal/create-jurnal.component'
import CreateAccount from 'pages/chart-of-accounts/create-account/create-account.component'
import OppeningBalance from 'pages/chart-of-accounts/opening-balance/opening-balance.component'
import ChartOfBalanceDetails from 'pages/chart-of-accounts/details/details.component'

export const loglinesRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/quotes',
        text: 'Quotes',
        Content: Quotes,
        show: true,
        icon: 'ri-file-paper-line',
        textTranslationCode: 'menu.quotes',
        moduleCodes: [
            moduleCodes.QUOTE_ALL_ACCESS,
            moduleCodes.QUOTE_VIEW_ONLY,
        ],
    },
    {
        path: '/quote-details/:id',
        text: 'Detail Quote',
        Content: () => FormQuote({ isDetailPage: true }),
        textTranslationCode: 'quotes.quote_details',
        moduleCodes: [
            moduleCodes.QUOTE_ALL_ACCESS,
            moduleCodes.QUOTE_VIEW_ONLY,
        ],
    },
    {
        path: '/new-quote',
        text: 'Quote',
        Content: () => FormQuote({ isDetailPage: false }),
        textTranslationCode: 'menu.quotes_new',
        moduleCodes: [moduleCodes.QUOTE_ALL_ACCESS],
    },
    {
        path: '/inquiry',
        text: 'Inquiry',
        Content: Bookings,
        show: true,
        icon: 'ri-book-2-line',
        moduleCodes: [
            moduleCodes.BOOKING_ALL_ACCESS,
            moduleCodes.BOOKING_VIEW_ONLY,
        ],
    },
    {
        path: '/inquiry/create',
        text: 'Inquiry',
        Content: () => FormBooking({ isPageDetails: false }),
        moduleCodes: [moduleCodes.BOOKING_ALL_ACCESS],
    },
    {
        path: '/inquiry/:id',
        text: 'Inquiry',
        Content: () => FormBooking({ isPageDetails: true }),
        moduleCodes: [
            moduleCodes.BOOKING_ALL_ACCESS,
            moduleCodes.BOOKING_VIEW_ONLY,
        ],
    },
    {
        path: 'freight-forwarding',
        text: 'Freight Forwarding',
        show: true,
        icon: 'ri-ship-line',
        expandable: true,
        moduleCodes: [
            moduleCodes.FF_AIR_ALL_ACCESS,
            moduleCodes.FF_AIR_VIEW_ONLY,
            moduleCodes.FF_CONSOLIDATION_ALL_ACCESS,
            moduleCodes.FF_CONSOLIDATION_VIEW_ONLY,
            moduleCodes.FF_SEA_ALL_ACCESS,
            moduleCodes.FF_SEA_VIEW_ONLY,
        ],
        sub: [
            {
                path: '/freight-forwarding/sea',
                text: 'SEA',
                breadcrumbText: 'Freight Forwarding: Sea',
                Content: () => Shipments('SEA'),
                show: true,
                moduleCodes: [
                    moduleCodes.FF_SEA_ALL_ACCESS,
                    moduleCodes.FF_SEA_VIEW_ONLY,
                ],
            },
            {
                path: '/freight-forwarding/air',
                text: 'AIR',
                breadcrumbText: 'Freight Forwarding: Air',
                Content: () => Shipments('AIR'),
                show: true,
                moduleCodes: [
                    moduleCodes.FF_AIR_ALL_ACCESS,
                    moduleCodes.FF_AIR_VIEW_ONLY,
                ],
            },
            {
                path: '/freight-forwarding/consolidation',
                text: 'Consolidation',
                breadcrumbText: 'Freight Forwarding: Consolidation',
                Content: () => Shipments('CONSOLIDATION'),
                show: true,
                moduleCodes: [
                    moduleCodes.FF_CONSOLIDATION_ALL_ACCESS,
                    moduleCodes.FF_CONSOLIDATION_VIEW_ONLY,
                ],
            },
        ],
    },
    {
        path: '/freight-forwarding/sea/create',
        text: 'SEA',
        breadcrumbText: 'Freight Forwarding: Sea',
        Content: () => CreateShipment('SEA'),
        moduleCodes: [moduleCodes.FF_SEA_ALL_ACCESS],
    },
    {
        path: '/freight-forwarding/air/create',
        text: 'AIR',
        breadcrumbText: 'Freight Forwarding: AIR',
        Content: () => CreateShipment('AIR'),
        moduleCodes: [moduleCodes.FF_AIR_ALL_ACCESS],
    },
    {
        path: '/freight-forwarding/consolidation/create',
        text: 'CONSOLIDATION',
        breadcrumbText: 'Freight Forwarding: Consolidation',
        Content: () => CreateConsolidation(),
        moduleCodes: [moduleCodes.FF_CONSOLIDATION_ALL_ACCESS],
    },
    {
        path: '/freight-forwarding/details/:id',
        text: 'SHIPMENT DETAILS',
        breadcrumbText: 'Freight Forwarding: Details',
        Content: () => DetailShipment(),
        moduleCodes: [
            moduleCodes.FF_AIR_ALL_ACCESS,
            moduleCodes.FF_AIR_VIEW_ONLY,
            moduleCodes.FF_SEA_ALL_ACCESS,
            moduleCodes.FF_SEA_VIEW_ONLY,
        ],
    },
    {
        path: '/freight-forwarding/consolidation/details/:id',
        text: 'CONSOLIDATION DETAILS',
        breadcrumbText: 'Consolidation: Details',
        Content: () => DetailConsolidation(),
        moduleCodes: [
            moduleCodes.FF_CONSOLIDATION_VIEW_ONLY,
            moduleCodes.FF_CONSOLIDATION_ALL_ACCESS,
        ],
    },
    {
        path: 'booking-details',
        text: 'Booking',
        Content: () => FormBooking({ isPageDetails: true }),
        textTranslationCode: 'menu.bookings_details',
        moduleCodes: [
            moduleCodes.BOOKING_ALL_ACCESS,
            moduleCodes.BOOKING_VIEW_ONLY,
        ],
    },
    {
        path: 'organisation',
        text: 'Organisation',
        show: true,
        icon: 'ri-contacts-book-2-line',
        textTranslationCode: 'menu.organisation',
        expandable: true,
        moduleCodes: [
            moduleCodes.ORGANISATION_ALL_ACCESS,
            moduleCodes.ORGANISATION_VIEW_ONLY,
        ],
        sub: [
            {
                path: '/organisation',
                text: 'All Organisation',
                textTranslationCode: 'menu.organisation_all',
                Content: Organisation,
                show: true,
                moduleCodes: [
                    moduleCodes.ORGANISATION_ALL_ACCESS,
                    moduleCodes.ORGANISATION_VIEW_ONLY,
                ],
            },
            {
                path: '/contact-list',
                text: 'Contact List',
                textTranslationCode: 'menu.contact_list',
                Content: Contact,
                show: true,
                moduleCodes: [
                    moduleCodes.ORGANISATION_ALL_ACCESS,
                    moduleCodes.ORGANISATION_VIEW_ONLY,
                ],
            },
        ],
    },
    {
        path: '/chart-of-accounts',
        text: 'Chart of Accounts',
        Content: ChartOfAccount,
        show: false,
    },
    {
        path: '/chart-of-accounts/details/:id',
        text: 'Chart of Accounts',
        Content: ChartOfBalanceDetails,
        show: false,
    },
    {
        path: '/chart-of-accounts/journal-entry',
        text: 'Chart of Accounts',
        Content: CreateJurnal,
        show: false,
    },
    {
        path: '/chart-of-accounts/new-account',
        text: 'Chart of Accounts',
        Content: CreateAccount,
        show: false,
    },
    {
        path: '/chart-of-accounts/oppening-balance',
        text: 'Chart of Accounts',
        Content: OppeningBalance,
        show: false,
    },
    {
        path: '/cash-bank',
        text: 'Cash & Bank',
        Content: CashBank,
        show: true,
        icon: 'ri-bank-line',
    },
    {
        path: '/cash-bank/detail/:id',
        text: 'Cash & Bank Edit Account',
        Content: CashBankDetail,
        show: false,
    },
    {
        path: '/cash-bank-form/detail/:id',
        text: 'Cash & Bank Edit Account',
        Content: DetailCashAndBackForm,
        show: false,
    },
    {
        path: '/cash-bank/detail/:id/edit',
        text: 'Cash & Bank',
        Content: CashBankEditAccount,
        show: false,
    },
    {
        path: '/sales',
        text: 'Sales',
        Content: Sales,
        show: true,
        icon: 'ri-currency-line',
    },
    {
        path: '/expenses',
        text: 'Purchases & Expenses',
        Content: Expenses,
        show: true,
        icon: 'ri-file-list-3-line',
    },
    {
        path: '/sales/create-invoice',
        text: 'Sales',
        Content: CreateSales,
        icon: 'ri-book-line',
    },
    {
        path: '/sales/create-order',
        text: 'Sales',
        Content: CreateSales,
        icon: 'ri-book-line',
    },
    {
        path: '/sales/details/:id',
        text: 'Sales Detail',
        Content: DetailSales,
        icon: 'ri-book-line',
    },
    {
        path: '/cash-and-bank/create-receive',
        text: 'Cash & Bank',
        Content: CreateCashAndBank,
        icon: 'ri-book-line',
    },
    {
        path: '/cash-and-bank/create-pay',
        text: 'Cash & Bank',
        Content: CreateCashAndBank,
        icon: 'ri-book-line',
    },
    {
        path: '/cash-and-bank/create-internal',
        text: 'Cash & Bank',
        Content: CreateCashAndBankInternal,
        icon: 'ri-book-line',
    },
    {
        path: '/pruchases-and-expenses/create-order',
        text: 'Order',
        Content: CreatePurchasesAndExpenses,
        icon: 'ri-book-line',
    },
    {
        path: '/pruchases-and-expenses/create-invoice',
        text: 'Invoice',
        Content: CreatePurchasesAndExpenses,
        icon: 'ri-book-line',
    },
    {
        path: '/pruchases-and-expenses/detail/:id',
        text: 'Details',
        Content: DetailExpenses,
        icon: 'ri-book-line',
    },
    {
        path: '/titipan',
        text: 'Titipan',
        Content: Titipan,
        show: true,
        icon: 'ri-safe-2-line',
    },
    {
        path: '/titipan/:id',
        text: 'Titipan',
        Content: TitipanDetails,
    },
    {
        path: '/titipan/payment-request',
        text: 'Payment Request',
        Content: PaymentRequest,
    },
    {
        path: '/titipan/top-up',
        text: 'Titipan',
        Content: () => TopUp('top-up'),
    },
    {
        path: '/titipan/withdraw',
        text: 'Titipan',
        Content: () => TopUp('withdraw'),
    },
    {
        path: '/titipan/payment-request/:id',
        text: 'Payment details',
        Content: () => PaymentDetails(),
    },
    {
        path: 'organisation-detail/:id',
        text: 'Organisation Details',
        Content: OrganisationDetail,
        textTranslationCode: 'menu.organisation_detail',
        moduleCodes: [
            moduleCodes.ORGANISATION_ALL_ACCESS,
            moduleCodes.ORGANISATION_VIEW_ONLY,
        ],
    },
    {
        path: 'create-organisation',
        text: 'Organisation ',
        Content: CreateOrganisation,
        textTranslationCode: 'menu.organisation_create',
        moduleCodes: [moduleCodes.ORGANISATION_ALL_ACCESS],
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        textTranslationCode: 'menu.manage_user',
        Content: UserAccess,
        show: true,
        icon: 'ri-user-line',
        moduleCodes: [
            moduleCodes.MANAGE_USER_ALL_ACCESS,
            moduleCodes.MANAGE_USER_VIEW_ONLY,
        ],
    },
    {
        path: '/manage-users/details/:id',
        text: 'User Details',
        textTranslationCode: 'menu.manage_user_details',
        Content: UserAccessDetail,
        moduleCodes: [
            moduleCodes.MANAGE_USER_ALL_ACCESS,
            moduleCodes.MANAGE_USER_VIEW_ONLY,
        ],
    },
    // {
    //     path: '/profile',
    //     text: 'Profile',
    //     Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
    // },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => UserAccessForm({ isNew: true, isProfilePage: false }),
        moduleCodes: [moduleCodes.MANAGE_USER_ALL_ACCESS],
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: false }),
        moduleCodes: [moduleCodes.MANAGE_USER_ALL_ACCESS],
    },
    {
        path: '/settings',
        text: 'Settings',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
        textTranslationCode: 'menu.settings',
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'experiment',
        text: 'Experiment Page',
        Content: Experiment,
    },
    {
        path: 'charge-code',
        text: 'Charge Code',
        Content: ChargeCode,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'product-category',
        text: 'Product Category',
        Content: ProductCategory,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'hs-code',
        text: 'HS Code',
        Content: HSCode,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'unlocodes',
        text: 'Unlocodes',
        Content: Unlocodes,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'service-rate',
        text: 'Service Rate',
        Content: ServiceRate,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
    {
        path: 'shipping-rate',
        text: 'Shipping Rate',
        Content: ShippingRate,
        moduleCodes: [
            moduleCodes.SETTINGS_ALL_ACCESS,
            moduleCodes.SETTINGS_VIEW_ONLY,
        ],
    },
]
