import { IMeta3 } from '@components/pagination/pagination.interface'
import { ICashBankSummary } from './../../repository/interface/cash-bank.interface'

export const cashBankSummaryData: ICashBankSummary[] = [
    {
        account_code: 'ACC-001',
        account_name: 'Cash',
        currency: 'USD',
        balance_statement: 10000,
        balance_jurnal: 9800,
    },
    {
        account_code: 'ACC-002',
        account_name: 'Bank',
        currency: 'EUR',
        balance_statement: 15000,
        balance_jurnal: 14900,
    },
    {
        account_code: 'ACC-003',
        account_name: 'Accounts Receivable',
        currency: 'IDR',
        balance_statement: 20000000,
        balance_jurnal: 19980000,
    },
    {
        account_code: 'ACC-004',
        account_name: 'Accounts Payable',
        currency: 'USD',
        balance_statement: 5000,
        balance_jurnal: 4900,
    },
    {
        account_code: 'ACC-005',
        account_name: 'Revenue',
        currency: 'GBP',
        balance_statement: 30000,
        balance_jurnal: 29500,
    },
    {
        account_code: 'ACC-006',
        account_name: 'Expenses',
        currency: 'JPY',
        balance_statement: 5000000,
        balance_jurnal: 4995000,
    },
    {
        account_code: 'ACC-007',
        account_name: 'Inventory',
        currency: 'SGD',
        balance_statement: 12000,
        balance_jurnal: 11800,
    },
    {
        account_code: 'ACC-008',
        account_name: 'Fixed Assets',
        currency: 'AUD',
        balance_statement: 25000,
        balance_jurnal: 24900,
    },
    {
        account_code: 'ACC-009',
        account_name: 'Equity',
        currency: 'CAD',
        balance_statement: 40000,
        balance_jurnal: 39800,
    },
    {
        account_code: 'ACC-010',
        account_name: 'Depreciation',
        currency: 'CHF',
        balance_statement: 7000,
        balance_jurnal: 6800,
    },
]

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
