import { useFormik } from 'formik'
import { useFormProductValidation } from './form-product.validation'
import { IFormProduct } from './form-product.interface'

const useFormProduct = () => {
    const formProductValidation = useFormProductValidation()
    const formik = useFormik<IFormProduct>({
        validationSchema: formProductValidation.schema,
        validateOnChange: true,
        initialValues: formProductValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormProduct

