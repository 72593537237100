import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import useFilter from './filter.service'
import { IFetchParams } from 'common/common.interface'
import FormInput from '@components/form-input/form-input.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'

const Filter = ({
    onFilterSubmit,
    isLoading,
}: {
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { formik, dropdownService } = useFilter(onFilterSubmit)
    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                <div className=" w-full">
                    <FormDropdown
                        name="status"
                        options={[
                            { label: 'Open', value: 'open' },
                            { label: 'Overdue', value: 'overdue' },
                            { label: 'Paid', value: 'paid' },
                            { label: 'Closed', value: 'closed' },
                            { label: 'Overpayment', value: 'overpayment' },
                        ]}
                        label={'COLUMN OPTION'}
                        useUppercaseLabel={true}
                        placeholder={'All Column'}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
                <div className="-mx-4 border-b border-gray-40 my-1"></div>
                <div className="flex gap-2 mt-1">
                    <Button
                        onClick={() => {
                            formik.submitForm()
                        }}
                        isLoading={isLoading}
                        label={'RESET'}
                        useUpperCase={true}
                        className="w-full"
                        variant="default"
                    />
                    <Button
                        onClick={() => {
                            formik.resetForm()
                        }}
                        isLoading={isLoading}
                        label={'SAVE FILTER'}
                        useUpperCase={true}
                        className="w-full"
                        variant="brand"
                    />
                </div>
            </FormikProvider>
        </div>
    )
}

export default Filter
