import useFileUpload from "./file-upload.service";

interface FileUploadProps {
    onFileUpload: (file: File) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload }) => {
    const { selectedFile, handleFileChange, handleDrop, } = useFileUpload({ onFileUpload })
    const convertToKB = (bytes: number) => (bytes / 1024).toFixed(2);

    return (
        <div className="w-full">
            <input type="file" id="fileInput" onChange={handleFileChange} accept=".png, .jpg, .jpeg, .pdf, .xls" hidden />
            <div className="flex">
                <label htmlFor="fileInput" className="min-w-fit  font-bold text-size-M border-[1px] rounded-s-md px-5 py-[10px] cursor-pointer themes-border-brand-v5 themes-text-brand-v5 themes-bg-hover-brand-v5">
                    BROWSE FILE
                </label>
                <div className="flex items-center border-[1px] rounded-e-md w-full pl-2 text-size-S themes-text-gray-v3 themes-bg-gray-v1">
                    {selectedFile ? selectedFile.name : ' No file choosen'}
                </div>
            </div>
            <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                className="flex flex-col justify-center items-center mt-2 themes-bg-brand-v1 rounded-md border-[1px] border-dashed themes-border-brand-v3 h-[150px] cursor-pointer"
            >
                {selectedFile ? (
                    <>
                        <i className="ri-file-line text-size-L mb-1 themes-text-brand-v5" />
                        <div className="text-size-M themes-text-gray-v6 mb-1">{selectedFile.name}</div>
                        <div className="text-size-XS themes-text-gray-v5">{convertToKB(selectedFile.size)} KB</div>
                    </>
                ) : (
                    <>
                        <i className="ri-upload-line text-size-L mb-1" />
                        <div className="text-size-M themes-text-gray-v6 mb-1">Click to upload or drag and drop</div>
                        <div className="text-size-XS themes-text-gray-v5">PNG, JPG, PDF or Xls (MAX. 5MB)</div>
                    </>
                )}

            </div>
        </div >
    );
};

export default FileUpload;
