import Button from '@components/button/button.component'
import Spinner from '@components/spinner/spinner.component'
import { IShipment } from 'repository/interface/shipment.interface'
import { shipmentDetailTabs } from './detail-shipment.static'
import { ITabItem } from '@components/tab/tab.interface'
import Tab from '@components/tab/tab.component'
import React from 'react'
import Dropdown from '@components/dropdown/dropdown.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const DetailShipmentRight = ({
    isDetailLoading,
    shipmentDetailData,
    selectedTabItems,
    content,
    isSubmitLoading,
    setSelectedTabItems,
    submitForm,
    resetForm,
    changeShipmentStatus,
    shipmentStatus,
}: {
    isDetailLoading: boolean
    shipmentDetailData: IShipment | undefined
    selectedTabItems: ITabItem
    content: JSX.Element
    isSubmitLoading: boolean
    shipmentStatus: string
    setSelectedTabItems: (value: ITabItem) => void
    submitForm: () => void
    resetForm: () => void
    changeShipmentStatus: (value: string) => void
}) => {
    const dropdownService = useDropdown()
    return (
        <div className="flex-grow rounded-[10px] pt-4 !px-0 !pb-0 sm:w-full themes-bg-white">
            {isDetailLoading && (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner />
                </div>
            )}

            {/* Content */}
            <div
                className={`${isDetailLoading ? 'hidden' : ''} h-full flex-grow flex flex-col sm:w-full`}
            >
                <div className="flex flex-wrap gap-2 justify-between items-center px-4">
                    <div>
                        <div className="flex gap-2">
                            <div className="">
                                <div className="text-size-M font-bold">
                                    {(
                                        shipmentDetailData?.consignee?.name ??
                                        'CONSIGNEE NAME'
                                    ).toUpperCase()}
                                </div>
                                <div className="text-size-XS themes-text-gray-v5">
                                    SHIPMENT ID
                                    {(
                                        shipmentDetailData?.shipment_id ?? '-'
                                    ).toUpperCase()}
                                </div>
                            </div>
                            <Dropdown
                                parentDivClassname="min-w-[200px]"
                                options={dropdownService.shipmentStatusData}
                                onClick={(data) => {
                                    const dt = data as unknown as IDropdownItem
                                    changeShipmentStatus(dt.value as string)
                                }}
                                value={dropdownService.shipmentStatusData.find(
                                    (d) => {
                                        return d.value === shipmentStatus
                                    },
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex gap-2 min-h-[40px]">
                        {selectedTabItems.value === 'details' ? (
                            <>
                                <Button
                                    variant="red-inverse"
                                    label="DISCARD CHANGES"
                                    icon="ri-delete-bin-line"
                                    onClick={() => {
                                        resetForm()
                                    }}
                                />
                                <Button
                                    label="SAVE CHANGES"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        submitForm()
                                    }}
                                    isLoading={isSubmitLoading}
                                    variant="brand-inverse"
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="border-b mt-4"></div>
                <div className="border-b px-4 ">
                    <Tab
                        containerClassName="shipment-detail-tab"
                        items={shipmentDetailTabs}
                        selectedItem={selectedTabItems}
                        onChange={(value) => {
                            setSelectedTabItems(value)
                        }}
                    />
                </div>
                <div>{content}</div>
            </div>
        </div>
    )
}

export default DetailShipmentRight
