import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFAccount } from './account-form.interface'

export const useAccountValidation = () => {
    const schema = Yup.object<IFAccount>().shape({
        account_name: Yup.string().required(requiredMessage('Account Name')),
        account_code: Yup.string().required(requiredMessage('Account Code')),
        category: Yup.string().required(requiredMessage('Category')),
        currency: Yup.string().required(requiredMessage('Currency')),
        details: Yup.string().required(requiredMessage('Details')),
        account_tax: Yup.string().required(requiredMessage('Account Tax')),
        bank_name: Yup.string().required(requiredMessage('Bank Name')),
        bank_number: Yup.number().required(requiredMessage('Bank Number')),
        description: Yup.string().optional(),
        account_access: Yup.string().required(
            requiredMessage('Account Access'),
        ),
    })

    const generateInitialValue = () => {
        const initialValue: IFAccount = {
            account_name: '',
            account_code: '',
            category: '',
            currency: '',
            details: '',
            account_tax: '',
            bank_name: '',
            bank_number: 0,
            description: '',
            account_access: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
