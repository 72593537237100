import { IMeta3 } from '@components/pagination/pagination.interface'
import { IChartOfAccountDetails } from './details.interface'

export const ChartOfAccountDetailsData: IChartOfAccountDetails[] = [
    {
        date: '25/11/2024',
        person: 'PT GLOBAL UNGGUL MANDIRI',
        number: 'Bank Transfer #10003',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 50000,
        balance: 50000,
        message: 'Payment from ABCDE',
        attachments: 'Product_Return_Form_20230125.pdf',
    },
    {
        date: '26/11/2024',
        person: 'PT ALPHA BETA',
        number: 'Bank Transfer #10004',
        type: 'Transfer to account : Savings Account',
        received: 100000,
        spent: 0,
        balance: 150000,
        message: 'Payment received from PT ALPHA BETA',
        attachments: 'Invoice_20231126.pdf',
    },
    {
        date: '27/11/2024',
        person: 'PT GAMMA DELTA',
        number: 'Bank Transfer #10005',
        type: 'Transfer from account : Current Account',
        received: 0,
        spent: 20000,
        balance: 130000,
        message: 'Payment for services',
        attachments: 'Service_Agreement_20231127.pdf',
    },
    {
        date: '28/11/2024',
        person: 'PT EPSILON ZETA',
        number: 'Bank Transfer #10006',
        type: 'Transfer to account : Fixed Deposit',
        received: 50000,
        spent: 0,
        balance: 180000,
        message: 'Partial payment received',
        attachments: 'Payment_Receipt_20231128.pdf',
    },
    {
        date: '29/11/2024',
        person: 'PT THETA IOTA',
        number: 'Bank Transfer #10007',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 30000,
        balance: 150000,
        message: 'Payment for goods',
        attachments: 'Goods_Invoice_20231129.pdf',
    },
    {
        date: '30/11/2024',
        person: 'PT KAPPA LAMBDA',
        number: 'Bank Transfer #10008',
        type: 'Transfer to account : Investment Account',
        received: 70000,
        spent: 0,
        balance: 220000,
        message: 'Advance payment received',
        attachments: 'Advance_Payment_20231130.pdf',
    },
    {
        date: '01/12/2024',
        person: 'PT MU NU',
        number: 'Bank Transfer #10009',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 40000,
        balance: 180000,
        message: 'Payment for maintenance',
        attachments: 'Maintenance_Invoice_20231201.pdf',
    },
    {
        date: '02/12/2024',
        person: 'PT XI OMICRON',
        number: 'Bank Transfer #10010',
        type: 'Transfer to account : Bank Account',
        received: 60000,
        spent: 0,
        balance: 240000,
        message: 'Payment received for project',
        attachments: 'Project_Invoice_20231202.pdf',
    },
    {
        date: '03/12/2024',
        person: 'PT PI RHO',
        number: 'Bank Transfer #10011',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 25000,
        balance: 215000,
        message: 'Payment for utilities',
        attachments: 'Utilities_Bill_20231203.pdf',
    },
    {
        date: '04/12/2024',
        person: 'PT SIGMA TAU',
        number: 'Bank Transfer #10012',
        type: 'Transfer to account : Bank Account',
        received: 80000,
        spent: 0,
        balance: 295000,
        message: 'Final payment received',
        attachments: 'Final_Invoice_20231204.pdf',
    },
    {
        date: '05/12/2024',
        person: 'PT UPSILON PHI',
        number: 'Bank Transfer #10013',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 60000,
        balance: 235000,
        message: 'Payment for office supplies',
        attachments: 'Office_Supplies_Invoice_20231205.pdf',
    },
    {
        date: '06/12/2024',
        person: 'PT CHI PSI',
        number: 'Bank Transfer #10014',
        type: 'Transfer to account : Bank Account',
        received: 90000,
        spent: 0,
        balance: 325000,
        message: 'Payment received for consulting',
        attachments: 'Consulting_Invoice_20231206.pdf',
    },
    {
        date: '07/12/2024',
        person: 'PT OMEGA',
        number: 'Bank Transfer #10015',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 45000,
        balance: 280000,
        message: 'Payment for advertising',
        attachments: 'Advertising_Bill_20231207.pdf',
    },
    {
        date: '08/12/2024',
        person: 'PT ALPHA ZETA',
        number: 'Bank Transfer #10016',
        type: 'Transfer to account : Bank Account',
        received: 75000,
        spent: 0,
        balance: 355000,
        message: 'Payment received for training',
        attachments: 'Training_Invoice_20231208.pdf',
    },
    {
        date: '09/12/2024',
        person: 'PT BETA GAMMA',
        number: 'Bank Transfer #10017',
        type: 'Transfer from account : Bank Account',
        received: 0,
        spent: 50000,
        balance: 305000,
        message: 'Payment for equipment',
        attachments: 'Equipment_Invoice_20231209.pdf',
    },
]

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
