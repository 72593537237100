import { IMeta3 } from '@components/pagination/pagination.interface'
import { IAddFiles } from './add-files.interface'


export const addFilesDummy: IAddFiles[] = [
    {
        date: '2024-02-01',
        file_number: 'FN001',
        invoice_number: 'INV001',
        customer: 'Customer A',
        status: 'open',
        total: 1500,
        attach: 'file1.pdf'
    },
    {
        date: '2024-02-02',
        file_number: 'FN002',
        invoice_number: 'INV002',
        customer: 'Customer B',
        status: 'paid',
        total: 2000,
        attach: 'file2.pdf'
    },
    {
        date: '2024-02-03',
        file_number: 'FN003',
        invoice_number: 'INV003',
        customer: 'Customer C',
        status: 'partially paid',
        total: 1750,
        attach: 'file3.pdf'
    },
    {
        date: '2024-02-04',
        file_number: 'FN004',
        invoice_number: 'INV004',
        customer: 'Customer D',
        status: 'overdue',
        total: 1250,
        attach: 'file4.pdf'
    },
    {
        date: '2024-02-05',
        file_number: 'FN005',
        invoice_number: 'INV005',
        customer: 'Customer E',
        status: 'close',
        total: 3000,
        attach: 'file5.pdf'
    },
    {
        date: '2024-02-06',
        file_number: 'FN006',
        invoice_number: 'INV006',
        customer: 'Customer F',
        status: 'open',
        total: 2200,
        attach: 'file6.pdf'
    },
    {
        date: '2024-02-07',
        file_number: 'FN007',
        invoice_number: 'INV007',
        customer: 'Customer G',
        status: 'paid',
        total: 2750,
        attach: 'file7.pdf'
    },
    {
        date: '2024-02-08',
        file_number: 'FN008',
        invoice_number: 'INV008',
        customer: 'Customer H',
        status: 'partially paid',
        total: 1800,
        attach: 'file8.pdf'
    },
    {
        date: '2024-02-09',
        file_number: 'FN009',
        invoice_number: 'INV009',
        customer: 'Customer I',
        status: 'overdue',
        total: 2500,
        attach: 'file9.pdf'
    },
    {
        date: '2024-02-10',
        file_number: 'FN010',
        invoice_number: 'INV010',
        customer: 'Customer J',
        status: 'close',
        total: 1400,
        attach: 'file10.pdf'
    },
    {
        date: '2024-02-11',
        file_number: 'FN011',
        invoice_number: 'INV011',
        customer: 'Customer K',
        status: 'open',
        total: 1950,
        attach: 'file11.pdf'
    },
    {
        date: '2024-02-12',
        file_number: 'FN012',
        invoice_number: 'INV012',
        customer: 'Customer L',
        status: 'paid',
        total: 1600,
        attach: 'file12.pdf'
    },
    {
        date: '2024-02-13',
        file_number: 'FN013',
        invoice_number: 'INV013',
        customer: 'Customer M',
        status: 'partially paid',
        total: 2100,
        attach: 'file13.pdf'
    },
    {
        date: '2024-02-14',
        file_number: 'FN014',
        invoice_number: 'INV014',
        customer: 'Customer N',
        status: 'overdue',
        total: 2300,
        attach: 'file14.pdf'
    },
    {
        date: '2024-02-15',
        file_number: 'FN015',
        invoice_number: 'INV015',
        customer: 'Customer O',
        status: 'close',
        total: 2600,
        attach: 'file15.pdf'
    }
];

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
