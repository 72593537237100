import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IShippingRate } from './interface/shipping-rate.interface'
import { IFShippingRate } from 'form-validation/shipping-rate.validation'

export const getShippingRate = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.shippingRates}`
        const response = await fetch<
            IResponseData<IShippingRate[]>,
            IFetchParams
        >({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Shipping Rate',
            })
        }
        return undefined
    }
}

export const submitShippingRate = async (values: IFShippingRate) => {
    try {
        const { code, ...payload } = values
        let payloadFix
        if (
            payload.container_mode === 'SEA' &&
            payload.container_type.code === 'FCL'
        ) {
            const { container_type, ...payload2 } = payload
            payloadFix = { ...payload2 }
        } else {
            payloadFix = payload
        }
        const endpoint = `${endpoints.shippingRates}`
        await post<IResponseData<any>, IFShippingRate>({
            endpoint: endpoint,
            payload: payloadFix,
        })

        Toast({
            header: 'Submit Success',
            message: 'Shipping Rate is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Shipping Rate',
        })
        return undefined
    }
}

export const updateShippingRate = async (values: IFShippingRate) => {
    try {
        const { code, ...payload } = values
        let payloadFix
        if (
            payload.container_mode === 'SEA' &&
            payload.container_type.code === 'FCL'
        ) {
            const { container_type, ...payload2 } = payload
            payloadFix = { ...payload2 }
        } else {
            payloadFix = payload
        }
        const endpoint = `${endpoints.shippingRates}/${code}`
        await put<IResponseData<any>, IFShippingRate>({
            endpoint: endpoint,
            payload: payloadFix,
        })

        Toast({
            header: 'Submit Success',
            message: 'Shipping Rate is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Shipping Rate',
        })
        return undefined
    }
}

export const deleteShippingRate = async (code: string) => {
    try {
        const endpoint = `${endpoints.shippingRates}/${code}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Shipping Rate is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Shipping Rate',
        })
        return undefined
    }
}
