import { ITabItem } from '@components/tab/tab.interface'

export const consolidationDetailTabs: ITabItem[] = [
    {
        label: 'Details',
        key: 'detail',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'List Shipment',
        key: 'list_shipment',
        value: 'list_shipment',
        className: 'brand',
    },
    {
        label: 'Containers',
        key: 'containers',
        value: 'containers',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        key: 'pack_lines',
        value: 'pack_lines',
        className: 'brand',
    },
    {
        label: 'Notes',
        key: 'notes',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'eDocumentation',
        key: 'eDocumentation',
        value: 'eDocumentation',
        className: 'brand',
    },
]
