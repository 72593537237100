import Button from "@components/button/button.component"
import DatePicker from "@components/date-picker/date-picker.component"
import Table from "@components/table/table.component"
import { IChartOfAccountDetails } from "./details.interface"
import { ChartOfAccountDetailsHeader, metaDummyDetails } from "./details.static"
import { ChartOfAccountDetailsData } from "./details.dummy"
import { useNavigate } from "react-router-dom"

const ChartOfBalanceDetails = () => {
    const navigate = useNavigate()
    return (
        <div className="content-full-height container-global flex flex-col">
            <div className='flex justify-start items-center'>
                <div className='flex items-center justify-between gap-2 p-4 border-b-[1px] w-full'>
                    <div className="flex items-center gap-2 ">
                        <div>
                            <div className='font-bold text-size-S'>ACCOUNT - OTHER CURRENT ASSETS</div>
                            <div className='text-size-L font-bold themes-text-brand-v6'>(1-10300) Other receivables</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 flex justify-between items-center themes-bg-brand-v1">
                <div className="text-size-XL font-bold">ACCOUNT TRANSACTION</div>
                <div className="flex gap-3 items-center">
                    <div className="w-[300px]" >
                        <div className="font-semibold mb-3">Asset Details</div>
                        <DatePicker isRange={true} />
                    </div>
                    <Button
                        label="APPLY"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { }}
                    />
                </div>
            </div>
            <div className="flex-grow h-[200px]">
                <Table<IChartOfAccountDetails>
                    headers={ChartOfAccountDetailsHeader}
                    data={ChartOfAccountDetailsData}
                    loading={false}
                    meta={metaDummyDetails}
                    moduleTitle={'Chart of Account Details'}
                    usePagination={false}
                />
            </div>
            <div className="flex justify-between px-4 border-t-[1px] py-3">
                <Button
                    label="CANCEL"
                    variant="default"
                    className="w-[150px]"
                    onClick={() => { navigate(-1) }}
                />

                <Button
                    type="submit"
                    label={`edit Account`}
                    icon="ri-reset-left-line"
                    variant="brand"
                    className="w-[200px]"
                    useUpperCase
                    onClick={() => { }}
                />
            </div>
        </div>

    )
}

export default ChartOfBalanceDetails
