import { useState } from 'react'
import { ITitipanDetilsFilter } from '../titipan.interface'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

export const initialBookingFilter: ITitipanDetilsFilter = {
    date: '',

}

const useTopUp = () => {
    const [currency, setCurrency] = useState('IDR')
    const navigate = useNavigate()
    const formik = useFormik<ITitipanDetilsFilter>({
        initialValues: initialBookingFilter,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
        currency,
        setCurrency,
        navigate,
    }
}

export default useTopUp
