import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { FormikContextType, FormikProvider } from 'formik'
import ContactListForm from './contact/contact-list-form.component'
import useDropdown from 'common/dropdown/dropdown.service'
type DropdownProps = ReturnType<typeof useDropdown>
const CreateOrEditContactModal = ({
    modalService,
    formik,
    isCreate,
    dropdownService,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
    dropdownService: DropdownProps
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW CONTACT' : 'EDIT CONTACT'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>

                    <div className="p-3">
                        <ContactListForm
                            dropdownService={dropdownService}
                            formik={formik}
                        />
                    </div>

                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE CONTACT' : 'UPDATE CONTACT'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditContactModal
