import { FormikProvider } from "formik"
import useFormShareEmail from "./share-email.service"
import FormInput from "@components/form-input/form-input.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"
import FormTextarea from "@components/form-textarea/form-textarea.component"

const FormShareEmail = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormShareEmail()

    return (
        <FormikProvider value={formik}>
            <div className="flex flex-col justify-between">
                <div className="p-4">
                    <div className=" grid grid-cols-2 gap-2">
                        <div>
                            <div className="mb-1">Transaction No Details</div>
                            <FormInput
                                name="email"
                                placeholder="Example: name@email.com"
                            />
                            <div className="mb-1">CC</div>
                            <FormInput
                                name="cc"
                                placeholder="Example: name@email.com"
                            />
                            <div className="mb-1">Sender</div>
                            <div className="text-size-S mb-2">sandi@limajaricargo.com</div>
                            <div className="mb-1">Message</div>
                            <FormTextarea
                                name={'message'}
                                placeholder={'Message'}
                                parentDivClassName="mb-3 h-[200px]"
                            />
                        </div>
                        <div>
                            <div className="flex flex-col gap-3 mb-3">
                                <div className="text-size-M font-semibold">Preview Attachment</div>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <i className="ri-image-line themes-text-brand-v5 text-[40px]" />
                                        <div>
                                            <div>Product_Image1.jpg</div>
                                            <div>500 KB</div>
                                        </div>
                                    </div>
                                    <i className="ri-download-2-line text-[20px]" />
                                </div>
                            </div>
                            <div className="mb-1">Attach Document</div>
                            <div className="h-[300px] w-full themes-bg-gray-v5 rounded-md">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-2 px-4 border-t-[1px] py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { modalService.closeModalHandling() }}
                    />

                    <Button
                        type="submit"
                        label="SHARE VIA EMAIL"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </div>
        </FormikProvider>
    )
}
export default FormShareEmail