import { ITableColumn } from '@components/table/table.interface'
import { numberWithCommas } from '@services/common.service'
import { IChartOfAccountSummary } from './chart-of-accounts.interface'

export const ChartOfAccountSummaryHeader: ITableColumn<IChartOfAccountSummary>[] = [
    {
        label: 'Account Code',
        accessor: 'account_code',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return (
                <div className="px-2 text-center themes-bg-brand-v1 rounded themes-text-brand-v5 text-sm font-bold  flex justify-center items-center !h-[30px]">
                    {data}
                </div>
            )
        },
    },
    {
        label: 'Account Name',
        accessor: 'account_name',
        sort: true,
        width: 400,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'category',
        label: 'Category',
        customBuild(_, rowData) {
            return rowData?.category || '-'
        },
        width: 200,
    },
    {
        accessor: 'default_tax',
        label: 'Default Tax',
        customBuild(_, rowData) {
            return rowData?.default_tax || '-'
        },
    },
    {
        accessor: 'balance',
        label: 'Balance (in IDR)',
        customBuild(_, rowData) {
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return 'Rp. '+totalAmount
        },
    },
]
