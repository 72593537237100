import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    cashBankDataSelector,
    cashBankFetchParamSelector,
    cashBankTabItemSelector,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './cash-bank-details.slice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import useOverlay from '@components/overlay/overlay.service'

const useCashBank = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const fetchParam = useSelector(cashBankFetchParamSelector)
    const cashBanksData = useSelector(cashBankDataSelector)
    const selectedTabItem = useSelector(cashBankTabItemSelector)

    const dropdownService = useDropdown()

    const filterOverlay = useOverlay()

    // states
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        console.log(fParams)
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    const deleteData = async (orderId?: string) => {
        try {
            setDeleteLoading(true)
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    return {
        setTabItems,
        cashBanksData,
        loading,
        selectedTabItem,
        fetchParam,
        filterOverlay,
        deleteLoading,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        deleteData,
    }
}

export default useCashBank
