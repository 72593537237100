var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './progression-step-small.style.css';
import useProgressionStep from '@components/progression-step/progression-step.service';
var ProgressionStepSmall = function (_a) {
    var steps = _a.steps, onChange = _a.onChange, selectedItem = _a.selectedItem, _b = _a.complatedStep, complatedStep = _b === void 0 ? false : _b, _c = _a.disableBackClick, disableBackClick = _c === void 0 ? false : _c;
    var _d = useProgressionStep(steps, selectedItem), labels = _d.labels, activeIndex = _d.activeIndex, setActiveIndex = _d.setActiveIndex;
    return (_jsx(_Fragment, { children: steps === null || steps === void 0 ? void 0 : steps.map(function (step, idx) {
            // if current step is not first then the step before is active
            // based on the latest design
            var isActive = activeIndex === idx;
            if (idx < activeIndex) {
                isActive = true;
            }
            var classActive = !isActive
                ? 'themes-text-gray-v5'
                : 'themes-text-brand-v5 themes-border-brand-v5';
            var classCursor = disableBackClick ? '' : 'cursor-pointer';
            return (_jsxs("div", __assign({ className: "flex justify-center gap-2 ".concat(classActive, " ").concat(classCursor), onClick: function () {
                    if (isActive && disableBackClick) {
                        return;
                    }
                    if (complatedStep || isActive) {
                        setActiveIndex(idx);
                        onChange(step);
                    }
                } }, { children: [_jsx("div", __assign({ className: "flex justify-center items-center" }, { children: _jsxs(_Fragment, { children: [_jsx("i", { className: "\n                                        ".concat(step.icon, "\n                                        ").concat(isActive ? 'themes-border-brand-v5 ' : 'themes-border-gray-v5', " \n                                        text-size-SM sm:text-[12px] not-italic flex justify-center aspect-square border\n                                        rounded-full w-[2.3rem] items-center mr-3  \n                                    ") }), _jsx("span", __assign({ className: "text-size-S font-normal sm:text-[12px] truncate" }, { children: labels[idx] }))] }) })), idx + 1 === steps.length ? ('') : (_jsx("div", __assign({ className: "flex justify-center items-center" }, { children: _jsx("div", { className: "h-[2px] w-[30px] mr-3 sm:hidden ".concat(isActive ? 'themes-bg-brand-v5' : 'themes-bg-gray-v5') }) })))] }), idx));
        }) }));
};
export default ProgressionStepSmall;
