import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { IFetchParams } from 'common/common.interface'
import useShipmentFilter, {
    initialShipmentFilter,
} from './shipments-filter.service'

const ShipmentsFilter = ({
    isGenerateReport = false,
    onFilterSubmit,
    isLoading,
}: {
    isGenerateReport: boolean
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { t } = useTranslation()
    const { dropdown, formik } = useShipmentFilter(
        onFilterSubmit,
        isGenerateReport,
    )
    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                {isGenerateReport && (
                    <div className=" w-full">
                        <FormDropdown
                            name="fileExportType"
                            options={[
                                { label: 'XLS', value: 'xls' },
                                { label: 'PDF', value: 'pdf' },
                            ]}
                            label={'File Type'}
                            useUppercaseLabel={true}
                            placeholder={'Select File Type'}
                            required={true}
                        />
                    </div>
                )}
                {isGenerateReport && (
                    <div className="flex gap-4 w-full">
                        <FormDatePicker
                            name="dateRange"
                            isRange={true}
                            disableDays="future"
                        />
                    </div>
                )}
                {isGenerateReport && (
                    <div className="py-4 font-bold text-size-M w-full">
                        Filter Data
                    </div>
                )}

                <div className=" w-full">
                    <FormDropdown
                        name="console_category"
                        options={dropdown.consoleCategoryData}
                        label={'Category'}
                        useUppercaseLabel={true}
                        placeholder={'All Category'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="container"
                        options={dropdown.containerModeData}
                        label={'Container Mode'}
                        useUppercaseLabel={true}
                        placeholder={'All Container Mode'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="created_by"
                        options={dropdown.usersData}
                        label={'Created By'}
                        useUppercaseLabel={true}
                        placeholder={'All User'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>

                <div className="pb-4 font-bold text-size-M w-full">ETD</div>

                <div className="w-full mb-3">
                    <FormDatePicker
                        name="etd"
                        isRange={true}
                        disableDays="future"
                    />
                </div>

                {/* Button For Filter */}
                {!isGenerateReport && (
                    <div>
                        <div className="-mx-4 border-b border-gray-40 mt-1"></div>
                        <div className="w-full flex gap-4 pt-2">
                            <Button
                                onClick={async () => {
                                    formik.setValues(initialShipmentFilter)
                                }}
                                label={t('action.reset')}
                                useUpperCase={true}
                                className="w-1/4"
                                variant="default"
                            />
                            <Button
                                onClick={() => {
                                    formik.submitForm()
                                }}
                                label={t('action.apply', {
                                    value: t('action.filter'),
                                })}
                                useUpperCase={true}
                                className="w-3/4"
                                variant="brand"
                            />
                        </div>
                    </div>
                )}

                {/* Button For Filter */}
                {isGenerateReport && (
                    <div>
                        <div className="-mx-4 border-b border-gray-40 mt-1"></div>
                        <div className="w-full flex gap-4 pt-2">
                            <Button
                                onClick={() => {
                                    formik.submitForm()
                                }}
                                isLoading={isLoading}
                                label={t('action.generate_report')}
                                useUpperCase={true}
                                className="w-full"
                                variant="brand"
                            />
                        </div>
                    </div>
                )}
            </FormikProvider>
        </div>
    )
}

export default ShipmentsFilter
