import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import Button from '@components/button/button.component'
import useBookings from './bookings.service'
import { bookingSummaryHeader } from './bookings.static'
import { IBooking } from 'repository/interface/booking.interface'
import BookingFilterOverlay from './components/filter/booking-filter-overlay.component'
import BookingsExportModal from './components/filter/booking-export.component'

const Booking = () => {
    const {
        tabItems,
        bookingsMeta,
        bookingsData,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        exportModalService,
        exportLoading,
        loadData,
        updateStatus,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        exportLoadData,
        setExportLoading,
    } = useBookings()

    const GetQuoteButton = (rowData: IBooking, closeHandling: () => void) => (
        <div className="w-full">
            <Button
                onClick={() => {
                    const id = rowData.code
                    navigate('/inquiry/' + id)
                    closeHandling()
                }}
                variant={'default'}
                label="Edit Inquiry"
                icon="ri-edit-line"
            />
        </div>
    )

    const rowOptionComponent = (
        rowData: IBooking,
        closeHandling: () => void,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                    const id = rowData.code
                    navigate('/inquiry/' + id)
                    closeHandling()
                }}
                className="!w-full border-none text-left"
                variant={'default'}
                label="Edit Inquiry"
                icon="ri-edit-line"
            />
            {rowData.status === 'OPEN' && (
                <Button
                    onClick={() => {
                        updateStatus(rowData, 'CANCELED')
                        closeHandling()
                    }}
                    className="!w-full border-none text-left themes-text-red-v3"
                    variant={'default'}
                    label="Cancel Inquiry"
                    icon="ri-close-line"
                />
            )}
            {/* {rowData.status === 'CONFIRMED' && (
                <Button
                    onClick={() => {
                        updateStatus(rowData, 'CLOSED')
                        closeHandling()
                    }}
                    className="!w-full border-none text-left themes-text-red-v3"
                    variant={'default'}
                    label="Close Inqury"
                    // icon="ri-close-line"
                />
            )} */}
        </div>
    )
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Inquiry ID '}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <BookingFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    // handleFilter(data)
                                    loadData({ ...fetchParam, ...data })
                                    return
                                }}
                            />
                        ),
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/inquiry/create')
                        },
                        label: 'CREATE INQUIRY',
                        className: '!text-size-S',
                    }}
                />

                <Table<IBooking>
                    headers={bookingSummaryHeader}
                    data={bookingsData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta2={bookingsMeta}
                    moduleTitle={'Inquiry'}
                    enableExport={true}
                    exportModalService={exportModalService}
                    additionalButtonBottom={
                        <Button
                            onClick={() => {
                                navigate('/new-quote/')
                            }}
                            variant={'default'}
                            label="GET A QUOTE"
                            className="w-36"
                        />
                    }
                    rowOptionComponent={rowOptionComponent}
                />
            </div>

            <BookingsExportModal
                modalService={exportModalService}
                onSubmit={async (data) => {
                    setExportLoading(true)
                    await exportLoadData(data)
                    setExportLoading(false)
                    exportModalService.closeModalHandling()
                }}
                isLoading={exportLoading}
            />
        </div>
    )
}

export default Booking
