const JurnalReport = () => {
    const dataDummy = [
        {
            account: '5-50000 - Cost of Sales',
            debit: 'Rp 748.921.840,00',
            credit: 'Rp 0,00'
        },
        {
            account: '1-10100 - Account Receiveable',
            debit: 'Rp 16.453.000,00',
            credit: 'Rp 0,00'
        },
        {
            account: '1-10200 - Inventory',
            debit: 'Rp 0,00',
            credit: 'Rp 748.921.840,00'
        },
        {
            account: '4-40000 - Revenues',
            debit: 'Rp 748.921.840,00',
            credit: 'Rp 16.453.000,00'
        }
    ]

    return (
        <div className="flex flex-col justify-between themes-bg-brand-v1 pb-20 rounded-b-md">
            <div className="grid grid-cols-2 p-4 text-size-S font-semibold themes-bg-gray-v2">
                <div>ACCOUNT</div>
                <div className="grid grid-cols-2">
                    <div className="text-right">DEBIT</div>
                    <div className="text-right">CREADIT</div>
                </div>
            </div>
            {dataDummy.map((item, idx) => {
                return (
                    <div key={`item-${idx}`} className="grid grid-cols-2 p-4 text-size-S  border-b-[1px] themes-bg-white">
                        <div className="font-semibold">{item.account} <i className="ri-external-link-line themes-text-gray-v4 cursor-pointer themes-text-hover-brand-v5" /></div>
                        <div className="grid grid-cols-2">
                            <div className="text-right">{item.debit}</div>
                            <div className="text-right">{item.credit}</div>
                        </div>
                    </div>
                )
            })}
            <div className="grid grid-cols-2 p-4 text-size-S font-bold border-b-[1px] themes-bg-white">
                <div>TOTAL</div>
                <div className="grid grid-cols-2">
                    <div className="text-right">Rp. 765.374.840,00</div>
                    <div className="text-right">Rp. 765.374.840,00</div>
                </div>
            </div>
            <div className="themes-text-gray-v7 text-size-S px-4 py-2 themes-bg-white">
                1 (in USD) = Rp. 16.453,00 (in IDR) rate by : <span className="font-bold">Bank Indonesia</span>
            </div>
        </div>
    )
}
export default JurnalReport