import { FormikProvider } from "formik"
import useFormContact from "./form-contact.service"
import FormInput from "@components/form-input/form-input.component"
import FormTextarea from "@components/form-textarea/form-textarea.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"
import FormCheckbox from "@components/form-checkbox/form-checkbox.component"

const FormContact = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormContact()
    return (
        <FormikProvider value={formik}>
            <div className="h-[calc(100vh-10rem)] flex flex-col justify-between">
                <div className="p-4">
                    <div className="mb-3">Customer</div>
                    <div>
                        <FormInput
                            name="name"
                            label="NAME"
                            placeholder="Enter name here"
                            required
                        />
                        <FormInput
                            name="email"
                            label="EMAIL"
                            placeholder="Enter email here"
                            required
                        />
                        <FormInput
                            name="telp"
                            label="TELEPHONE NUMBER"
                            placeholder="Enter telephone here"
                            required
                        />
                        <FormTextarea
                            name={'billing_address'}
                            label={'BILLING ADDRESS'}
                            placeholder={'Add Billing Address'}
                            className="!h-[94px] mb-2"
                        />
                        <FormTextarea
                            name={'shipping_address'}
                            label={'SHIPPING ADDRESS'}
                            placeholder={'Add Shipping Address'}
                            className="!h-[94px] mb-2"
                        />
                        <FormCheckbox
                            name={'same_address'}
                            label="Same with billing address"
                            parentDivClassName="!mb-0"
                            classNameLabel='text-size-M !font-normal'
                        />
                    </div>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { modalService.closeModalHandling() }}
                    />

                    <Button
                        type="submit"
                        label="CREATE ASSET"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </div>
        </FormikProvider>
    )
}
export default FormContact