import { IMeta3 } from '@components/pagination/pagination.interface'
import { ICashBankDetailSummary } from '../../../repository/interface/cash-bank-details.interface'

export const cashBankSummaryData: ICashBankDetailSummary[] = [
    {
        created: '2024-02-01T10:30:00Z',
        person: 'John Doe',
        description: 'Payment received from client',
        received_balance: 5000,
        spent_balance: 0,
        balance: 5000,
        message: 'Invoice #12345 paid',
        attach: 'receipt_12345.pdf',
    },
    {
        created: '2024-02-02T15:00:00Z',
        person: 'Jane Smith',
        description: 'Office rent payment',
        received_balance: 0,
        spent_balance: 2000,
        balance: 3000,
        message: 'Paid February office rent',
    },
    {
        created: '2024-02-03T09:15:00Z',
        person: 'Alice Johnson',
        description: 'Client refund',
        received_balance: 0,
        spent_balance: 1500,
        balance: 1500,
        message: 'Refund for order #7890',
        attach: 'refund_7890.pdf',
    },
    {
        created: '2024-02-04T14:45:00Z',
        person: 'Bob Williams',
        description: 'Freelancer payment',
        received_balance: 0,
        spent_balance: 800,
        balance: 700,
        message: 'Paid for design services',
    },
    {
        created: '2024-02-05T11:20:00Z',
        person: 'Charlie Brown',
        description: 'Salary deposit',
        received_balance: 4000,
        spent_balance: 0,
        balance: 4700,
        message: 'February salary credited',
        attach: 'salary_feb.pdf',
    },
    {
        created: '2024-02-06T16:10:00Z',
        person: 'Daniel Green',
        description: 'Software subscription',
        received_balance: 0,
        spent_balance: 500,
        balance: 4200,
        message: 'Annual subscription paid',
    },
    {
        created: '2024-02-07T08:30:00Z',
        person: 'Eva White',
        description: 'Received investment',
        received_balance: 10000,
        spent_balance: 0,
        balance: 14200,
        message: 'Angel investor funding',
        attach: 'investment_agreement.pdf',
    },
    {
        created: '2024-02-08T12:50:00Z',
        person: 'Frank Martin',
        description: 'Office supplies purchase',
        received_balance: 0,
        spent_balance: 350,
        balance: 13850,
        message: 'Purchased printer ink and paper',
    },
    {
        created: '2024-02-09T18:05:00Z',
        person: 'Grace Adams',
        description: 'Customer payment and for waiting user customer',
        received_balance: 7500,
        spent_balance: 0,
        balance: 21350,
        message: 'Received payment for project X and for project Y',
        attach: 'invoice_5678.pdf',
    },
    {
        created: '2024-02-10T09:40:00Z',
        person: 'Henry Ford',
        description: 'Business trip expenses',
        received_balance: 0,
        spent_balance: 1200,
        balance: 20150,
        message: 'Flight and accommodation paid',
    },
]

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
