import { FormikProvider } from "formik"
import useFormProduct from "./form-product.service"
import FormInput from "@components/form-input/form-input.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"
import FormCheckbox from "@components/form-checkbox/form-checkbox.component"
import FormDropdown from "@components/form-dropdown/form-dropdown.component"
import { CurrencyItem } from "../create-purchases-and-expenses.dummy"

const FormProduct = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormProduct()
    return (
        <FormikProvider value={formik}>
            <div className="p-4 h-[calc(100vh-14rem)] overflow-auto ">
                <div>
                    <FormInput
                        name="name"
                        label="PRODUCT NAME"
                        placeholder="Enter Product here"
                    />
                    <div className="grid grid-cols-2 gap-1">
                        <FormInput
                            name="product_code"
                            label="PRODUCT CODE"
                            disabled
                        />
                        <FormDropdown
                            label="unit"
                            placeholder="Select unit"
                            name={'unit'}
                            options={[]}
                            disabled={false}
                        />
                    </div>
                    <FormCheckbox
                        name={'this_buy'}
                        label="I buy this product"
                        classNameLabel='text-size-M !font-normal mb-1'
                    />
                    <div className="grid grid-cols-2 gap-1">
                        <FormDropdown
                            disabled={false}
                            label="CURRENCY"
                            placeholder="Select Currency"
                            name={'buy.currency'}
                            options={CurrencyItem}
                        />
                        <FormInput
                            name="buy.unit_price"
                            label="UNIT PRICE"
                            descriptionValue={'Rp'}
                        />
                    </div>
                    <FormDropdown
                        disabled={false}
                        label="BUY ACCOUNT"
                        placeholder="Select Account"
                        name={'buy.account'}
                        options={[]}
                    />
                    <div className="grid grid-cols-2 gap-1">
                        <FormDropdown
                            disabled={false}
                            label="BUY TAX"
                            placeholder="Select Tax"
                            name={'buy.tax'}
                            options={CurrencyItem}
                        />
                    </div>
                    <FormCheckbox
                        name={'this_sell'}
                        label="I sell this product"
                        classNameLabel='text-size-M !font-normal mb-1'
                    />
                    <div className="grid grid-cols-2 gap-1">
                        <FormDropdown
                            disabled={false}
                            label="CURRENCY"
                            placeholder="Select Currency"
                            name={'sell.currency'}
                            options={CurrencyItem}
                        />
                        <FormInput
                            name="sell.unit_price"
                            label="UNIT PRICE"
                            descriptionValue={'Rp'}
                        />
                    </div>
                    <FormDropdown
                        disabled={false}
                        label="SELL ACCOUNT"
                        placeholder="Select Account"
                        name={'sell.account'}
                        options={[]}
                    />
                    <div className="grid grid-cols-2 gap-1">
                        <FormDropdown
                            disabled={false}
                            label="SELL TAX"
                            placeholder="Select Tax"
                            name={'sell.tax'}
                            options={CurrencyItem}
                        />
                    </div>
                    <div className="border-y-[1px] py-4">
                        <FormDropdown
                            disabled={false}
                            label="MINIMUM STOCK LIMIT"
                            placeholder="Select Payment Method"
                            name={'minimum_stock_limit'}
                            options={CurrencyItem}
                        />
                        <div className="pb-2 text-size-XS themes-text-gray-v6">
                            Opening stock quantity can be recorded using sock adjustment
                        </div>
                        <FormCheckbox
                            name={'monitor_inventory'}
                            label="Monitor intentory of goods"
                            classNameLabel='text-size-M !font-normal mb-1'
                        />
                        <FormDropdown
                            disabled={false}
                            label="DEFAULT INVENTORY ACCOUNT"
                            placeholder="Select Exchange Rate"
                            name={'default_inventory_account'}
                            options={CurrencyItem}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-between px-4 border-t-[1px] py-3">
                <Button
                    label="CENCEL"
                    variant="default"
                    className="w-[150px]"
                    onClick={() => { modalService.closeModalHandling() }}
                />

                <Button
                    type="submit"
                    label="CREATE ASSET"
                    variant="brand"
                    className="w-[150px]"
                    onClick={() => { formik.submitForm() }}
                />
            </div>
        </FormikProvider>
    )
}
export default FormProduct