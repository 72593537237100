import Table from '@components/table/table.component'

import { titipanSummaryHeader } from './titipan-details.static'
import Button from '@components/button/button.component'
import { ITitipanDetails } from '../titipan.interface'
import useTitipanDetails from './titipan-details.service'
import { dummyTitipanDetails } from '../titipan.dummy'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { FormikProvider } from 'formik'
import FilterOverlay from '../components/filter/filter-overlay.component'

const TitipanDetails = () => {
    const {
        loading,
        formik,
        tabData,
        filterOverlay,
        navigate,
        setTabData
    } = useTitipanDetails()

    const rowOptionComponent = (
        rowData: ITitipanDetails,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                }}
                className="!w-full border-none text-left"
                icon="ri-bank-card-line"
                label="Create Deposit Transaction"
            />
            <Button
                onClick={() => {
                }}
                className="!w-full border-none text-left themes-text-red-v3"
                icon="ri-close-line"
                label="Cancel Payment Request"
            />
        </div>
    )


    const TitipanDropdown = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S
                            cursor-pointer"
                >
                    CREATE TRANSACTION
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute left-0 mt-2 w-[200px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/titipan/top-up')}
                    >
                        Top Up
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/titipan/withdraw')}
                    >
                        Withdraw
                    </div>
                </div>
            </div>
        )
    }


    const DropdownMenu = ({ label, items }: { label: string, items: string[] }) => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-hover-brand-v5 themes-bg-white border-[1px] px-4 themes-text-hover-white 
                            w-[200px] h-[38px] rounded-md flex justify-between items-center text-size-M 
                            cursor-pointer"
                >
                    {label}
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute bottom-full left-0 mb-2 w-[200px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    {items.map((item, index) => (
                        <div key={index} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                            {item}
                        </div>
                    ))}
                </div>
                {/* TODO between receive money doc or bit doc */}
                <div className="hidden">
                    {/* <PurchaseInvoiceDoc ref={componentRef} /> */}
                </div>
            </div>
        )
    }

    return (
        <div className="content-full-height">
            <div className='flex justify-between items-center py-2'>
                <div className='flex items-center gap-4  p-3'>
                    <i className="ri-arrow-left-line text-[20px] cursor-pointer" onClick={() => navigate(-1)} />
                    <div>
                        <div className='font-bold text-size-S'>DEPOSIT</div>
                        <div className='text-size-L font-bold themes-text-brand-v6'>JOHN STARK</div>
                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    <span className='text-size-S themes-text-gray-v6'>Last Transaction: 19/01/2025</span>
                    {TitipanDropdown()}
                </div>
            </div>

            <div className='flex justify-start gap-2'>
                <div onClick={() => setTabData('idr')} className={`cursor-pointer text-size-L font-semibold px-4 py-2 rounded-t-lg ${tabData === 'idr' ? 'themes-bg-white themes-text-brand-v5' : 'themes-bg-gray-v1 themes-text-gray-v6'} `}>
                    IDR BALANCE
                </div>
                <div onClick={() => setTabData('usd')} className={`cursor-pointer text-size-L font-semibold px-4 py-2 rounded-t-lg  ${tabData === 'usd' ? 'themes-bg-white themes-text-brand-v5' : 'themes-bg-gray-v1 themes-text-gray-v6'} `}>
                    USD BALANCE
                </div>
            </div>
            <div className='flex flex-col container-global !border-t-0 !rounded-t-none'>
                <div className='border-b px-4 pt-4'>
                    <FormikProvider value={formik}>
                        <div className='flex items-start gap-4 w-[500px]'>
                            <FormDatePicker
                                isRange={true}
                                name="date"
                                placeholder="date"
                                parentDivClassName="mb-4"
                                label="date"
                            />
                            <i className="ri-filter-2-line text-[30px] cursor-pointer mt-2" onClick={() => filterOverlay.toggleOverlayHandling()} />
                            <FilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    return
                                }}
                            />
                        </div>
                    </FormikProvider>
                </div>
                <div className='p-4 border-b w-full grid grid-cols-2 items-center'>
                    <div className='flex gap-4'>
                        <div>
                            <div className='text-size-XS font-semibold themes-text-gray-v6 mb-2'>Current Balance</div>
                            <div className='text-size-M themes-text-brand-v7'>Rp. 60.520.000</div>
                        </div>
                        <div>
                            <div className='text-size-XS font-semibold themes-text-gray-v6 mb-2'>Outstanding</div>
                            <div className='text-size-M themes-text-red-v3'>Rp 1.000.000</div>
                        </div>
                        <div>
                            <div className='text-size-XS font-semibold themes-text-gray-v6 mb-2'>Remaining Balance</div>
                            <div className='text-size-M themes-text-brand-v7'>Rp. 59.520.000</div>
                        </div>
                    </div>
                    <div className='text-size-M font-semibold themes-text-gray-v6'>
                        Opening Balance Rp. 1.000.000,00
                    </div>
                </div>
                <div className="flex flex-grow flex-col pt-0 h-[calc(100vh-28rem)] ">
                    <Table<ITitipanDetails>
                        headers={titipanSummaryHeader}
                        data={dummyTitipanDetails}
                        loading={loading}
                        moduleTitle={'Titipan Details'}
                        rowOptionComponent={(rowData) => (
                            rowOptionComponent(rowData)
                        )}
                        usePagination={false}
                    />
                </div>
                <div className="flex justify-end px-4 border-t-[1px] py-3">
                    <DropdownMenu label="Actions" items={['Adjustment', 'Money Exchange']} />
                    <DropdownMenu label="GENERATE REPORT" items={['All Balance', 'IDR Balance', 'USD Balance']} />
                </div>
            </div>
        </div>

    )
}

export default TitipanDetails
