import { FormikProvider } from "formik"
import useCreateSales from "./create-sales.service"
import FormDropdown from "@components/form-dropdown/form-dropdown.component"
import FormInput from "@components/form-input/form-input.component"
import FormTextarea from "@components/form-textarea/form-textarea.component"
import FormDatePicker from "@components/form-date-picker/form-date-picker.component"
import ToggleSwitch from "@components/toggle-switch/toggle-switch.component"
import SOLinePrice from "./line-price/line-price.component"
import FormCheckbox from "@components/form-checkbox/form-checkbox.component"
import Button from "@components/button/button.component"
import { CurrencyItem, CustomerItem, FileNumberItems, TermItem, TypeNumberItems, WithholdingTaxItems } from "./create-sales.dummy"
import FormMeasurementInput from "@components/form-measurement-input/form-measurement-input.component"
import FormContact from "./form-contact/form-contact.component"
import FormProduct from "./form-product/form-product.component"
import Modal from "@components/modal/modal.component"
import FormTax from "./form-tax/form-tax.component"
import FormTransactionNo from "./form-transaction-no/form-transaction-no.component"
import FormTerm from "./form-term/form-term.component"
import FileUpload from "component/file-uploud/file-uploud.component"
import ModalRight from "component/modal/modal.component"

const CreateSales = () => {
    const {
        formik,
        rate,
        togglePriceIncludeTax,
        optionsDiscount,
        modalServiceContact,
        modalServiceProduct,
        modalServiceTax,
        modalServiceTransactionNo,
        modalServiceTerm,
        getTypeCreate,
        navigate,
        setTogglePriceIncludeTax,
    } = useCreateSales()
    const { values } = formik

    const formatNumber = (value: string | number) => {
        const number =
            typeof value === "string"
                ? parseFloat(value.replace(/,/g, "").replace(/\./g, ".")) || 0
                : value || 0;

        return new Intl.NumberFormat("id-ID", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    };

    const currency = values.currency
    const total = formatNumber(values.total);
    const subTotal = formatNumber(values.sub_total);
    const discountPerLines = formatNumber(values.discount_per_lines);
    const additionalDiscount = formatNumber(values.total_additional_discount);
    const withholdingTax = formatNumber(values.withholding_tax.total_withholding_discount);
    const taxPerLine = values.tax_per_line;
    const balanceDue = formatNumber(values.balance_due);
    const ExchangeRate = formatNumber(rate)

    console.log(getTypeCreate)


    return (
        <div className="container-global content-full-height">
            <FormikProvider value={formik}>
                <div className="px-4 py-2">
                    <div className="themes-text-gray-v6 text-size-XS font-bold mb-1">SALES</div>
                    <div className="themes-text-brand-v6 text-size-L font-bold">CREATE SALES {getTypeCreate.toUpperCase()}</div>
                </div>
                <div className=" h-[calc(100vh-14.2rem)] overflow-auto">
                    <div className="grid grid-cols-3 gap-2 px-4 pt-2 themes-bg-brand-v1">
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Customer</div>
                            <div className="grid grid-cols-1 gap-2">
                                <div>
                                    <FormDropdown
                                        disabled={false}
                                        label="CUSTOMER"
                                        placeholder="Select Customer"
                                        name={'customer'}
                                        required
                                        options={CustomerItem}
                                        additionalButton={{
                                            title: '+ Add New Contact',
                                            className: "themes-bg-white themes-text-gray-v6 themes-bg-hover-brand-v3 text-size-S flex justify-center py-2 cursor-pointer border-[1px] rounded-md mt-1",
                                            onClick: () => {
                                                modalServiceContact.openModalHandling()
                                            }
                                        }}
                                    />
                                    <FormInput
                                        name="email"
                                        label="EMAIL"
                                        placeholder="Enter email here"
                                        required
                                    />
                                    <FormTextarea
                                        name={'billing_address'}
                                        label={'BILLING ADDRESS'}
                                        placeholder={'Add Billing Address'}
                                        className="!h-[94px] mb-2"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Shipping Information</div>
                            <div className="grid grid-cols-1 gap-2">
                                <FormDropdown
                                    disabled={false}
                                    label="TYPE NUMBER"
                                    placeholder="Select Type Number"
                                    name={'type_number'}
                                    required
                                    options={TypeNumberItems}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="FILE NUMBER"
                                    placeholder="Select File Number"
                                    name={'file_nummber'}
                                    required
                                    options={FileNumberItems}
                                />
                                <FormCheckbox
                                    name={'mark_as_deposit'}
                                    label="Mark as Titipan Transaction"
                                    parentDivClassName="!mb-0"
                                    classNameLabel='text-size-M !font-normal'
                                />
                            </div>
                        </div>
                        <div className="font-semibold flex text-size-XL pt-2 pl-2">
                            Total
                            <span className="ml-3 themes-text-brand-v7">{currency}. {total}</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-2 px-4 pt-2">
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Invoice Details</div>
                            <div>
                                <FormDatePicker
                                    name={'transaction_date'}
                                    isRange={false}
                                    label="TRANSACTION DATE"
                                    useUppercaseLabel
                                    placeholder="Select Date"
                                    parentDivClassName="mb-3"
                                />
                                <FormDatePicker
                                    name={'due_date'}
                                    isRange={false}
                                    label="DUE DATE"
                                    useUppercaseLabel
                                    placeholder="Select Date"
                                    parentDivClassName="mb-3"
                                />
                                <FormDropdown
                                    label="TERM"
                                    placeholder="Select term"
                                    name={'term'}
                                    options={TermItem}
                                    disabled={false}
                                    additionalButton={{
                                        title: '+ Add New Term',
                                        className: "themes-bg-white themes-text-gray-v6 themes-bg-hover-brand-v3 text-size-S flex justify-center py-2 cursor-pointer border-[1px] rounded-md mt-1",
                                        onClick: () => {
                                            modalServiceTerm.openModalHandling()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">File Information</div>
                            <div>
                                <FormInput
                                    name="transaction_no"
                                    label="TRANSACTION NO"
                                    placeholder="Auto Generated"
                                    disabled={false}
                                    icon="ri-settings-3-line"
                                    onIconClick={() => modalServiceTransactionNo.openModalHandling()}
                                    tooltipIcon={{
                                        text: 'tooltip',
                                        customText: (
                                            <div className='w-[230px] text-size-S'>
                                                <div className='mb-2'>
                                                    Transaction number will be generated automatically by system. Click here to setup transaction number.
                                                </div>
                                                <div>Estimated number: 10010</div>
                                            </div>
                                        ),
                                    }}
                                />
                                <FormInput
                                    name="customer_reference_no"
                                    label="CUSTOMER REFERENCE NO."
                                    placeholder="Input Customer Reference No."
                                    disabled={false}
                                />
                                <FormDropdown
                                    label="WAREHOUSE"
                                    placeholder="Select warehouse"
                                    name={'warehouse'}
                                    options={[]}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Attach Document</div>
                            <div>
                                <FileUpload onFileUpload={() => { }} />
                            </div>
                        </div>
                    </div>
                    <div className="px-4 flex justify-between pt-2">
                        <div className="flex gap-2">
                            <FormDropdown
                                disabled={false}
                                label="Add Artersik"
                                useUppercaseLabel
                                placeholder="Select Currency"
                                name={'currency'}
                                parentDivClassName="w-[230px]"
                                options={CurrencyItem}
                            />
                            {currency !== 'Rp' &&
                                <div>
                                    <div className="text-size-M font-semibold">Exchange Rate</div>
                                    <div className="text-size-S font-semibold themes-text-gray-v5">
                                        Bank Indonesia <span className=" ml-5 themes-text-gray-v9 font-normal">1 {currency} = Rp.{ExchangeRate}</span>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="flex items-center">
                            <div className="text-size-M min-w-fit mr-2">Price Include Tax</div>
                            <ToggleSwitch
                                initialOn={togglePriceIncludeTax}
                                onClick={() => { setTogglePriceIncludeTax(!togglePriceIncludeTax) }}
                            />
                        </div>
                    </div>
                    <div className="px-4 mb-4">
                        <div className="border-[1px] rounded-md themes-bg-brand-v1">
                            <SOLinePrice
                                formikProps={formik}
                                optionsDiscount={optionsDiscount}
                                modalServiceProduct={modalServiceProduct}
                                modalServiceTax={modalServiceTax}
                            />
                            <div className="flex justify-between  themes-bg-white border-t-[1px] rounded-md p-4">
                                <div className="w-[300px]">
                                    <div className="text-size-M font-semibold mb-4">Additional Information</div>
                                    <div className="flex flex-col gap-3">
                                        <FormTextarea
                                            name={'massage'}
                                            label={'MESSAGE'}
                                            placeholder={'Add massage'}
                                        />
                                        <FormTextarea
                                            name={'memo'}
                                            label={'MEMO'}
                                            placeholder={'Additional Notes'}
                                            additionalInfo="Internal Only"
                                        />
                                    </div>
                                </div>
                                <div className="w-[400px] flex flex-col gap-2">
                                    <div className="flex justify-between">
                                        <div className="text-size-S font-semibold themes-text-gray-v4">Sub Total</div>
                                        <div className="text-size-M font-semibold">{currency}. {subTotal}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="text-size-S font-semibold themes-text-gray-v4">Discount per lines</div>
                                        <div className="text-size-S">{currency}. {discountPerLines}</div>
                                    </div>
                                    <div className="flex justify-between mt-1">
                                        <FormMeasurementInput
                                            options={optionsDiscount}
                                            name={'additional_discount'}
                                            label="DISCOUNT"
                                            placeholder={'0,00'}
                                            parentDivClassName="w-[200px]"
                                            type='number'
                                        />
                                        <div className="text-size-S">{currency}. {additionalDiscount}</div>
                                    </div>
                                    {taxPerLine.map((item, idx) => {
                                        return <div className="flex justify-between" key={`${idx}-tax-item`}>
                                            <div className="text-size-M font-semibold themes-text-gray-v4">{item.name}</div>
                                            <div className="text-size-S">{currency}. {formatNumber(item.price)}</div>
                                        </div>
                                    })}
                                    <div className="flex justify-between">
                                        <div className="text-[18px] font-semibold themes-text-gray-v4">Total</div>
                                        <div className="text-[18px] font-semibold">{currency}. {total}</div>
                                    </div>
                                    {getTypeCreate === 'order' && (
                                        <>
                                            <div className="flex justify-between mt-1">
                                                <FormCheckbox
                                                    name={'withholding_tax.withholding_data'}
                                                    label="Withholding Tax"
                                                    parentDivClassName="!mb-0"
                                                    classNameLabel='text-size-M !font-normal'
                                                />
                                                <div className="text-size-S">{currency}. {withholdingTax}</div>
                                            </div>
                                            {values.withholding_tax.withholding_data && (
                                                <>
                                                    <div className="grid grid-cols-2 gap-2">
                                                        <FormMeasurementInput
                                                            options={optionsDiscount}
                                                            name={'withholding_tax.withholding_discount'}
                                                            placeholder={'0,00'}
                                                            type='number'
                                                        />
                                                        <FormDropdown
                                                            disabled={false}
                                                            placeholder="Select"
                                                            name={'withholding_tax.name_price'}
                                                            options={WithholdingTaxItems}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <div className="flex justify-between">
                                        <div className="text-[18px] font-semibold themes-text-gray-v4">Balance Due</div>
                                        <div className="text-[18px] font-semibold">{currency}. {balanceDue}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CANCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { navigate('/sales') }}
                    />

                    <Button
                        type="submit"
                        label={`CREATE SALES ${getTypeCreate.toUpperCase()}`}
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </FormikProvider>
            <ModalRight
                form={
                    <FormContact modalService={modalServiceContact} />
                }
                modalService={modalServiceContact}
                title={'NEW CONTACT'}
            />
            <ModalRight
                form={
                    <FormProduct modalService={modalServiceProduct} />
                }
                modalService={modalServiceProduct}
                title={'ADD PRODUCT'}
            />
            <Modal isModalOpen={modalServiceTax.isModalOpen}
                className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                            Create New Tax
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceTax.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormTax modalService={modalServiceTax} />
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalServiceTransactionNo.isModalOpen}
                className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                            Transaction Number Setting
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceTransactionNo.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormTransactionNo modalService={modalServiceTransactionNo} />
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalServiceTerm.isModalOpen}
                className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                            Add New Term
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceTerm.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormTerm modalService={modalServiceTerm} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CreateSales
