import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export const edocFormValidation: YUP.Schema<any> = YUP.object().shape({
    filename: YUP.string().required(requiredMessage('File Name')),
    document_type: YUP.string().required(requiredMessage('Document Type')),
    path: YUP.string().required(requiredMessage('Path')),
    description: YUP.string(),
})

export interface IFormEdoc {
    code?: string
    filename: string
    document_type: string
    path: string
    description?: string
}
export interface IFormEdocPayload {
    filename: string
    document_type: string
    path: string
    description?: string
}

// form edocr initial data
export const edocFormInitial: IFormEdoc = {
    filename: '',
    document_type: '',
    path: '',
    description: '',
}
