import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { FormikContextType, FormikProvider } from 'formik'
import NotesListForm from './notes/notes-form.component'
import useDropdown from 'common/dropdown/dropdown.service'
type DropdownProps = ReturnType<typeof useDropdown>

const CreateOrEditNoteModal = ({
    modalService,
    formik,
    isCreate,
    dropdownService,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
    dropdownService: DropdownProps
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW ADDRESS' : 'EDIT ADDRESS'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>
                    <NotesListForm
                        dropdownService={dropdownService}
                        formik={formik}
                    />
                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE ADDRESS' : 'UPDATE ADDRESS'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditNoteModal
