import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import Checkbox from '@components/checkbox/checkbox.component'
import { ISalesInvoice } from './sales.interface'
import { formatDate } from '@services/common.service'

export const tabItemStatic: ITabItem[] = [
    {
        label: 'Invoice',
        totalData: 0,
        key: 'status',
        value: 'INVOICE',
        className: 'brand',
    },
    {
        label: 'Order',
        totalData: 0,
        key: 'status',
        value: 'ORDER',
        className: 'brand',
    },
]

export const salesInvoiceSummaryHeader: ITableColumn<ISalesInvoice>[] = [
    {
        label: 'Date',
        accessor: 'date',
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Number',
        accessor: 'number',
    },
    {
        label: 'Customer',
        accessor: 'customer',
    },
    {
        label: 'Due Date',
        accessor: 'due_date',
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus} !h-[30px]`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        label: 'Deposit Payment',
        accessor: 'deposit_payment',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.deposit_payment}
                    disabled
                />
            )
        },
    },
    {
        label: 'Balance Due',
        accessor: 'balance_due',
    },
    {
        label: 'Total',
        accessor: 'total',
    },
    {
        label: 'Message',
        accessor: 'message',
    },
    {
        label: 'Attachment',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]

export const salesOrderSummaryHeader: ITableColumn<ISalesInvoice>[] = [
    {
        label: 'Date',
        accessor: 'date',
    },
    {
        label: 'Number',
        accessor: 'number',
    },
    {
        label: 'Customer',
        accessor: 'customer',
    },
    {
        label: 'Due Date',
        accessor: 'due_date',
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus}`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        label: 'Deposit Payment',
        accessor: 'deposit_payment',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.deposit_payment}
                    disabled
                />
            )
        },
    },
    {
        label: 'Balance Due',
        accessor: 'balance_due',
    },
    {
        label: 'Total',
        accessor: 'total',
    },
    {
        label: 'Message',
        accessor: 'message',
    },
    {
        label: 'Attachment',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]
