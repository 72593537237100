import Button from '@components/button/button.component'
import { ISalesInvoice, ISalesOrder } from '../../sales.interface'
import { IAddFiles } from 'pages/expenses/create-purchases-and-expenses/component/add-files/add-files.interface'

export const RowOptionInvoice = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: ISalesInvoice
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)


export const RowOptionAddFiles = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IAddFiles
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)

export const RowOptionOrder = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: ISalesOrder
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)
