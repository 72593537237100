import { useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@components/modal/modal.service'

interface ICreateAccount {
    account_name: string
    account_code: string
    category: string
    currency: string
    details: string
    default_tax: string
    bank_name: string
    bank_number: string
    description: string

}

export const initialBookingFilter: ICreateAccount = {
    account_name: '',
    account_code: '',
    category: '',
    currency: '',
    details: '',
    default_tax: '',
    bank_name: '',
    bank_number: '',
    description: '',

}

const useCreateAccount = () => {
    const navigate = useNavigate()
    const modalServiceBalance = useModal()
    const formik = useFormik<ICreateAccount>({
        initialValues: initialBookingFilter,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
        navigate,
        modalServiceBalance,
    }
}

export default useCreateAccount
