import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import {
    IRoleModule,
} from 'repository/interface/role.interface'
import { getRoleModule } from 'repository/role.repository'
import { useCreateRoleValidation } from './setting-role.validation'

interface IFormCreateRole {
    role: string
}

export const useCreateRole = () => {
    const [roleModuleData, setRoleModuleData] = useState<IRoleModule[]>([])

    const getModuleData = () => {
        const data = getRoleModule()
        if (!data) {
            setRoleModuleData([])
            return
        }
        setRoleModuleData(data)
    }

    const createRoleValidation = useCreateRoleValidation()

    const formik = useFormik<IFormCreateRole>({
        validationSchema: createRoleValidation.schema,
        validateOnChange: true,
        initialValues: createRoleValidation.generateInitialValue(),
        onSubmit: async (values) => {
            console.log(values)
        },
    })

    useEffect(() => {
        getModuleData()
    }, [])

    return {
        formik,
        roleModuleData,
    }
}