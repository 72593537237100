import { IShipment } from 'repository/interface/shipment.interface'
import * as Yup from 'yup'
import { userDataSelector } from 'pages/login/login.slice'
import { useSelector } from 'react-redux'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import moment from 'moment'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

// Interface Definitions
export interface IFConsolidation {
    code?: string
    consol_id: string
    created_by: string
    creation_date: string
    container_booking_reference: string
    master_bill: string
    incoterm: string
    carrier: string
    creditor: string
    flight_number: string
    destination: string
    warehouse: string
    voyage: string
    departure_cto_address: string
    departure_cfs_address: string
    departure_container_yard: string
    departure_port_transport: string
    arrival_cto_address: string
    arrival_cfs_address: string
    arrival_container_yard: string
    arrival_port_transport: string
    transport_mode: string
    container_mode: string
    console_category: { code: string }
    container_type: { code: string }
    consolidation_number: string
    container_number: string
    load_port: { code: string }
    load_port_etd: string
    load_port_eta: string
    seal_number: string
    discharge_port: { code: string }
    discharge_port_etd: string
    discharge_port_eta: string
    service_level: string
    ship_count: number
    weight: IMeasurement
    volume: IMeasurement
    prepaid: IMeasurement
    payment_category: string
    total_packs: number
    chargable: IMeasurement
    collect: IMeasurement
    sending_agent: { code: string }
    sending_agent_address: { code: string; name: string; address: string }
    sending_agent_contact: {
        code: string
        name: string
        email: string
        phone: string
    }
    receiving_agent: { code: string }
    receiving_agent_address: { code: string; name: string; address: string }
    receiving_agent_contact: {
        code: string
        name: string
        email: string
        phone: string
    }
    shipments: IShipment[]
}

export interface IFConsolidationPayload {
    code?: string
    transport_mode: string
    container_mode: string
    console_category: { code: string }
    container_type: { code: string }
    consolidation_number: string
    container_number: string
    container_booking_reference: string
    master_bill: string
    incoterm: string
    carrier: string
    creditor: string
    flight_number: string
    load_port: { code: string }
    load_port_etd: string
    load_port_eta: string
    seal_number: string
    discharge_port: { code: string }
    discharge_port_etd: string
    discharge_port_eta: string
    service_level: string
    ship_count: number
    weight: string
    weight_unit: { code: string }
    volume: string
    volume_unit: { code: string }
    prepaid: string
    prepaid_currency: { code: string }
    payment_category: string
    total_packs: number
    chargable: string
    chargable_unit: { code: string }
    collect: string
    collect_currency: { code: string }
    sending_agent: { code: string } | undefined
    sending_agent_address: string | undefined
    sending_agent_contact: { code: string } | undefined
    receiving_agent: { code: string } | undefined
    receiving_agent_address: string | undefined
    receiving_agent_contact: { code: string } | undefined
    shipments: { code: string }[]
    destination: string
    warehouse: string
    voyage: string
    departure_cto_address: string
    departure_cfs_address: string
    departure_container_yard: string
    departure_port_transport: string
    arrival_cto_address: string
    arrival_cfs_address: string
    arrival_container_yard: string
    arrival_port_transport: string
}

// Validation Schema
export const useConsolidationValidation = () => {
    const user: IUserAuth = useSelector(userDataSelector)
    const schema = Yup.object<IFConsolidation>().shape({
        transport_mode: Yup.string().required('Transport Mode is required'),
        container_mode: Yup.string().required('Container Mode is required'),
        // console_type: Yup.string().required('Console Type is required'),
        console_category: Yup.object().shape({
            code: Yup.string().required('Console Category Code is required'),
        }),
        container_type: Yup.object().shape({
            code: Yup.string().required('Container Type Code is required'),
        }),
        // consolidation_number: Yup.string().required(
        //     'Consolidation Number is required',
        // ),
        container_number: Yup.string().required('Container Number is required'),
        container_booking_reference: Yup.string().required(
            'Container Booking Reference is required',
        ),
        master_bill: Yup.string().required('Master Bill is required'),
        incoterm: Yup.string().required('Incoterm is required'),
        carrier: Yup.string().required('Carrier is required'),
        creditor: Yup.string().required('Creditor is required'),
        flight_number: Yup.string().required('Flight Number is required'),
        // phase: Yup.string().required('Phase is required'),
        // feeder: Yup.string().required('Feeder is required'),
        // seal_number: Yup.string().required('Seal Number is required'),
        load_port: Yup.object().shape({
            code: Yup.string().required('Load Port Code is required'),
        }),

        discharge_port: Yup.object().shape({
            code: Yup.string().required('Discharge Port Code is required'),
        }),
        service_level: Yup.string().required('Service Level is required'),
        voyage: Yup.string().required('Voyage'),
        ship_count: Yup.number().required('Ship Count is required'),
        weight: Yup.object<IMeasurement>().shape({
            qty: Yup.string().required('Weight Qty is required'),
            unit: Yup.string().required('Weight Unit is required'),
        }),
        volume: Yup.object<IMeasurement>().shape({
            qty: Yup.string().required('Volume Qty is required'),
            unit: Yup.string().required('Volume Unit is required'),
        }),
        prepaid: Yup.object<IMeasurement>().shape({
            qty: Yup.string().required('Prepaid is required'),
            unit: Yup.string().required('Prepaid Currency is required'),
        }),
        payment_category: Yup.string().required('Payment Category is required'),
        total_packs: Yup.number().required('Total Packs is required'),
        chargable: Yup.object<IMeasurement>().shape({
            qty: Yup.string().required('Chargable is required'),
            unit: Yup.string().required('Chargable Currency is required'),
        }),
        collect: Yup.object<IMeasurement>().shape({
            qty: Yup.string().required('Collect is required'),
            unit: Yup.string().required('Collect Currency is required'),
        }),
        shipments: Yup.array().of(
            Yup.object().shape({
                code: Yup.string().required('Shipment is required'),
            }),
        ),
        departure_cto_address: Yup.string().required(
            'Departure CTO address is required',
        ),
        departure_cfs_address: Yup.string().required(
            'Departure CFS address is required',
        ),
        departure_container_yard: Yup.string().required('Required'),
        departure_port_transport: Yup.string().required('Required'),
        arrival_cto_address: Yup.string().required(
            'Arrival CTO address is required',
        ),
        arrival_cfs_address: Yup.string().required(
            'Arrival CFS address is required',
        ),
        arrival_container_yard: Yup.string().required('Required'),
        arrival_port_transport: Yup.string().required('Required'),
    })

    const generateInitialValue = () => {
        const initial: IFConsolidation = {
            transport_mode: '',
            container_mode: '',
            console_category: { code: '' },
            container_type: { code: '' },
            consolidation_number: '',
            container_number: '',
            load_port: { code: '' },
            load_port_etd: '',
            load_port_eta: '',
            seal_number: '',
            discharge_port: { code: '' },
            discharge_port_etd: '',
            discharge_port_eta: '',
            service_level: '',
            ship_count: 0,
            weight: { qty: '0', unit: '', unitLabel: '' },
            volume: { qty: '0', unit: '', unitLabel: '' },
            prepaid: { qty: '0', unit: '', unitLabel: '' },
            payment_category: '',
            total_packs: 0,
            chargable: { qty: '0', unit: '', unitLabel: '' },
            collect: { qty: '0', unit: '', unitLabel: '' },
            sending_agent: { code: '' },
            sending_agent_address: {
                code: '',
                name: '',
                address: '',
            },
            sending_agent_contact: {
                code: '',
                name: '',
                email: '',
                phone: '',
            },
            receiving_agent: { code: '' },
            receiving_agent_address: {
                code: '',
                name: '',
                address: '',
            },
            receiving_agent_contact: {
                code: '',
                name: '',
                email: '',
                phone: '',
            },
            shipments: [],
            consol_id: '(auto generated)',
            created_by: user.fullName,
            creation_date: moment().format('DD/MM/YYYY'),
            container_booking_reference: '',
            master_bill: '',
            incoterm: '',
            carrier: '',
            creditor: '',
            flight_number: '',
            destination: '',
            warehouse: '',
            voyage: '',
            departure_cto_address: '',
            departure_cfs_address: '',
            departure_container_yard: '',
            departure_port_transport: '',
            arrival_cto_address: '',
            arrival_cfs_address: '',
            arrival_container_yard: '',
            arrival_port_transport: '',
        }
        return initial
    }

    return {
        schema,
        generateInitialValue,
    }
}
