import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import { IFormPackLine } from 'form-validation/pack-line.validation'
import { FormikProps, FormikProvider } from 'formik'
import { IUnit } from 'repository/interface/unit.interface'
import { IUseModal } from '@components/modal/modal.service'
import Table from '@components/table/table.component'
import { IItemListsTable } from './item-lists.interface'
import { dummyItemLists, tableHeader } from './item-lists.static'
import { useState } from 'react'
import { useItemLists } from './item-lists.service'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import { RowOptionItemList } from './components/option/option.component'

const ItemLists = ({}: {}) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)
    const iconAccordion = isAccordionOpen
        ? 'ri-arrow-up-double-line'
        : 'ri-arrow-down-double-line'

    const {
        deleteItem,
        deleteLoading,
        dropdownService,
        isLoading,
        formikDetail,
        isNew,
        modalFormItemList,
        selectRow,
        setIsNew,
    } = useItemLists()

    return (
        <div className="flex flex-col h-full">
            <div
                className={`flex-col overflow-auto h-[calc(100vh-240px)] overflow-y-hidden`}
            >
                <div className="flex flex-col">
                    <div
                        className="flex p-4 border-b cursor-pointer items-center"
                        onClick={() => {
                            setIsAccordionOpen(!isAccordionOpen)
                        }}
                    >
                        <div className="text-size-M font-bold w-full">
                            Pack Line Information
                        </div>
                        <div className="flex items-center">
                            <i
                                className={`${iconAccordion} text-size-XL cursor-pointer`}
                            ></i>
                        </div>
                    </div>

                    <div
                        className={`
                    p-4 themes-bg-gray-v2
                    overflow-hidden transition-all duration-800 ease-in-out
                   ${isAccordionOpen ? 'max-h-100 opacity-100' : 'max-h-0 opacity-0 !py-0'}
                `}
                    >
                        <div className="grid grid-cols-2 w-full gap-5">
                            <div className="flex flex-col gap-4">
                                <div className="grid grid-cols-3 gap-y-3">
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Pack ID.
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        PCK001
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Package Type
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        PALLET
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5 flex items-center">
                                        Product Category
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        ARTWORK
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Total Inner Packs
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        10
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Container Number
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        -
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        In Container?
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        No
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Created Date
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        12/20/24, 15:00
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="grid grid-cols-3 gap-y-3">
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Length Measure
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        1 M
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Width Measure
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        0.5 M
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5 flex items-center">
                                        Height Measure
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        2 M
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Weight Measure
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        50 KG
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Volume Measure
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        60 KG
                                    </div>
                                    <div className="col-span-1 text-size-S themes-text-gray-v5">
                                        Goods Description
                                    </div>
                                    <div className="col-span-2 text-size-S themes-text-gray-v6 font-normal">
                                        -
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between p-4">
                    <div className="flex flex-col">
                        <div className="text-size-M font-bold">ITEM LIST</div>
                        <div className="text-size-S themes-text-gray-v4">
                            Show items inside this packing.
                        </div>
                    </div>
                    <Button
                        label="ATTACH ITEM"
                        useUpperCase
                        className="!text-size-M min-w-[140px]"
                        icon="ri-add-line"
                        onClick={() => {
                            modalFormItemList.openModalHandling()
                        }}
                    />
                </div>
                <div className="px-4">
                    <Table<IItemListsTable>
                        data={dummyItemLists}
                        headers={tableHeader}
                        loading={false}
                        moduleTitle="Inner Pack Details Table"
                        usePagination={false}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionItemList
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() => deleteItem(rowData.name)}
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default ItemLists
