var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { useRef } from 'react';
import Button from '@components/button/button.component';
var FormInputFile = function (_a) {
    var _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.parentDivClassName, parentDivClassName = _d === void 0 ? '' : _d, _e = _a.hideError, hideError = _e === void 0 ? false : _e, props = __rest(_a, ["readonly", "disabled", "parentDivClassName", "hideError"]);
    var _f = useField(props.name), field = _f[0], meta = _f[1];
    var setFieldValue = useFormikContext().setFieldValue;
    var fileInputRef = useRef(null);
    var isError = meta.touched && meta.error;
    var errorClassMessage = isError && !hideError ? '' : 'hidden';
    var handleButtonClick = function () {
        if (fileInputRef === null || fileInputRef === void 0 ? void 0 : fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    var handleFileChange = function (e) {
        var _a;
        var file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            setFieldValue(props.name, file);
        }
    };
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-4") }, { children: [props.label && (_jsx("div", __assign({ className: "text-size-S font-bold mb-1" }, { children: props.label }))), _jsxs("div", __assign({ className: "flex rounded-[5px] border themes-bg-gray-v1 items-center themes-border-gray-v4" }, { children: [_jsx(Button, { onClick: handleButtonClick, variant: "brand-inverse", label: "BROWSE FILE", className: "rounded-[0px] rounded-tl-[5px] rounded-bl-[5px] -mb-[0.5px] -ml-[1px] -mt-[0.5px]", disabled: disabled }), _jsx("span", __assign({ className: "themes-text-gray-v5 px-4 !text-size-XS" }, { children: field.value ? field.value.name : 'No file selected' }))] })), _jsx("input", { ref: fileInputRef, type: "file", accept: ".png, .jpg, .jpeg, .pdf, .xlsx, .xls", style: { display: 'none' }, onChange: handleFileChange, disabled: disabled }), isError && (_jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) })))] })));
};
export default FormInputFile;
