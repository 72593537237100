import * as React from 'react'
import './receive-money-doc.style.css'
import Logo from './receive-money-logo.png'
import { IQuotation } from 'repository/interface/quotations.interface'
import moment from 'moment'

interface ComponentToPrintProps {
    text?: string
    data?: IQuotation
}

export const ReceiveMoneyDoc = React.forwardRef<
    HTMLDivElement | null,
    ComponentToPrintProps
>((props, ref) => {
    const { data, text } = props
    return (
        <div
            ref={ref}
            className="mx-[50px] flex flex-col justify-center min-h-screen"
        >
            <div className="min-h-[calc(100vh-5rem)] flex flex-col">
                {/* header */}
                <div className="flex flex-col">
                    <img src={Logo} alt="Logo" className="h-20 w-fit mb-3" />
                    <tbody className="max-w-80">
                        <tr className="base-text">
                            <td className="text w-28">COMPANY PROFILE</td>
                            <td className="text-dark">LIMAJARI CARGO</td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28">ADDRESS</td>
                            <td className="text-dark">
                                Jl. Raya Kerobokan 100X, Kerobokan, Badung,
                                80361 Bali
                            </td>
                        </tr>
                    </tbody>
                </div>
                {/* body */}
                <div className="flex flex-col flex-grow mt-6">
                    <p className="heading w-full text-center">CASH RECEIPT</p>

                    <div className="sub-heading custom-border-bottom-bold text-start w-full mt-4">
                        RECEIPT DETAILS
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <table className="w-full border-collapse col-span-1">
                            <tbody>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-24">
                                        Received From
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        PT GLOBAL UNGGUL MANDIRI
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-24">
                                        Deposit Account
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        CASH
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="w-full border-collapse col-span-1">
                            <tbody>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-24">
                                        Transaction No
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10004
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-24">
                                        Due Date
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        01/01/2025
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6">
                        <table className="w-full mt-2 rounded-2xl custom-border-light">
                            <tbody>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-dark px-2 py-2 bg-secondary w-[100px]">
                                        ACCOUNT CODE
                                    </td>
                                    <td className="text-dark px-2 py-2 bg-secondary w-[120px]">
                                        ACCOUNT
                                    </td>
                                    <td className="text-dark px-2 py-2 bg-secondary">
                                        DESCRIPTION
                                    </td>
                                    <td className="text-dark px-2 py-2 bg-secondary w-[80px]">
                                        TAX
                                    </td>
                                    <td className="text-dark px-2 py-2 bg-secondary w-[110px] text-right">
                                        AMOUNT
                                    </td>
                                </tr>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-dark px-2 py-2">
                                        1-10002
                                    </td>
                                    <td className="text px-2 py-2">
                                        Bank Account
                                    </td>
                                    <td className="text px-2 py-2">-</td>
                                    <td className="text px-2 py-2">PPN</td>
                                    <td className="text px-2 py-2 text-right">
                                        Rp. 2.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="text-dark px-2 py-2">
                                        1-10003
                                    </td>
                                    <td className="text px-2 py-2">
                                        Account Receivable
                                    </td>
                                    <td className="text px-2 py-2">-</td>
                                    <td className="text px-2 py-2">PPN</td>
                                    <td className="text px-2 py-2 text-right">
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={3}></td>
                                    <td className="text-dark px-2 py-2">
                                        SUB TOTAL
                                    </td>
                                    <td className="text-dark px-2 py-2 text-right">
                                        Rp. 3.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={3}></td>
                                    <td className="text-dark px-2 py-2">
                                        PPN 11%
                                    </td>
                                    <td className="text-dark px-2 py-2 text-right">
                                        Rp. 330.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td
                                        className="px-2 py-2 bg-secondary"
                                        colSpan={3}
                                    ></td>
                                    <td className="text-dark text-xl px-2 py-2 bg-secondary ">
                                        TOTAL
                                    </td>
                                    <td className="text-dark px-2 py-2 bg-secondary text-right">
                                        RP.3.330.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={5}>
                                        <div className="w-full flex flex-row justify-end">
                                            <p className="sub-heading mr-1 text-[11px]">
                                                Amount in words:
                                            </p>
                                            <p className="sub-heading text-[10px] font-semibold">
                                                THREE MILLION THREE HUNDRED
                                                THIRTY THOUSAND RUPIAH
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full mt-4">
                        <p className="text custom-border-bottom-bold text-start">
                            Message
                        </p>
                        <p className="text text-start py-1">
                            Is there any additional information that we need to
                            know, please kindly inform us in the field shown
                            below
                        </p>
                    </div>
                    <div className="flex mt-6">
                        <p className="big-heading mr-2">TOTAL</p>
                        <p className="big-heading font-bold">
                            Rp. 3.330.000,00
                        </p>
                    </div>
                    <div className="mt-6">
                        <table className="w-full border border-collapse mt-2 rounded-2xl">
                            <tbody>
                                <tr className="base-text">
                                    <th className="text-dark custom-border-light bg-secondary">
                                        Created by
                                    </th>
                                    <th className="text-dark custom-border-light bg-secondary">
                                        Checked by
                                    </th>
                                    <th className="text-dark custom-border-light bg-secondary">
                                        Approved by
                                    </th>
                                    <th className="text-dark custom-border-light bg-secondary">
                                        Received by
                                    </th>
                                </tr>
                                <tr className="base-text">
                                    <td className="text-dark custom-border-light h-32"></td>
                                    <td className="text-dark custom-border-light h-32"></td>
                                    <td className="text-dark custom-border-light h-32"></td>
                                    <td className="text-dark custom-border-light h-32"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* footer */}
                {/* <div className="flex flex-row justify-between items-center w-full custom-border-top-light self-end">
                    <img
                        src={LogisticalSquare}
                        alt="Logo"
                        className="w-9 h-9"
                    />
                    <p className="text mb-0">
                        ©OneByOne Logistical Pty Ltd 2024. Connect and control
                        with digital precision™
                    </p>
                </div> */}
            </div>
        </div>
    )
})
