import { ITableColumn } from '@components/table/table.interface'
import { IInnerPacksTable } from './inner-packs.interface'

export const tableHeader: ITableColumn<IInnerPacksTable>[] = [
    {
        label: 'No.',
        accessor: 'no',
    },
    {
        label: 'Package Type',
        accessor: 'package_type',
    },
    {
        label: 'Quantity',
        accessor: 'qty',
    },
    {
        label: 'Length',
        accessor: 'length',
    },
    {
        label: 'Width',
        accessor: 'width',
    },
    {
        label: 'Height',
        accessor: 'height',
    },
    {
        label: 'UD',
        accessor: 'ud',
    },
    {
        label: 'Weight (KG)',
        accessor: 'weight',
    },
    {
        label: 'UW',
        accessor: 'uw',
    },
    {
        label: 'Volume ',
        accessor: 'volume',
    },
    {
        label: 'UV',
        accessor: 'uv',
    },
    {
        label: 'Goods Description',
        accessor: 'goods_description',
    },
]

export const dummyInnerPacks: IInnerPacksTable[] = [
    {
        no: '1',
        package_type: 'BOX',
        qty: '5',
        length: '1.2',
        width: '0.8',
        height: '0.6',
        ud: 'M',
        weight: '10',
        uw: 'KG',
        volume: '0.576',
        uv: 'M³',
        goods_description: 'Artwork Frame',
    },
    {
        no: '2',
        package_type: 'PALLET',
        qty: '10',
        length: '1.5',
        width: '1.2',
        height: '0.9',
        ud: 'M',
        weight: '50',
        uw: 'KG',
        volume: '1.62',
        uv: 'M³',
        goods_description: 'Printed Canvas',
    },
    {
        no: '3',
        package_type: 'CARTON',
        qty: '20',
        length: '0.6',
        width: '0.4',
        height: '0.3',
        ud: 'M',
        weight: '3',
        uw: 'KG',
        volume: '0.072',
        uv: 'M³',
        goods_description: 'Glassware',
    },
    {
        no: '4',
        package_type: 'CRATE',
        qty: '2',
        length: '2',
        width: '1.5',
        height: '1',
        ud: 'M',
        weight: '120',
        uw: 'KG',
        volume: '3',
        uv: 'M³',
        goods_description: 'Wood Sculpture',
    },
    {
        no: '5',
        package_type: 'BAG',
        qty: '15',
        length: '0.5',
        width: '0.3',
        height: '0.2',
        ud: 'M',
        weight: '2',
        uw: 'KG',
        volume: '0.03',
        uv: 'M³',
        goods_description: 'Fabric Rolls',
    },
]
