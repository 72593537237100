import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import Button from '@components/button/button.component'
import '../../user-access-form.style.css'
import { useCreateRole } from './setting-role.service'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'

const CreateRoleModal = ({
    modalService,
    modalServiceSettingRole,
}: {
    modalService: IUseModal
    modalServiceSettingRole: IUseModal
}) => {
    const {
        formik,
        roleModuleData,
    } = useCreateRole()
    const item = ['Create', 'View Only', 'Edit', 'Delete']
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="md:w-1/2 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
        >
            <div className="flex flex-col h-[calc(100vh-4rem)] ">
                <div className="p-4 flex w-full items-center border-[1px]">
                    {/* Header */}
                    <div className="flex-1 flex-col flex leading-tight">
                        <span className="text-size-M font-bold ">
                            CREATE NEW ROLE
                        </span>
                    </div>
                </div>

                <FormikProvider value={formik}>
                    <div className='px-4 pt-4'>
                        <FormInput
                            name="role"
                            label="Role"
                            useUppercaseLabel
                            placeholder='Enter Role'
                        />
                    </div>

                </FormikProvider>

                <div className="flex-grow grid grid-cols-2 w-full themes-bg-white border-t">
                    {/* Body */}
                    <div className='border-r-[1px]'>
                        <div className='px-4 border-b-[1px] p-3 text-size-L'>Access Name</div>
                        <div className='px-4 border-b-[1px] text-size-M w-full flex items-center justify-between'>
                            <div className="py-2 flex items-center "> All Access For All Module</div>
                            <div>
                                <ToggleSwitch
                                    initialOn={false}
                                    onClick={() => {
                                        // if (subModuleKey) {
                                        //     toggleAction(
                                        //         act.key,
                                        //         moduleKey,
                                        //         subModuleKey,
                                        //     )
                                        // } else {
                                        //     toggleAction(act.key, moduleKey)
                                        // }
                                    }}
                                />
                            </div>
                        </div>
                        <div className=''>
                            {roleModuleData.map((data, index) => (
                                <div key={index} className='flex justify-between px-4 py-2 border-b-[1px] cursor-pointer themes-text-gray-v5 themes-bg-hover-brand-v1 themes-text-hover-brand-v5'>
                                    <div>{data.label}</div>
                                    <i className="ri-arrow-right-s-line" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className=''>
                        <div className='px-4 border-b-[1px] p-3 text-size-L'>Access Type</div>
                        <div className='px-4 border-b-[1px] text-size-M w-full flex items-center justify-between'>
                            <div className="py-2 flex items-center "> All Access</div>
                            <div>
                                <ToggleSwitch
                                    initialOn={false}
                                    onClick={() => {
                                        // if (subModuleKey) {
                                        //     toggleAction(
                                        //         act.key,
                                        //         moduleKey,
                                        //         subModuleKey,
                                        //     )
                                        // } else {
                                        //     toggleAction(act.key, moduleKey)
                                        // }
                                    }}
                                />
                            </div>

                        </div>
                        {item.map((act, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`w-full flex items-center justify-between border-b themes-bg-hover-brand-v1 themes-text-hover-brand-v5 cursor-pointer px-4`}
                                >
                                    <div className="py-2 flex items-center ">
                                        {act}
                                    </div>
                                    <div>
                                        <ToggleSwitch
                                            initialOn={false}
                                            onClick={() => {
                                                // if (subModuleKey) {
                                                //     toggleAction(
                                                //         act.key,
                                                //         moduleKey,
                                                //         subModuleKey,
                                                //     )
                                                // } else {
                                                //     toggleAction(act.key, moduleKey)
                                                // }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex gap-2 border-t w-full p-3">
                    {/* Footer */}
                    <Button
                        className="w-full"
                        label="CANCEL"
                        variant='brand-inverse'
                        onClick={() => {
                            modalService.closeModalHandling()
                            modalServiceSettingRole.openModalHandling()
                        }}
                    />
                    <Button
                        className="w-full"
                        label="CREATE NEW"
                        variant='brand'
                        onClick={() => {
                            modalService.closeModalHandling()
                            modalServiceSettingRole.openModalHandling()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default CreateRoleModal
