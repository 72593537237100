import { headerChild, headerParent } from './item-list.static'
import ModalSidebar from '@components/modal-sidebar/modal-sidebar.component'
import TableExpandable from '@components/table-expandable/table-expandable.component'
import { useItemLists } from './item-list.service'
import { useOrderItem } from './order-item.service'
import ItemListForm from './item-list-form.component'
import OrderItemForm from './order-item-form.component'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'
import { ParentOption, ChildrenOption } from './component/option.component'
const ItemList = () => {
    const {
        data,
        formik,
        modalService,
        isNew,
        submitLoading,
        deleteLoading,
        isLoading,
        selectRow,
        deleteData,
        setIsNew,
        resetForm,
    } = useItemLists()

    const {
        dataItem,
        formikItem,
        modalServiceItem,
        isNewItem,
        submitLoadingItem,
        deleteLoadingItem,
        isLoadingItem,
        selectRowItem,
        deleteItem,
        resetFormItem,
        setIsNewItem,
        openModalWithParentData,
    } = useOrderItem()

    return (
        <div className="container-global h-[calc(100vh-283px)] flex ">
            <div className="flex flex-grow flex-col w-[calc(100vw-30rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TableExpandable<IItemList, IOrderItem>
                    childAccessor="items"
                    data={data}
                    showHeaderOnEmpty={true}
                    headerCustom={'themes-bg-gray-v1'}
                    headerChild={headerChild}
                    headerParent={headerParent}
                    moduleTitle={'Item List'}
                    loading={isLoading || isLoadingItem}
                    addChildRowHandling={(parent) => {
                        resetFormItem()
                        setIsNewItem(true)
                        openModalWithParentData(parent)
                        modalServiceItem.openModalHandling()
                    }}
                    rowOptionComponent={(rowData, closeHandling) => (
                        <ParentOption
                            closeHandling={closeHandling}
                            rowData={rowData}
                            openModalEdit={selectRow}
                            deleteHandling={() => deleteData(rowData.code)}
                            deleteLoading={deleteLoading}
                        />
                    )}
                    rowChildrenOptionComponent={(rowData, closeHandling) => (
                        <ChildrenOption
                            closeHandling={closeHandling}
                            rowData={rowData}
                            openModalEdit={selectRowItem}
                            deleteHandling={() =>
                                deleteItem(rowData.parent.code, rowData.code)
                            }
                            deleteLoading={deleteLoading}
                        />
                    )}
                    addItemHandling={() => {
                        resetForm()
                        setIsNew(true)
                        modalService.openModalHandling()
                    }}
                />
            </div>

            <ModalSidebar
                content={
                    <ItemListForm
                        formik={formik}
                        submitLoading={submitLoading}
                        deleteHandling={() => deleteData()}
                        deleteLoading={deleteLoading}
                        isNew={isNew}
                    />
                }
                modalService={modalService}
                title={isNew ? 'Create New Order' : 'Update Order:'}
            />

            <ModalSidebar
                content={
                    <OrderItemForm
                        data={dataItem}
                        formik={formikItem}
                        submitLoading={submitLoadingItem}
                        deleteHandling={() => deleteItem()}
                        deleteLoading={deleteLoadingItem}
                        isNew={isNewItem}
                    />
                }
                modalService={modalServiceItem}
                title={
                    isNewItem ? 'Create New Order Item' : 'Update Order Item'
                }
            />
        </div>
    )
}

export default ItemList
