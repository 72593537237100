import { useFormik } from 'formik'
import { formLineInitial, useCreateJurnalValidation } from './create-jurnal.validation'
import { IFormCreateJurnal } from './create-jurnal.interface'
import { useEffect } from 'react';
import { useModal } from '@components/modal/modal.service';
import { useNavigate } from 'react-router-dom';

const useCreateJurnal = () => {
    const navigate = useNavigate()
    //Modal
    const modalServiceTransactionNo = useModal()

    // formik
    const salesValidation = useCreateJurnalValidation()
    const formik = useFormik<IFormCreateJurnal>({
        validationSchema: salesValidation.schema,
        validateOnChange: true,
        initialValues: salesValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })
    const { values, setFieldValue } = formik
    const { linePrice } = values

    // Use Effect 
    // Add new line if all required fields are filled
    useEffect(() => {
        const lastLine = linePrice[linePrice.length - 1];
        if (
            lastLine &&
            lastLine.account &&
            lastLine.debit &&
            lastLine.credit
        ) {
            formik.setFieldValue('linePrice', [
                ...linePrice,
                formLineInitial,
            ]);
        }
    }, [linePrice, formik]);

    useEffect(() => {
        // Exit early if there are no line prices
        if (!values.linePrice?.length) return;

        // Initialize calculation variables
        let total_debit = 0;
        let total_credit = 0;

        // Process each line item
        values.linePrice.forEach((line) => {
            const debit = parseFloat(line.debit) || 0;
            const credit = parseFloat(line.credit) || 0;

            total_debit += debit;
            total_credit += credit;
        });

        // Update form fields with calculated values
        setFieldValue("total_debit", total_debit);
        setFieldValue("total_credit", total_credit);

    }, [values.linePrice, setFieldValue]);


    return {
        formik,
        modalServiceTransactionNo,
        navigate,
    }
}

export default useCreateJurnal
