import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { RootState, useAppDispatch } from 'store'
import {
    shipmentDataSelector,
    shipmentFetchParamSelector,
    shipmentMetaSelector,
    shipmentSelectedTabItemSelector,
    shipmentTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
    setDataConsolidation,
    consolidationDataSelector,
    consolidationMetaSelector,
} from './shipments.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { generateSimpleReport } from '@services/common.export.service'
import {
    airShipmentSummaryHeader,
    consolidationShipmentStatusHeader,
    consolidationShipmentSummaryHeader,
    seaShipmentSummaryHeader,
    shipmentStatusHeader,
} from './shipments.static'
import moment from 'moment'
import { getConsolidation, getShipments } from 'repository/shipment.repository'
import { ShipmentType } from './shipments.interface'
import { ITableColumn } from '@components/table/table.interface'
import { IShipment } from 'repository/interface/shipment.interface'
import { fetchConsoleCategory } from 'repository/dropdown.repository'
import {
    IConsoleCategory,
    IConsolidation,
} from 'repository/interface/consolidation.interface'

const useShipments = (shipmentType: ShipmentType) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(shipmentFetchParamSelector)
    const shipmentsAirData = useSelector((state: RootState) =>
        shipmentDataSelector(state, 'AIR'),
    )
    const shipmentsSeaData = useSelector((state: RootState) =>
        shipmentDataSelector(state, 'SEA'),
    )
    const shipmentsAirMeta = useSelector((state: RootState) =>
        shipmentMetaSelector(state, 'AIR'),
    )
    const shipmentsSeaMeta = useSelector((state: RootState) =>
        shipmentMetaSelector(state, 'SEA'),
    )
    const consolidationData = useSelector(consolidationDataSelector)
    const consolidationMeta = useSelector(consolidationMetaSelector)
    const tabItems = useSelector((state: RootState) =>
        shipmentTabItemsSelector(state, shipmentType),
    )
    const selectedTabItem = useSelector((state: RootState) =>
        shipmentSelectedTabItemSelector(state, shipmentType),
    )

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData({
            ...fetchParam,
            transport:
                shipmentType === 'CONSOLIDATION' ? undefined : shipmentType,
            page: 1,
            size: 50,
        })
    }, [fetchParam])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)

            if (shipmentType === 'CONSOLIDATION') {
                const response = await getConsolidation(params, true)
                if (!response) {
                    setLoading(false)
                    dispatch(
                        setDataConsolidation({
                            data: [],
                            meta: {
                                start: 0,
                                size: 0,
                                page: 0,
                                data_count: 0,
                                total_data_count: 0,
                                total_page: 0,
                            },
                        }),
                    )
                    return
                }
                if (recountingData) {
                    getConsolidationTabItems(params)
                }
                dispatch(setDataConsolidation(response))
            } else {
                const response = await getShipments(params, true)
                if (!response) {
                    setLoading(false)
                    dispatch(
                        setData({
                            type: shipmentType,
                            response: {
                                data: [],
                                meta: {
                                    start: 0,
                                    size: 0,
                                    page: 0,
                                    data_count: 0,
                                    total_data_count: 0,
                                    total_page: 0,
                                },
                            },
                        }),
                    )
                    return
                }
                if (recountingData) {
                    getTabItems(params)
                }
                dispatch(setData({ response: response, type: shipmentType }))
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        let response
        if (shipmentType === 'CONSOLIDATION') {
            response = await getConsolidation(params, true)
        } else {
            response = await getShipments(params, true)
        }

        if (!response) {
            setLoading(false)
            return
        }

        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const filename =
            shipmentType === 'CONSOLIDATION'
                ? `Consolidation_${date}`
                : `Shipment_${date}`

        const generateReport = generateSimpleReport({
            headers:
                shipmentType === 'CONSOLIDATION'
                    ? consolidationShipmentSummaryHeader
                    : seaShipmentSummaryHeader,
            data: data,
            fileName: filename,
            title: 'Shipment Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
            transport:
                shipmentType === 'CONSOLIDATION' ? undefined : shipmentType,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalOpen = 0
        let totalClosed = 0
        let totalConfirmed = 0
        let totalCanceled = 0
        let totalDelivered = 0

        const rOpen = await getShipments({
            ...fParams,
            status: 'OPEN',
            size: 1,
        })
        const rConfirmed = await getShipments({
            ...fParams,
            status: 'CONFIRMED',
            size: 1,
        })
        const rDelivered = await getShipments({
            ...fParams,
            status: 'DELIVERED',
            size: 1,
        })
        const rClosed = await getShipments({
            ...fParams,
            status: 'CLOSED',
            size: 1,
        })
        const rCanceled = await getShipments({
            ...fParams,
            status: 'CANCELED',
            size: 1,
        })

        if (!rOpen || !rConfirmed || !rClosed || !rCanceled || !rDelivered) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalOpen = rOpen?.meta.total_data_count
        totalClosed = rClosed?.meta.total_data_count
        totalConfirmed = rConfirmed?.meta.total_data_count
        totalCanceled = rCanceled?.meta.total_data_count
        totalDelivered = rDelivered?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'OPEN') count = totalOpen
            else if (d.value === 'CLOSED') count = totalClosed
            else if (d.value === 'CANCELED') count = totalCanceled
            else if (d.value === 'CONFIRMED') count = totalConfirmed
            else if (d.value === 'DELIVERED') count = totalDelivered
            else count = 0

            return { ...d, totalData: count }
        })

        dispatch(setTabItems({ type: shipmentType, tabs: tItems }))
    }

    const getConsolidationTypeTotalData = async (
        category: string,
        fParams: IFetchParams,
    ) => {
        const res = await getConsolidation({
            ...fParams,
            size: 1,
            console_category: category,
        })
        return res?.meta?.total_data_count ?? 0
    }

    const getConsolidationTabItems = async (fParams: IFetchParams) => {
        let tabLabel: IConsoleCategory[] = []

        // get console type
        const responseConsoleType = await fetchConsoleCategory()
        if (!responseConsoleType) {
            return
        }
        tabLabel = responseConsoleType.data
        const tItems: ITabItem[] = tabLabel.map((d) => {
            const dt: ITabItem = {
                totalData: 0,
                label: d.name,
                value: d.code,
                className: 'brand',
                key: 'categories',
            }
            return dt
        })
        const tItems2 = [consolidationShipmentStatusHeader[0], ...tItems]
        dispatch(setTabItems({ type: shipmentType, tabs: tItems2 }))
        countConsolidationTabItems(fParams, tItems2)
    }

    const countConsolidationTabItems = async (
        fParams: IFetchParams,
        tabItem: ITabItem[],
    ) => {
        // get total each console type
        const dx: ITabItem[] = await Promise.all(
            tabItem.map(async (d) => {
                const dt = await getConsolidationTypeTotalData(d.value, fParams)
                return {
                    ...d,
                    totalData: dt,
                }
            }),
        )

        dispatch(setTabItems({ type: shipmentType, tabs: dx }))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem({ type: shipmentType, tab: data }))
    }

    const getShipmentColumn = (): ITableColumn[] => {
        if (shipmentType === 'AIR') {
            return airShipmentSummaryHeader
        } else if (shipmentType === 'SEA') {
            return seaShipmentSummaryHeader
        } else if (shipmentType === 'CONSOLIDATION') {
            return consolidationShipmentSummaryHeader
        } else {
            return []
        }
    }

    const getShipmentTab = (): ITabItem[] => {
        if (shipmentType === 'CONSOLIDATION') {
            return consolidationShipmentStatusHeader
        } else {
            return shipmentStatusHeader
        }
    }

    const data = () => {
        if (shipmentType === 'CONSOLIDATION') return consolidationData
        else if (shipmentType === 'AIR') return shipmentsAirData
        else return shipmentsSeaData
    }
    const meta = () => {
        if (shipmentType === 'CONSOLIDATION') return consolidationMeta
        else if (shipmentType === 'AIR') return shipmentsAirMeta
        else return shipmentsSeaMeta
    }

    return {
        setTabItems,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        exportModalService,
        filterOverlay,
        exportLoading,
        data,
        meta,
        setExportLoading,
        loadData,
        exportLoadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        handleFilter,
        getShipmentColumn,
        getShipmentTab,
    }
}

export default useShipments
