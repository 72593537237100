import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    titipanDataSelector,
    titipanFetchParamSelector,
    setFetchParam,
    setTabItems,
} from './titipan.slice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'

const useTitipan = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()

    // selectors
    const fetchParam = useSelector(titipanFetchParamSelector)
    const titipanData = useSelector(titipanDataSelector)
    const filterOverlay = useOverlay()

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [tabFilter, setTabFilter] = useState<ITabItem>()

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        console.log(fParams)
    }
    return {
        setTabItems,
        titipanData,
        loading,
        fetchParam,
        exportModalService,
        filterOverlay,
        tabFilter,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
    }
}

export default useTitipan
