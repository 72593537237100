import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { IShipment } from 'repository/interface/shipment.interface'
import useShipments from './shipments.service'
import { ShipmentType } from './shipments.interface'
import ShipmentsExportModal from './components/filter/shipments-export.component'
import ShipmentsFilterOverlay from './components/filter/shipments-filter-overlay.component'
import { useNavigate } from 'react-router-dom'
import { IConsolidation } from 'repository/interface/consolidation.interface'

const Shipments = (shipmentType: ShipmentType) => {
    const nav = useNavigate()
    const {
        tabItems,
        selectedTabItem,
        loading,
        fetchParam,
        exportModalService,
        exportLoading,
        filterOverlay,
        loadData,
        meta,
        data,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        getShipmentColumn,
        setExportLoading,
        exportLoadData,
    } = useShipments(shipmentType)

    return (
        <>
            <div className="container-global content-full-height flex">
                <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                    <TabMaster
                        items={tabItems || []}
                        tabFilter={selectedTabItem}
                        onChange={(item) => setTabFilter(item)}
                        //  search ----------------------------------------------------------------
                        useSearch={true}
                        placeHolderSearch={
                            shipmentType === 'CONSOLIDATION'
                                ? 'Search Consolidation ID'
                                : 'Search Shipment ID'
                        }
                        containerSearchClassName={'flex-1'}
                        initialSearch={fetchParam.search}
                        onSearchSubmit={(values) => handleSearch(values)}
                        iconButton1={{
                            onClick: () =>
                                filterOverlay.toggleOverlayHandling(),
                            icon: 'ri-filter-2-line',
                            filterOverlayComponent: (
                                <ShipmentsFilterOverlay
                                    filterOverlayService={filterOverlay}
                                    onFilterSubmit={(data) => {
                                        loadData({ ...fetchParam, ...data })
                                        return
                                    }}
                                />
                            ),
                        }}
                        actionButton1={{
                            onClick: () => {
                                navigate(
                                    `/freight-forwarding/${shipmentType.toLowerCase()}/create`,
                                )
                            },
                            label:
                                shipmentType === 'CONSOLIDATION'
                                    ? 'NEW CONSOLIDATION'
                                    : 'CREATE SHIPMENT',
                            className: '!text-size-S',
                        }}
                    />

                    <Table<IShipment | IConsolidation>
                        headers={getShipmentColumn()}
                        data={data()}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        meta2={meta()}
                        moduleTitle={'Inquiry'}
                        enableExport={true}
                        exportModalService={exportModalService}
                        onRowClick={(data) => {
                            if (shipmentType !== 'CONSOLIDATION') {
                                nav('/freight-forwarding/details/' + data.code)
                            } else {
                                nav(
                                    '/freight-forwarding/consolidation/details/' +
                                        data.code,
                                )
                            }
                        }}
                    />
                </div>
            </div>
            <ShipmentsExportModal
                modalService={exportModalService}
                onSubmit={async (data) => {
                    setExportLoading(true)
                    await exportLoadData(data)
                    setExportLoading(false)
                    exportModalService.closeModalHandling()
                }}
                isLoading={exportLoading}
            />
        </>
    )
}

export default Shipments
