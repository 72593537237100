import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { FormikContextType, FormikProvider } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { IFormContact } from 'form-validation/organisation-contact.validation'
type DropdownProps = ReturnType<typeof useDropdown>

const ContactListForm = ({
    dropdownService,
    formik,
}: {
    dropdownService: DropdownProps
    formik?: FormikContextType<IFormContact>
}) => {
    const emails = formik?.values?.emails ?? []
    const phones = formik?.values?.phones ?? []

    return (
        <div>
            <FormInput
                label="Name"
                placeholder="Enter Contact Name"
                name="name"
                parentDivClassName="col-span-2"
                useUppercaseLabel={true}
            />
            <FormInput
                label="Frist Name"
                placeholder="Enter Contact First Name"
                name="first_name"
                parentDivClassName="col-span-2"
                useUppercaseLabel={true}
            />
            <FormInput
                label="Last Name"
                placeholder="Enter Last Name"
                name="last_name"
                parentDivClassName="col-span-2"
                useUppercaseLabel={true}
            />

            <FormDropdown
                label="Contact Type"
                name="contact_type"
                useUppercaseLabel={true}
                placeholder="Select Contact Type"
                options={dropdownService.contactTypesData}
            />

            <FormInput
                label="Job Title"
                useUppercaseLabel={true}
                placeholder="Enter Job Title"
                name="job_title"
                parentDivClassName="col-span-2"
                className="!mb-0"
            />

            <FormDropdown
                label="Job Category"
                useUppercaseLabel={true}
                name="job_category.code"
                placeholder="Select Job Category"
                options={dropdownService.jobCategoriesData}
            />

            <div className="flex flex-row gap-2">
                <div className="w-[40%]">
                    <FormDropdown
                        placeholder="+62"
                        name={`phones.country_code`}
                        options={dropdownService.countriesData.map(
                            (country) => ({
                                ...country,
                                label: `${country.additionalData?.phone_code} ${country.additionalData?.name}`,
                                value: `${country.additionalData?.phone_code}`,
                            }),
                        )}
                        required={true}
                        useUppercaseLabel
                    />
                </div>
                <div className="w-full">
                    <FormInput
                        placeholder="81198983431"
                        required={true}
                        type="number"
                        name="phones.number"
                        className="no-spinner"
                        useUppercaseLabel
                    />
                </div>
            </div>
            <FormInput
                label="Email"
                placeholder="Enter Email"
                name="emails"
                useUppercaseLabel={true}
                parentDivClassName="col-span-2 mt-3"
            />

            <FormDatePicker
                name="birth_date"
                useUppercaseLabel={true}
                isRange={false}
                label="Born Date (Optional)"
                parentDivClassName="col-span-2 mt-3"
            />

            <FormInput
                label="Working Address {Optional)"
                placeholder="Enter Working Address"
                name="working_address"
                useUppercaseLabel={true}
                parentDivClassName="col-span-2 mt-3"
            />
        </div>
    )
}

export default ContactListForm
