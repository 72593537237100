import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import useFilter from './filter.service'
import { IFetchParams } from 'common/common.interface'

const Filter = ({
    onFilterSubmit,
    isLoading,
}: {
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { formik, dropdownService } = useFilter(onFilterSubmit)

    const typeOptions = [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
        { value: 'type3', label: 'Type 3' },
    ]

    const bankOptions = [
        { value: 'bank1', label: 'Bank 1' },
        { value: 'bank2', label: 'Bank 2' },
        { value: 'bank3', label: 'Bank 3' },
    ]

    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                <div className=" w-full">
                    <FormDropdown
                        name="type"
                        options={typeOptions}
                        label={'Type'}
                        useUppercaseLabel={true}
                        placeholder={'All Type'}
                        isClearable={true}
                        isSearchable={true}
                    />
                    <FormDropdown
                        name="bank"
                        options={bankOptions}
                        label={'BANK OPTION'}
                        useUppercaseLabel={true}
                        placeholder={'All bank'}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
                <div className="-mx-4 border-b border-gray-40 my-1"></div>
                <div className="flex gap-2 mt-1">
                    <Button
                        onClick={() => {
                            formik.submitForm()
                        }}
                        isLoading={isLoading}
                        label={'RESET'}
                        useUpperCase={true}
                        className="w-full"
                        variant="default"
                    />
                    <Button
                        onClick={() => {
                            formik.resetForm()
                        }}
                        isLoading={isLoading}
                        label={'SAVE FILTER'}
                        useUpperCase={true}
                        className="w-full"
                        variant="brand"
                    />
                </div>
            </FormikProvider>
        </div>
    )
}

export default Filter
