import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

// Hold - Still waiting API filter quotes
interface IPaymentRequestFilter
    extends Pick<
        IFetchParams,
        | 'customer'
        | 'currency'
        | 'type'
        | 'deposit'
        | 'payment'
        | 'bank'
    > {}

export const initialPaymentRequestFilter: IPaymentRequestFilter = {
    customer: [],
    currency: [],
    type: [],
    deposit: [],
    payment: [],
    bank: []
}

const usePaymentRequestFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport?: boolean,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IPaymentRequestFilter>({
        initialValues: initialPaymentRequestFilter,
        onSubmit: (values) => {
            console.log(values)
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getCurrency()
    }, [])

    const generateFilter = async (values: IPaymentRequestFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        await onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default usePaymentRequestFilter
