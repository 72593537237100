import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
// import { ICashBank } from 'repository/interface/cash-bank.interface'

interface ICashBankSlice {
    data: any[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    isLoading: boolean
}
export const initialState: ICashBankSlice = {
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
}

const cashBankSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<any[]>>) {
            console.log(state, 'testtt')
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    status: data.value,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
    },
})

// these all the variables used for selector
export const cashBankFetchParamSelector = (state: RootState) =>
    state.booking.fetchParams || {}
export const cashBankExportFetchParamSelector = (state: RootState) =>
    state.booking.exportFetchParams || {}
export const cashBankDataSelector = (state: RootState) =>
    state.booking.data || []
export const cashBankMetaSelector = (state: RootState) =>
    state.booking.meta || {}
export const cashBankIsLoading = (state: RootState) => state.booking.isLoading
export const cashBankTabItemSelector = (state: RootState) =>
    state.booking.tabItemSelected

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = cashBankSlice.actions

export default cashBankSlice.reducer
