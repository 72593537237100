import Table from "@components/table/table.component"
import { metaDummy } from "pages/quotes/quotes.static"
import { IAddFiles } from "./add-files.interface"
import { addFilesDummy } from "./add-fles.dummy"
import { addFilesHeader } from "./add-files.static"
import { RowOptionAddFiles } from "pages/sales/components/option/option.component"
import useAddFiles from "./add-files.service"
import FormInput from "@components/form-input/form-input.component"
import Tooltip from "@components/tooltip/tooltip.component"
import { FormikProvider } from "formik"
import Button from "@components/button/button.component"

const AddFiles = () => {
    const {
        loading,
        handlePagination,
        deleteLoading,
        deleteData,
        formik,
    } = useAddFiles()

    return (
        <div>
            <div className="my-2">
                <div className="mx-3 flex justify-between items-center">
                    <div className="">
                        <div className="text-size-L font-bold">Select Shipment File(s)</div>
                        <div className="text-size-M">Select Shipment File that are ready.</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <FormikProvider value={formik}>
                            <form
                                className="w-full flex justify-end  mobile:pt-1 "
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                }}
                            >
                                <Tooltip
                                    text={`Search term must be at least 3 characters`}
                                    isShow={
                                        formik.errors.searchTerm
                                            ? true
                                            : false
                                    }
                                >
                                    <FormInput
                                        autoComplete="auto-off"
                                        hideError={true}
                                        placeholder={'Search (min 3 char)'}
                                        parentDivClassName="!mb-0 min-w-[180px]"
                                        icon="ri-search-line"
                                        name="searchTerm"
                                        onKeyDown={(
                                            event: React.KeyboardEvent<HTMLInputElement>,
                                        ) => {
                                            if (
                                                event.key === 'Backspace' &&
                                                formik.values.searchTerm
                                                    .length === 1
                                            ) {
                                                formik.values.searchTerm =
                                                    ''
                                                formik.handleSubmit()
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </form>
                        </FormikProvider>
                        <i className="ri-filter-2-line cursor-pointer text-size-XL ml-2" />
                    </div>
                </div>
            </div>
            <div className="h-[calc(100vh-20rem)] flex border-t-[1px] ">
                <div className="flex flex-grow flex-col">
                    <Table<IAddFiles>
                        headers={addFilesHeader}
                        data={addFilesDummy}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        checkboxVisible

                        meta={metaDummy}
                        moduleTitle={'Add Files'}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionAddFiles
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.customer)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex border-t-[1px] justify-between px-4 py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => {  }}
                    />

                    <Button
                        type="submit"
                        label="CREATE SALES"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => {  }}
                    />
                </div>
        </div>

    )
}

export default AddFiles
