import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IRadioOption } from '@components/form-radio/form-radio.interface'

// Kategori Item (Kategori pelanggan atau vendor)
export const CategoryItem: IDropdownItem[] = [
    { label: 'Retail', value: 'category-001' },
    { label: 'Wholesale', value: 'category-002' },
    { label: 'Corporate', value: 'category-003' },
    { label: 'Government', value: 'category-004' },
    { label: 'Non-Profit', value: 'category-005' },
]

// Detail Item (Metode pembayaran atau syarat pembayaran)
export const DetailsItem: IDropdownItem[] = [
    { label: 'Net 30', value: 'NET-30' },
    { label: 'Net 60', value: 'NET-60' },
    { label: 'Net 90', value: 'NET-90' },
    { label: 'Cash on Delivery', value: 'COD' },
    { label: 'Prepaid', value: 'PREPAID' },
]

// Mata Uang
export const CurrencyItem: IDropdownItem[] = [
    { label: 'US Dollar (USD)', value: 'USD' },
    { label: 'Euro (EUR)', value: 'EUR' },
    { label: 'Japanese Yen (JPY)', value: 'JPY' },
    { label: 'Australian Dollar (AUD)', value: 'AUD' },
    { label: 'Chinese Yuan (CNY)', value: 'CNY' },
    { label: 'Indonesian Rupiah (IDR)', value: 'IDR' },
]

// Jenis Pajak
export const AccountTaxItem: IDropdownItem[] = [
    { label: 'PPh Pasal 21 - Pajak Penghasilan Karyawan', value: 'PPh21' },
    { label: 'PPh Pasal 22 - Pajak atas Barang Impor', value: 'PPh22' },
    { label: 'PPh Pasal 23 - Pajak atas Jasa dan Dividen', value: 'PPh23' },
    { label: 'PPh Pasal 24 - Kredit Pajak Luar Negeri', value: 'PPh24' },
    { label: 'PPh Pasal 25 - Angsuran Pajak Penghasilan', value: 'PPh25' },
    { label: 'PPh Pasal 26 - Pajak Penghasilan untuk WNA', value: 'PPh26' },
    { label: 'PPh Pasal 29 - Kurang Bayar Pajak Tahunan', value: 'PPh29' },
    { label: 'PPh Pasal 4 Ayat (2) - Pajak Final', value: 'PPh4(2)' },
    { label: 'PPN - Pajak Pertambahan Nilai', value: 'PPN' },
    { label: 'PPnBM - Pajak Barang Mewah', value: 'PPnBM' },
    { label: 'Bea Materai - Pajak atas Dokumen', value: 'BeaMaterai' },
]

// Nama Bank
export const BankNameItem: IDropdownItem[] = [
    { label: 'Bank Central Asia (BCA)', value: 'BCA' },
    { label: 'Bank Mandiri', value: 'Mandiri' },
    { label: 'Bank Negara Indonesia (BNI)', value: 'BNI' },
    { label: 'Bank Rakyat Indonesia (BRI)', value: 'BRI' },
    { label: 'Bank CIMB Niaga', value: 'CIMB' },
    { label: 'Bank Danamon', value: 'Danamon' },
    { label: 'Bank Permata', value: 'Permata' },
    { label: 'Bank OCBC NISP', value: 'OCBC' },
]

// account access options
export const AccountAccessOptions: IRadioOption[] = [
    { value: 'all_user', label: 'All User' },
    { value: 'some_user', label: 'Some User' },
    { value: 'selected_roles', label: 'Selected Roles' },
]
