import { useFormik } from 'formik'
import { useFormTermValidation } from './form-term.validation'
import { IFormTerm } from './form-term.interface'

const useFormTerm = () => {
    const formTermValidation = useFormTermValidation()
    const formik = useFormik<IFormTerm>({
        validationSchema: formTermValidation.schema,
        validateOnChange: true,
        initialValues: formTermValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormTerm

