import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IUser } from 'repository/interface/user.interface'
import { RootState } from 'store'
import { IUserAccessFilter } from './user-access.interface'
import { TabDataUser, tabItemsInitial } from './user-access.static'
// import { formatDateTime } from '@services/common.service'

interface IUserAccessSlice {
    data: IUser[]
    meta: IMeta2
    fetchParams: IFetchParams
    statusSelected: ITabItem
    filter: IUserAccessFilter
    tabItems: ITabItem[]
    tabItemSelected: ITabItem
    tabItemSelectedHelper: string
    // activityLogs: IUserActivities[]
    isLoading: boolean
}

export const initialState: IUserAccessSlice = {
    data: [],
    tabItemSelected: tabItemsInitial[0],
    tabItems: TabDataUser,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    statusSelected: {
        value: 'ALL',
        key: '',
        label: '',
        totalData: 0,
    },
    filter: { pageNumber: 1, pageSize: 50 },
    // activityLogs: [],
    fetchParams: {},
    isLoading: false,
    tabItemSelectedHelper: '',
}

const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setUserData(state, action: PayloadAction<IResponseData<IUser[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const tabItems = action.payload
            return {
                ...state,
                tabItems,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload
            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<IUserAccessFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setFilterSearchUser(state, action: PayloadAction<string>) {
            const search = action.payload
            const filter = { ...state.filter, search }
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
        // setActivityLogs(
        //     state,
        //     action: PayloadAction<IResponseData<IUserActivities[]>>,
        // ) {
        //     const data = action.payload.data
        //     const activityLogs = data?.map((item) => ({
        //         ...item,
        //         time: item.time ? formatDateTime(item.time) : '',
        //     }))
        //     return {
        //         ...state,
        //         activityLogs,
        //     }
        // },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: { ...fetchParam, transport: data.value },
                tabItemSelected: data,
            }
        },
        setSelectedTabItemHelper(state, action: PayloadAction<string>) {
            const data = action.payload
            return {
                ...state,
                tabItemSelectedHelper: data,
            }
        },
    },
})

// these all the variables used for selector
export const usersFetchParamSelector = (state: RootState) =>
    state.user.fetchParams || {}
export const tabStatusFilterSelector = (state: RootState) =>
    state.user.statusSelected || {}
export const filterSelector = (state: RootState) => state.user.filter || {}
export const userDataSelector = (state: RootState) => state.user.data || {}
export const userMeta = (state: RootState) => state.user.meta || {}
export const tabItemsSelector = (state: RootState) => state.user.tabItems || []
export const usersSelectedTabItemSelector = (state: RootState) =>
    state.user.tabItemSelected
export const usersSelectedTabItemHelperSelector = (state: RootState) =>
    state.user.tabItemSelectedHelper
// export const activityLogsSelector = (state: RootState) =>
//     state.user.activityLogs || []
export const usersIsLoading = (state: RootState) => state.user.isLoading

// all actions
export const {
    setUserData,
    setSelectedTabItem,
    setSelectedStatus,
    setFilter,
    setFilterSearchUser,
    setPageNumber,
    setFetchParam,
    setTabItems,
    setSelectedTabItemHelper,
    // setActivityLogs,
} = userAccessSlice.actions

// Reducer
export default userAccessSlice.reducer
