import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { ShipmentType } from '../../shipments.interface'
import moment from 'moment'

// Hold - Still waiting API filter quotes
interface IShipmentFilter
    extends Pick<
        IFetchParams,
        | 'console_category'
        | 'container'
        | 'created_by'
        | 'etd'
        | 'origin'
        | 'fileExportType'
    > {}

export const initialShipmentFilter: IShipmentFilter = {
    fileExportType: 'xls',
    origin: [],
    console_category: [],
    container: [],
    created_by: [],
    etd: {
        from: '',
        to: '',
    },
}

const useShipmentsFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport: boolean = false,
    shipmentType: ShipmentType = 'AIR',
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IShipmentFilter>({
        initialValues: initialShipmentFilter,
        onSubmit: (values) => {
            const payload = {
                fileExportType: values.fileExportType,
                console_category: values.console_category,
                container: values.container,
                etd_start: moment(values.etd?.from).format('YYYY-MM-DD'),
                etd_end: moment(values.etd?.to).format('YYYY-MM-DD'),
                created_by: values.created_by,
            }

            generateFilter(payload)
        },
    })

    useEffect(() => {
        dropdown.getContainerModes(shipmentType)
        dropdown.getConsoleCategory()
        dropdown.getUsers()
    }, [])

    const generateFilter = async (values: IShipmentFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useShipmentsFilter
