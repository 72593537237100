import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import {
    IRoleModule,
} from 'repository/interface/role.interface'
import { getRoleModule } from 'repository/role.repository'

export const useSettingRole = () => {
    const [roleModuleData, setRoleModuleData] = useState<IRoleModule[]>([])
    const dropdownService = useDropdown()

    const getModuleData = () => {
        const data = getRoleModule()
        if (!data) {
            setRoleModuleData([])
            return
        }
        setRoleModuleData(data)
    }
    
    useEffect(() => {
        getModuleData()
    }, [])

    return {
        dropdownService,
        roleModuleData,
    }
}