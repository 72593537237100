import { IMeta } from '@components/pagination/pagination.interface'
import { ISalesInvoice, ISalesOrder } from './sales.interface'

export const dummySalesInvoice: ISalesInvoice[] = [
    {
        created: '2025-01-20T10:15:00Z',
        updated: '2025-01-25T12:30:00Z',
        date: '2025-01-20',
        number: 'INV-1001',
        customer: 'John Doe',
        due_date: '2025-02-05',
        deposit_payment: true,
        balance_due: '500.00',
        total: '1500.00',
        status: 'partially paid',
        message: 'Initial deposit received.',
        attach: 'invoice_1001.pdf',
    },
    {
        created: '2025-01-22T11:45:00Z',
        updated: '2025-01-26T14:20:00Z',
        date: '2025-01-22',
        number: 'INV-1002',
        customer: 'Acme Corporation',
        due_date: '2025-02-10',
        deposit_payment: false,
        balance_due: '2500.00',
        total: '2500.00',
        status: 'open',
        message: 'Full payment pending.',
        attach: 'invoice_1002.pdf',
    },
    {
        created: '2025-01-23T09:30:00Z',
        updated: '2025-01-27T10:50:00Z',
        date: '2025-01-23',
        number: 'INV-1003',
        customer: 'XYZ Ltd.',
        due_date: '2025-02-12',
        deposit_payment: true,
        balance_due: '1200.00',
        total: '3200.00',
        status: 'partially paid',
        message: '50% deposit received.',
        attach: 'invoice_1003.pdf',
    },
    {
        created: '2025-01-24T13:15:00Z',
        updated: '2025-01-28T15:40:00Z',
        date: '2025-01-24',
        number: 'INV-1004',
        customer: 'Jane Smith',
        due_date: '2025-02-15',
        deposit_payment: false,
        balance_due: '1800.00',
        total: '1800.00',
        status: 'overdue',
        message: 'Payment reminder sent.',
        attach: 'invoice_1004.pdf',
    },
    {
        created: '2025-01-25T08:50:00Z',
        updated: '2025-01-29T09:10:00Z',
        date: '2025-01-25',
        number: 'INV-1005',
        customer: 'Tech Solutions',
        due_date: '2025-02-20',
        deposit_payment: true,
        balance_due: '0.00',
        total: '2700.00',
        status: 'paid',
        message: 'Payment completed successfully.',
        attach: 'invoice_1005.pdf',
    },
    {
        created: '2025-01-26T12:20:00Z',
        updated: '2025-01-30T13:50:00Z',
        date: '2025-01-26',
        number: 'INV-1006',
        customer: 'Global Traders',
        due_date: '2025-02-22',
        deposit_payment: false,
        balance_due: '3100.00',
        total: '3100.00',
        status: 'open',
        message: 'Awaiting payment confirmation.',
        attach: 'invoice_1006.pdf',
    },
    {
        created: '2025-01-27T07:10:00Z',
        updated: '2025-01-31T08:30:00Z',
        date: '2025-01-27',
        number: 'INV-1007',
        customer: 'Logistics Hub',
        due_date: '2025-02-25',
        deposit_payment: true,
        balance_due: '400.00',
        total: '1400.00',
        status: 'partially paid',
        message: 'Balance payment due soon.',
        attach: 'invoice_1007.pdf',
    },
    {
        created: '2025-01-28T14:00:00Z',
        updated: '2025-02-01T15:20:00Z',
        date: '2025-01-28',
        number: 'INV-1008',
        customer: 'QuickMart',
        due_date: '2025-02-28',
        deposit_payment: false,
        balance_due: '0.00',
        total: '2900.00',
        status: 'paid',
        message: 'Invoice paid in full.',
        attach: 'invoice_1008.pdf',
    },
    {
        created: '2025-01-29T10:40:00Z',
        updated: '2025-02-02T11:55:00Z',
        date: '2025-01-29',
        number: 'INV-1009',
        customer: 'Green Energy Co.',
        due_date: '2025-03-03',
        deposit_payment: true,
        balance_due: '600.00',
        total: '2600.00',
        status: 'partially paid',
        message: 'Payment processing.',
        attach: 'invoice_1009.pdf',
    },
    {
        created: '2025-01-30T16:30:00Z',
        updated: '2025-02-03T17:45:00Z',
        date: '2025-01-30',
        number: 'INV-1010',
        customer: 'Blue Sky Enterprises',
        due_date: '2025-03-05',
        deposit_payment: false,
        balance_due: '2100.00',
        total: '2100.00',
        status: 'overdue',
        message: 'Payment overdue alert.',
        attach: 'invoice_1010.pdf',
    },
]

export const dummySalesOrder: ISalesOrder[] = [
    {
        created: '2025-01-20T10:15:00Z',
        updated: '2025-01-25T12:30:00Z',
        date: '2025-01-20',
        number: 'INV-1001',
        customer: 'John Doe',
        due_date: '2025-02-05',
        deposit_payment: true,
        balance_due: '500.00',
        total: '1500.00',
        status: 'partially paid',
        message: 'Initial deposit received.',
        attach: 'invoice_1001.pdf',
    },
    {
        created: '2025-01-22T11:45:00Z',
        updated: '2025-01-26T14:20:00Z',
        date: '2025-01-22',
        number: 'INV-1002',
        customer: 'Acme Corporation',
        due_date: '2025-02-10',
        deposit_payment: false,
        balance_due: '2500.00',
        total: '2500.00',
        status: 'open',
        message: 'Full payment pending.',
        attach: 'invoice_1002.pdf',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
