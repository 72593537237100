import { ITabItem } from '@components/tab/tab.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getShipmentDetail,
    updateShipment,
    updateShipmentStatus,
} from 'repository/shipment.repository'
import {
    additionalInfoTabs,
    shipmentDetailTabs,
} from './detail-shipment.static'
import {
    IFormShipmentDetail,
    IFormShipmentDetailPayload,
    useShipmentDetailValidation,
} from 'form-validation/shipment-details.validation'
import { useFormik } from 'formik'
import DetailShipmentForm from './detail-shipment-form.component'
import { IOrganisation } from 'form-validation/form-contact.validation'
import { IAddress } from 'repository/interface/address.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { fetchOrganisationsAddress } from 'repository/dropdown.repository'
import ItemList from './item-list/item-list.component'
import { useSelector } from 'react-redux'
import {
    setShipmentDetailData,
    shipmentDetailSelector,
} from './detail-shipment.slice'
import { useAppDispatch } from 'store'
import PackLines from './pack-lines/pack-lines.component'
import Notes from './notes/notes.component'
import Edocs from './edocs/edocs.component'
import moment from 'moment'
import { IConsolidation } from 'repository/interface/consolidation.interface'

const useDetailShipment = () => {
    const { id } = useParams()
    const dropdownService = useDropdown()
    const nav = useNavigate()
    const dispatch = useAppDispatch()

    // Selector
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // States
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [selectedTabItems, setSelectedTabItems] = useState<ITabItem>(
        shipmentDetailTabs[0],
    )
    const [selectedTabAdditionalInfo, setSelectedTabAdditionalInfo] =
        useState<ITabItem>(additionalInfoTabs[0])
    const [isDetailLoading, setDetailLoading] = useState(false)

    const [shipperAddress, setShipperAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [consigneeAddress, setConsigneeAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [shipmentDetail, setShipmentDetail] = useState<
        IFormShipmentDetail | undefined
    >()
    const [shipmentStatus, setShipmentStatus] = useState<string>('')

    // formik
    const shipmentDetailValidation = useShipmentDetailValidation()
    const formik = useFormik<IFormShipmentDetail>({
        validationSchema: shipmentDetailValidation.schema,
        validateOnChange: true,
        initialValues: shipmentDetailValidation.initialValue,
        onSubmit: (values) => {
            submitForm(values)
        },
    })

    const getDetailData = async () => {
        const code = id
        setDetailLoading(true)
        if (!code) {
            setDetailLoading(false)
            nav('/inquiry')
            return
        }
        const response = await getShipmentDetail(code)
        if (!response) {
            setDetailLoading(false)
            nav(-1)
            return
        }

        // Get Data
        const data = response.data
        dispatch(setShipmentDetailData(data))

        // Get set to form
        let shipperAddress = ''
        let consigneeAddres = ''
        if (data.shipper_address) {
            const d = data.shipper_address
            shipperAddress = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
        }
        if (data.consignee_address) {
            const d = data.consignee_address
            consigneeAddres = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
        }

        const formValue: IFormShipmentDetail = {
            transport_mode: data.transport_mode ?? 'AIR',
            container_mode: data.container_mode ?? '',
            container_number: data.container_number ?? '',
            volume_measure: data.volume ?? 0,
            volume_unit: data.volume_unit?.code ?? '',
            weight_measure: data.weight ?? 0,
            weight_unit: data.weight_unit?.code ?? '',
            service_level: data.service_level ?? '',
            shippers_ref: data.shipper_ref ?? '',
            payment_type: data.payment_type ?? '',
            house_bill: data.house_bill ?? '',
            house_bill_status: data.house_bill_status ?? '',
            origin_location: data.origin_location?.code ?? '',
            origin_country: data.origin_location?.country ?? '',
            origin_state: data.origin_location?.name ?? '',
            destination_location: data.destination_location?.code ?? '',
            destination_country: data.destination_location?.country ?? '',
            destination_state: data.destination_location?.name ?? '',
            etd: data.etd ?? '',
            atd: data.atd ?? '',
            eta: data.eta ?? '',
            ata: data.ata ?? '',
            shipper: data.shipper?.code ?? '',
            shipper_address: data.shipper_address?.code ?? '',
            shipper_address_1: shipperAddress,
            consignee: data.consignee?.code ?? '',
            consignee_address: data.consignee_address?.code ?? '',
            consignee_address_1: consigneeAddres ?? '',
            seal_number: data.seal_number ?? '',
            charges_additional_terms: data.additional_term ?? '',
            charges_apply: data.charges_apply ?? '',
            charges_inco_term: data.incoterm ?? '',
            consolidation: data.consolidation?.code ? true : false,
            consolidation_console_category:
                data.consolidation?.console_category?.code ?? '',
            consolidation_console_type: data.consolidation?.console_type ?? '',
            consolidation_id: data.consolidation?.code ?? '',
            contacts_info_agent: data.agent,
            contacts_info_notify: data.notify_contacts,
            delivery_actual_deliver: data.act_delivery_date ?? '',
            delivery_estimated: data.est_delivery_date ?? '',
            delivery_included: data.with_delivery ?? false,
            feeder: data.feeder,
            marks_and_numbers: data.marks_and_numbers ?? '',
            pick_up_actual: data.act_pick_up_date ?? '',
            pick_up_estimated: data.est_pick_up_date ?? '',
            pick_up_included: data.include_pick_up ?? false,
            pick_up_warehouse_transit: '',
            status: data.status ?? '',
        }

        setShipmentDetail(formValue)
        formik.setValues(formValue)
        setShipmentStatus(data.status ?? '')
        setDetailLoading(false)
    }

    const addNewNotify = () => {
        const notifyContactData = formik.values.contacts_info_notify ?? []
        const newContact: IOrganisation = {
            code: '',
            name: '',
        }

        formik.setValues((state) => ({
            ...state,
            contacts_info_notify: [...notifyContactData, newContact],
        }))
    }

    const deleteNotifyByIndex = (index: number) => {
        const notifyData = formik.values.contacts_info_notify ?? []
        const updateData = notifyData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, notify_contacts: updateData }))
    }

    const getContent = () => {
        const activeTab = selectedTabItems.value
        if (activeTab === 'details') {
            return (
                <DetailShipmentForm
                    formik={formik}
                    selectedAdditionalInfoTab={selectedTabAdditionalInfo}
                    setAdditionalInfoTab={(val) =>
                        setSelectedTabAdditionalInfo(val)
                    }
                    addNewNotify={() => {
                        addNewNotify()
                    }}
                    deleteNotifyByIndex={(index) => {
                        deleteNotifyByIndex(index)
                    }}
                    shipperAddress={shipperAddress}
                    consigneeAddress={consigneeAddress}
                    addressHandling={(a, b) => {
                        addressHandling(a, b)
                    }}
                />
            )
        } else if (activeTab === 'item_list') {
            return <ItemList />
        } else if (activeTab === 'pack_lines') {
            return <PackLines />
        } else if (activeTab === 'notes') {
            return <Notes />
        } else if (activeTab === 'eDocumentation') {
            return <Edocs />
        } else {
            return <div></div>
        }
    }

    const addressHandling = async (
        selectedCode: string,
        type: 'shipper' | 'consignee',
    ) => {
        const response = await fetchOrganisationsAddress({
            organisationCode: selectedCode,
        })
        if (!response) return

        const dropdown: IDropdownItem<IAddress>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        if (type === 'consignee') {
            setConsigneeAddres(dropdown)
        } else {
            setShipperAddres(dropdown)
        }
    }

    const resetForm = () => {
        if (!shipmentDetail) return

        // Location and destination data
        const locationData = dropdownService.unlocodesData
        const selectedOrigin = locationData.find(
            (d) => d.value === shipmentDetail.origin_location,
        )
        const selectedDestination = locationData.find(
            (d) => d.value === shipmentDetail.destination_location,
        )

        formik.setValues({
            ...shipmentDetail,
            origin_country: selectedOrigin?.additionalData?.country ?? '-',
            origin_state: selectedOrigin?.additionalData?.name ?? '-',
            destination_country:
                selectedDestination?.additionalData?.country ?? '-',
            destination_state: selectedDestination?.additionalData?.name ?? '-',
        })
    }

    const submitForm = async (value: IFormShipmentDetail) => {
        setIsSubmitLoading(true)

        const payload: IFormShipmentDetailPayload = {
            volume: value.volume_measure.toString(),
            volume_unit: {
                code: value.volume_unit,
            },
            weight: value.weight_measure?.toString() || '0',
            weight_unit: {
                code: value.weight_unit || 'KILOGRAM',
            },
            status: value.status,
            container_mode: value.container_mode,
            shipper: {
                code: value.shipper,
            },
            shipper_address: {
                code: value.shipper_address,
            },
            include_pick_up: value.pick_up_included ?? false,
            consignee: {
                code: value.consignee,
            },
            consignee_address: {
                code: value.consignee_address,
            },
            with_delivery: value.delivery_included ?? false,
            etd: moment(value.etd).local().format('YYYY-MM-DD 00:00:00'),
            eta: moment(value.eta).local().format('YYYY-MM-DD 00:00:00'),
            origin_location: {
                code: value.origin_location,
            },
            destination_location: {
                code: value.destination_location,
            },
            agent: {
                code: value.contacts_info_agent?.code ?? '',
            },
            payment_type: value.payment_type,
            incoterm: value.charges_inco_term ?? '',
            service_level: value.service_level,
            house_bill_status: value.house_bill_status,
            marks_and_numbers: value.marks_and_numbers ?? '',
            atd: moment(value?.atd).local().format('YYYY-MM-DD 00:00:00'),
            ata: moment(value?.ata).local().format('YYYY-MM-DD 00:00:00'),
            est_pick_up_date:
                moment(value.pick_up_estimated)
                    .local()
                    .format('YYYY-MM-DD 00:00:00') ?? '',
            act_pick_up_date:
                moment(value.pick_up_actual)
                    .local()
                    .format('YYYY-MM-DD 00:00:00') ??
                '' ??
                '',
            est_delivery_date:
                moment(value.delivery_estimated)
                    .local()
                    .format('YYYY-MM-DD 00:00:00') ??
                '' ??
                '',
            act_delivery_date:
                moment(value.delivery_actual_deliver)
                    .local()
                    .format('YYYY-MM-DD 00:00:00') ??
                '' ??
                '',
            additional_term: value.charges_additional_terms ?? '',
            charges_apply: value.charges_apply ?? '',
            container_number: value.container_number,
            shipper_ref: value.shippers_ref,
            house_bill: value.house_bill,
            seal_number: value.seal_number ?? '',
            emkl: value.emkl ?? '',
            carrier: {
                code: value.carrier_company ?? '',
            },
            feeder: value.feeder ?? '',
            carrier_number: value.carrier_number ?? '',
            carrier_date: value.carrier_date ?? '',
            consolidation: {
                code: value.consolidation_id ?? '',
            },
        }

        try {
            const code = id ?? ''
            await updateShipment(code, payload)
            setIsSubmitLoading(false)
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const changeShipmentStatus = (status: string) => {
        setShipmentStatus(status)
        updateShipmentStatus(id ?? '', status ?? '')
    }

    useEffect(() => {
        getDetailData()
        dropdownService.getBookingStatuses()
        dropdownService.getTransportModes()
        dropdownService.getServiceLevel()
        dropdownService.getPaymentTypes()
        dropdownService.getIncoterms()
        dropdownService.getChargesApplies()
        dropdownService.getVolumeUnit()
        dropdownService.getPaymentTypes()
        dropdownService.getUnlocos()
        dropdownService.getShipperOrganisations()
        dropdownService.getConsigneeOrganisations()
        dropdownService.getCurrency()
        dropdownService.getHouseBillStatus()
        dropdownService.getConsoleCategory()
        dropdownService.getConsoleType()
        dropdownService.getConsolidation()
        dropdownService.getAgentOrganisations()
        dropdownService.getShipmentStatus()
    }, [])

    useEffect(() => {
        if (!shipmentDetailData?.transport_mode) {
            return
        }
        dropdownService.getContainerModes(shipmentDetailData.transport_mode)
    }, [shipmentDetailData])

    return {
        nav,
        setSelectedTabItems,
        getContent,
        addressHandling,
        resetForm,
        changeShipmentStatus,
        shipmentStatus,
        selectedTabAdditionalInfo,
        formik,
        isDetailLoading,
        shipmentDetailData,
        selectedTabItems,
        isSubmitLoading,
    }
}

export default useDetailShipment
