import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'

interface IChartOfAccountSlice {
    data: any[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    isLoading: boolean
}
export const initialState: IChartOfAccountSlice = {
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
}

const chartOfAccountSlice = createSlice({
    name: 'chartOfAccount',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<any[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    status: data.value,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
    },
})

// these all the variables used for selector
export const ChartOfAccountFetchParamSelector = (state: RootState) =>
    state.chartOfAccount.fetchParams || {}
export const ChartOfAccountExportFetchParamSelector = (state: RootState) =>
    state.chartOfAccount.exportFetchParams || {}
export const ChartOfAccountDataSelector = (state: RootState) =>
    state.chartOfAccount.data || []
export const ChartOfAccountMetaSelector = (state: RootState) =>
    state.chartOfAccount.meta || {}
export const ChartOfAccountIsLoading = (state: RootState) => state.chartOfAccount.isLoading

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = chartOfAccountSlice.actions

export default chartOfAccountSlice.reducer
