import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useModal } from '@components/modal/modal.service'
import { Toast } from '@components/toast/toast.component'
import useDropdown from 'common/dropdown/dropdown.service'
import {
    IFUser,
    userFormInitial,
    userFormValidation,
} from 'form-validation/user.validation'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getRegion } from 'repository/common.repository'
import { userDetailDataSelector } from '../user-access-detail/user-access-detail.slice'
import { IUserDetail, IUserUpdate } from 'repository/interface/user.interface'
import { createUser, updateUser } from 'repository/user.repository'

import useUserAccess from '../user-access.service'
import { getRoleModule } from 'repository/role.repository'
import { IRoleModule } from 'repository/interface/role.interface'

export interface IUseUserAccessForm {
    isNew: boolean
    isProfilePage: boolean
}
export const useUserAccessForm = ({ isNew }: IUseUserAccessForm) => {
    const { refreshSummary } = useUserAccess()
    const nav = useNavigate()
    const { id } = useParams()
    const userDetailData = useSelector(userDetailDataSelector)
    const [province, setProvince] = useState<IDropdownItem[]>([])
    const [city, setCity] = useState<IDropdownItem[]>([])
    const [roleModuleData, setRoleModuleData] = useState<IRoleModule[]>([])
    const [tabs, setTabs] = useState<'details' | 'configurasi'>('configurasi')
    const [selectedModule, setSelectedModule] = useState<
        IRoleModule | undefined
    >({
        enable: true,
        key: 'dashboard',
        label: 'Dashboard',
        action: {
            view: true,
        },
    })
    const settingRoleModalService = useModal()
    const createRoleModalservice = useModal()
    const dropdownService = useDropdown()

    const dataToFormik = (data: IUserDetail) => {
        const newData: IFUser = {
            title: data.title,
            full_name: data.name,
            job_title: data.job_title,
            job_category: data?.job_category?.code || '',
            email: data.email,
            user_name: data.user_name,
            role: data.role.code,
            site: data.site.code,
            country: data.address?.country?.code,
            state: data.address?.state?.code,
            city: data.address?.city?.code,
            street: data.address?.street,
            status: data.status,
            mobile: data.mobile,
            emergency_contact_phone: data.emergency_contact_phone,
        }
        return newData
    }
    const formikToPayload = (data: IFUser) => {
        const newData: IUserUpdate = {
            title: data.title,
            name: data.full_name,
            job_title: data.job_title,
            job_category: { code: data?.job_category },
            email: data.email,
            status: data.status,
            role: { code: data.role },
            site: { code: data.site },
            address: {
                city: { code: data.city },
                state: { code: data.state },
                country: { code: data.country },
                street: data.street,
            },
            mobile: data.mobile,
            emergency_contact_phone: data.emergency_contact_phone,
            user_name: data.user_name,
        }
        return newData
    }

    const formikModule = useFormik<IFUser>({
        validationSchema: userFormValidation,
        initialValues: isNew ? userFormInitial : dataToFormik(userDetailData),
        onSubmit: async (values) => {
            let response
            if (isNew) {
                response = await createUser(formikToPayload(values), false)
            } else {
                response = await updateUser(id, formikToPayload(values), false)
            }

            if (!response) {
                const fullname = values.full_name
                const message = isNew
                    ? `Failed to create a User.`
                    : `"${fullname}" failed to Updated.`
                Toast({
                    header: 'Failed!',
                    message: message,
                    type: 'error',
                })
                return
            }
            const fullname = values.full_name
            const title = isNew
                ? `User "${fullname}" Created`
                : `User "${fullname}" Updated`
            const message = isNew
                ? `"${fullname}" has been successfully Created.`
                : `"${fullname}" has been successfully Updated.`
            Toast({
                header: title,
                message: message,
                type: 'success',
            })

            refreshSummary()
        },
    })

    const getProvinceDropdown = async () => {
        const region = getRegion()
        const dropdown: IDropdownItem[] = region.map((d) => {
            return { label: d.province, value: d.province } as IDropdownItem
        })
        setProvince(dropdown)
    }

    const getCityDropdown = async (selectedProvince: string) => {
        const region = getRegion()
        const province = region.find((d) => d.province === selectedProvince)
        if (!province) {
            setCity([])
            return
        }

        const dropdown = province.city.map((d) => {
            return { label: d, value: d } as IDropdownItem
        })
        setCity(dropdown)
    }

    const getModuleData = () => {
        const data = getRoleModule()
        if (!data) {
            setRoleModuleData([])
            return
        }
        setRoleModuleData(data)
    }

    // Return ----------------------------------------------------------------------

    useEffect(() => {
        getModuleData()
    }, [])

    return {
        formikModule,
        province,
        city,
        settingRoleModalService,
        createRoleModalservice,
        dropdownService,
        tabs,
        roleModuleData,
        selectedModule,
        setTabs,
        refreshSummary,
        nav,
        getCityDropdown,
        getProvinceDropdown,
    }
}
