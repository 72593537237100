import { useFormik } from 'formik'
import { formLineInitial, useCreateCashAndBankValidation } from './create-cash-and-bank.validation'
import { IFormCreateCashAndBank, ITaxPerLine } from './create-cash-and-bank.interface'
import { useEffect, useState } from 'react';
import { IDropdownItem } from '@components/dropdown/dropdown.interface';
import { useModal } from '@components/modal/modal.service';
import { useLocation, useNavigate } from 'react-router-dom';

const useCreateCashAndBank = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const getTypeCreate = location.pathname.replace(/^\/?cash-and-bank\/create-/, "");
    const [togglePriceIncludeTax, setTogglePriceIncludeTax] = useState<boolean>(false);
    const [rate, setRate] = useState(16000);
    const [optionsDiscount, setOptionsDiscount] = useState<IDropdownItem[]>([
        { label: '%', value: '%' },
        { label: 'Rp', value: 'Rp' },
    ])

    //Modal
    const modalServiceContact = useModal()
    const modalServiceProduct = useModal()
    const modalServiceTax = useModal()
    const modalServiceTransactionNo = useModal()

    // formik
    const salesValidation = useCreateCashAndBankValidation()
    const formik = useFormik<IFormCreateCashAndBank>({
        validationSchema: salesValidation.schema,
        validateOnChange: true,
        initialValues: salesValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })
    const { values, setFieldValue } = formik
    const { linePrice } = values

    // function to update exchange rate based on currency
    const updateExchangeRate = () => {
        switch (values.currency) {
            case '$':
                setRate(16000);
                break;
            case 'CN¥':
                setRate(2400);
                break;
            case '€':
                setRate(18000);
                break;
            case 'JP¥':
                setRate(110);
                break;
            case 'AU$':
                setRate(12000);
                break;
            default:
                setRate(16000);
        }
    };

    // Use Effect for updating exchange rate and optionsDiscount based on currency
    useEffect(() => {
        updateExchangeRate();
        setOptionsDiscount([
            { label: "%", value: "%" },
            { label: values.currency, value: values.currency },
        ]);
    }, [values.currency]);

    // Add new line if all required fields are filled
    useEffect(() => {
        const lastLine = linePrice[linePrice.length - 1];
        console.log(linePrice)
        if (
            lastLine &&
            lastLine.payment &&
            lastLine.tax &&
            lastLine.amount
        ) {
            formik.setFieldValue('linePrice', [
                ...linePrice,
                formLineInitial,
            ]);
        }
    }, [linePrice, formik]);

    // Effect for updating price item in line
    useEffect(() => {
        if (!values.linePrice?.length) return;

        let subtotal = 0;
        let totalTax = 0;
        let total_withholding_discount = 0;
        const taxPerLine: ITaxPerLine[] = [];

        const updatedLinePrice = values.linePrice.map((line) => {
            const taxRate = parseFloat(line.tax?.match(/\d+(\.\d+)?/g)?.[0] || "0");
            const amount = parseFloat(line.amount) || 0;
            let taxAmount = 0;
            let lineSubtotal = 0;

            if (togglePriceIncludeTax) {
                const unitPriceExcludingTax = amount / (1 + taxRate / 100);
                lineSubtotal = unitPriceExcludingTax;
                taxAmount = amount - unitPriceExcludingTax;
            } else {
                lineSubtotal = amount;
                taxAmount = (taxRate / 100) * amount;
            }

            subtotal += lineSubtotal;
            totalTax += taxAmount;

            if (line.tax) {
                taxPerLine.push({
                    name: line.tax || "Tax",
                    price: taxAmount.toFixed(2).toString(),
                });
            }

            return { ...line };
        });

        const total = subtotal + totalTax;

        if (values.withholding_tax.withholding_data) {
            const { qty, unit } = values.withholding_tax.withholding_discount;
            const discountQty = parseFloat(qty) || 0;

            if (unit === "%") {
                total_withholding_discount = total * (discountQty / 100);
            } else {
                total_withholding_discount = discountQty;
            }
        }

        const balanceDue = total - total_withholding_discount;

        if (JSON.stringify(updatedLinePrice) !== JSON.stringify(values.linePrice)) {
            setFieldValue("linePrice", updatedLinePrice);
        }

        setFieldValue("sub_total", subtotal);
        setFieldValue("total_tax", totalTax);
        setFieldValue("tax_per_line", taxPerLine);
        setFieldValue("total", total);
        setFieldValue("withholding_tax.total_withholding_discount", total_withholding_discount);
        setFieldValue("balance_due", balanceDue);
        setFieldValue("price_include_tax", togglePriceIncludeTax);

    }, [values.linePrice, setFieldValue, togglePriceIncludeTax, values.withholding_tax]);

    return {
        formik,
        rate,
        togglePriceIncludeTax,
        optionsDiscount,
        modalServiceContact,
        modalServiceProduct,
        getTypeCreate,
        modalServiceTax,
        modalServiceTransactionNo,
        navigate,
        setTogglePriceIncludeTax,
    }
}

export default useCreateCashAndBank
