import Button from '@components/button/button.component'
import './user-access-detail.style.css'
import AvatarCircle from '@components/avatar-circle/avatar-circle.component'
import { UserSettingIcon } from './components/user-settings-icon.component'
// import { useNavigate, useParams } from 'react-router-dom'
import Table from '@components/table/table.component'
import { IUserActivityLogs } from '../user-access.interface'
import { userActivityHeaders } from '../user-access.static'
// import { dummyActivittyUser } from '../user-access.dummy'
import SuspendUserModal from '../component/suspend-user-modal.component'
import useUserAccessDetail from './user-access-detail.service'
import { Toast } from '@components/toast/toast.component'
const UserAccessDetail = () => {
    const {
        sendEmail,
        navigate,
        extraAction,
        modalConfirmationService,
        userDetailData,
        userActivityLogs,
        id,
    } = useUserAccessDetail()
    return (
        <>
            <div className={`user-detail transition-opacity duration-500`}>
                <div className="flex-col content-1 container-global content-full-height">
                    {/* Flex 1, 2 rows */}

                    <div className="flex flex-row p-4 border-b justify-between  gap-4">
                        <AvatarCircle name={userDetailData.name} size={50} />
                        <div className="flex-1 flex flex-row leading-none self-center font-semibold">
                            <div className="text-size-M px-2 pt-1">
                                {userDetailData.name}
                            </div>
                            <div className="text-size-XS themes-bg-brand-v1 themes-text-brand-v7 rounded-sm px-2 py-1.5 font-semibold">
                                {userDetailData.role.name}
                            </div>
                        </div>
                        <Button
                            variant="brand-inverse"
                            className="!w-[160px]"
                            label={'Edit User'}
                            onClick={() => {
                                navigate(`/manage-users/update/${id}`)
                            }}
                            useUpperCase={true}
                        />
                        <UserSettingIcon
                            isActive={
                                userDetailData.status === 'ACTIVE'
                                    ? true
                                    : false
                            }
                            suspendFunction={() => {
                                modalConfirmationService.openModalHandling()
                            }}
                        />
                    </div>
                    <div className="flex-1 flex sm:flex-wrap sm:overflow-y-auto min-h-0">
                        <div className="content-1-A !overflow-y-auto">
                            {/* Image Session */}

                            {/* Detail User Session */}
                            <div className="flex-1 flex ">
                                <div className="flex-1 ">
                                    <div className="flex flex-col gap-4">
                                        <ValueLabel
                                            label={'User ID'}
                                            value={userDetailData.code}
                                        />
                                        <div className="flex justify-between">
                                            <ValueLabel
                                                label={'Email Address'}
                                                value={userDetailData.email}
                                            />
                                            <div
                                                className="flex gap-2 items-end themes-text-gray-v6 cursor-pointer"
                                                onClick={() => {
                                                    sendEmail()
                                                }}
                                            >
                                                <i className="ri-mail-line"></i>
                                            </div>
                                        </div>
                                        {/* Border */}
                                        <div className="border-t "></div>
                                        <ValueLabel
                                            label={'Job Title'}
                                            value={userDetailData.job_title}
                                        />
                                        <ValueLabel
                                            label={'Job Category'}
                                            value={
                                                userDetailData?.job_category
                                                    ?.name
                                            }
                                        />
                                        {/* Border */}
                                        <div className="border-t "></div>
                                        <div className="flex justify-between">
                                            <ValueLabel
                                                label={'Phone Number'}
                                                value={
                                                    userDetailData?.mobile
                                                        ? `${userDetailData.mobile.country_code || ''} ${userDetailData.mobile.number || ''}`
                                                        : '-'
                                                }
                                            />
                                            <div
                                                className="flex gap-2 items-end themes-text-gray-v6 cursor-pointer"
                                                onClick={() => {
                                                    const phoneNumber = `${userDetailData?.mobile
                                                            ? `${userDetailData.mobile.country_code || ''} ${userDetailData.mobile.number || ''}`
                                                            : '-'
                                                        }`
                                                    navigator.clipboard.writeText(
                                                        phoneNumber,
                                                    )
                                                    Toast({
                                                        header: 'Copy Successful!',
                                                        message:
                                                            'Successfully copied the phone number',
                                                        type: 'info',
                                                    })
                                                }}
                                            >
                                                <i className="ri-file-copy-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <ValueLabel
                                                label={'Address'}
                                                value={
                                                    userDetailData?.address
                                                        ? `${userDetailData.address.street || ''} ${userDetailData.address.city?.name || ''} ${userDetailData.address.state?.name || ''} ${userDetailData.address.country?.name || ''} ${userDetailData.address.post_code || ''}`.trim()
                                                        : '-'
                                                }
                                            />
                                            <div
                                                className="flex gap-2 items-end themes-text-gray-v6 cursor-pointer"
                                                onClick={() => {
                                                    const data = `${userDetailData?.address
                                                            ? `${userDetailData.address.street || ''} ${userDetailData.address.city?.name || ''} ${userDetailData.address.state?.name || ''} ${userDetailData.address.country?.name || ''} ${userDetailData.address.post_code || ''}`.trim()
                                                            : '-'
                                                        }`
                                                    navigator.clipboard.writeText(
                                                        data,
                                                    )
                                                    Toast({
                                                        header: 'Copy Successful!',
                                                        message:
                                                            'Successfully copied the address',
                                                        type: 'info',
                                                    })
                                                }}
                                            >
                                                <i className="ri-file-copy-line"></i>
                                            </div>
                                        </div>

                                        {/* Border */}
                                        <div className="border-t "></div>
                                        <ValueLabel
                                            label={'Site'}
                                            value={userDetailData.site.name}
                                        />
                                        <ValueLabel
                                            label={'User Role'}
                                            value={userDetailData.role.name}
                                        />
                                        <ValueLabel
                                            label={'Status'}
                                            value={userDetailData.status}
                                        />
                                        <ValueLabel
                                            label={'Last Active'}
                                            value={userDetailData.updated}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-2 border-l sm:border-t !p-0 grid ">
                            {/* Flex 2, 1 rows */}

                            <div className="flex gap-4 pt-4 pl-4 w-full">
                                <div className="flex-grow text-size-L pb-1 pt-1 font-bold">
                                    USER ACTIVITY LOGS
                                </div>
                            </div>

                            {/* Border */}
                            <div className="border-b h-0"></div>

                            <div className="flex-1 min-h-0 overflow-auto  w-full grid ">
                                <Table<IUserActivityLogs>
                                    headers={userActivityHeaders}
                                    data={userActivityLogs}
                                    moduleTitle={'User'}
                                    usePagination={false}
                                    enableExport={false}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {' '}
                        <div className="flex justify-between p-4 border-t">
                            <Button
                                label="Back"
                                variant="default"
                                className="w-btnSmallWidth"
                                onClick={() => {
                                    navigate('/manage-users')
                                }}
                            />
                        </div>
                    </div>
                </div>

                <SuspendUserModal
                    data={userDetailData}
                    modalService={modalConfirmationService}
                    isActive={userDetailData.status === 'ACTIVE' ? true : false}
                    onSubmit={function async(): void {
                        try {
                            extraAction(userDetailData.status)
                        } catch (e) {
                            console.log(e)
                        }
                    }}
                />
            </div>
        </>
    )
}

const ValueLabel = ({
    label = ' - ',
    value = ' - ',
}: {
    label?: string
    value?: string
}) => {
    const labelShow = label === '' || !label ? '-' : label
    const valueShow = value === '' || !value ? '-' : value
    return (
        <div className="leading-none ">
            <p className="!text-size-XS font-light pb-1">{labelShow}</p>
            <p className="!text-size-M font-normal">{valueShow}</p>
        </div>
    )
}

export default UserAccessDetail
