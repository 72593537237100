import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { FormikContextType, FormikProvider } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormEdoc } from 'form-validation/organisation-edocs.validation'
type DropdownProps = ReturnType<typeof useDropdown>

const EDocumentationListForm = ({
    dropdownService,
    formik,
}: {
    dropdownService: DropdownProps
    formik?: FormikContextType<IFormEdoc>
}) => {
    return (
        <div>
            <FormInput
                label="File Name"
                placeholder="Enter File Name"
                name="filename"
                parentDivClassName="col-span-2"
                className="!mb-0"
                useUppercaseLabel={true}
            />
            <FormDropdown
                label="Document Type"
                name="document_type"
                placeholder="Select Edoc Type"
                options={dropdownService.documentTypeData}
                useUppercaseLabel={true}
            />
            <FormInput
                label="Path"
                placeholder="Enter Path"
                name="path"
                parentDivClassName="col-span-2"
                className="!mb-0"
                useUppercaseLabel={true}
            />
            <FormTextarea
                label="Description (Optional)"
                placeholder="Enter Description"
                name="description"
                parentDivClassName="col-span-2"
                useUppercaseLabel={true}
            />
        </div>
    )
}

export default EDocumentationListForm
