import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { tabItemStatic } from './expenses.static'

interface IExpensesSlice {
    data: any[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    isLoading: boolean
    tabItemSelected: ITabItem
}
export const initialState: IExpensesSlice = {
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
    tabItemSelected: tabItemStatic[0]
}

const expensesSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<any[]>>) {
            console.log(state, 'testtt')
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    status: data.value,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
    },
})

// these all the variables used for selector
export const expensesFetchParamSelector = (state: RootState) =>
    state.expense.fetchParams || {}
export const expensesExportFetchParamSelector = (state: RootState) =>
    state.expense.exportFetchParams || {}
export const expensesDataSelector = (state: RootState) =>
    state.expense.data || []
export const expensesMetaSelector = (state: RootState) =>
    state.expense.meta || {}
export const expensesIsLoading = (state: RootState) => state.expense.isLoading
export const expensesTabItemSelector = (state: RootState) =>
    state.expense.tabItemSelected

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = expensesSlice.actions

export default expensesSlice.reducer
