import { useState } from 'react'
import { ITitipanDetilsFilter } from '../titipan.interface'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import useOverlay from '@components/overlay/overlay.service'

export const initialBookingFilter: ITitipanDetilsFilter = {
    date: '',
    type: [],
    bank: [],
}

const useTitipanDetails = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [tabData, setTabData] = useState<string>('idr')

    const filterOverlay = useOverlay()

        const formik = useFormik<ITitipanDetilsFilter>({
            initialValues: initialBookingFilter,
            onSubmit: (values) => {
                console.log(values)
            },
        })

    return {
        formik,
        loading,
        tabData,
        filterOverlay,
        setTabData,
        navigate
    }
}

export default useTitipanDetails
