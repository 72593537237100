import Table from '@components/table/table.component'
import useSales from './sales.service'
import {
    salesInvoiceSummaryHeader,
    salesOrderSummaryHeader,
    tabItemStatic,
} from './sales.static'
import { dummySalesInvoice, dummySalesOrder, metaDummy } from './sales.dummy'
import { ISalesOrder, ISalesInvoice } from './sales.interface'
import TabMaster2 from 'pages/components/tab-master2/tab-master2.component'
import FilterOverlay from 'pages/sales/components/filter/filter-overlay.component'
import {
    RowOptionInvoice,
    RowOptionOrder,
} from './components/option/option.component'

const Sales = () => {
    const {
        selectedTabItem,
        loading,
        fetchParam,
        exportModalService,
        filterOverlay,
        tabFilter,
        deleteLoading,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        deleteData,
    } = useSales()

    const SalesDropdown = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                >
                    CREATE SALES
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute left-0 mt-2 w-[200px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/sales/create-invoice')}
                    >
                        SALES INVOICE
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/sales/create-order')}
                    >
                        SALES ORDER
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster2
                    items={tabItemStatic || []}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <FilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={() => {}}
                            />
                        ),
                    }}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Sales ID '}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    additionalButton1={<>{SalesDropdown()}</>}
                />
                {tabFilter?.value === 'ORDER' ? (
                    <Table<ISalesOrder>
                        headers={salesOrderSummaryHeader}
                        data={dummySalesOrder}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        meta={metaDummy}
                        moduleTitle={'Sales'}
                        exportModalService={exportModalService}
                        onRowClick={(item) =>{
                            navigate(`/sales/details/${item.number}`)
                        }}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionOrder
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.message)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                ) : (
                    <Table<ISalesInvoice>
                        headers={salesInvoiceSummaryHeader}
                        data={dummySalesInvoice}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        meta={metaDummy}
                        moduleTitle={'Sales'}
                        exportModalService={exportModalService}
                        onRowClick={(item) =>{
                            navigate(`/sales/details/${item.number}`)
                        }}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionInvoice
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.message)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                )}
            </div>
        </div>
    )
}

export default Sales
