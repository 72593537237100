import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './unlocodes.slice'
import { IFetchParams } from 'common/common.interface'
import {
    IFHSCode,
    useHSCodeValidation,
} from 'form-validation/hs-code.validation'
import { getUnlocodes } from 'pages/organisation/organisation.repository'
export const useUnlocodes = () => {
    const dispatch = useAppDispatch()
    const dropdownService = useDropdown()
    const modalService = useModal()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const data = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)

    const getData = async (params: IFetchParams) => {
        setIsLoading(true)
        const response = await getUnlocodes(params)
        if (!response) {
            return
        } else {
            dispatch(setData(response))
        }

        setIsLoading(false)
    }

    const handleSearch = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    useEffect(() => {
        getData(fetchParams)
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getVolumeUnit()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        isNew,
        meta,
        fetchParams,
        setIsNew,
        handleSearch,
    }
}
