import App from 'App'
import { IRoute } from 'common/common.interface'
import { loglinesRoutes } from 'common/routes/loglines.routes'
import { useConfig } from 'config/config.service'
import Page404 from 'pages/404-page/404-page.component'
import { Logout, Login } from 'pages/login/login.component'
import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
    modulesCodesSelector,
    moduleAccessLoadedSelector,
} from './common/routes/loglines.routes.slice'
import { useSelector } from 'react-redux'
import Spinner from '@components/spinner/spinner.component'

const AppRoute: React.FC = () => {
    useConfig()
    const [routes] = useState<IRoute[]>(loglinesRoutes)
    const userModuleCodes = useSelector(modulesCodesSelector)
    const isModulesLoaded = useSelector(moduleAccessLoadedSelector)

    if (!isModulesLoaded) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        )
    }

    return (
        <Routes>
            {/* Route Before Logun */}
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />

            {/* Route After Login */}
            <Route element={<App />}>
                {routes.map(
                    (
                        { Content, path, expandable, sub, moduleCodes }: IRoute,
                        idx: number,
                    ) => {
                        const hasAccess =
                            !moduleCodes ||
                            userModuleCodes.some((code) =>
                                moduleCodes.includes(code),
                            )

                        if (!hasAccess) return null

                        if (!expandable) {
                            return (
                                <Route
                                    key={idx}
                                    path={path}
                                    Component={Content}
                                />
                            )
                        } else {
                            return sub?.map((item, idxx) => {
                                const subHasAccess =
                                    !item.moduleCodes ||
                                    item.moduleCodes.length === 0 ||
                                    item.moduleCodes.some((code) =>
                                        userModuleCodes.includes(code),
                                    )

                                if (!subHasAccess) return null

                                return (
                                    <Route
                                        key={idxx}
                                        path={item.path}
                                        Component={item.Content}
                                    />
                                )
                            })
                        }
                    },
                )}
            </Route>

            {/* 404 */}
            <Route path="/*" element={<Page404 />} />
        </Routes>
    )
}

export default AppRoute
