import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

const useDetailCashAndBackForm = () => {
    const componentRef = useRef(null)

    const reactToPrintContent = () => {
        return componentRef.current
    }
    const handlePrint = useReactToPrint({
        documentTitle: 'BANK INTERNAL TRANSFER',
    })

    return {
        componentRef,
        reactToPrintContent,
        handlePrint,
    }
}

export default useDetailCashAndBackForm
