import { FormikContextType } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormAddress } from 'form-validation/organisation-address.validation'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import { ICapabilities } from '../../organisation-details.interface'

const CapabilitiesForm = ({
    formik,
    capabilitiesData,
}: {
    formik?: FormikContextType<IFormAddress>
    capabilitiesData: ICapabilities[]
}) => {
    return (
        <div className="overflow-auto themes-bg-gray-v1 p-2 rounded-logistical-radius max-h-80">
            <div className="grid grid-cols-1 mt-1">
                {capabilitiesData.map((data, idx) => (
                    <div key={`${data.code}-${idx}`}>
                        <div className="flex justify-between w-full mb-2">
                            <p className="text-[0.75rem]">{data.label}</p>
                            <div>
                                <FormCheckbox
                                    name={`capabilities.${data.code}`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CapabilitiesForm
