import * as React from 'react'
import './quote-doc.style.css'
import Logo from '@assets/img/logos/LoglinesLogo.png'
import LogisticalSquare from '@assets/img/icons/logistical-square.png'
import { IQuotation } from 'repository/interface/quotations.interface'
import moment from 'moment'

interface ComponentToPrintProps {
    text?: string
    data?: IQuotation
}

export const QuotesRangeRateDoc = React.forwardRef<
    HTMLDivElement | null,
    ComponentToPrintProps
>((props, ref) => {
    const { data, text } = props
    return (
        <div
            ref={ref}
            className="mx-[50px] flex flex-col justify-center min-h-screen"
        >
            <div className="min-h-[calc(100vh-5rem)] flex flex-col">
                {/* header */}
                <div className="flex flex-row justify-between items-center">
                    <img src={Logo} alt="Logo" className="h-12 w-auto" />
                    <tbody>
                        <tr className="base-text">
                            <td
                                className="sub-heading custom-border-bottom-bold"
                                colSpan={2}
                            >
                                QUOTATION
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-light">
                                Quote No.
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-light">
                                {data?.quote_id || '-'}
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-light">
                                Issued Date
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-light">
                                {moment(data?.quote_date).format(
                                    'MMM DD, YYYY',
                                ) || '-'}
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-bold">
                                Valid Till
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-bold">
                                {moment(data?.etd).format('MMM DD, YYYY') ||
                                    '-'}
                            </td>
                        </tr>
                    </tbody>
                </div>
                {/* body */}
                <div className="flex flex-col flex-grow">
                    <div className="w-full bg-secondary grid grid-cols-2 p-4 rounded-md gap-14 mt-8">
                        <tbody>
                            <tr className="base-text">
                                <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                    QUOTATION BY
                                </td>
                                <td className="text custom-border-bottom-bold w-full"></td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Company
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    {`LogLines`}
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Issued by
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Bunga Citra
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text">Address</td>
                                <td className="text-dark w-full">
                                    Bali, Indonesia No 88 SUNSET BUREAU Jl.
                                    Sunset Road No.88, Seminyak, Kec. Kuta,
                                    Kabupaten Badung, Bali 80361
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr className="base-text">
                                <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                    QUOTATION TO
                                </td>
                                <td className="text custom-border-bottom-bold w-full"></td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Costumer
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    {data?.container_mode}
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Contact
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Bali
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text">Address</td>
                                <td className="text-dark w-full">
                                    Bali, Indonesia No 88 SUNSET BUREAU Jl.
                                    Sunset Road No.88, Seminyak, Kec. Kuta,
                                    Kabupaten Badung, Bali 80361
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    <p className="heading custom-border-top-light w-full mt-2">
                        Quotation Details
                    </p>
                    <div className="mt-2">
                        <tbody>
                            <tr className="base-text">
                                <td className="sub-heading custom-border-bottom-bold text-nowrap min-w-36">
                                    RATE DETAILS
                                </td>
                                <td className="text custom-border-bottom-bold w-full"></td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Transport Mode
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    {`Air Freight`}
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Destination
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Bali
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Carrier
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    EK
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Schedule
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Update
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Fuel
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    -
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Sec Sur
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    -
                                </td>
                            </tr>
                        </tbody>
                        <span className="text">Rates Range</span>
                        <table className="w-full border border-collapse mt-2 rounded-2xl">
                            <tbody>
                                <tr className="base-text">
                                    <td className="text custom-border-light bg-secondary">
                                        Weight
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        +100 kg
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        Com.
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-light bg-secondary">
                                        Rate
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        $3.50
                                    </td>
                                    <td className="text-dark custom-border-light">
                                        G/H
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                <tr className="base-text">
                                    <th className="sub-heading custom-border-bottom-bold min-w-80 text-start">
                                        ADDITIONAL CHARGES
                                    </th>
                                    <th className="sub-heading custom-border-bottom-bold text-start">
                                        DESCRIPTION
                                    </th>
                                    <th className="sub-heading custom-border-bottom-bold min-w-64 text-start">
                                        USD
                                    </th>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            1.
                                        </span>{' '}
                                        Export Documents
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            2.
                                        </span>{' '}
                                        Handling and airport terminal fee
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            3.
                                        </span>{' '}
                                        Packing
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            4.
                                        </span>{' '}
                                        Export custom clearance at origin
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            5.
                                        </span>{' '}
                                        Pick up service
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            6.
                                        </span>{' '}
                                        Pallet if requested
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left">
                                            7.
                                        </span>{' '}
                                        Fumigation
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        <span className="inline-block w-5 text-left"></span>{' '}
                                        Fumigation Australia & New Zealand
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        COO + Invoice
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $45.00 / Shipment
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 flex flex-col">
                        <p className="sub-heading custom-border-bottom-bold min-w-80 text-start">
                            Notes
                        </p>
                        <p className="text custom-border-bottom-light w-full min-h-20">
                            Is there any additional information that we need to
                            know, please kindly inform us in the field shown
                            below
                        </p>
                    </div>
                </div>
                {/* footer */}
                <div className="flex flex-row justify-between items-center w-full custom-border-top-light self-end">
                    <img
                        src={LogisticalSquare}
                        alt="Logo"
                        className="w-9 h-9"
                    />
                    <p className="text mb-0">
                        ©OneByOne Logistical Pty Ltd 2024. Connect and control
                        with digital precision™
                    </p>
                </div>
            </div>
        </div>
    )
})
