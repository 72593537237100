import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFShippingRate } from 'form-validation/shipping-rate.validation'
import { FormikProps, FormikProvider } from 'formik'

const ShippingRateForm = ({
    formik,
    submitLoading,
    deleteHandling,
    deleteLoading,
    isNew,
}: {
    formik: FormikProps<IFShippingRate>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdown = useDropdown()
    const { container_mode, transport_mode } = formik.values
    const isFcl = container_mode === 'FCL' && transport_mode === 'SEA'
    const unitDropdown = isFcl
        ? dropdown.customsUnitsData
        : dropdown.weightUnitsData

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <FormInput
                        parentDivClassName="w-full"
                        name="name"
                        label="Name"
                        useUppercaseLabel={true}
                        placeholder="Input Name"
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="transport_mode"
                        label="Transport Mode"
                        useUppercaseLabel={true}
                        placeholder="Select Transport Mode"
                        options={dropdown.transportModesData}
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="container_mode"
                        label="Container Mode"
                        useUppercaseLabel={true}
                        placeholder="Select Container Mode"
                        options={dropdown.containerModeData}
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="origin_location.code"
                        label="Origin Location "
                        useUppercaseLabel={true}
                        placeholder="Select Origin Location"
                        options={dropdown.unlocodesData}
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="destination_location.code"
                        label="Destination Location "
                        useUppercaseLabel={true}
                        placeholder="Select Destination Location"
                        options={dropdown.unlocodesData}
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="carrier.code"
                        label="Carrier"
                        useUppercaseLabel={true}
                        placeholder="Select Carrier"
                        options={dropdown.organisationCarrierData}
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="currency.code"
                        label="Currency Code"
                        useUppercaseLabel={true}
                        placeholder="Select Currency"
                        options={dropdown.currencyData}
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="price"
                        label="Price"
                        useUppercaseLabel={true}
                        placeholder="Input Price"
                        type="number"
                    />
                    <FormDropdown
                        parentDivClassName="w-full"
                        name="unit.code"
                        label="Unit"
                        useUppercaseLabel={true}
                        placeholder="Select Unit"
                        options={unitDropdown}
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="transit_time"
                        label="Transit Time"
                        useUppercaseLabel={true}
                        placeholder="Input Transit Time"
                        type="number"
                    />

                    {transport_mode === 'SEA' && container_mode === 'FCL' && (
                        <FormDropdown
                            parentDivClassName="w-full"
                            name="container_type.code"
                            label="Container Type"
                            useUppercaseLabel={true}
                            placeholder="Select Container Type"
                            options={dropdown.containerTypeData}
                        />
                    )}
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ShippingRateForm
