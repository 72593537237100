import { FormikProvider } from "formik"
import useFormTax from "./form-tax.service"
import FormInput from "@components/form-input/form-input.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"
import FormDropdown from "@components/form-dropdown/form-dropdown.component"
import FormCheckbox from "@components/form-checkbox/form-checkbox.component"

const FormTax = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormTax()
    return (
        <FormikProvider value={formik}>
            <div className=" flex flex-col justify-between">
                <div className="p-4">
                    <div className="mb-3">Tax Details</div>
                    <div className="grid grid-cols-2 gap-2">
                        <FormInput
                            name="tax_name"
                            label="TAX NAME"
                            placeholder="Enter tax name here"
                            required
                        />
                        <FormInput
                            name="effective_rate"
                            label="EFFECTIVE RATE"
                            placeholder="Enter effectiver rate here"
                            required
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <FormDropdown
                            label="SELL ACCOUNT"
                            placeholder="Select Account"
                            name={'sell_account'}
                            options={[]}
                            disabled={false}
                        />
                        <FormDropdown
                            label="BUY ACCOUNT"
                            placeholder="Select Account"
                            name={'buy_account'}
                            options={[]}
                            disabled={false}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2 text-size-S mb-2 themes-text-gray-v6">
                        <div>This account will record your sales tax</div>
                        <div>This account will record your purchases taxes</div>
                    </div>
                    <FormCheckbox
                        name={'multiplier'}
                        label="11/12 multiplier for DPP Nilai Lain"
                        classNameLabel='text-size-M !font-normal'
                    />
                    <FormCheckbox
                        name={'third_party'}
                        label="Third party tax witholding"
                        classNameLabel='text-size-M !font-normal mb-1'
                    />
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { modalService.closeModalHandling() }}
                    />

                    <Button
                        type="submit"
                        label="SAVE CHANGES"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </div>
        </FormikProvider>
    )
}
export default FormTax