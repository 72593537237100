import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export const noteFormValidation: YUP.Schema<any> = YUP.object().shape({
    category: YUP.string().required(requiredMessage('Category')),
    text: YUP.string().required(requiredMessage('Text')),
})

export interface IFormNote {
    code?: string
    category: string
    text: string
}
export interface IFormNotePayload {
    category: string
    text: string
}

// form noter initial data
export const noteFormInitial: IFormNote = {
    category: '',
    text: '',
}
