import { useFormik } from 'formik'
import { useFormContactValidation } from './form-contact.validation'
import { IFormContact } from './form-contact.interface'

const useFormContact = () => {
    const formContactValidation = useFormContactValidation()
    const formik = useFormik<IFormContact>({
        validationSchema: formContactValidation.schema,
        validateOnChange: true,
        initialValues: formContactValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormContact

