import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'

export interface IFProductCategory {
    code: string
    name: string
}

// Validation schema
export const useProductCategoryValidation = () => {
    const schema = Yup.object<IFProductCategory>().shape({
        name: Yup.string().required(requiredMessage('Name')),
    })

    // Initial values
    const initialValue: IFProductCategory = {
        code: '(Auto Generated)',
        name: '',
    }

    return {
        initialValue,
        schema,
    }
}
