import Spinner from '@components/spinner/spinner.component'
import useDetailConsolidation from './detail-consolidation.service'
import Button from '@components/button/button.component'
import Tab from '@components/tab/tab.component'
import { consolidationDetailTabs } from './detail-consolidation.static'
import Step1 from '../create-consolidation/component/step-1.component'
import { FormikProvider } from 'formik'
import Step2 from '../create-consolidation/component/step-2.component'
import Containers from './containers/containers.component'
import PackLines from './pack-lines/pack-lines.component'
import Notes from './notes/notes.component'
import Edocs from './edocs/edocs.component'

const DetailConsolidation = () => {
    const {
        consolidationDetailData,
        isSubmitLoading,
        isDetailLoading,
        selectedTab,
        formik,
        receivingAgentAddressDropdown,
        receivingAgentContactDropdown,
        sendingAgenAddressDropdown,
        sendingAgentContactDropdown,
        firstLoadSendingAgent,
        firstLoadSReceivingAgent,
        attachShipmentModal,
        formikShipment,
        setSelectedTab,
        resetForm,
        setFirstLoadReceivingAgent,
        setFirstLoadSendingAgent,
        shipmentAddHandling,
        removeShipmentHandling,
    } = useDetailConsolidation()

    const content = () => {
        if (selectedTab.value === 'details') {
            return (
                <FormikProvider value={formik}>
                    <Step1
                        isNew={false}
                        formik={formik}
                        sendingAgentAddress={sendingAgenAddressDropdown}
                        sendingAgentContact={sendingAgentContactDropdown}
                        receivingAgentAddress={receivingAgentAddressDropdown}
                        receivingAgentContact={receivingAgentContactDropdown}
                        firstLoadSendingAgent={firstLoadSendingAgent}
                        firstLoadReceivingAgent={firstLoadSReceivingAgent}
                        setFirstLoadReceivingAgent={() => {
                            setFirstLoadReceivingAgent(false)
                        }}
                        setFirstLoadSendingAgent={() => {
                            setFirstLoadSendingAgent(false)
                        }}
                    />
                </FormikProvider>
            )
        } else if (selectedTab.value === 'list_shipment') {
            return (
                <div className="mt-4">
                    <Step2
                        formik={formikShipment}
                        modalService={attachShipmentModal}
                        attachShipmentHandling={(value) => {
                            shipmentAddHandling(value)
                        }}
                        deleteShipmentHandling={(value) => {
                            removeShipmentHandling(value)
                        }}
                    />
                </div>
            )
        } else if (selectedTab.value === 'pack_lines') {
            return (
                <div>
                    <PackLines />
                </div>
            )
        } else if (selectedTab.value === 'containers') {
            return (
                <div>
                    <Containers />
                </div>
            )
        } else if (selectedTab.value === 'notes') {
            return (
                <div>
                    <Notes />
                </div>
            )
        } else if (selectedTab.value === 'eDocumentation') {
            return (
                <div>
                    <Edocs />
                </div>
            )
        } else {
            return <div></div>
        }
    }

    return (
        <div className=" flex gap-4 sm:flex-wrap w-full sm:overflow-auto container-global content-full-height p-4">
            <div className="flex-grow rounded-[10px] sm:w-full themes-bg-white">
                {isDetailLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner />
                    </div>
                )}

                {/* Content */}
                <div
                    className={`${isDetailLoading ? 'hidden' : ''} h-full flex-grow flex flex-col sm:w-full`}
                >
                    <div className="flex flex-wrap gap-2 justify-between items-center">
                        <div>
                            <div className="flex gap-2">
                                <div className="">
                                    <div className="text-size-M font-bold">
                                        CONSOLE ID{' '}
                                        {(
                                            consolidationDetailData?.console_id ??
                                            ' - '
                                        ).toUpperCase()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 min-h-[40px]">
                            {selectedTab.value === 'details' ? (
                                <>
                                    <Button
                                        variant="red-inverse"
                                        label="DISCARD CHANGES"
                                        icon="ri-delete-bin-line"
                                        onClick={() => {
                                            resetForm()
                                        }}
                                        isDisabled={!formik.dirty}
                                    />
                                    <Button
                                        label="SAVE CHANGES"
                                        icon="ri-save-line"
                                        onClick={() => {
                                            formik.submitForm()
                                        }}
                                        isLoading={isSubmitLoading}
                                        variant="brand-inverse"
                                        isDisabled={!formik.dirty}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div className="border-b mt-4"></div>
                    <div className="border-b ">
                        <Tab
                            containerClassName="shipment-detail-tab"
                            items={consolidationDetailTabs}
                            selectedItem={selectedTab}
                            onChange={(value) => {
                                setSelectedTab(value)
                            }}
                        />
                    </div>
                    <div className="h-[calc(100vh-200px)] overflow-auto">
                        {content()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailConsolidation
