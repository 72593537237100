import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IProductCategory } from './interface/product-category.interface'
import { IFProductCategory } from 'form-validation/product-category.validation'

export const getProductCategory = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.productCategories}`
        const response = await fetch<
            IResponseData<IProductCategory[]>,
            IFetchParams
        >({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Product Category',
            })
        }
        return undefined
    }
}

export const submitProductCategory = async (payload: IFProductCategory) => {
    try {
        const endpoint = `${endpoints.productCategories}`
        await post<IResponseData<any>, IFProductCategory>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Product Category is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Product Category',
        })
        return undefined
    }
}

export const updateProductCategory = async (values: IFProductCategory) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.productCategories}/${code}`
        await put<IResponseData<any>, IFProductCategory>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Product Category is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Product Category',
        })
        return undefined
    }
}

export const deleteProductCategory = async (code: string) => {
    try {
        const endpoint = `${endpoints.productCategories}/${code}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Product Category is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Product Category',
        })
        return undefined
    }
}
