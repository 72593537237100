import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import Button from '@components/button/button.component'
import '../../user-access-form.style.css'
import { FormikContextType, FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { useSettingRole } from './setting-role.service'
import {
    IRoleModule,
} from 'repository/interface/role.interface'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'

const SettingRoleModal = ({
    modalService,
    modalServiceCreateRole,
    formikModule,
    submitHandling,
}: {
    modalService: IUseModal
    modalServiceCreateRole:IUseModal
    formikModule: FormikContextType<any>
    submitHandling: (data: IRoleModule[]) => void
}) => {
    const {
        dropdownService,
        roleModuleData,
    } = useSettingRole()
    const item = ['Create', 'View Only', 'Edit', 'Delete']
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="md:w-1/2 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky right-5"
        >
            <div className="flex flex-col h-[calc(100vh-4rem)] ">
                <div className="p-4 flex w-full items-center border-[1px]">
                    {/* Header */}
                    <div className="flex-1 flex-col flex leading-tight">
                        <span className="text-size-M font-bold ">
                            SELECT ROLES SETTING
                        </span>
                    </div>
                    <div className="flex-end ">
                        <i
                            className="ri-close-line cursor-pointer text-size-XL themes-text-hover-brand-v5"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                        />
                    </div>
                </div>
                <div className='flex justify-between p-4 items-center'>
                    <div className='text-size-L'>User Roles</div>
                    <Button
                        label="CREATE NEW ROLE"
                        onClick={() => {
                            modalServiceCreateRole.openModalHandling()
                            modalService.closeModalHandling()
                        }}
                    />
                </div>

                <div className="flex-grow grid grid-cols-3 w-full px-4 themes-bg-white border-t">
                    {/* Body */}
                    <div className='border-r-[1px] py-4 pr-4'>
                        <FormikProvider value={formikModule}>
                            <FormDropdown
                                name="role"
                                label="Role"
                                isSearchable={true}
                                useUppercaseLabel
                                options={dropdownService.rolesData}
                            />
                        </FormikProvider>
                    </div>
                    <div className='border-r-[1px]'>
                        <div className='border-b-[1px] p-2 text-size-M'>All Access For All Module</div>
                        <div>
                            {roleModuleData.map((data, index) => (
                                <div key={index} className='flex justify-between px-3 py-2 border-b-[1px] cursor-pointer themes-text-gray-v5 themes-bg-hover-brand-v1 themes-text-hover-brand-v5'>
                                    <div>{data.label}</div>
                                    <i className="ri-arrow-right-s-line" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className=''>
                        <div className='border-b-[1px] p-2 text-size-M'>All Access</div>
                        {item.map((act, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`w-full flex items-center px-3 justify-between border-b themes-bg-hover-brand-v1 themes-text-hover-brand-v5 cursor-pointer`}
                                >
                                    <div className="py-2 flex items-center ">
                                        {act}
                                    </div>
                                    <div>
                                        <ToggleSwitch
                                            initialOn={false}
                                            onClick={() => {
                                                // if (subModuleKey) {
                                                //     toggleAction(
                                                //         act.key,
                                                //         moduleKey,
                                                //         subModuleKey,
                                                //     )
                                                // } else {
                                                //     toggleAction(act.key, moduleKey)
                                                // }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="border-t w-full p-3">
                    {/* Footer */}
                    <Button
                        className="w-full"
                        label="SAVE ROLE SETTING"
                        variant='brand-inverse'
                        onClick={() => {
                            submitHandling(roleModuleData)
                            modalService.closeModalHandling()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default SettingRoleModal
