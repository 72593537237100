import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import { FormikContextType } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormAddress } from 'form-validation/organisation-address.validation'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'
import useAddressListService from './address-list.service'
type DropdownProps = ReturnType<typeof useDropdown>

const AddressListForm = ({
    dropdownService,
    formik,
}: {
    dropdownService: DropdownProps
    formik?: FormikContextType<IFormAddress>
    type?: string
}) => {
    const { countries, cities, states, getCountries, getCities, getStates } =
        useAddressListService()

    return (
        <div className="overflow-auto">
            <div className="flex flex-col py-3 gap-3">
                <FormInput
                    useUppercaseLabel={true}
                    label="Address Name"
                    name="name"
                    parentDivClassName="!mb-0"
                    placeholder="Enter Address Name"
                />
                <FormTextarea
                    useUppercaseLabel={true}
                    label="Address 1"
                    name="street"
                    placeholder="Enter Address"
                />
                <FormTextarea
                    useUppercaseLabel={true}
                    label="Address 2 (Optional)"
                    name="street_2"
                    placeholder="Enter Address"
                />
                <div className="w-full flex gap-3">
                    <FormDropdown
                        name="country.code"
                        label="Country/Region"
                        useUppercaseLabel={true}
                        placeholder="Select Country"
                        options={countries}
                        parentDivClassName="w-1/2 !mb-0"
                        additionalOnClick={(d) => {
                            const value = (d as IDropdownItem<ICountries>)
                                .additionalData?.code
                            formik?.setFieldValue('country.code', value)
                            if (value) {
                                getStates(value)
                            }
                        }}
                        isSearchable={true}
                    />
                    <FormDropdown
                        useUppercaseLabel={true}
                        name="state.code"
                        label="State"
                        placeholder="Select State"
                        parentDivClassName="w-1/2 !mb-0"
                        options={states}
                        disabled={!states?.length}
                        additionalOnClick={(d) => {
                            const value = (d as IDropdownItem<IStates>)
                                .additionalData?.code
                            formik?.setFieldValue('state.code', value)
                            if (value) getCities(value)
                        }}
                        isSearchable={true}
                    />
                </div>
                <div className="w-full flex gap-3">
                    <FormDropdown
                        useUppercaseLabel={true}
                        name="city.code"
                        label="City"
                        placeholder="Select City"
                        disabled={!cities?.length}
                        options={cities}
                        parentDivClassName="w-1/2 !mb-0"
                        additionalOnClick={(d) => {
                            const value = (d as IDropdownItem<ICities>)
                                .additionalData?.code
                            formik?.setFieldValue('city.code', value)
                        }}
                    />
                    <FormInput
                        useUppercaseLabel={true}
                        name="post_code"
                        label="Postcode"
                        placeholder="Select Postcode"
                        parentDivClassName="w-1/2 !mb-0"
                    />
                </div>
                <FormDropdown
                    useUppercaseLabel={true}
                    name={'unlocode'}
                    label="UNLOCO"
                    disabled={dropdownService.unlocodesData.length === 0}
                    placeholder="Select UNLOCO"
                    options={dropdownService.unlocodesData}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label="Related City/Port (Optional)"
                    parentDivClassName="!mb-0"
                    name="unlocode"
                    disabled={true}
                />
                <FormToggleSwitch label={'Active Address'} name="is_active" />
            </div>
        </div>
    )
}

export default AddressListForm
