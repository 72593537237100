import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import Button from '@components/button/button.component'
import { IShipment } from 'repository/interface/shipment.interface'

const DeleteConfirmation = ({
    modalService,
    onConfirm,
    shipment,
}: {
    modalService: IUseModal
    onConfirm: () => void
    shipment: IShipment | undefined
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="w-[400px]">
            <div>
                <div className="text-size-M font-bold mb-4 pb-4 border-b flex justify-between">
                    Are you sure?
                    <i
                        className="ri-close-fill cursor-pointer "
                        onClick={() => {
                            modalService.closeModalHandling()
                        }}
                    ></i>
                </div>
                <div className="mb-3">
                    Are you sure want to remove shipment "
                    <b>{shipment?.shipment_id}</b>""
                </div>
                <div className="w-full flex gap-3 justify-end border-t pt-3">
                    <Button
                        label="Cancel"
                        onClick={() => {
                            modalService.closeModalHandling()
                        }}
                    />
                    <Button
                        label="Delete"
                        variant="red-inverse"
                        onClick={() => {
                            onConfirm()
                            modalService.closeModalHandling()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default DeleteConfirmation
