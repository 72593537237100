import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export const addressFormValidation: YUP.Schema<any> = YUP.object().shape({
    name: YUP.string().required(requiredMessage('Name')),
    street: YUP.string().required(requiredMessage('Address 1')),
    street_2: YUP.string(),
    city: YUP.object()
        .shape({
            code: YUP.string().required(requiredMessage('City')),
        })
        .required(requiredMessage('City')),
    state: YUP.object()
        .shape({
            code: YUP.string().required(requiredMessage('State')),
        })
        .required(requiredMessage('State')),
    country: YUP.object()
        .shape({
            code: YUP.string().required(requiredMessage('Country')),
        })
        .required(requiredMessage('Country')),
    post_code: YUP.string().required(requiredMessage('Post Code')),
    unlocode: YUP.string().required(requiredMessage('Unlocode')),
    is_main: YUP.boolean(),
    is_active: YUP.boolean(),
    capabilities: YUP.object(),
})

export interface IFormAddress {
    code?: string
    name?: string
    street?: string
    street_2?: string
    city?: {
        code?: string
    }
    state?: {
        code?: string
    }
    country?: {
        code?: string
    }
    post_code?: string
    unlocode?: string
    is_main?: boolean
    is_active?: boolean
    capabilities?: {
        [key: string]: boolean
    }
}

export interface IFormAddressPayload {
    name?: string
    street?: string
    street_2?: string
    city?: {
        code?: string
    }
    state?: {
        code?: string
    }
    country?: {
        code?: string
    }
    post_code?: string
    unlocode?: string
    is_main?: boolean
    is_active?: boolean
    capabilities?: string[]
}

// form addressr initial data
export const addressFormInitial: IFormAddress = {
    name: '',
    street: '',
    street_2: '',
    city: {
        code: '',
    },
    state: {
        code: '',
    },
    country: {
        code: '',
    },
    post_code: '',
    unlocode: '',
    is_main: false,
    is_active: false,
    capabilities: {},
}
