import * as Yup from 'yup'
import { IFormProduct } from './form-product.interface'

export const useFormProductValidation = () => {
    const schema = Yup.object<IFormProduct>().shape({

    })

    const generateInitialValue = () => {
        const initialValue: IFormProduct = {
            product: '',
            product_code: '',
            unit: '',
            this_buy: false,
            buy: {
                currency: '',
                unit_price: '',
                account: '',
                tax: '',
            },
            this_sell: false,
            sell: {
                currency: '',
                unit_price: '',
                account: '',
                tax: '',
            },
            minimum_stock_limit:'',
            monitor_inventory:false,
            default_inventory_account:'',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
