
import Input from '@components/input/input.component'

const SOLinePriceDetails = () => {
    return (
        <div>
            <div className="overflow-x-scroll form-line-price-table">
                <div className="table-wrapper !h-[550px]">
                    <table>
                        <thead>
                            <tr className="text-muted">
                                <td>Product</td>
                                <td>Description</td>
                                <td>Qty</td>
                                <td>Units</td>
                                <td>Unit Price</td>
                                <td>Discount</td>
                                <td>Tax</td>
                                <td>Amount</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={`form-line-so-`} className='!mb-2'>
                                <td>
                                    <Input
                                        value={'Shipment Fee'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'-'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'10'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'PCS'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'1.520.000,00'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'0.00'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={'PPN'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />

                                </td>
                                <td>
                                    <Input
                                        value={'1.520.000,00'}
                                        className='w-[250px] mb-2'
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default SOLinePriceDetails

