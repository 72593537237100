import { ITabItem } from '@components/tab/tab.interface'
import { IContact, IPhones } from 'repository/interface/contact.interface'
import { ITableColumn } from '@components/table/table.interface'
import { IOrganisation } from 'repository/interface/organisation.interface'
import { formatDate } from '@services/common.service'

export const tabItemStatic: ITabItem[] = [
    {
        label: 'Active',
        totalData: 0,
        key: 'status',
        value: 'ACTIVE',
        className: 'brand',
    },
    {
        label: 'Inactive',
        totalData: 0,
        key: 'status',
        value: 'INACTIVE',
        className: 'red',
    },
]

export const contactSummaryHeader: ITableColumn<IContact>[] = [
    {
        label: 'Full Name',
        accessor: 'name',
    },
    {
        label: 'Job Title',
        accessor: 'job_title',
    },
    {
        label: 'Job Category',
        accessor: 'job_category.name',
    },
    {
        label: 'Email',
        accessor: 'email',
        customBuild: (data: string, rowData?: IContact) => {
            const emails = rowData?.emails || []
            return (
                <div className="flex gap-2 uppercase mobile:flex-wrap !h-[30px] items-center">
                    {emails.map((email: string, index: number) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-gray-100 rounded-sm text-size-XS"
                        >
                            {email}
                        </span>
                    ))}
                </div>
            )
        },
    },
    {
        label: 'Phone Number',
        accessor: 'phone',
        customBuild: (data: string, rowData?: IContact) => {
            const phones = rowData?.phones || []
            return (
                <div className="flex gap-2 uppercase mobile:flex-wrap !h-[30px] items-center">
                    {phones.map((phone: IPhones, index: number) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-gray-100 rounded-sm text-size-XS"
                        >
                            {phone.country_code}
                            {phone.number}
                        </span>
                    ))}
                </div>
            )
        },
    },
    {
        label: 'Organisations',
        accessor: 'organisations',
        className: '!max-w-none',
        customBuild: (data: string, rowData?: IContact) => {
            const organisations = rowData?.organisations || []
            return (
                <div className="flex gap-2 uppercase mobile:flex-wrap !h-[30px] items-center">
                    {organisations.map((org: IOrganisation, index: number) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-gray-100 rounded-sm text-size-XS"
                        >
                            {org.name}
                        </span>
                    ))}
                </div>
            )
        },
    },
    {
        label: 'Last Update',
        accessor: 'updated',
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
]
