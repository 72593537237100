import { CashBankSummaryHeader } from './cash-bank.static'
import useCashBank from './cash-bank.service'
import { ICashBankSummary } from 'repository/interface/cash-bank.interface'
import Table from '@components/table/table.component'
import { metaDummy, cashBankSummaryData } from './cash-bank.dummy'
import TabMaster2 from '../components/tab-master2/tab-master2.component'

const CashBank = () => {
    const {
        loading,
        fetchParam,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
    } = useCashBank()

    const CashBankDropdown = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                >
                    CREATE TRANSACTION
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute left-0 mt-2 w-[220px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() =>
                            navigate('/cash-and-bank/create-internal')
                        }
                    >
                        Bank Internal Transfer
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/cash-and-bank/create-receive')}
                    >
                        Receives Money
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/cash-and-bank/create-pay')}
                    >
                        Pay Money
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster2
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search (min 3 char)'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    additionalButton1={<>{CashBankDropdown()}</>}
                />
                <Table<ICashBankSummary>
                    headers={CashBankSummaryHeader}
                    data={cashBankSummaryData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta3={metaDummy}
                    moduleTitle={'Cash Bank Summary'}
                    onRowClick={(data: ICashBankSummary) => {
                        const id = data.account_code
                        navigate(`/cash-bank/detail/${id}`)
                    }}
                />
            </div>
        </div>
    )
}

export default CashBank
