import Button from '@components/button/button.component'
import useTopUp from './top-up.service'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import '../titipan.style.css'
import FileUpload from 'component/file-uploud/file-uploud.component'

type ITopUpType = 'withdraw' | 'top-up'

const TopUp = (typeForm: ITopUpType) => {
    const {
        formik,
        currency,
        setCurrency,
        navigate,
    } = useTopUp()


    const idr = `https://flagcdn.com/id.svg`
    const usd = `https://flagcdn.com/us.svg`

    const currencyOptions = [
        { label: 'IDR', value: 'IDR' },
        { label: 'USD', value: 'USD' },
    ]

    const label = typeForm === 'withdraw' ? 'WITHDRAW' :'TOP UP'

    return (
        <div className="content-full-height">
            <div className='flex justify-start items-center py-2'>
                <div className=''>
                    <div className='font-bold text-size-S'>DEPOSIT TRANSACTION</div>
                    <div className='text-size-L font-bold themes-text-brand-v6'>{label}</div>
                </div>
            </div>

            <div className='flex flex-col container-global !border-0 !rounded-t-none'>
                <div className=" overflow-auto h-[calc(100vh-14rem)] ">
                    <FormikProvider value={formik}>
                        <div className='themes-bg-gray-v1 flex flex-col items-center p-3'>
                            <div className='w-[40%]'>
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-3'>Account Name</div>
                                <FormDropdown
                                    disabled={false}
                                    label="Customer"
                                    placeholder="Select customer"
                                    name={'customer'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <FormInput
                                    label="Deposit Account Code"
                                    name={"deposit_account_code"}
                                    placeholder="Auto Generated"
                                    useUppercaseLabel
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='themes-bg-white flex flex-col items-center p-3'>
                            <div className='flex text-size-S gap-2'>
                                <div className='themes-text-gray-v5'>TOP UP BALANCE IN</div>
                                <div className="relative inline-block ml-4 group">
                                    <div className=" cursor-pointer themes-text-brand-v5">
                                        {currency}
                                        <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />                                    </div>

                                    <div
                                        className="absolute left-0 w-[100px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                                    >
                                        {currencyOptions.map((item, idx) => {
                                            return (
                                                <div
                                                    key={`item-${idx}`}
                                                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                                    onClick={() => { setCurrency(item.value) }}
                                                >
                                                    {item.label}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='flex border-b-[1px] items-start gap-1'>
                                <div className='themes-text-brand-v7 text-[32px] font-semibold'>Rp</div>
                                <FormInput
                                    name={"top_up"}
                                    placeholder="0,00"
                                    parentDivClassName='top-up-input'
                                />
                            </div>
                            <div className='flex gap-2 text-size-S py-3'>
                                <span className='themes-text-gray-v5 font-semibold'> Exchange Rate</span>
                                1$ = Rp.16.000,00
                                <i className="ri-edit-fill themes-text-hover-brand-v5 cursor-pointer" />
                            </div>
                            <div className='border-[1px] w-full mb-3' />
                            <div className='w-[40%]'>
                                <div className='themes-bg-gray-v1 rounded-xl p-3 mb-5 px-14'>
                                    <div className='flex justify-center mb-3 font-bold'>Current Titipan Balance</div>
                                    <div className='flex justify-between mb-3'>
                                        <img
                                            src={idr}
                                            className="h-5 w-7 sm:hidden"
                                            alt={idr}
                                        />
                                        <div>Rp 6.000.000,00</div>
                                    </div>
                                    <div className='flex justify-between mb-3'>
                                        <img
                                            src={usd}
                                            className="h-5 w-7 sm:hidden"
                                            alt={usd}
                                        />
                                        <div>$500.000</div>
                                    </div>
                                </div>
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-3'>Top Up Information</div>
                                <FormDatePicker
                                    isRange={false}
                                    name="transaction_date"
                                    placeholder="DD/MM/YYYY"
                                    parentDivClassName="mb-4"
                                    label="transaction date"
                                    useUppercaseLabel
                                />
                                <FormInput
                                    label="Transaction No"
                                    name={"transaction_no"}
                                    placeholder="Auto Generated"
                                    useUppercaseLabel

                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Top Up Method"
                                    placeholder="Select Top Up Method"
                                    name={'top_up_method'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Bank Account"
                                    placeholder="Select Bank Account"
                                    name={'bank_account'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <FileUpload onFileUpload={() => { }} />
                            </div>
                        </div>
                    </FormikProvider>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        onClick={() => { navigate(-1)}}
                        className="w-[220px] "
                        label="CANCEL"
                        variant='default'
                    />
                    <Button
                        onClick={() => {
                        }}
                        className="w-[220px]"
                        label="CONFIRM TOP UP"
                        variant='brand'
                    />
                </div>
            </div>
        </div>

    )
}

export default TopUp
