import { IEdoc } from 'repository/interface/e-doc.interface'

function FileItem({ e_doc }: { e_doc: IEdoc }) {
    return (
        <div className="flex flex-row justify-between items-center w-full py-3 border-b border-gray-200">
            <div className="flex flex-row gap-3 w-full">
                <i className="ri-file-line text-[2rem] text-[#00B0F2]"></i>
                <div className="flex flex-col gap-1">
                    <p
                        className="text-size-S truncate max-w-20"
                        title={e_doc?.filename}
                    >
                        {e_doc?.filename}
                    </p>
                    <p
                        className="text-size-XS text-gray-400 truncate"
                        title={e_doc?.document_type}
                    >
                        {e_doc?.document_type}
                    </p>
                </div>
            </div>

            {e_doc?.path && (
                <a
                    href={e_doc.path}
                    download={e_doc.filename}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                >
                    <i className="ri-download-2-line text-[1.5rem] text-gray-400 hover:text-[#00B0F2] cursor-pointer"></i>
                </a>
            )}
        </div>
    )
}

export default FileItem
