import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { paymentRequestHeaders } from './payment-request.static'
import Button from '@components/button/button.component'
import usePaymentRequest from './payment-request.service'
import { dummyPaymentRequest, metaDummy } from '../titipan.dummy'
import { IPaymentRequest } from '../titipan.interface'
import PaymentRequestFilterOverlay from '../components/payment-request-filter/payment-request-filter-overlay.component'

const PaymentRequest = () => {
    const {
        tabItems,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        navigate,
        setTabFilter,
        handleSearch,
        handlePagination,
    } = usePaymentRequest()

    const rowOptionComponent = (
        rowData: IPaymentRequest,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                }}
                className="!w-full border-none text-left"
                icon="ri-bank-card-line"
                label="Create Deposit Transaction"
            />
            <Button
                onClick={() => {
                }}
                className="!w-full border-none text-left themes-text-red-v3"
                icon="ri-close-line"
                label="Cancel Payment Request"
            />
        </div>
    )

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className='flex items-center gap-4 border-b p-3'>
                    <i className="ri-arrow-left-line text-[20px] cursor-pointer" onClick={() => navigate('/titipan')} />
                    <div>
                        <div className='font-bold text-size-S'>DEPOSIT</div>
                        <div className='text-size-L font-bold themes-text-brand-v6'>PAYMENT REQUEST</div>
                    </div>
                </div>
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch='Search (min 3 char)'
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <PaymentRequestFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    // filter params , still no API used
                                    // loadData({ ...fetchParam, ...data })
                                    return
                                }}
                            />
                        ),
                    }}
                />

                <Table<IPaymentRequest>
                    headers={paymentRequestHeaders}
                    data={dummyPaymentRequest}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Quotes'}
                    onRowClick={(item) => {
                        navigate(`/titipan/payment-request/${item.requestPaymentNo}`)
                    }}
                    rowOptionComponent={rowOptionComponent}
                    classNameRowOptionOverlay="w-[13rem]"
                />
            </div>
        </div>
    )
}

export default PaymentRequest
