import { IDropdownItem } from "@components/dropdown/dropdown.interface";

export const CurrencyItem: IDropdownItem[] = [
    { label: 'US Dollar (USD)', value: '$' },
    { label: 'Euro (EUR)', value: '€' },
    { label: 'Japanese Yen (JPY)', value: 'JP¥' },
    { label: 'Australian Dollar (AUD)', value: 'AU$' },
    { label: 'Chinese Yuan (CNY)', value: 'CN¥' },
    { label: 'Indonesian Rupiah (IDR)', value: 'Rp' },
];

export const WithholdingTaxItems: IDropdownItem[] = [
    { label: "PPh Pasal 21 - Pajak Penghasilan Karyawan", value: "PPh21" },
    { label: "PPh Pasal 22 - Pajak atas Barang Impor", value: "PPh22" },
    { label: "PPh Pasal 23 - Pajak atas Jasa dan Dividen", value: "PPh23" },
    { label: "PPh Pasal 24 - Kredit Pajak Luar Negeri", value: "PPh24" },
    { label: "PPh Pasal 25 - Angsuran Pajak Penghasilan", value: "PPh25" },
    { label: "PPh Pasal 26 - Pajak Penghasilan untuk WNA", value: "PPh26" },
    { label: "PPh Pasal 29 - Kurang Bayar Pajak Tahunan", value: "PPh29" },
    { label: "PPh Pasal 4 Ayat (2) - Pajak Final", value: "PPh4(2)" },
    { label: "PPN - Pajak Pertambahan Nilai", value: "PPN" },
    { label: "PPnBM - Pajak Barang Mewah", value: "PPnBM" },
    { label: "Bea Materai - Pajak atas Dokumen", value: "BeaMaterai" },
];

export const ProductItem: IDropdownItem[] = [
    { label: 'Laptop', value: 'PROD-001' },
    { label: 'Smartphone', value: 'PROD-002' },
    { label: 'Headphones', value: 'PROD-003' },
    { label: 'Wireless Mouse', value: 'PROD-004' },
    { label: 'Keyboard', value: 'PROD-005' },
    { label: 'Monitor', value: 'PROD-006' },
    { label: 'Printer', value: 'PROD-007' },
    { label: 'Tablet', value: 'PROD-008' },
];

export const TaxItem: IDropdownItem[] = [
    { label: 'VAT 5%', value: 'VAT 5%' },
    { label: 'VAT 10%', value: 'VAT 10%' },
    { label: 'Sales Tax 12%', value: 'Sales Tax 12%' },
    { label: 'Excise Duty 15%', value: 'Excise Duty 15%' },
    { label: 'Luxury Tax 8%', value: 'Luxury Tax 8%' },
    { label: 'Service Tax 18%', value: 'Service Tax 18%' },
    { label: 'Custom Duty 20%', value: 'Custom Duty 20%' },
];





