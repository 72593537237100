import * as React from 'react'
import './quote-doc.style.css'
import Logo from '@assets/img/logos/LoglinesLogo.png'
import LogisticalSquare from '@assets/img/icons/logistical-square.png'
import { IQuotation } from 'repository/interface/quotations.interface'
import moment from 'moment'

interface ComponentToPrintProps {
    text?: string
    data?: IQuotation
}

export const QuotesSpesificVolumeDoc = React.forwardRef<
    HTMLDivElement | null,
    ComponentToPrintProps
>((props, ref) => {
    const { data, text } = props
    return (
        <div
            ref={ref}
            className="mx-[50px] flex flex-col justify-center min-h-screen"
        >
            <div className="min-h-[calc(100vh-5rem)] flex flex-col">
                {/* header */}
                <div className="flex flex-row justify-between items-center">
                    <img src={Logo} alt="Logo" className="h-12 w-auto" />
                    <tbody>
                        <tr className="base-text">
                            <td
                                className="sub-heading custom-border-bottom-bold"
                                colSpan={2}
                            >
                                QUOTATION
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-light">
                                Quote No.
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-light">
                                {data?.quote_id || '-'}
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-light">
                                Issued Date
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-light">
                                {moment(data?.quote_date).format(
                                    'MMM DD, YYYY',
                                ) || '-'}
                            </td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-28 custom-border-bottom-bold">
                                Valid Till
                            </td>
                            <td className="text-dark w-28 custom-border-bottom-bold">
                                {moment(data?.etd).format('MMM DD, YYYY') ||
                                    '-'}
                            </td>
                        </tr>
                    </tbody>
                </div>
                {/* body */}
                <div className="flex flex-col flex-grow">
                    <div className="w-full bg-secondary grid grid-cols-2 p-4 rounded-md gap-14 mt-8">
                        <tbody>
                            <tr className="base-text">
                                <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                    QUOTATION BY
                                </td>
                                <td className="text custom-border-bottom-bold w-full"></td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Company
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    {`LogLines`}
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Issued by
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Bunga Citra
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text">Address</td>
                                <td className="text-dark w-full">
                                    Bali, Indonesia No 88 SUNSET BUREAU Jl.
                                    Sunset Road No.88, Seminyak, Kec. Kuta,
                                    Kabupaten Badung, Bali 80361
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr className="base-text">
                                <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                    QUOTATION TO
                                </td>
                                <td className="text custom-border-bottom-bold w-full"></td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    {data?.container_mode}
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    {`LogLines`}
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text custom-border-bottom-light">
                                    Contact
                                </td>
                                <td className="text-dark custom-border-bottom-light w-full">
                                    Bali
                                </td>
                            </tr>
                            <tr className="base-text">
                                <td className="text">Address</td>
                                <td className="text-dark w-full">
                                    Bali, Indonesia No 88 SUNSET BUREAU Jl.
                                    Sunset Road No.88, Seminyak, Kec. Kuta,
                                    Kabupaten Badung, Bali 80361
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    <p className="heading custom-border-top-light w-full mt-2">
                        Quotation Details
                    </p>
                    <div className="w-full grid grid-cols-2 gap-8 mt-2">
                        <div>
                            <tbody>
                                <tr className="base-text">
                                    <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                        ROUTE DETAILS
                                    </td>
                                    <td className="text custom-border-bottom-bold w-full"></td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Origin Port
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        ADALV - Andorra la Vella, Andorra
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        With Pick-up
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        Yes
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Destination Port
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        AUMEL - Melbourne, VIC, Australia
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        With Delivery
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        No
                                    </td>
                                </tr>
                            </tbody>
                            <span className="text">
                                Container Details (optional)
                            </span>
                            <table className="w-full border border-collapse mt-2 rounded-2xl">
                                <tbody>
                                    <tr className="base-text">
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Count
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Container
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Container Type
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Cubis Capacity
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Gross Weight
                                        </td>
                                    </tr>
                                    <tr className="base-text">
                                        <td className="text-dark text-size-xs custom-border-light">
                                            1
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            20HC - Twenty foot high cube
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            DRY
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            38.500
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            32,000.000
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <tbody>
                                <tr className="base-text">
                                    <td className="sub-heading custom-border-bottom-bold text-nowrap">
                                        GOODS DETAILS
                                    </td>
                                    <td className="text custom-border-bottom-bold w-full"></td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Weight
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        12 KG
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Volume
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        12,000 M3
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Value of Goods
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $4,000 AUD
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Insurance value
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        $200 AUD
                                    </td>
                                </tr>
                            </tbody>
                            <span className="text">
                                Package Details (optional)
                            </span>
                            <table className="w-full border border-collapse mt-2 rounded-2xl">
                                <tbody>
                                    <tr className="base-text">
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            No
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Package Type
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Quantity
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Volumetric Weight
                                        </td>
                                        <td className="text text-size-xs custom-border-light bg-secondary">
                                            Actual Weight
                                        </td>
                                    </tr>
                                    <tr className="base-text">
                                        <td className="text-dark text-size-xs custom-border-light">
                                            1
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            -
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            -
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            -
                                        </td>
                                        <td className="text-dark text-size-xs custom-border-light">
                                            -
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mt-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                <tr className="base-text">
                                    <th className="sub-heading custom-border-bottom-bold min-w-40 text-start">
                                        Rates
                                    </th>
                                    <th className="sub-heading custom-border-bottom-bold text-start"></th>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Transport Mode
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        Sea Freight
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Service Level
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        STD - Standard
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Container Mode
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        Sea Freight (LCL and FCL)
                                    </td>
                                </tr>
                                <tr className="base-text">
                                    <td className="text custom-border-bottom-light">
                                        Incoterm
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        Ex Works
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 flex flex-col">
                        <p className="sub-heading custom-border-bottom-bold min-w-80 text-start">
                            Notes
                        </p>
                        <p className="text custom-border-bottom-light w-full min-h-20">
                            Is there any additional information that we need to
                            know, please kindly inform us in the field shown
                            below
                        </p>
                    </div>
                    <div className="mt-4 flex flex-row justify-between">
                        <p className="heading">Rates</p>
                        <p className="heading">
                            TOTAL <span className="font-bold">$4,000 AUD</span>
                        </p>
                    </div>
                </div>
                {/* footer */}
                <div className="flex flex-row justify-between items-center w-full custom-border-top-light self-end">
                    <img
                        src={LogisticalSquare}
                        alt="Logo"
                        className="w-9 h-9"
                    />
                    <p className="text mb-0">
                        ©OneByOne Logistical Pty Ltd 2024. Connect and control
                        with digital precision™
                    </p>
                </div>
            </div>
        </div>
    )
})
