import * as Yup from 'yup'
import { IFormTerm } from './form-term.interface'

export const useFormTermValidation = () => {
    const schema = Yup.object<IFormTerm>().shape({

    })

    const generateInitialValue = () => {
        const initialValue: IFormTerm = {
            typeTranscionNo: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
