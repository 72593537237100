import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IQuoteSummaryPills } from './form.interface'
import { IPillItem } from '@components/pills/pills.component'

export const quoteSummaryPillHeaders: IPillItem<IQuoteSummaryPills>[] = [
    {
        label: 'Charge List',
        value: 'chargeList',
    },
    {
        label: 'Notes',
        value: 'notes',
    },
]

export const periodOptions: IDropdownItem[] = [
    { label: 'A Week', value: 'A_WEEK' },
    { label: 'Two Weeks', value: 'TWO_WEEKS' },
    { label: 'A Month', value: 'A_MONTH' },
    { label: '3 Months', value: '3_MONTHS' },
]
