import SimpleTable from '@components/simple-table/simple-table.component'
import { IFConsolidation } from 'form-validation/consolidation.validation'
import { FormikProps } from 'formik'
import Button from '@components/button/button.component'
import ModalSidebarComponent from '@components/modal-sidebar/modal-sidebar.component'
import { IUseModal, useModal } from '@components/modal/modal.service'
import { AttachShipment } from '../attach-shipment/attach-shipment.component'
import { IShipment } from 'repository/interface/shipment.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { shipmentColumn } from '../create-consolidation.static'
import { useState } from 'react'
import DeleteConfirmation from '../../detail-consolidation/component/delete-confirmation.component'

const Step2 = ({
    formik,
    modalService,
    attachShipmentHandling,
    deleteShipmentHandling,
}: {
    formik: FormikProps<IFConsolidation>
    modalService: IUseModal
    attachShipmentHandling: (data: IShipment[]) => void
    deleteShipmentHandling: (data: string) => void
}) => {
    const { shipments } = formik.values
    const [selectedShipment, setSelectedShipment] = useState<IShipment>()
    const modalConfirm = useModal()

    const newHeader: ISTColumn<IShipment>[] = [
        ...shipmentColumn,
        {
            accessor: 'code',
            label: '',
            customBuild: (_, a, b, c, rowData) => {
                return (
                    <Button
                        onClick={() => {
                            setSelectedShipment(rowData)
                            modalConfirm.openModalHandling()
                        }}
                        className="flex justify-center items-center h-[30px] w-[30px]"
                        iconClassName="text-size-XS"
                        icon="ri-delete-bin-line"
                        variant="red-inverse"
                    />
                )
            },
        },
    ]

    return (
        <div className="w-full">
            <div className="w-full justify-between flex mb-4">
                <div>
                    <div className="text-size-S font-bold">Attach Shipment</div>
                    <div className="text-size-XS">
                        Please attach the Shipment ID to this new consolidation
                        for accurate tracking and management.
                    </div>
                </div>
                <Button
                    label={'ATTACH SHIPMENT'}
                    variant="brand-inverse"
                    onClick={async () => {
                        modalService.openModalHandling()
                    }}
                />
            </div>
            <div className="h-[calc(100vh-410px)]">
                <SimpleTable
                    headers={newHeader}
                    data={shipments}
                    showHeaderOnEmpty
                />
            </div>

            <ModalSidebarComponent
                content={
                    <AttachShipment
                        onAttach={(d) => {
                            modalService.closeModalHandling()
                            attachShipmentHandling(d)
                        }}
                    />
                }
                modalService={modalService}
                title={'Attach Shipments ID'}
                widthClassName="md:w-1/2 mobile:min-w-0 min-w-[700px]"
            />

            <DeleteConfirmation
                modalService={modalConfirm}
                onConfirm={() => {
                    deleteShipmentHandling(selectedShipment?.code ?? '')
                }}
                shipment={selectedShipment}
            />
        </div>
    )
}

export default Step2
