import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import {
    paymentRequestFetchParamSelector,
    paymentRequestSelectedTabItemSelector,
    paymentRequestTabItemsSelector,
    setDataPaymentRequest,
    setFetchParamPaymentRequest,
    setSelectedTabItemPaymentRequest
} from '../titipan.slice'
import { useNavigate } from 'react-router-dom'
import useOverlay from '@components/overlay/overlay.service'

const usePaymentRequest = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const fetchParam = useSelector(paymentRequestFetchParamSelector)
    const tabItems = useSelector(paymentRequestTabItemsSelector)
    const selectedTabItem = useSelector(paymentRequestSelectedTabItemSelector)
    const dropdownService = useDropdown()

    const filterOverlay = useOverlay()

    // states
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData({ ...fetchParam, page: 1, size: 50 })
    }, [fetchParam])

    useEffect(() => {
        dropdownService.getCurrency()
    }, [])


    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        try {
            setLoading(true)
            // const response = await getQuotation(params, true)
            // if (!response) {
            setLoading(false)
            dispatch(
                setDataPaymentRequest({
                    data: [],
                    meta: {
                        start: 0,
                        size: 0,
                        page: 0,
                        data_count: 0,
                        total_data_count: 0,
                        total_page: 0,
                    },
                }),
            )
            return
            // }
            // if (recountingData) {
            //     getTabItems(params)
            // }
            // dispatch(setDataPaymentRequest(response))
            // // dispatch(
            // //     setSelectedTabItemHelper((params.transport as string) ?? 'all'),
            // // )
            // setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParamPaymentRequest(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItemPaymentRequest(data))
    }

    return {
        tabItems,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        navigate,
        setTabFilter,
        handleSearch,
        handlePagination,
    }
}

export default usePaymentRequest
