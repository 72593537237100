import { ThemeType } from 'common/common.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { currentThemeSelector, setTheme } from 'config/config.slice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getTimezonesDropdown } from 'repository/timezones.repository'
import {
    accordion1OpenSelector,
    accordion2OpenSelector,
    accordion3OpenSelector,
    setAccordion1,
    setAccordion2,
    setAccordion3,
} from './settings.slice'
import { useModal } from '@components/modal/modal.service'
import { IStaticDataType } from './settings.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'

export const availableThemes: IDropdownItem<ThemeType>[] = [
    { label: 'Bright Sky Blue', value: 'bright-sky-blue-themes' },
    { label: 'Dark Sapphire Blue', value: 'dark-sapphire-blue-themes' },
    { label: 'Dark Teal', value: 'dark-teal-themes' },
]

export const useThemes = () => {
    const dispatch = useDispatch()

    const currentThemes = useSelector(currentThemeSelector)

    const [themes] = useState<IDropdownItem<ThemeType>[]>(availableThemes)
    const [selectedThemes, setSelectedThemes] =
        useState<IDropdownItem<ThemeType>>()
    const [timezonesList] = useState(getTimezonesDropdown())

    useEffect(() => {
        themes.forEach((d) => {
            if (currentThemes && d.value === currentThemes) {
                setSelectedThemes(d)
            }
        })
    }, [currentThemes])

    const themesChangesHandling = (val: IDropdownItem<ThemeType>) => {
        dispatch(setTheme(val.value as ThemeType))
    }

    return {
        themes,
        selectedThemes,
        timezonesList,
        themesChangesHandling,
    }
}

export const useSettings = () => {
    const dispatch = useDispatch()
    const isAccordion1Open = useSelector(accordion1OpenSelector)
    const isAccordion2Open = useSelector(accordion2OpenSelector)
    const isAccordion3Open = useSelector(accordion3OpenSelector)

    const handlingAccordion = (num: number) => {
        if (num === 1) {
            dispatch(setAccordion1(!isAccordion1Open))
        } else if (num === 2) {
            dispatch(setAccordion2(!isAccordion2Open))
        } else if (num === 3) {
            dispatch(setAccordion3(!isAccordion3Open))
        }
    }
    return {
        handlingAccordion,
        isAccordion1Open,
        isAccordion2Open,
        isAccordion3Open,
    }
}

export const useStaticDataService = () => {
    const modalService = useModal()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [data, setData] = useState<IDropdownItem<any>[]>([]) // to show
    const [tmpData, setTmpData] = useState<IDropdownItem<any>[]>([]) // clean data for search

    // formik for search
    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            handleSearch(values.searchTerm)
        },
    })

    // Static data, most of data is used for dropdown
    const dropdownService = useDropdown()
    const transportMode = dropdownService.transportModesData
    const containerMode = dropdownService.allContainerModeData
    const orderStatus = dropdownService.orderStatusData
    const shipmentStatus = dropdownService.shipmentStatusData
    const bookingStatus = dropdownService.bookingStatusesData
    const noteCategory = dropdownService.noteCategoryData

    const getDataHandling = (type: IStaticDataType) => {
        setIsLoading(true)
        formik.setValues({
            searchTerm: '',
        })

        // title
        const newTitle = type.replace('_', ' ').toUpperCase()
        setTitle(newTitle)

        // data
        let dt: IDropdownItem<any>[] = []
        if (type === 'transport_mode') {
            dt = transportMode
        } else if (type === 'booking_status') {
            dt = bookingStatus
        } else if (type === 'container_mode') {
            dt = containerMode
        } else if (type === 'order_status') {
            dt = orderStatus
        } else if (type === 'shipment_status') {
            dt = shipmentStatus
        } else if (type === 'note_category') {
            dt = noteCategory
        }
        setData(dt)
        setTmpData(dt)

        modalService.openModalHandling()
        setIsLoading(false)
    }

    const handleSearch = (textSearch: string) => {
        const search = textSearch.toUpperCase()
        if (search === '') {
            setData(tmpData)
            return
        }

        const dt = [...tmpData]
        const dtFilter = dt.filter((d) => {
            const isExistsInValue = d.value
                .toString()
                .toUpperCase()
                .includes(search)
            const isExistsInLabel = d.label
                .toString()
                .toUpperCase()
                .includes(search)
            if (isExistsInLabel || isExistsInValue) {
                return true
            }
            return false
        })
        setData(dtFilter)
    }

    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getShipmentStatus()
        dropdownService.getBookingStatuses()
        dropdownService.getNoteCategory()
        dropdownService.getAllContainerModes()
        dropdownService.getOrderStatus()
    }, [])

    return {
        getDataHandling,
        title,
        modalService,
        isLoading,
        data,
        formik,
    }
}
