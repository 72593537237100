import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export const accountFormValidation: YUP.Schema<any> = YUP.object().shape({
    name: YUP.string().required(requiredMessage('Account Name')),
    account_number: YUP.string().required(requiredMessage('Account Number')),
    routing_number: YUP.string().required(requiredMessage('Routing Number')),
    currency: YUP.object().shape({
        code: YUP.string().required(requiredMessage('Currency')),
    }),
    remark: YUP.string(),
})

export interface IFormAccount {
    code?: string
    name: string
    account_number: string
    routing_number?: string
    currency: {
        code: string
    }
    remark?: string
}
export interface IFormAccountPayload {
    name: string
    account_number: string
    routing_number?: string
    currency: {
        code: string
    }
    remark?: string
}

// form Account initial data
export const accountFormInitial: IFormAccount = {
    name: '',
    account_number: '',
    routing_number: '',
    remark: '',
    currency: { code: '' },
}
