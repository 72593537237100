import * as Yup from 'yup'
import { IFormTransactionNo } from './form-transaction-no.interface'

export const useFormTransactionNoValidation = () => {
    const schema = Yup.object<IFormTransactionNo>().shape({

    })

    const generateInitialValue = () => {
        const initialValue: IFormTransactionNo = {
            typeTranscionNo: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
