import { staticDataTableHeader } from '../settings.static'
import { IUseModal } from '@components/modal/modal.service'
import Modal from '@components/modal/modal.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import Button from '@components/button/button.component'
import Table from '@components/table/table.component'
import FormInput from '@components/form-input/form-input.component'
import { FormikProps, FormikProvider } from 'formik'

const StaticDataModal = ({
    modalService,
    loadingData,
    title,
    data,
    formik,
}: {
    formik: FormikProps<{ searchTerm: string }>
    modalService: IUseModal
    loadingData: boolean
    title: string
    data: IDropdownItem[]
}) => {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="!p-0 md:absolute sticky !m-4 top-[10rem] max-w-[700px]"
        >
            <div className="flex flex-1 flex-col">
                {/* Header */}
                <div
                    className="
                    flex  text-size-M justify-between p-4 items-center border-b
                "
                >
                    <div className="flex-1 font-bold">{title}</div>
                    <div className="flex-1">
                        <FormikProvider value={formik}>
                            <form
                                className="w-full flex justify-end  mobile:pt-1"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                }}
                            >
                                <FormInput
                                    autoComplete="off"
                                    hideError={true}
                                    placeholder={'Search by Code/Label'}
                                    parentDivClassName="!mb-0 w-full min-w-[180px] "
                                    icon="ri-search-line"
                                    name="searchTerm"
                                    onKeyDown={(
                                        event: React.KeyboardEvent<HTMLInputElement>,
                                    ) => {
                                        if (
                                            event.key === 'Backspace' &&
                                            formik.values.searchTerm.length ===
                                                1
                                        ) {
                                            formik.values.searchTerm = ''
                                            formik.handleSubmit()
                                        }
                                    }}
                                />
                            </form>
                        </FormikProvider>
                    </div>
                </div>

                {/* Content */}
                <div className="h-[500px] border-b">
                    <Table
                        data={data}
                        headers={staticDataTableHeader}
                        loading={loadingData}
                        moduleTitle={''}
                        usePagination={false}
                    />
                </div>

                <div className="p-4 flex justify-end">
                    <Button
                        label="CLOSE"
                        onClick={() => {
                            modalService.closeModalHandling()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default StaticDataModal
