import { IMeta3 } from '@components/pagination/pagination.interface'
import {
    IExpenses,
    IInvoice,
    IOrder,
} from '../../repository/interface/expenses.interface'

export const orderData: IOrder[] = [
    {
        created: '2024-02-01',
        number: 'INV-001',
        vendor: 'Vendor A',
        due_date: 1706784000, // Unix timestamp
        status: 'open',
        balance: 5000,
        total: 10000,
        message: 'Payment pending',
        attach: 'invoice_001.pdf',
        currency: 'USD',
    },
    {
        created: '2024-02-02',
        number: 'INV-002',
        vendor: 'Vendor B',
        due_date: 1706870400,
        status: 'paid',
        balance: 3000,
        total: 7000,
        message: 'Paid in full',
        attach: 'receipt_002.pdf',
        currency: 'EUR',
    },
    {
        created: '2024-02-03',
        number: 'INV-003',
        vendor: 'Vendor C',
        due_date: 1706956800,
        status: 'open',
        balance: 1500,
        total: 5000,
        message: 'Partially paid',
        attach: 'invoice_003.pdf',
        currency: 'JPY',
    },
    {
        created: '2024-02-04',
        number: 'INV-004',
        vendor: 'Vendor D',
        due_date: 1707043200,
        status: 'overdue',
        balance: 0,
        total: 8000,
        message: 'Overdue',
        attach: 'invoice_004.pdf',
        currency: 'GBP',
    },
    {
        created: '2024-02-05',
        number: 'INV-005',
        vendor: 'Vendor E',
        due_date: 1707129600,
        status: 'open',
        balance: 2200,
        total: 6000,
        message: 'Awaiting confirmation',
        attach: 'invoice_005.pdf',
        currency: 'AUD',
    },
    {
        created: '2024-02-06',
        number: 'INV-006',
        vendor: 'Vendor F',
        due_date: 1707216000,
        status: 'paid',
        balance: 0,
        total: 7500,
        message: 'Payment received',
        attach: 'receipt_006.pdf',
        currency: 'CAD',
    },
    {
        created: '2024-02-07',
        number: 'INV-007',
        vendor: 'Vendor G',
        due_date: 1707302400,
        status: 'open',
        balance: 500,
        total: 5000,
        message: 'Pending approval',
        attach: 'invoice_007.pdf',
        currency: 'SGD',
    },
    {
        created: '2024-02-08',
        number: 'INV-008',
        vendor: 'Vendor H',
        due_date: 1707388800,
        status: 'overdue',
        balance: 1200,
        total: 4000,
        message: 'Delayed payment',
        attach: 'invoice_008.pdf',
        currency: 'CHF',
    },
    {
        created: '2024-02-09',
        number: 'INV-009',
        vendor: 'Vendor I',
        due_date: 1707475200,
        status: 'paid',
        balance: 0,
        total: 9000,
        message: 'Completed',
        attach: 'receipt_009.pdf',
        currency: 'CNY',
    },
    {
        created: '2024-02-10',
        number: 'INV-010',
        vendor: 'Vendor J',
        due_date: 1707561600,
        status: 'open',
        balance: 700,
        total: 3500,
        message: 'Under review',
        attach: 'invoice_010.pdf',
        currency: 'INR',
    },
]

export const invoiceData: IInvoice[] = [
    {
        created: '2024-02-01',
        number: 'INV-001',
        vendor: 'Vendor A',
        due_date: 1706784000, // Unix timestamp
        status: 'open',
        balance: 5000,
        total: 10000,
        message: 'Payment pending',
        attach: 'invoice_001.pdf',
        currency: 'USD',
    },
    {
        created: '2024-02-02',
        number: 'INV-002',
        vendor: 'Vendor B',
        due_date: 1706870400,
        status: 'paid',
        balance: 3000,
        total: 7000,
        message: 'Paid in full',
        attach: 'receipt_002.pdf',
        currency: 'EUR',
    },
    {
        created: '2024-02-03',
        number: 'INV-003',
        vendor: 'Vendor C',
        due_date: 1706956800,
        status: 'open',
        balance: 1500,
        total: 5000,
        message: 'Partially paid',
        attach: 'invoice_003.pdf',
        currency: 'JPY',
    },
    {
        created: '2024-02-04',
        number: 'INV-004',
        vendor: 'Vendor D',
        due_date: 1707043200,
        status: 'overdue',
        balance: 0,
        total: 8000,
        message: 'Overdue',
        attach: 'invoice_004.pdf',
        currency: 'GBP',
    },
    {
        created: '2024-02-05',
        number: 'INV-005',
        vendor: 'Vendor E',
        due_date: 1707129600,
        status: 'open',
        balance: 2200,
        total: 6000,
        message: 'Awaiting confirmation',
        attach: 'invoice_005.pdf',
        currency: 'AUD',
    },
    {
        created: '2024-02-06',
        number: 'INV-006',
        vendor: 'Vendor F',
        due_date: 1707216000,
        status: 'paid',
        balance: 0,
        total: 7500,
        message: 'Payment received',
        attach: 'receipt_006.pdf',
        currency: 'CAD',
    },
    {
        created: '2024-02-07',
        number: 'INV-007',
        vendor: 'Vendor G',
        due_date: 1707302400,
        status: 'open',
        balance: 500,
        total: 5000,
        message: 'Pending approval',
        attach: 'invoice_007.pdf',
        currency: 'SGD',
    },
    {
        created: '2024-02-08',
        number: 'INV-008',
        vendor: 'Vendor H',
        due_date: 1707388800,
        status: 'overdue',
        balance: 1200,
        total: 4000,
        message: 'Delayed payment',
        attach: 'invoice_008.pdf',
        currency: 'CHF',
    },
    {
        created: '2024-02-09',
        number: 'INV-009',
        vendor: 'Vendor I',
        due_date: 1707475200,
        status: 'paid',
        balance: 0,
        total: 9000,
        message: 'Completed',
        attach: 'receipt_009.pdf',
        currency: 'CNY',
    },
    {
        created: '2024-02-10',
        number: 'INV-010',
        vendor: 'Vendor J',
        due_date: 1707561600,
        status: 'open',
        balance: 700,
        total: 3500,
        message: 'Under review',
        attach: 'invoice_010.pdf',
        currency: 'INR',
    },
]

export const expensesData: IExpenses[] = [
    {
        created: '2024-02-01',
        number: 'TXN-001',
        category: 'salary',
        beneficiary: 'John Doe',
        status: 'paid',
        balance: 5000,
        total: 10000,
        message: 'Salary for January',
        attach: 'salary_jan.pdf',
        currency: 'USD',
    },
    {
        created: '2024-02-02',
        number: 'TXN-002',
        category: 'invoice payment',
        beneficiary: 'ABC Corp',
        status: 'open',
        balance: 3000,
        total: 7000,
        message: 'Payment for services',
        attach: 'invoice_abc.pdf',
        currency: 'EUR',
    },
    {
        created: '2024-02-03',
        number: 'TXN-003',
        category: 'refund',
        beneficiary: 'Jane Smith',
        status: 'overdue',
        balance: 1500,
        total: 5000,
        message: 'Refund for order #12345',
        attach: 'refund_12345.pdf',
        currency: 'JPY',
    },
    {
        created: '2024-02-04',
        number: 'TXN-004',
        category: 'loan repayment',
        beneficiary: 'XYZ Bank',
        status: 'paid',
        balance: 0,
        total: 8000,
        message: 'Loan installment for Feb',
        attach: 'loan_feb.pdf',
        currency: 'GBP',
    },
    {
        created: '2024-02-05',
        number: 'TXN-005',
        category: 'donation',
        beneficiary: 'Charity Org',
        status: 'close',
        balance: 2200,
        total: 6000,
        message: 'Donation for education fund',
        attach: 'donation_receipt.pdf',
        currency: 'AUD',
    },
    {
        created: '2024-02-06',
        number: 'TXN-006',
        category: 'business expense',
        beneficiary: 'XYZ Supplies',
        status: 'open',
        balance: 0,
        total: 7500,
        message: 'Office supplies purchase',
        attach: 'office_supplies.pdf',
        currency: 'CAD',
    },
    {
        created: '2024-02-07',
        number: 'TXN-007',
        category: 'tax payment',
        beneficiary: 'Govt Tax Dept',
        status: 'overdue',
        balance: 500,
        total: 5000,
        message: 'Quarterly tax payment',
        attach: 'tax_q1.pdf',
        currency: 'SGD',
    },
]

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
