import { ITableColumn } from '@components/table/table.interface'
import { ITitipan } from './titipan.interface'

export const titipanSummaryHeader: ITableColumn<ITitipan>[] = [
    {
        label: 'Account Name',
        accessor: 'accountName',
    },
    {
        label: 'Account Number',
        accessor: 'accountNumber',
    },
    {
        label: 'Last Transaction',
        accessor: 'lastTransaction',
    },
    {
        label: 'Balance USD',
        accessor: 'balanceUsd',
    },
    {
        label: 'Balance IDR',
        accessor: 'balanceIdr',
    },
]
