import Tab from '@components/tab/tab.component'
import { FormikProvider } from 'formik'
import useTabMaster from './tab-master2.service'
import Tooltip from '@components/tooltip/tooltip.component'
import FormInput from '@components/form-input/form-input.component'
import Button from '@components/button/button.component'
import React, { useEffect, useState } from 'react'
import { ITabMaster } from './tab-master2.interface'

const TabMaster2 = ({
    useSearch,
    placeHolderSearch,
    items,
    tabFilter,
    iconButton1,
    iconButton2,
    iconButton3,
    actionButton1,
    actionButton2,
    containerSearchClassName,
    initialSearch,
    onChange,
    onSearchSubmit,
    additionalButton1,
    additionalButton2,
    tabClassname,
    containerClassname,
    showTotalData,
    minSearchChar = 3,
}: ITabMaster) => {
    const { formik } = useTabMaster({
        handleSearch: onSearchSubmit,
        initialSearch,
        minSearchChar,
    })
    const [isWrapped, setIsWrapped] = useState(false)

    const isButtonExists = !!(iconButton1 || iconButton2 || iconButton3)
    const isRightSectionVisible = isButtonExists || useSearch
    const widthSideFlex = useSearch ? 'sm:w-full' : ' '


    const checkWrapping = () => {
        const childA = document.getElementById('tabmaster-side-A')
        const childB = document.getElementById('tabmaster-side-B')

        if (childA && childB) {
            const childATop = childA.getBoundingClientRect().top
            const childBTop = childB.getBoundingClientRect().top
            const isWrappedCondition = childBTop < childATop
            setIsWrapped(isWrappedCondition)
        }
    }

    useEffect(() => {
        const divElement = document.getElementById('loglines-content')
        if (!divElement) return

        const handleWidthChange = () => {
            checkWrapping()
        }

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentRect.width !== divElement.offsetWidth) {
                    handleWidthChange()
                }
            }
        })

        observer.observe(divElement)

        // Clean up observer on component unmount
        return () => observer.disconnect()
    }, [])

    useEffect(() => {
        // const handleInitialCheck = () => {
        //     setTimeout(checkWrapping, 1)
        // }
        // Re-check on window resize to detect any wrapping changes
        window.addEventListener('resize', checkWrapping)
        return () => window.removeEventListener('resize', checkWrapping)
    }, [])

    return (
        <>
            <div
                className={`gap-1 flex flex-col border-b border-solid themes-border-color  ${containerClassname}`}
                style={{ flexWrap: useSearch ? 'wrap-reverse' : 'nowrap' }}
            >
                <div
                    className={` px-3 pt-0 flex items-end overflow-auto ${items && 'border-b border-solid themes-border-color'} `}
                >
                    <Tab
                        containerClassName={`flex-grow sm:w-full h-full ${tabClassname}`}
                        items={items ?? []}
                        tabFilter={tabFilter}
                        onChange={(item) => {
                            onChange(item)
                        }}
                        showTotalData={showTotalData}
                    />
                </div>
                <div
                    className={`w-full gap-1 flex justify-between border-solid  themes-border-color px-3 ${containerClassname}`}
                    style={{ flexWrap: useSearch ? 'wrap-reverse' : 'nowrap' }}
                >
                    <div
                        id="tabmaster-side-A"
                        className={`pb-3 pt-2 flex items-center overflow-auto  w-fit sm:w-full sm:mb-2`}
                    >
                        {useSearch && (
                            <FormikProvider value={formik}>
                                <form
                                    className="w-full flex justify-end  mobile:pt-1 "
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        formik.handleSubmit()
                                    }}
                                >
                                    <Tooltip
                                        text={`Search term must be at least ${minSearchChar} characters`}
                                        isShow={
                                            formik.errors.searchTerm
                                                ? true
                                                : false
                                        }
                                    >
                                        <FormInput
                                            autoComplete="auto-off"
                                            hideError={true}
                                            placeholder={placeHolderSearch}
                                            parentDivClassName="!mb-0 min-w-[180px]"
                                            icon="ri-search-line"
                                            name="searchTerm"
                                            onKeyDown={(
                                                event: React.KeyboardEvent<HTMLInputElement>,
                                            ) => {
                                                if (
                                                    event.key === 'Backspace' &&
                                                    formik.values.searchTerm
                                                        .length === 1
                                                ) {
                                                    formik.values.searchTerm =
                                                        ''
                                                    formik.handleSubmit()
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </form>
                            </FormikProvider>
                        )}

                        {iconButton1 && (
                            <div>
                                <Button
                                    onClick={() => {
                                        iconButton1.onClick()
                                    }}
                                    icon={iconButton1.icon}
                                    className={`${iconButton1.className} ml-2 w-btnIconWidth !border-transparent`}
                                    variant="default"
                                />

                                {iconButton1?.filterOverlayComponent
                                    ? iconButton1.filterOverlayComponent
                                    : ''}
                            </div>
                        )}

                        {iconButton2 && (
                            <div>
                                <Button
                                    onClick={() => {
                                        iconButton2.onClick()
                                    }}
                                    icon={iconButton2.icon}
                                    className={`${iconButton2.className} ml-2 w-btnIconWidth !border-transparent`}
                                    variant="default"
                                />

                                {iconButton2?.filterOverlayComponent
                                    ? iconButton2.filterOverlayComponent
                                    : ''}
                            </div>
                        )}

                        {iconButton3 && (
                            <div>
                                <Button
                                    onClick={() => {
                                        iconButton3.onClick()
                                    }}
                                    icon={iconButton3.icon}
                                    className={`${iconButton3.className} ml-2 w-btnIconWidth !border-transparent`}
                                    variant="default"
                                />
                                {iconButton3?.filterOverlayComponent
                                    ? iconButton3.filterOverlayComponent
                                    : ''}
                            </div>
                        )}
                    </div>

                    <>
                        {isRightSectionVisible && (
                            <div
                                id="tabmaster-side-B"
                                className={`
                                ${widthSideFlex} ${containerSearchClassName || ''} 
                                ${isWrapped ? 'pt-2 md:max-w-none' : 'py-2'}
                            sm:min-w-[300px] mobile:min-w-[100px] 2xl:max-w-[600px] xl:max-w-[500px] md:max-w-[500px] flex flex-grow items-center mobile:justify-end 
                            mobile:flex-wrap mobile:flex-wrap-reverse mobile:gap-1  justify-end`}
                            >
                                {actionButton1 && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                actionButton1.onClick()
                                            }}
                                            label={actionButton1.label}
                                            icon={actionButton1.icon}
                                            iconSuffix={
                                                actionButton1.iconSuffix
                                            }
                                            className={`${actionButton1.className} ml-2 w-btnRegularWidth !text-size-S`}
                                            iconClassName={`${actionButton1.iconClassName}`}
                                            variant={`${actionButton1.variant ? actionButton1.variant : 'brand'}`}
                                        />
                                        {actionButton1?.OverlayComponent
                                            ? actionButton1.OverlayComponent
                                            : ''}
                                    </div>
                                )}

                                {actionButton2 && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                actionButton2.onClick()
                                            }}
                                            label={actionButton2.label}
                                            icon={actionButton2.icon}
                                            iconSuffix={
                                                actionButton2.iconSuffix
                                            }
                                            className={`${actionButton2.className} ml-2 w-btnRegularWidth !text-size-S`}
                                            iconClassName={`${actionButton2.iconClassName}`}
                                            variant={`${actionButton2.variant ? actionButton2.variant : 'brand'}`}
                                        />
                                        {actionButton2?.OverlayComponent
                                            ? actionButton2.OverlayComponent
                                            : ''}
                                    </div>
                                )}

                                {additionalButton1}
                                {additionalButton2}
                            </div>
                        )}
                    </>
                </div>
            </div>
        </>
    )
}

export default TabMaster2
