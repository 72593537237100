import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import Tab from '@components/tab/tab.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormShipmentDetail } from 'form-validation/shipment-details.validation'
import { FormikProps, FormikProvider } from 'formik'
import { ITabItem } from '@components/tab/tab.interface'
import { additionalInfoTabs } from './detail-shipment.static'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IOrganisation } from 'repository/interface/organisation.interface'
import { copyTextToClipboard } from '@services/common.service'
import { ILocation } from 'repository/interface/location.interface'
import { IAddress } from 'repository/interface/address.interface'
import { IConsolidation } from 'repository/interface/consolidation.interface'

const DetailShipmentForm = ({
    formik,
    selectedAdditionalInfoTab,
    setAdditionalInfoTab,
    addressHandling,
    consigneeAddress,
    shipperAddress,
}: {
    formik: FormikProps<IFormShipmentDetail>
    selectedAdditionalInfoTab: ITabItem
    setAdditionalInfoTab: (value: ITabItem) => void
    addNewNotify: () => void
    deleteNotifyByIndex: (index: number) => void
    addressHandling: (
        selectedCode: string,
        type: 'shipper' | 'consignee',
    ) => void
    consigneeAddress: IDropdownItem<IAddress>[]
    shipperAddress: IDropdownItem<IAddress>[]
}) => {
    const { contacts_info_notify } = formik.values
    const dropdownService = useDropdown()
    return (
        <div className="h-[calc(100vh-230px)] overflow-auto p-4">
            <FormikProvider value={formik}>
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                    {/* Shipment Info */}
                    <div className="">
                        <div className="text-size-M font-bold mb-5">
                            Shipment Info
                        </div>
                        <FormDropdown
                            name="transport_mode"
                            options={dropdownService.transportModesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="transport mode"
                            placeholder="Select Transport Mode"
                            disabled={true}
                        />
                        <FormDropdown
                            name="container_mode"
                            options={dropdownService.containerModeData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="container mode"
                            placeholder="Select Container Mode"
                        />
                        <FormInput
                            name="container_number"
                            label="container number"
                            useUppercaseLabel={true}
                            placeholder="Add Container Number"
                        />
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="volume_measure"
                                type="number"
                                label="volume"
                                useUppercaseLabel={true}
                                placeholder="Add Volume"
                            />
                            <FormDropdown
                                parentDivClassname="w-full"
                                name="volume_unit"
                                options={dropdownService.volumeUnitsData}
                                parentDivClassName="w-full"
                                useUppercaseLabel={true}
                                label="volume unit"
                            />
                        </div>

                        <FormDropdown
                            parentDivClassname="w-full"
                            name="service_level"
                            options={dropdownService.serviceLevelData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Service Level"
                        />

                        <FormInput
                            name="shippers_ref"
                            label="shipper's ref"
                            useUppercaseLabel={true}
                            placeholder="Add Shipper's Ref"
                        />
                        <FormDropdown
                            className="w-full"
                            name="payment_type"
                            options={dropdownService.paymentTypesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="payment type"
                            placeholder="Select Payment Type"
                        />
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="house_bill"
                                label="house bill"
                                useUppercaseLabel={true}
                                placeholder="Add House Bill"
                            />
                            <FormDropdown
                                parentDivClassname="w-full"
                                name="house_bill_status"
                                options={dropdownService.houseBillStatusData}
                                parentDivClassName="w-full"
                                useUppercaseLabel={true}
                                label="house bill status"
                            />
                        </div>

                        <FormInput
                            name="seal_number"
                            label="seal number"
                            useUppercaseLabel={true}
                            placeholder="Add Seal Number"
                        />
                        <FormInput
                            name="feeder"
                            label="feeder"
                            useUppercaseLabel={true}
                            placeholder="Add Feeder Number"
                        />
                        <FormTextarea
                            name="marks_and_numbers"
                            label="Marks & Numbers"
                            placeholder="Marks & Numbers info"
                        />
                    </div>
                    {/* Origin Location */}
                    <div className="">
                        <div className="text-size-M font-bold mb-5">
                            Origin Location
                        </div>
                        <FormDropdown
                            className="w-full"
                            name="origin_location"
                            options={dropdownService.unlocodesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="origin location"
                            placeholder="Select Origin Location"
                            additionalOnClick={(d) => {
                                const data =
                                    d as unknown as IDropdownItem<ILocation>
                                const country =
                                    data.additionalData?.country ?? ''
                                const state = data.additionalData?.name ?? ''
                                formik.setFieldValue('origin_country', country)
                                formik.setFieldValue('origin_state', state)
                            }}
                        />
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="origin_country"
                                label="country/region"
                                useUppercaseLabel={true}
                                placeholder="Select"
                                disabled={true}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name="origin_state"
                                label="state"
                                useUppercaseLabel={true}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormDatePicker
                                parentDivClassName="w-full"
                                name="etd"
                                label="ETD"
                                isRange={false}
                            />
                            <FormDatePicker
                                parentDivClassName="w-full"
                                name="atd"
                                label="ATD"
                                isRange={false}
                            />
                        </div>
                        <div className="border-b my-3"></div>
                        <div className="text-size-M font-bold mb-5">
                            Shipper Info
                        </div>
                        <FormDropdown
                            className="w-full"
                            name="shipper"
                            options={dropdownService.shipperOrganisationData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Shipper"
                            placeholder="Select Shipper"
                            additionalOnClick={(d) => {
                                const data =
                                    d as unknown as IDropdownItem<IOrganisation>
                                addressHandling(
                                    data.additionalData?.code ?? 'null',
                                    'shipper',
                                )
                                formik.setFieldValue('shipper_address_1', '')
                            }}
                        />
                        <FormDropdown
                            className="w-full"
                            name="shipper_address"
                            options={shipperAddress}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="address"
                            placeholder="Select Address"
                            additionalOnClick={(data) => {
                                const d = (data as IDropdownItem<IAddress>)
                                    .additionalData
                                if (!d) return
                                const label = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
                                formik.setFieldValue('shipper_address_1', label)
                            }}
                        />
                        <FormTextarea
                            name="shipper_address_1"
                            label="ADDRESS 1"
                            placeholder="Add Address 1"
                            disabled={true}
                            parentDivClassName="mb-3"
                            rows={3}
                        />
                        <FormCheckbox
                            name={'pick_up_included'}
                            label="Include Pick Up"
                            parentDivClassName="mt-5 !mb-6"
                        />
                        <FormDatePicker
                            parentDivClassName="w-full mb-3"
                            name="pick_up_estimated"
                            label="ESTIMATED PICK UP"
                            isRange={false}
                        />
                        <FormDatePicker
                            parentDivClassName="w-full"
                            name="pick_up_actual"
                            label="ACTUAL PICK UP"
                            isRange={false}
                        />
                    </div>
                    <div className="">
                        <div className="text-size-M font-bold mb-5">
                            Destination Location
                        </div>
                        <FormDropdown
                            className="w-full"
                            name="destination_location"
                            options={dropdownService.unlocodesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Destination Location"
                            placeholder="Select Destination"
                            additionalOnClick={(d) => {
                                const data =
                                    d as unknown as IDropdownItem<ILocation>
                                const country =
                                    data.additionalData?.country ?? ''
                                const state = data.additionalData?.name ?? ''
                                formik.setFieldValue(
                                    'destination_country',
                                    country,
                                )
                                formik.setFieldValue('destination_state', state)
                            }}
                        />
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="destination_country"
                                label="country/region"
                                useUppercaseLabel={true}
                                disabled={true}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name="destination_state"
                                label="state"
                                useUppercaseLabel={true}
                                disabled={true}
                            />
                        </div>
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
                            <FormDatePicker
                                parentDivClassName="w-full"
                                name="eta"
                                label="ETA"
                                isRange={false}
                            />
                            <FormDatePicker
                                parentDivClassName="w-full"
                                name="ata"
                                label="ATA"
                                isRange={false}
                            />
                        </div>
                        <div className="border-b my-3"></div>
                        <div className="text-size-M font-bold mb-5">
                            Consignee Info
                        </div>
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="consignee"
                            options={dropdownService.consigneeOrganisationData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="consignee"
                            additionalOnClick={(d) => {
                                const data =
                                    d as unknown as IDropdownItem<IOrganisation>
                                addressHandling(
                                    data.additionalData?.code ?? 'null',
                                    'consignee',
                                )
                                formik.setFieldValue('consignee_address_1', '')
                            }}
                        />
                        <FormDropdown
                            className="w-full"
                            name="consignee_address"
                            options={consigneeAddress}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="address"
                            placeholder="Select Address"
                            additionalOnClick={(data) => {
                                const d = (data as IDropdownItem<IAddress>)
                                    .additionalData
                                if (!d) return
                                const label = `${d.street}, ${d.street_2}, ${d.city.name}, ${d.state.name}, ${d.country.name}`
                                formik.setFieldValue(
                                    'consignee_address_1',
                                    label,
                                )
                            }}
                        />
                        <FormTextarea
                            name="consignee_address_1"
                            label="ADDRESS 1"
                            placeholder="Add Address 1"
                            disabled={true}
                            parentDivClassName="mb-3"
                            rows={3}
                        />

                        <FormCheckbox
                            name={'delivery_included'}
                            label="Include Delivery"
                            parentDivClassName="mt-5 !mb-6"
                        />
                        <FormDatePicker
                            parentDivClassName="w-full mb-3"
                            name="delivery_estimated"
                            label="ESTIMATED DELIVERY"
                            isRange={false}
                        />
                        <FormDatePicker
                            parentDivClassName="w-full"
                            name="delivery_actual_deliver"
                            label="ACTUAL DELIVERY"
                            isRange={false}
                        />
                    </div>
                    <div className="">
                        <div className="text-size-M font-bold mb-5">
                            <FormCheckbox
                                name={'consolidation'}
                                label="CONSOLIDATION"
                                parentDivClassName=""
                            />
                        </div>
                        <FormDropdown
                            className="w-full"
                            name="consolidation_id"
                            options={dropdownService.consolidationData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Consolidation ID"
                            placeholder="Select Consolidation ID"
                            additionalOnClick={(d) => {
                                const data =
                                    d as unknown as IDropdownItem<IConsolidation>
                                const type =
                                    data.additionalData?.console_type ?? '-'
                                const category =
                                    data.additionalData?.console_category
                                        ?.code ?? '-'
                                formik.setFieldValue(
                                    'consolidation_console_type',
                                    type,
                                )
                                formik.setFieldValue(
                                    'consolidation_console_category',
                                    category,
                                )
                            }}
                        />
                        <FormDropdown
                            className="w-full"
                            name="consolidation_console_type"
                            options={dropdownService.consoleTypeData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Console Type"
                            placeholder="Select Console Type"
                            disabled
                        />
                        <FormDropdown
                            className="w-full"
                            name="consolidation_console_category"
                            options={dropdownService.consoleCategoryData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Console Category"
                            placeholder="Select Console Category"
                            disabled
                        />
                        <div className="border-b my-3"></div>

                        <div className="text-size-M font-bold mb-3">
                            Additional Info
                        </div>
                        <Tab
                            containerClassName="shipment-detail-tab"
                            items={additionalInfoTabs}
                            selectedItem={selectedAdditionalInfoTab}
                            onChange={(value) => {
                                setAdditionalInfoTab(value)
                            }}
                        />

                        {selectedAdditionalInfoTab.value === 'charges' && (
                            <div className="pt-5">
                                <FormDropdown
                                    className="w-full"
                                    name="charges_inco_term"
                                    options={dropdownService.incotermsData}
                                    parentDivClassName="w-full"
                                    useUppercaseLabel={true}
                                    label="Inco Term"
                                    placeholder="Select Inco Term"
                                />
                                <FormInput
                                    parentDivClassName="w-full"
                                    name="charges_additional_terms"
                                    label="Additional Terms"
                                    useUppercaseLabel={true}
                                    placeholder="Add Additional Term"
                                />
                                <FormDropdown
                                    className="w-full"
                                    name="charges_apply"
                                    options={dropdownService.chargeAppliesData}
                                    parentDivClassName="w-full"
                                    useUppercaseLabel={true}
                                    label="Charges Apply"
                                    placeholder="Select Charges Apply"
                                />
                            </div>
                        )}

                        {/* {selectedAdditionalInfoTab.value === 'goods_info' && (
                        <div className="pt-5    ">
                            <FormDropdown
                                name="goods_product_category"
                                placeholder="Select Goods"
                                label="Product Category"
                                parentDivClassName="w-full !mb-0"
                                useUppercaseLabel={true}
                                options={[]}
                                isClearable={true}
                            />
                            <div className="w-full flex gap-2 mb-3 mt-3 sm:flex-wrap">
                                <FormInput
                                    parentDivClassName="w-full !mb-0"
                                    name="goods_value"
                                    placeholder="Add Goods Value"
                                    label="goods value"
                                />
                                <FormDropdown
                                    name="goods_value_currency"
                                    placeholder="Select Currency"
                                    label="Currency"
                                    parentDivClassName="w-full !mb-0"
                                    useUppercaseLabel={true}
                                    options={dropdownService.currencyData}
                                    isClearable={true}
                                />
                            </div>
                            <div className="w-full flex gap-2 mb-3 mt-3 sm:flex-wrap">
                                <FormInput
                                    parentDivClassName="w-full !mb-0"
                                    name="goods_insurance_value"
                                    placeholder="Add Goods Value"
                                    label="insurance value"
                                    useUppercaseLabel={true}
                                />
                                <FormDropdown
                                    name="goods_insurance_value_currency"
                                    placeholder="Select Currency"
                                    label="Currency"
                                    parentDivClassName="w-full !mb-0"
                                    useUppercaseLabel={true}
                                    options={dropdownService.currencyData}
                                    isClearable={true}
                                />
                            </div>
                            <FormInput
                                parentDivClassName="w-full"
                                name="goods_gross_weight"
                                placeholder="Add Gross Weight"
                                label="Gross Weight"
                                useUppercaseLabel={true}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name="goods_chargable_weight"
                                placeholder="Add Chargable Weight"
                                label="Chargable Weight"
                                useUppercaseLabel={true}
                            />
                            <FormDropdown
                                name="goods_vol_as"
                                placeholder="Select VOL/AS"
                                label="Vol/AS"
                                parentDivClassName="w-full"
                                useUppercaseLabel={true}
                                options={[]}
                                isClearable={true}
                            />

                            <FormTextarea
                                name="goods_description"
                                label="GOODS DESCRIPTION"
                                placeholder="Add Description"
                            />
                        </div>
                    )} */}

                        {selectedAdditionalInfoTab.value === 'contact_info' && (
                            <div className="pt-5">
                                <FormDropdown
                                    name="contacts_info_agent.code"
                                    placeholder="Select Agent"
                                    label="Agent"
                                    parentDivClassName="w-full !mb-0"
                                    useUppercaseLabel={true}
                                    options={
                                        dropdownService.agentOrganisationData
                                    }
                                    isClearable={true}
                                    additionalOnClick={(dt) => {
                                        const d = (
                                            dt as IDropdownItem<IOrganisation>
                                        ).additionalData
                                        const value = !d
                                            ? {
                                                  code: '',
                                                  name: '',
                                                  email: '',
                                                  phone: '',
                                              }
                                            : {
                                                  code: d.code,
                                                  name: d.name,
                                                  email: d?.email_1 ?? '',
                                                  phone: d?.mobile_no ?? '',
                                              }
                                        formik.setFieldValue(
                                            'contacts_info_agent',
                                            value,
                                        )
                                    }}
                                    onClear={() => {
                                        const value = {
                                            code: '',
                                            name: '',
                                            email: '',
                                            phone: '',
                                        }
                                        formik.setFieldValue(
                                            'contacts_info_agent',
                                            value,
                                        )
                                    }}
                                />
                                <div className="w-full flex gap-2 mb-2 mt-3 sm:flex-wrap">
                                    <FormInput
                                        parentDivClassName="w-full !mb-0"
                                        name="contacts_info_agent.email"
                                        id="contacts_info_agent.email"
                                        placeholder="Email"
                                        disabled={true}
                                        icon="ri-file-copy-line"
                                        onIconClick={() => {
                                            copyTextToClipboard(
                                                'contacts_info_agent.email',
                                            )
                                        }}
                                    />
                                    <FormInput
                                        parentDivClassName="w-full !mb-0"
                                        name="contacts_info_agent.phone"
                                        id="contacts_info_agent.phone"
                                        placeholder="Phone Number"
                                        disabled={true}
                                        icon="ri-file-copy-line"
                                        onIconClick={() => {
                                            copyTextToClipboard(
                                                'contacts_info_agent.phone',
                                            )
                                        }}
                                    />
                                </div>

                                {(contacts_info_notify ?? []).map(
                                    (data, index) => {
                                        return (
                                            <div
                                                key={'notify-parent-' + index}
                                                className="flex gap-2"
                                            >
                                                <div
                                                    key={'notify-' + index}
                                                    className="flex flex-wrap flex-grow gap-2 mb-3"
                                                >
                                                    <FormDropdown
                                                        name={`notify_contacts[${index}].code`}
                                                        key={`notify_contacts[${index}].code`}
                                                        placeholder="Select Contact to Notify"
                                                        label={
                                                            'NOTIFY ' +
                                                            (index + 1)
                                                        }
                                                        parentDivClassName="w-full !mb-0"
                                                        useUppercaseLabel={true}
                                                        options={
                                                            dropdownService.agentOrganisationData
                                                        }
                                                        isClearable={true}
                                                        additionalOnClick={(
                                                            dt,
                                                        ) => {
                                                            const d = (
                                                                dt as IDropdownItem<IOrganisation>
                                                            ).additionalData
                                                            const value = !d
                                                                ? {
                                                                      code: '',
                                                                      name: '',
                                                                      email: '',
                                                                      phone: '',
                                                                  }
                                                                : {
                                                                      code: d.code,
                                                                      name: d.name,
                                                                      email: d.email_1,
                                                                      phone:
                                                                          d.phone_no ??
                                                                          '',
                                                                  }
                                                            formik.setFieldValue(
                                                                `notify_contacts[${index}]`,
                                                                value,
                                                            )
                                                        }}
                                                    />
                                                    <div className="w-full flex gap-2 sm:flex-wrap">
                                                        <FormInput
                                                            id={`notify_contacts[${index}].email`}
                                                            key={`notify_contacts[${index}].email`}
                                                            parentDivClassName="w-full !mb-0"
                                                            name={`notify_contacts[${index}].email`}
                                                            placeholder="Email"
                                                            disabled={true}
                                                            icon="ri-file-copy-line"
                                                            onIconClick={() => {
                                                                copyTextToClipboard(
                                                                    `notify_contacts[${index}].email`,
                                                                )
                                                            }}
                                                        />
                                                        <FormInput
                                                            parentDivClassName="w-full !mb-0"
                                                            key={`notify[${index}].phone`}
                                                            name={`notify[${index}].phone`}
                                                            id={`notify[${index}].phone`}
                                                            placeholder="Phone Number"
                                                            disabled={true}
                                                            icon="ri-file-copy-line"
                                                            onIconClick={() => {
                                                                copyTextToClipboard(
                                                                    `notify_contacts[${index}].phone`,
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    },
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default DetailShipmentForm
