import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import useFilter from './filter.service'
import { IFetchParams } from 'common/common.interface'
import FormInput from '@components/form-input/form-input.component'

const Filter = ({
    onFilterSubmit,
    isLoading,
}: {
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { formik, dropdownService } = useFilter(onFilterSubmit)
    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                <div className=" w-full">
                    <FormInput
                        name="site"
                        label={'SEARCH'}
                        useUppercaseLabel={true}
                        placeholder={'Search'}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="site"
                        options={dropdownService.organisationCarrierData}
                        label={'SITE'}
                        useUppercaseLabel={true}
                        placeholder={'All Sites'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="item_category_3"
                        options={[
                            { label: 'Dry', value: 'DRY' },
                            { label: 'Frozen', value: 'FROZEN' },
                            { label: 'Fresh', value: 'FRESH' },
                        ]}
                        label={'ITEM CATEGORY 3'}
                        useUppercaseLabel={true}
                        placeholder={'All Item Category 3'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="item_status"
                        options={[
                            { label: 'Active', value: 'ACTIVE' },
                            { label: 'NPL', value: 'NPL' },
                        ]}
                        label={'ITEM STATUS'}
                        useUppercaseLabel={true}
                        placeholder={'All Item Status'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="stock_level_status"
                        options={[
                            { label: 'AVAILABLE', value: 'AVAILABLE' },
                            { label: 'LOW STOCK', value: 'LOW STOCK' },
                        ]}
                        label={'STOCK LEVEL STATUS'}
                        useUppercaseLabel={true}
                        placeholder={'All Stock Level Status'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>

                <div className="-mx-4 border-b border-gray-40 my-1"></div>
                <div className="flex gap-2 mt-1">
                    <Button
                        onClick={() => {
                            formik.submitForm()
                        }}
                        isLoading={isLoading}
                        label={'RESET'}
                        useUpperCase={true}
                        className="w-full"
                        variant="default"
                    />
                    <Button
                        onClick={() => {
                            formik.resetForm()
                        }}
                        isLoading={isLoading}
                        label={'SAVE FILTER'}
                        useUpperCase={true}
                        className="w-full"
                        variant="brand"
                    />
                </div>
            </FormikProvider>
        </div>
    )
}

export default Filter
