import Button from '@components/button/button.component'
import { IItemListsTable } from '../../item-lists.interface'

export const RowOptionItemList = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IItemListsTable
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit Item"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Remove Item"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)
