import Button from '@components/button/button.component'
import {
    IUseUserAccessForm,
    useUserAccessForm,
} from './user-access-form.service'
import { FormikProvider } from 'formik'
import './user-access-form.style.css'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import SettingRoleModal from './modal/setting-role/setting-role.component'
import CreateRoleModal from './modal/create-role/setting-role.component'

export default function UserAccessForm({
    isNew,
    isProfilePage,
}: IUseUserAccessForm) {
    const {
        formikModule,
        settingRoleModalService,
        createRoleModalservice,
        dropdownService,
        roleModuleData,
        nav,
        tabs,
        setTabs,
    } =
        useUserAccessForm({ isNew, isProfilePage })

    const label = isNew ? 'CREATE NEW USER' : 'UPDATE USER'
    const buttonLabel = isNew ? 'CREATE NEW USER' : 'UPDATE USER'
    const cancelButtonLabel = isNew ? 'CANCEL CREATE' : 'CANCEL UPDATE'

    const item = ['Create', 'View Only', 'Edit', 'Delete']

    return (
        <>
            <div className={`container-global user-access`}>
                <div className="border-b themes-border-color">
                    <div className="p-4 text-size-S font-bold">{label}</div>
                </div>

                {/* Form */}
                <div className="flex sm:flex-wrap content flex-col">
                    <FormikProvider value={formikModule}>
                        <div className='border-b flex gap-3 px-4 text-size-S'>
                            <div
                                className={`py-2 cursor-pointer ${tabs === 'details' ? 'themes-text-brand-v5 font-bold border-b-[1px] themes-border-brand-v5' : 'themes-text-gray-v5'}`}
                                onClick={() => setTabs('details')}>
                                Details
                            </div>
                            <div
                                className={`py-2 cursor-pointer ${tabs === 'configurasi' ? 'themes-text-brand-v5 font-bold border-b-[1px] themes-border-brand-v5' : 'themes-text-gray-v5'}`}
                                onClick={() => setTabs('configurasi')}>
                                Configuration
                            </div>
                        </div>
                        {tabs === 'details' && (
                            <div className='flex gap-4 px-5 h-full'>
                                <div className="w-full pt-5 border-r-[1px] pr-5">
                                    <div className="text-size-S font-bold mb-3">
                                        User Profile
                                    </div>
                                    <div className='flex gap-2 w-full'>
                                        <FormInput
                                            label="TITLE"
                                            placeholder="Select Title"
                                            name={'title'}
                                            useUppercaseLabel
                                        />
                                        <div className="w-full">
                                            <FormInput
                                                label="FULL NAME"
                                                placeholder="Enter User Full Name"
                                                name={'full_name'}
                                                useUppercaseLabel
                                            />
                                        </div>
                                    </div>
                                    <FormInput
                                        label="JOB TITLE"
                                        placeholder="Enter User Job Title"
                                        name={'job_title'}
                                        useUppercaseLabel
                                    />
                                    <FormDropdown
                                        name="job_category"
                                        label="JOB CATEGORY"
                                        options={dropdownService.jobCategoriesData}
                                        dropDownIndicator={true}
                                        additionalOnClick={(d) => {
                                            const value = (d as IDropdownItem<ICities>)
                                                .additionalData?.code
                                            formikModule.setFieldValue(
                                                'job_category',
                                                value,
                                            )
                                        }}
                                        isSearchable={true}
                                        useUppercaseLabel
                                    />

                                    <div className="text-size-S font-bold mb-3">
                                        User Account
                                    </div>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <FormInput
                                            label="EMAIL"
                                            placeholder="Enter User Email"
                                            name={'email'}
                                            useUppercaseLabel
                                        />
                                        <FormInput
                                            label="USERNAME"
                                            placeholder="Enter Username"
                                            name={'user_name'}
                                            useUppercaseLabel
                                        />
                                    </div>
                                </div>
                                <div className="w-full pt-5">
                                    <div className="text-size-S font-bold mb-3">
                                        User Address (Optional)
                                    </div>
                                    <div className="flex gap-2 w-full">
                                        <div className="w-full">
                                            <FormDropdown
                                                name="country"
                                                label="Country"
                                                useUppercaseLabel
                                                placeholder='Select Country'
                                                options={dropdownService.countriesData}
                                                dropDownIndicator={true}
                                                additionalOnClick={(d) => {
                                                    const value = (
                                                        d as IDropdownItem<ICountries>
                                                    ).additionalData?.code
                                                    formikModule.setFieldValue(
                                                        'country',
                                                        value,
                                                    )
                                                    if (value)
                                                        dropdownService.getStates(value)
                                                }}
                                                isSearchable={true}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormDropdown
                                                name="state"
                                                label="Province"
                                                options={dropdownService.statesData}
                                                useUppercaseLabel
                                                placeholder='Select Province'
                                                dropDownIndicator={true}
                                                additionalOnClick={(d) => {
                                                    const value = (
                                                        d as IDropdownItem<IStates>
                                                    ).additionalData?.code
                                                    formikModule.setFieldValue(
                                                        'state',
                                                        value,
                                                    )
                                                    if (value)
                                                        dropdownService.getCities(value)
                                                }}
                                                isSearchable={true}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormDropdown
                                                name="city"
                                                label="City"
                                                useUppercaseLabel
                                                placeholder='Select City'
                                                options={dropdownService.citiesData}
                                                dropDownIndicator={true}
                                                additionalOnClick={(d) => {
                                                    const value = (
                                                        d as IDropdownItem<ICities>
                                                    ).additionalData?.code
                                                    formikModule.setFieldValue(
                                                        'city',
                                                        value,
                                                    )
                                                }}
                                                isSearchable={true}
                                            />
                                        </div>
                                    </div>
                                    <FormTextArea
                                        name="street"
                                        label="Address Details"
                                        placeholder="Enter Address Details"
                                        rows={3}
                                        useUppercaseLabel
                                    />

                                    <div className="w-full mt-2">
                                        <div className="text-size-S font-bold mb-3">
                                            User Contact (Optional)
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="w-[30%]">
                                                <FormDropdown
                                                    placeholder="+62"
                                                    name={`mobile.country_code`}
                                                    options={dropdownService.countriesData.map(
                                                        (country) => ({
                                                            ...country,
                                                            label: `${country.additionalData?.phone_code} ${country.additionalData?.name}`,
                                                            value: `${country.additionalData?.phone_code}`,
                                                        }),
                                                    )}
                                                    required={true}
                                                    useUppercaseLabel
                                                />
                                            </div>
                                            <div className="w-full">
                                                <FormInput
                                                    placeholder="81198983431"
                                                    required={true}
                                                    type="number"
                                                    name="mobile.number"
                                                    className="no-spinner"
                                                    useUppercaseLabel
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="text-size-S font-bold mb-3">
                                            Emergency Contact (Optional)
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 w-full">
                                            <div className="w-full">
                                                <FormInput
                                                    name="emergency_contact_name"
                                                    label="Emergency Contact Name"
                                                    placeholder="Enter Contact name"
                                                    useUppercaseLabel
                                                />
                                            </div>
                                            <div className="w-full">
                                                <div className="flex flex-row gap-2">
                                                    <div className="w-[40%]">
                                                        <FormDropdown
                                                            placeholder="+62"
                                                            name={`emergency_contact_phone.country_code`}
                                                            useUppercaseLabel
                                                            options={dropdownService.countriesData.map(
                                                                (country) => ({
                                                                    ...country,
                                                                    label: `${country.additionalData?.phone_code} ${country.additionalData?.name}`,
                                                                    value: `${country.additionalData?.phone_code}`,
                                                                }),
                                                            )}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <FormInput
                                                            placeholder="81198983431"
                                                            required={true}
                                                            useUppercaseLabel
                                                            type="number"
                                                            name={`emergency_contact_phone.number`}
                                                            className="no-spinner"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {tabs === 'configurasi' && (
                            <div className='grid grid-cols-8 h-full'>
                                <div className='col-span-2 p-3 border-r-[1px]'>
                                    <div className="flex gap-3 justify-between mb-3">
                                        <div className="flex-grow leading-tight t">
                                            <div className="text-size-S font-bold">
                                                Site
                                            </div>
                                            <div className="text-size-XS leading-tight themes-text-gray-v4">
                                                Which site should this user be assigned to?
                                            </div>
                                        </div>
                                    </div>
                                    <FormDropdown
                                        name="site"
                                        label="Site"
                                        isSearchable={true}
                                        useUppercaseLabel
                                        options={dropdownService.branchesData}
                                    />
                                    <div className="flex gap-3 justify-between mb-3">
                                        <div className="flex-grow leading-tight t">
                                            <div className="text-size-S font-bold">
                                                User Role
                                            </div>
                                            <div className="text-size-XS leading-tight themes-text-gray-v4">
                                                What type of access is this user made user for?
                                            </div>
                                        </div>
                                        <div
                                            className="flex-initial flex items-center justify-end text-size-S w-[240px] cursor-pointer"
                                            onClick={() =>
                                                settingRoleModalService.openModalHandling()
                                            }
                                        >
                                            <i className="ri-edit-line mr-2 -mt-1" />
                                            SET UP ROLE
                                        </div>
                                    </div>
                                    <FormDropdown
                                        name="role"
                                        label="Role"
                                        isSearchable={true}
                                        useUppercaseLabel
                                        options={dropdownService.rolesData}
                                    />
                                </div>
                                <div className='col-span-3 border-r-[1px]'>
                                    <div className='border-b-[2px] p-3 text-size-L'>Access Name</div>
                                    <div>
                                        {roleModuleData.map((data, index) => (
                                            <div key={index} className='flex justify-between px-3 py-2 border-b-[1px] cursor-pointer themes-text-gray-v5'>
                                                <div>{data.label}</div>
                                                <i className="ri-arrow-right-s-line" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-span-3 '>
                                    <div className='border-b-[2px] p-3 text-size-L'>Access Type</div>
                                    {item.map((act, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`w-full flex items-center px-3 justify-between border-b themes-bg-hover-brand-v1 cursor-pointer`}
                                            >
                                                <div className="py-2 flex items-center ">
                                                    {act}
                                                </div>
                                                <div>
                                                    <ToggleSwitch
                                                        initialOn={false}
                                                        onClick={() => {
                                                            // if (subModuleKey) {
                                                            //     toggleAction(
                                                            //         act.key,
                                                            //         moduleKey,
                                                            //         subModuleKey,
                                                            //     )
                                                            // } else {
                                                            //     toggleAction(act.key, moduleKey)
                                                            // }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        )}

                    </FormikProvider>
                </div>
                {/* Button */}
                <div className="flex justify-between p-4 border-t">
                    <Button
                        onClick={() => {
                            nav(-1)
                        }}
                        type="button"
                        label={cancelButtonLabel}
                    />
                    <Button
                        type="button"
                        onClick={async () => {
                            formikModule.submitForm()
                        }}
                        variant="brand"
                        label={buttonLabel}
                    />
                </div>
            </div>

            <SettingRoleModal
                formikModule={formikModule}
                modalService={settingRoleModalService}
                modalServiceCreateRole={createRoleModalservice}
                submitHandling={(data) => {
                    console.log(data)
                }}
            />

            <CreateRoleModal
                modalService={createRoleModalservice}
                modalServiceSettingRole={settingRoleModalService}
            />
        </>
    )
}
