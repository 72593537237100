import {
    ExpensesHeader,
    InvoiceHeader,
    OrderHeader,
    tabItemStatic,
} from './expenses.static'
import useExpenses from './expenses.service'
import {
    IExpenses,
    IInvoice,
    IOrder,
} from 'repository/interface/expenses.interface'
import Table from '@components/table/table.component'
import {
    metaDummy,
    expensesData,
    invoiceData,
    orderData,
} from './expenses.dummy'
import FilterOverlay from './components/filter/filter-overlay.component'
import TabMaster2 from '../components/tab-master2/tab-master2.component'
import {
    RowOptionExpenses,
    RowOptionInvoice,
    RowOptionOrder,
} from './components/option/option.component'

const Expenses = () => {
    const {
        selectedTabItem,
        loading,
        fetchParam,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        filterOverlay,
        deleteLoading,
        deleteData,
        tabFilter,
    } = useExpenses()

    const ExpensesDropdown = () => {
        return (
            <div className="flex flex-row">
                <div className="relative inline-block ml-4 group">
                    <div
                        className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                    >
                        CREATE PURCHASES
                        <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                    </div>

                    <div
                        className="absolute left-0 mt-2 w-[220px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                    >
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                                navigate(
                                    '/pruchases-and-expenses/create-invoice',
                                )
                            }
                        >
                            Purchases Invoice
                        </div>
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                                navigate('/pruchases-and-expenses/create-order')
                            }
                        >
                            Purchases Order
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster2
                    items={tabItemStatic || []}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <FilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={() => {}}
                            />
                        ),
                    }}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search (min 3 char)'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    additionalButton1={<>{ExpensesDropdown()}</>}
                />
                {tabFilter?.value === 'expenses' ? (
                    <Table<IExpenses>
                        headers={ExpensesHeader}
                        data={expensesData}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        meta3={metaDummy}
                        onRowClick={(data) => {
                            navigate(
                                `/pruchases-and-expenses/detail/${data.category}`,
                            )
                        }}
                        moduleTitle={'Purchases & Expenses'}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionExpenses
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.message)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                ) : tabFilter?.value === 'purchases_order' ? (
                    <Table<IOrder>
                        headers={OrderHeader}
                        data={orderData}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        meta3={metaDummy}
                        moduleTitle={'Purchases & Expenses'}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionOrder
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.message)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                ) : (
                    <Table<IInvoice>
                        headers={InvoiceHeader}
                        data={invoiceData}
                        loading={loading}
                        nextHandling={(page) => {
                            handlePagination(page)
                        }}
                        previousHandling={(page) => {
                            handlePagination(page)
                        }}
                        onRowClick={(data) => {
                            navigate(
                                `/pruchases-and-expenses/detail/${data.number}`,
                            )
                        }}
                        meta3={metaDummy}
                        moduleTitle={'Purchases & Expenses'}
                        rowOptionComponent={(rowData, closeHandling) => (
                            <RowOptionInvoice
                                closeHandling={closeHandling}
                                rowData={rowData}
                                deleteHandling={() =>
                                    deleteData(rowData.message)
                                }
                                deleteLoading={deleteLoading}
                            />
                        )}
                    />
                )}
            </div>
        </div>
    )
}

export default Expenses
