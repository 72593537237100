/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import Input from '@components/input/input.component'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { useModal } from '@components/modal/modal.service'
import useOrganisationDetail from '../../organisation-details.service'
import { useAccountListService } from './accounts.service'
import { accountHeaders } from '../../organisation-details.static'
import { IAccounts } from '../../organisation-details.interface'
import { FormikProvider } from 'formik'
import AccountsListForm from './accounts-form.component'
import Button from '@components/button/button.component'
import CreateOrEditAccountModal from '../create-or-edit-account-modal.component'
import RemoveModal from '../remove-modal.component'
import DuplicateModal from '../duplicate-modal.component'

const Accounts = () => {
    const {
        accountListData: data,
        loading,
        accountDetail,
        handleAccountDetail,
        formikOrgAccountDetail,
        formikOrgCreateAccount,
        deleteAccount,
        handleDuplicate,
        dropdownService,
        accountSelected,
        createOrEditAccountModal,
    } = useAccountListService()

    const { removeModalService } = useOrganisationDetail()

    const duplicateModal = useModal()

    return (
        <>
            <div className="flex gap-x-2">
                <div
                    className={`${data?.length > 0 ? 'w-3/4' : 'w-full'} h-[calc(100vh-19rem)]`}
                >
                    {' '}
                    <SimpleTable<IAccounts>
                        headers={accountHeaders}
                        data={data || []}
                        isAddRow={true}
                        addRowLabel="Add New Account"
                        onRowClick={(data) => handleAccountDetail(data)}
                        customBuildParam4={(data) => ({
                            // set details
                            // func1: () => func1(data.code),
                            func2: () => {
                                createOrEditAccountModal.openModalHandling()
                            },
                            func3: () => {
                                removeModalService.openModalHandling()
                            },
                        })}
                        addRowClick={() => {
                            createOrEditAccountModal.openModalHandling()
                        }}
                        variant="gray"
                    />
                </div>
                {data?.length > 0 && (
                    <FormikProvider value={formikOrgAccountDetail}>
                        <div className="w-1/4 border rounded h-[calc(100vh-19rem)] flex flex-col">
                            <div className="flex justify-between items-center sm:flex-col">
                                <div className="p-4">Account Details</div>
                                <div className="m-2 flex">
                                    <i
                                        onClick={() => {
                                            duplicateModal.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-file-copy-line text-[1.25rem]"
                                    ></i>
                                    <i
                                        onClick={() => {
                                            removeModalService.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-delete-bin-line text-[1.25rem] text-red-600"
                                    ></i>
                                </div>
                            </div>
                            <div className="border-b"></div>
                            <div className="overflow-auto">
                                <AccountsListForm
                                    formik={formikOrgAccountDetail}
                                    dropdownService={dropdownService}
                                />
                            </div>
                            <div className="flex flex-col gap-2 p-3">
                                <Button
                                    label="SAVE CHANGES"
                                    variant="brand-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgAccountDetail.submitForm()
                                    }}
                                />
                                <Button
                                    label="DISCARD CHANGES"
                                    variant="red-inverse"
                                    className="w-full"
                                    icon="ri-save-line"
                                    onClick={() => {
                                        formikOrgAccountDetail.resetForm({
                                            values: accountSelected,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </FormikProvider>
                )}
                <RemoveModal
                    modalService={removeModalService}
                    type="removeAccount"
                    onClick={() => {
                        const id = accountDetail?.code || ''
                        deleteAccount(id)
                    }}
                    data={accountDetail?.name || ''}
                />
                <DuplicateModal
                    modalService={duplicateModal}
                    type="duplicateAccount"
                    onClick={handleDuplicate}
                    data={accountDetail?.name || ''}
                />
            </div>
            <CreateOrEditAccountModal
                modalService={createOrEditAccountModal}
                formik={formikOrgCreateAccount}
                isCreate={true}
                dropdownService={dropdownService}
            />
        </>
    )
}

export default Accounts
