import { IFetchParams, IResponseData } from 'common/common.interface'
import { IPackLines } from './interface/packlines.interface'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IFormPackLinePayload } from 'form-validation/pack-line.validation'
import { Toast } from '@components/toast/toast.component'

export const fetchPackLines = async (
    shipmentCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<IResponseData<IPackLines[]>, IFetchParams>(
            {
                endpoint: `${endpoints.shipments}/${shipmentCode}/pack_lines`,
                params: {},
            },
        )
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const submitPackLines = async (
    code: string,
    consolidationCode: string,
    payload: IFormPackLinePayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/pack_lines/${consolidationCode}`
        await post<IResponseData<any>, IFormPackLinePayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Pack lines is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Pack Lines',
        })
        return undefined
    }
}

export const updatePackLines = async (
    code: string,
    packLinesode: string,
    payload: IFormPackLinePayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/pack_lines/${packLinesode}`
        await put<IResponseData<any>, IFormPackLinePayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Pack lines is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Pack Lines',
        })
        return undefined
    }
}

export const deletePackLines = async (code: string, packLinesode: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/pack_lines/${packLinesode}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Pack Line is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Pack Line',
        })
        return undefined
    }
}
