import { ITabItem } from '@components/tab/tab.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    addConsolidationShipment,
    getConsolidationDetail,
    getConsolidationShipment,
    removeConsolidationShipment,
    updateConsolidation,
} from 'repository/shipment.repository'
import { useAppDispatch } from 'store'
import { consolidationDetailTabs } from './detail-consolidation.static'
import { useSelector } from 'react-redux'
import {
    consolidationDetailSelector,
    setDetailData,
} from './detail-consolidation.slice'
import {
    IFConsolidation,
    IFConsolidationPayload,
    useConsolidationValidation,
} from 'form-validation/consolidation.validation'
import { formatDate } from '@services/common.service'
import { IContact } from 'repository/interface/contact.interface'
import { useFormik } from 'formik'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IAddress } from 'repository/interface/address.interface'
import {
    fetchOrganisationsAddress,
    fetchOrganisationsContact,
} from 'repository/dropdown.repository'
import { useModal } from '@components/modal/modal.service'
import { IShipment } from 'repository/interface/shipment.interface'

const useDetailConsolidation = () => {
    const { id } = useParams()
    const dropdownService = useDropdown()
    const nav = useNavigate()
    const dispatch = useAppDispatch()
    const attachShipmentModal = useModal()

    const [selectedTab, setSelectedTab] = useState<ITabItem>(
        consolidationDetailTabs[0],
    )
    const [firstLoadSendingAgent, setFirstLoadSendingAgent] = useState(true) // helper form
    const [firstLoadSReceivingAgent, setFirstLoadReceivingAgent] =
        useState(true) // helper form
    const [isDetailLoading, setDetailLoading] = useState(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const consolidationDetailData = useSelector(consolidationDetailSelector)
    const [consolidationtDetail, setConsolidationtDetail] = useState<
        IFConsolidation | undefined
    >()

    const [sendingAgenAddressDropdown, setSendingAgentAddressDropdown] =
        useState<IDropdownItem<IAddress>[]>([])
    const [receivingAgentAddressDropdown, setReceivingAgentAddressDropdown] =
        useState<IDropdownItem<IAddress>[]>([])
    const [sendingAgentContactDropdown, setSendingAgentContactDropdown] =
        useState<IDropdownItem<IContact>[]>([])
    const [receivingAgentContactDropdown, setReceivingAgentContactDropdown] =
        useState<IDropdownItem<IContact>[]>([])

    // formik
    const consolidationValidation = useConsolidationValidation()
    const formik = useFormik<IFConsolidation>({
        validationSchema: consolidationValidation.schema,
        validateOnChange: true,
        initialValues: consolidationValidation.generateInitialValue(),
        onSubmit: (values) => {
            submitForm(values)
        },
    })
    // formik
    const shipmentValidation = useConsolidationValidation()
    const formikShipment = useFormik<IFConsolidation>({
        validationSchema: consolidationValidation.schema,
        validateOnChange: true,
        initialValues: shipmentValidation.generateInitialValue(),
        onSubmit: () => {},
    })
    const { transport_mode, sending_agent, receiving_agent } = formik.values

    const getEmail = (contact: IContact | undefined) => {
        if (!contact) return ''
        const isEmailExists = contact.emails && contact.emails.length > 0
        if (!contact.emails) return ''
        if (!isEmailExists) return ''
        return contact.emails[0]
    }

    const getPhone = (contact: IContact | undefined) => {
        if (!contact) return ''
        const isEmailExists = contact.phones && contact.phones.length > 0
        if (!contact.phones) return ''
        if (!isEmailExists) return ''
        const country_code = contact.phones[0].country_code
        const number = contact.phones[0].number
        return country_code + number
    }

    const getAddress = (d: IAddress | undefined) => {
        if (!d) return ''
        const address = `${d?.street}, ${d?.street_2}, ${d?.city.name}, ${d?.state.name}, ${d?.country.name}`
        return address
    }

    const getDetailData = async () => {
        const code = id
        setDetailLoading(true)
        if (!code) {
            setDetailLoading(false)
            nav('/inquiry')
            return
        }
        const response = await getConsolidationDetail(code)
        if (!response) {
            setDetailLoading(false)
            nav(-1)
            return
        }

        // Get Data
        const data = response.data
        dispatch(setDetailData(data))

        const formValue: IFConsolidation = {
            code: data.code ?? '',
            consol_id: data.console_id ?? '',
            created_by: data.created_by?.name ?? '',
            creation_date: formatDate(data.created) ?? '',
            container_booking_reference: data.container_booking_reference ?? '',
            master_bill: data.master_bill ?? '',
            incoterm: data.incoterm ?? '',
            carrier: data.carrier ?? '',
            creditor: data.creditor ?? '',
            flight_number: data.flight_number ?? '',
            destination: data.destination ?? '',
            warehouse: data.warehouse ?? '',
            voyage: data.voyage ?? '',
            departure_cto_address: data.departure_cto_address ?? '',
            departure_cfs_address: data.departure_cfs_address ?? '',
            departure_container_yard: data.departure_container_yard ?? '',
            departure_port_transport: data.departure_port_transport ?? '',
            arrival_cto_address: data.arrival_cto_address ?? '',
            arrival_cfs_address: data.arrival_cfs_address ?? '',
            arrival_container_yard: data.arrival_container_yard ?? '',
            arrival_port_transport: data.arrival_port_transport ?? '',
            transport_mode: data.transport_mode ?? '',
            container_mode: data.container_mode ?? '',
            console_category: {
                code: data.console_category?.code ?? '',
            },
            container_type: {
                code: data.container_type?.code ?? '',
            },
            consolidation_number: data.consolidation_number ?? '',
            container_number: data.container_number ?? '',
            load_port: {
                code: data.load_port?.code ?? '',
            },
            load_port_etd: data.load_port_etd ?? '',
            load_port_eta: data.load_port_eta ?? '',
            seal_number: data.seal_number ?? '',
            discharge_port: {
                code: data.discharge_port?.code ?? '',
            },
            discharge_port_etd: data.discharge_port_etd ?? '',
            discharge_port_eta: data.discharge_port_eta ?? '',
            service_level: data.service_level ?? '',
            ship_count: data.ship_count ?? 0,
            weight: {
                unit: data.weight_unit?.code ?? '',
                qty: data.weight?.toString() ?? '0',
                unitLabel: data.weight_unit?.label ?? '',
            },
            volume: {
                unit: data.volume_unit?.code ?? '',
                qty: data.volume?.toString() ?? '0',
                unitLabel: data.volume_unit?.label ?? '',
            },
            prepaid: {
                unit: data.prepaid_currency?.code ?? '',
                qty: data.prepaid?.toString() ?? '0',
                unitLabel: data.prepaid_currency?.short_code ?? '',
            },
            payment_category: data.payment_category ?? '',
            total_packs: data.total_packs ?? 0,
            chargable: {
                unit: data.volume_unit?.code ?? '',
                qty: data.volume?.toString() ?? '0',
                unitLabel: data.volume_unit?.label ?? '',
            },
            collect: {
                unit: data.collect_currency?.code ?? '',
                qty: data.collect?.toString() ?? '0',
                unitLabel: data.collect_currency?.short_code ?? '',
            },
            sending_agent: {
                code: data.sending_agent?.code ?? '',
            },
            sending_agent_address: {
                code: data.sending_agent_address?.code ?? '',
                name: data.sending_agent_address?.name ?? '',
                address: getAddress(data.sending_agent_address),
            },
            sending_agent_contact: {
                code: data.sending_agent_contact?.code ?? '',
                name: data.sending_agent_contact?.name ?? '',
                email: getEmail(data.sending_agent_contact),
                phone: getPhone(data.sending_agent_contact),
            },
            receiving_agent: {
                code: data.receiving_agent?.code ?? '',
            },
            receiving_agent_address: {
                code: data.receiving_agent_address?.code ?? '',
                name: data.receiving_agent_address?.name ?? '',
                address: getAddress(data.receiving_agent_address),
            },
            receiving_agent_contact: {
                code: data.receiving_agent_contact?.code ?? '',
                name: data.receiving_agent_contact?.name ?? '',
                email: getEmail(data.receiving_agent_contact),
                phone: getPhone(data.receiving_agent_contact),
            },
            shipments: [],
        }

        setConsolidationtDetail(formValue)
        formik.resetForm({ values: { ...formValue } })
        setDetailLoading(false)
    }

    // Shipment Handling ----------------------------------------

    const shipmentAddHandling = async (value: IShipment[]) => {
        value.forEach(async (d) => {
            const shipments = await addConsolidationShipment(d, id ?? '')
            if (!shipments) {
                return
            }
        })
        // loadShipmentHandling()
        const { shipments } = formikShipment.values
        const newShipment = [...shipments, ...value]
        formikShipment.setFieldValue('shipments', newShipment)
    }

    const loadShipmentHandling = async () => {
        const shipments = await getConsolidationShipment(id ?? '')
        if (!shipments) {
            return
        }

        formikShipment.setFieldValue('shipments', shipments.data ?? [])
    }
    const removeShipmentHandling = async (code: string) => {
        const response = await removeConsolidationShipment(code, id ?? '')
        if (!response) {
            return
        }

        const { shipments } = formikShipment.values
        const newShipment = shipments.filter((d) => {
            return d.code !== code
        })
        formikShipment.setFieldValue('shipments', newShipment)
    }

    // End Shipment Handling ------------------------------------

    const addressHandling = async (
        type: 'sending' | 'receiving',
        selectedCode: string,
    ) => {
        const response = await fetchOrganisationsAddress({
            organisationCode: selectedCode,
        })
        if (response) {
            const dropdown: IDropdownItem<IAddress>[] = response.data.map(
                (d) => {
                    return {
                        label: d.name,
                        value: d.code,
                        additionalData: d,
                    }
                },
            )
            if (type === 'receiving') setReceivingAgentAddressDropdown(dropdown)
            if (type === 'sending') setSendingAgentAddressDropdown(dropdown)
            return
        }
    }

    const contactHandling = async (
        type: 'sending' | 'receiving',
        selectedCode: string,
    ) => {
        const response = await fetchOrganisationsContact({
            organisationCode: selectedCode,
        })
        if (response) {
            const dropdown: IDropdownItem<IContact>[] = response.data.map(
                (d) => {
                    return {
                        label: d.name ?? '',
                        value: d.code ?? '',
                        additionalData: d,
                    }
                },
            )
            if (type === 'receiving') setReceivingAgentContactDropdown(dropdown)
            if (type === 'sending') setSendingAgentContactDropdown(dropdown)
            return
        }
    }

    const resetForm = () => {
        if (!consolidationtDetail) return
        formik.setValues({
            ...consolidationtDetail,
        })
        setFirstLoadSendingAgent(true)
        setFirstLoadReceivingAgent(true)
    }

    const submitForm = async (value: IFConsolidation) => {
        setIsSubmitLoading(true)
        const {
            consol_id,
            weight,
            volume,
            prepaid,
            chargable,
            collect,
            sending_agent_address,
            receiving_agent_address,
            sending_agent_contact,
            receiving_agent_contact,
            shipments,
            created_by,
            creation_date,
            ...dt
        } = value

        const payload: IFConsolidationPayload = {
            ...dt,
            weight: weight.qty ?? '0',
            weight_unit: {
                code: weight.unit ?? '',
            },
            volume: volume.qty ?? '0',
            volume_unit: {
                code: volume.unit ?? '',
            },
            prepaid: prepaid.qty ?? '0',
            prepaid_currency: {
                code: prepaid.unit ?? '0',
            },
            chargable: chargable.qty ?? '0',
            chargable_unit: {
                code: chargable.unit ?? '0',
            },
            collect: collect.qty ?? '',
            collect_currency: {
                code: collect.unit ?? '',
            },
            sending_agent_address:
                sending_agent_address.code === ''
                    ? undefined
                    : sending_agent_address.code,
            sending_agent_contact:
                sending_agent_contact.code === ''
                    ? undefined
                    : {
                          code: sending_agent_contact.code,
                      },
            receiving_agent_address:
                receiving_agent_address.code === ''
                    ? undefined
                    : receiving_agent_address.code,
            receiving_agent_contact:
                receiving_agent_contact.code === ''
                    ? undefined
                    : {
                          code: receiving_agent_contact.code,
                      },
            shipments: shipments.map((d) => {
                return {
                    code: d.code ?? '',
                }
            }),
        }

        try {
            await updateConsolidation(payload)
            formik.resetForm({ values: { ...value } })
            setIsSubmitLoading(false)
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getUnlocos()
        dropdownService.getContainerType()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getAgentOrganisations()
        dropdownService.getIncoterms()
        dropdownService.getServiceLevel()
        dropdownService.getPaymentCategory()
        dropdownService.getCurrency()
        dropdownService.getConsoleCategory()
    }, [])

    useEffect(() => {
        getDetailData()
        loadShipmentHandling()
    }, [])

    useEffect(() => {
        if (!transport_mode) return
        dropdownService.getContainerModes(transport_mode)
    }, [transport_mode])

    useEffect(() => {
        if (!sending_agent.code) return
        addressHandling('sending', sending_agent.code)
        contactHandling('sending', sending_agent.code)
    }, [sending_agent.code, receiving_agent.code])

    useEffect(() => {
        if (!receiving_agent.code) return
        addressHandling('receiving', receiving_agent.code)
        contactHandling('receiving', receiving_agent.code)
    }, [receiving_agent.code])

    return {
        selectedTab,
        isDetailLoading,
        consolidationDetailData,
        consolidationtDetail,
        formik,
        isSubmitLoading,
        sendingAgenAddressDropdown,
        receivingAgentAddressDropdown,
        sendingAgentContactDropdown,
        receivingAgentContactDropdown,
        firstLoadSendingAgent,
        firstLoadSReceivingAgent,
        attachShipmentModal,
        formikShipment,
        setFirstLoadReceivingAgent,
        setFirstLoadSendingAgent,
        resetForm,
        setSelectedTab,
        getDetailData,
        submitForm,
        shipmentAddHandling,
        loadShipmentHandling,
        removeShipmentHandling,
    }
}

export default useDetailConsolidation
