import Button from '@components/button/button.component'
import useCreateAccount from './create-account.service'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormRadio from '@components/form-radio/form-radio.component'
import { IRadioOption } from '@components/form-radio/form-radio.interface'
import Modal from '@components/modal/modal.component'
import DatePicker from '@components/date-picker/date-picker.component'


const CreateAccount = () => {
    const {
        formik,
        navigate,
        modalServiceBalance,
    } = useCreateAccount()
    const optionsAccess: IRadioOption[] = [
        { value: 'all', label: 'All User' },
        { value: 'some_user', label: 'Some User' },
        { value: 'selected_roles', label: 'Selected Roles' },
    ]
    return (
        <div className="content-full-height">
            <div className='flex justify-start items-center py-2'>
                <div className=''>
                    <div className='font-bold text-size-S'>Chart of Accounts</div>
                    <div className='text-size-L font-bold themes-text-brand-v6'>NEW ACCOUNT</div>
                </div>
            </div>

            <div className='flex flex-col container-global !border-0 !rounded-t-none'>
                <div className="overflow-auto h-[calc(100vh-10rem)] ">
                    <FormikProvider value={formik}>
                        <div className='themes-bg-white flex flex-col items-center p-3'>
                            <div className='w-[40%]'>
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-3'>Account Information</div>
                                <FormInput
                                    label="ACCOUNT NAME"
                                    name={"account_name"}
                                    placeholder="Enter Account Name"
                                    useUppercaseLabel
                                />
                                <FormInput
                                    label="NUMBER ACCOUNT CODE"
                                    name={"account_code"}
                                    placeholder="Enter Account Name"
                                    useUppercaseLabel
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="currency"
                                    placeholder="Select currency"
                                    name={'currency'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="details"
                                    placeholder="Select details"
                                    name={'details'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="default Account Tax"
                                    placeholder="Select tax"
                                    name={'default_tax'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Bank Name"
                                    placeholder="Select Bank"
                                    name={'bank_name'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormInput
                                    label="Bank NUmber"
                                    name={"bank_number"}
                                    placeholder="Enter Bank Number"
                                    useUppercaseLabel
                                />
                                <div className='flex justify-between mb-3'>
                                    <span>Start Balance</span>
                                    <div className='themes-text-brand-v5 flex gap-2'>
                                        Rp 0,0
                                        <span className='font-bold cursor-pointer' onClick={() => modalServiceBalance.openModalHandling()}>EDIT</span>
                                    </div>
                                </div>
                                <FormTextarea
                                    name={'description'}
                                    label={'DESCRIPTION'}
                                    placeholder={'Additional Notes'}
                                    className='!h-[100px]'
                                />
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-1'>Account Access</div>
                                <div className='themes-text-grayv-v6 text-size-S font-semibold mb-3'>Account Access</div>
                                <FormRadio
                                    name="accountAcces"
                                    label="Automatic created" options={optionsAccess}
                                    parentDivClassName="flex gap-2 mb-10"
                                    classNameLabel="!text-size-s font-normal"
                                />

                                <div className="flex justify-between py-3 gap-3">
                                    <Button
                                        onClick={() => { navigate(-1) }}
                                        className="w-full"
                                        label="CANCEL"
                                        variant='default'
                                    />
                                    <Button
                                        onClick={() => {
                                        }}
                                        className="w-full"
                                        label="CONFIRM TOP UP"
                                        variant='brand'
                                    />
                                </div>
                            </div>
                        </div>
                    </FormikProvider>
                </div>
                <Modal isModalOpen={modalServiceBalance.isModalOpen}
                    className="md:w-1/4 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
                >
                    <div>
                        <div className="flex flex-1 flex-col">
                            {/* Header */}
                            <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                                Opening Balances
                                <div className="flex justify-center items-center pl-4">
                                    <i
                                        className="ri-close-fill cursor-pointer "
                                        onClick={() => {
                                            modalServiceBalance.closeModalHandling()
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className='p-4'>
                                <div className='themes-text-gray-v6 mb-2 font-bold'>SELECT CONVERSION DATE</div>
                                <DatePicker isRange={false} />
                                <div className='themes-text-gray-v5 mt-2'>Please set the date of your migration or the day you start using the application</div>
                            </div>
                            <div className="flex font-bold gap-2 p-4 items-center border-t">
                                <Button
                                    onClick={() => { modalServiceBalance.closeModalHandling() }}
                                    className="w-full"
                                    label="BANK"
                                    variant='default'
                                />
                                <Button
                                    onClick={() => { modalServiceBalance.closeModalHandling(); navigate('/chart-of-accounts/oppening-balance')}}
                                    className="w-full"
                                    label="VIEW CONVERSION BALANCE"
                                    variant='brand'
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>

    )
}

export default CreateAccount
