import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    organisationsDataSelector,
    organisationsFetchParamSelector,
    organisationsMetaSelector,
    organisationsSelectedTabItemSelector,
    organisationsTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './organisation.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { generateSimpleReport } from '@services/common.export.service'
import moment from 'moment'
import { ITableColumn } from '@components/table/table.interface'
import { getOrganisations } from 'repository/organisation.repository'
import { organisationSummaryHeaders } from './organisation.static'
import { deleteOrganisation } from './organisation.repository'

const useOrganisations = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(organisationsFetchParamSelector)
    const organisationsData = useSelector(organisationsDataSelector)
    const organisationsMeta = useSelector(organisationsMetaSelector)
    const tabItems = useSelector(organisationsTabItemsSelector)
    const selectedTabItem = useSelector(organisationsSelectedTabItemSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {
        loadData({ ...fetchParam, page: 1, size: 50 })
    }, [fetchParam])

    useEffect(() => {}, [])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)
            const response = await getOrganisations(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setData(response))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteHandling = async (organisationId: string) => {
        try {
            setDeleteLoading(true)
            await deleteOrganisation(organisationId)
            loadData({ ...fetchParam, page: 1, size: 50 })
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        const response = await getOrganisations(params, true)
        if (!response) {
            setLoading(false)
            return
        }
        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const generateReport = generateSimpleReport({
            headers: organisationSummaryHeaders as ITableColumn[],
            data: data,
            fileName: `Booking_${date}`,
            title: 'Booking Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalActive = 0
        let totalSuspended = 0

        const rActive = await getOrganisations({
            ...fParams,
            active: true,
            size: 1,
        })
        const rSuspended = await getOrganisations({
            ...fParams,
            active: false,
            size: 1,
        })

        if (!rActive || !rSuspended) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalActive = rActive?.meta.total_data_count
        totalSuspended = rSuspended?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'true') count = totalActive
            else if (d.value === 'false') count = totalSuspended
            else count = 0

            return { ...d, totalData: count }
        })
        dispatch(setTabItems(tItems))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    return {
        setTabItems,
        organisationsMeta,
        organisationsData,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        exportModalService,
        filterOverlay,
        exportLoading,
        deleteLoading,
        deleteHandling,
        setExportLoading,
        loadData,
        exportLoadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        handleFilter,
    }
}

export default useOrganisations
