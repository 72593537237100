import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { FormikProps, FormikProvider } from 'formik'
import { discountTypes } from './item-list.static'
import { IFOrderItems } from 'form-validation/order-item.validation'
import FileItem from './component/file-item.component'
import { useEffect } from 'react'
import { IItemList } from 'repository/interface/item-list.interface'
import { useOrderItem } from './order-item.service'
import { useItemLists } from './item-list.service'

const OrderItemForm = ({
    formik,
    submitLoading,
    deleteLoading,
    isNew,
    deleteHandling,
    data,
}: {
    formik: FormikProps<IFOrderItems>
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
    deleteHandling: () => void
    data: IItemList[]
}) => {
    const { calculateTotalAmount, formatOrderItemData } = useOrderItem()
    const dropdownService = useDropdown()

    const {
        orderId,
        orderStatus,
        paymentStatus,
        supplier,
        orderName,
        totalAmount,
        globalDiscount,
        orderDate,
        notes,
        price,
        total_qty,
        discount,
        e_doc,
    } = formatOrderItemData(formik)

    useEffect(() => {
        const totalAmountItem = calculateTotalAmount({
            price,
            total_qty,
            discount,
        })
        formik.setFieldValue('total_amount', totalAmountItem)
    }, [price, total_qty, discount])

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)]`}>
                <FormikProvider value={formik}>
                    <div className="flex flex-row gap-2  bg-gray-100 min-w-max p-4">
                        <div className="flex flex-col gap-2 text-size-XS w-full">
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order ID</p>
                                <p>{orderId}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Order Status
                                </p>
                                <p>{orderStatus}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28 bg-[#F0F1F3]">
                                    Payment Status
                                </p>
                                <p>{paymentStatus}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Supplier</p>
                                <p>{supplier}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order Name</p>
                                <p>{orderName}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Total Amount
                                </p>
                                <p>{totalAmount}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Global Discount
                                </p>
                                <p>{globalDiscount}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order Date</p>
                                <p>{orderDate}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Notes</p>
                                <p>{notes}</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 bg-white w-[90%] rounded-lg px-4 max-h-[230px] overflow-y-auto">
                            {Array.isArray(e_doc) && e_doc.length > 0 ? (
                                e_doc.map((item, index) => (
                                    <FileItem key={index} e_doc={item} />
                                ))
                            ) : (
                                <div className="flex justify-center items-center h-[230px] text-gray-500 text-center text-size-M">
                                    No Documents
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="text-size-M font-bold">
                                Item Information
                            </div>
                            <div className="text-size-S themes-text-gray-v4 mb-3">
                                Add general information about the items for this
                                order.
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="item_id"
                                label="Item No"
                                useUppercaseLabel={true}
                                placeholder="(Auto Generate)"
                                disabled={true}
                            />
                            <button
                                onClick={() =>
                                    formik.setFieldValue(
                                        'priority',
                                        !formik.values.priority,
                                    )
                                }
                                className={`flex flex-row w-full gap-2`}
                            >
                                <i
                                    className={`text-[1rem] ${
                                        formik.values.priority
                                            ? 'ri-star-fill text-[#FFBA1B]'
                                            : 'ri-star-line text-[#8D99A5]'
                                    }`}
                                ></i>

                                <span className="text-size-M">
                                    Set as Priority
                                </span>
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="name"
                                label="Order Name"
                                useUppercaseLabel={true}
                            />
                            <FormDropdown
                                parentDivClassname="w-full"
                                name="unit"
                                options={dropdownService.quantityUnitData}
                                parentDivClassName="w-full"
                                useUppercaseLabel={true}
                                label="Unit"
                            />
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="total_qty"
                                label="Total QTY"
                                type="number"
                                useUppercaseLabel={true}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name="ready_qty"
                                label="Ready QTY"
                                type="number"
                                useUppercaseLabel={true}
                            />
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="price"
                                label="Price Per Unit"
                                type="number"
                                useUppercaseLabel={true}
                            />
                            <FormMeasurementInput
                                name={'discount'}
                                label="Discount (optional)"
                                useUppercaseLabel={true}
                                options={discountTypes}
                                parentDivClassName="mb-4 w-full"
                            />
                        </div>
                        <FormInput
                            parentDivClassName="w-full"
                            name="total_amount"
                            label="Total Amount"
                            type="number"
                            useUppercaseLabel={true}
                            disabled
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="item_material"
                            options={dropdownService.hsCodesData} // TODO no get data
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Item Material (Optional)"
                            isMultiSelect={true}
                            placeholder="Select Item Material"
                            isClearable={true}
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="product_category"
                            options={dropdownService.productCategoriesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Product Category"
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="hs_code"
                            options={dropdownService.hsCodesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="HS Code (Optional)"
                        />
                        <div className="flex gap-2">
                            <FormMeasurementInput
                                name={'weight'}
                                label="Weight (optional)"
                                useUppercaseLabel={true}
                                options={dropdownService.weightUnitsData}
                                parentDivClassName="mb-4 w-full"
                            />
                            <FormMeasurementInput
                                name={'volume'}
                                label="Volumetric Weight (optional)"
                                useUppercaseLabel={true}
                                options={dropdownService.volumeUnitsData}
                                parentDivClassName="mb-4 w-full"
                            />
                        </div>
                        <FormInput
                            parentDivClassName="w-full"
                            name="supplier_item_number"
                            label="Supplier Item Code (Optional)"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full"
                            name="customer_item_number"
                            label="Customer Item Code (Optional)"
                            useUppercaseLabel={true}
                        />
                        <FormTextarea
                            name={'note'}
                            label="Note (Optional)"
                            className="mb-3"
                            useUppercaseLabel={true}
                        />
                    </div>
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default OrderItemForm
