export const moduleCodes = {
    BOOKING_VIEW_ONLY: 'BOOKING_VIEW_ONLY',
    BOOKING_ALL_ACCESS: 'BOOKING_ALL_ACCESS',
    FF_AIR_ALL_ACCESS: 'FF_AIR_ALL_ACCESS',
    FF_AIR_VIEW_ONLY: 'FF_AIR_VIEW_ONLY',
    FF_CONSOLIDATION_ALL_ACCESS: 'FF_CONSOLIDATION_ALL_ACCESS',
    FF_CONSOLIDATION_VIEW_ONLY: 'FF_CONSOLIDATION_VIEW_ONLY',
    FF_SEA_ALL_ACCESS: 'FF_SEA_ALL_ACCESS',
    FF_SEA_VIEW_ONLY: 'FF_SEA_VIEW_ONLY',
    MANAGE_USER_ALL_ACCESS: 'MANAGE_USER_ALL_ACCESS',
    MANAGE_USER_VIEW_ONLY: 'MANAGE_USER_VIEW_ONLY',
    ORGANISATION_ALL_ACCESS: 'ORGANISATION_ALL_ACCESS',
    ORGANISATION_VIEW_ONLY: 'ORGANISATION_VIEW_ONLY',
    QUOTE_ALL_ACCESS: 'QUOTE_ALL_ACCESS',
    QUOTE_VIEW_ONLY: 'QUOTE_VIEW_ONLY',
    SETTINGS_ALL_ACCESS: 'SETTINGS_ALL_ACCESS',
    SETTINGS_VIEW_ONLY: 'SETTINGS_VIEW_ONLY',
    WHT_INBOUND_ALL_ACCESS: 'WHT_INBOUND_ALL_ACCESS',
    WHT_INBOUND_VIEW_ONLY: 'WHT_INBOUND_VIEW_ONLY',
    WHT_INVENTORY_ALL_ACCESS: 'WHT_INVENTORY_ALL_ACCESS',
    WHT_INVENTORY_VIEW_ONLY: 'WHT_INVENTORY_VIEW_ONLY',
    WHT_OUTBOUND_ALL_ACCESS: 'WHT_OUTBOUND_ALL_ACCESS',
    WHT_OUTBOUND_VIEW_ONLY: 'WHT_OUTBOUND_VIEW_ONLY',
    WHT_PACKING_ALL_ACCESS: 'WHT_PACKING_ALL_ACCESS',
    WHT_PACKING_VIEW_ONLY: 'WHT_PACKING_VIEW_ONLY',
    WHT_PICK_UP_JOB_ALL_ACCESS: 'WHT_PICK_UP_JOB_ALL_ACCESS',
    WHT_PICK_UP_JOB_VIEW_ONLY: 'WHT_PICK_UP_JOB_VIEW_ONLY',
}
