import * as React from 'react'
import './../sales-doc.style.css'
import Logo from './../lima-jari-logo.png'
import { IQuotation } from 'repository/interface/quotations.interface'
import moment from 'moment'

interface ComponentToPrintProps {
    text?: string
    data?: IQuotation
}

export const ReceiveMoneyDoc = React.forwardRef<
    HTMLDivElement | null,
    ComponentToPrintProps
>((props, ref) => {
    const { data, text } = props
    return (
        <div
            ref={ref}
            className="mx-[50px] flex flex-col justify-center min-h-screen"
        >
            <div className="min-h-[calc(100vh-5rem)] flex flex-col">
                {/* header */}
                <div className="flex flex-col">
                    <img src={Logo} alt="Logo" className="h-20 w-fit mb-3" />
                    <tbody className="max-w-[24rem]">
                        <tr className="base-text">
                            <td className="text w-32">COMPANY PROFILE</td>
                            <td className="text-dark">LIMAJARI CARGO</td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-32">ADDRESS</td>
                            <td className="text-dark">
                                Jl. Raya Kerobokan 100X, Kerobokan, Badung,
                                80361 Bali
                            </td>
                        </tr>
                    </tbody>
                </div>
                {/* body */}

                <div className="flex flex-col flex-grow mt-6">
                    <p className="heading w-full text-center">
                        SALES INVOICE #10004
                    </p>
                    <div className="mt-4 grid grid-cols-2 gap-4 bg-secondary py-4">
                        <div>
                            <div className="sub-heading custom-border-bottom-bold text-start w-full">
                                BILLING TO
                            </div>
                            <table className="w-full border-collapse col-span-1">
                                <tbody>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            Customer
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            SAE
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            Email
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            saesanti@gmail.com
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            Address
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            -
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="sub-heading custom-border-bottom-bold text-start w-full">
                                FILE INFORMATION
                            </div>
                            <table className="w-full border-collapse col-span-1">
                                <tbody>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            File Type
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            Shipment file
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            File Number
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            S12242025 - Emily Cooper
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-32">
                                            Titipan Transaction
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            YES
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="sub-heading custom-border-bottom-bold text-start w-full mt-4">
                        INVOICE DETAILS
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <table className="w-full border-collapse col-span-1">
                            <tbody>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Transaction Date
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        06/02/2025
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Due Date
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        06/02/2025
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Term
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        Net 30
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="w-full border-collapse col-span-1">
                            <tbody>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Transaction No
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10004
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Reference No
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        CASH
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-32">
                                        Warehouse
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        -
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6">
                        <table className="w-full mt-2 rounded-2xl custom-border-light">
                            <tbody>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[120px]">
                                        PRODUCT
                                    </td>

                                    <td className="text-dark-sm px-2 py-2 bg-secondary">
                                        DESCRIPTION
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[30px]">
                                        QTY
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[30px]">
                                        UNIT
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[50px]">
                                        DISCOUNT
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[110px] text-right">
                                        Price
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[30px] text-right">
                                        TAX
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[110px] text-right">
                                        AMOUNT
                                    </td>
                                </tr>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-sm px-2 py-2">
                                        Shipment Fee
                                    </td>
                                    <td className="text-sm px-2 py-2">-</td>
                                    <td className="text-sm px-2 py-2">100</td>
                                    <td className="text-sm px-2 py-2">PPN</td>
                                    <td className="text-sm px-2 py-2">10 %</td>
                                    <td className="text-sm px-2 py-2 text-right">
                                        Rp. 1.000.000,00
                                    </td>
                                    <td className="text-sm px-2 py-2">PPN</td>
                                    <td className="text-sm px-2 py-2 text-right">
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={5}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        SUB TOTAL
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={5}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        DISCOUNT
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={5}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        TOTAL
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={5}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        PAYMENT PAID
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>

                                <tr className="custom-border-light-bottom base-text">
                                    <td
                                        className="px-2 py-2 bg-secondary"
                                        colSpan={5}
                                    ></td>
                                    <td
                                        className="text-dark text-xl px-2 py-2 bg-secondary"
                                        colSpan={1}
                                    >
                                        BALANCE DUE
                                    </td>
                                    <td
                                        className="text-dark px-2 py-2 bg-secondary text-right"
                                        colSpan={2}
                                    >
                                        RP.0,00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full mt-4">
                        <p className="text custom-border-bottom-bold text-start">
                            Message
                        </p>
                        <p className="text text-start py-1">
                            Is there any additional information that we need to
                            know, please kindly inform us in the field shown
                            below
                        </p>
                    </div>
                    <div className="flex mt-6">
                        <p className="big-heading mr-6">BALANCE DUE</p>
                        <p className="big-heading font-bold">Rp.0,00</p>
                    </div>
                </div>
                {/* footer */}
                {/* <div className="flex flex-row justify-between items-center w-full custom-border-top-light self-end">
                    <img
                        src={LogisticalSquare}
                        alt="Logo"
                        className="w-9 h-9"
                    />
                    <p className="text mb-0">
                        ©OneByOne Logistical Pty Ltd 2024. Connect and control
                        with digital precision™
                    </p>
                </div> */}
            </div>
        </div>
    )
})
