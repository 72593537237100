import * as Yup from 'yup'
import { IFormShareEmail } from './share-email.interface'

export const useFormShareEmailValidation = () => {
    const schema = Yup.object<IFormShareEmail>().shape({

    })

    const generateInitialValue = () => {
        const initialValue: IFormShareEmail = {
            typeTranscionNo: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
