import { ISteps } from '@components/progression-step/progression-step.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITableColumn } from '@components/table/table.interface'
import { formatDate } from '@services/common.service'
import { IShipment } from 'repository/interface/shipment.interface'

export const steps: ISteps[] = [
    {
        label: 'Consol Details',
        value: 'consol_details',
        icon: 'ri-box-1-line',
    },
    {
        label: 'Attach Shipment',
        value: 'attach_shipment',
        icon: 'ri-truck-line',
    },
]

export const shipmentColumn: ISTColumn<IShipment>[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipment_id',
        customBuild: (data) => {
            return (
                <div className="themes-text-brand-v5 themes-bg-brand-v1">
                    {data?.toString()}
                </div>
            )
        },
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Shipper',
        accessor: 'shipper.name',
    },
    {
        label: 'Origin',
        accessor: 'origin_location.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'Weight',
        accessor: 'weight',
    },
    {
        label: 'Volume',
        accessor: 'volume',
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return <div>{formatDate(data?.toString() ?? '')}</div>
        },
    },
    {
        label: 'ETA',
        accessor: 'eta',
        customBuild: (data) => {
            if (!data) return <></>
            return <div>{formatDate(data.toString())}</div>
        },
    },
]
