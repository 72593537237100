import { ITableColumn } from '@components/table/table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { formatDate, numberWithCommas } from '@services/common.service'
import { ICashBankDetailSummary } from 'repository/interface/cash-bank-details.interface'

export const CashBankSummaryHeader: ITableColumn<ICashBankDetailSummary>[] = [
    {
        label: 'Date',
        accessor: 'created',
        sort: true,
        width: 400,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Person',
        accessor: 'person',
        sort: true,
        width: 400,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'description',
        label: 'Description',
        customBuild(_, rowData) {
            return rowData?.description || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        accessor: 'received_balance',
        label: 'Received',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.received_balance !== undefined
                    ? numberWithCommas(rowData.received_balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'spent_balance',
        label: 'Spent',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.spent_balance !== undefined
                    ? numberWithCommas(rowData.spent_balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'balance',
        label: 'Balance',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'message',
        label: 'Message',
        customBuild(_, rowData) {
            return rowData?.message || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        label: 'Attachments',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap !h-[30px]">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]

export const tabItemStatic: ITabItem[] = [
    {
        label: 'Jurnal Transaction',
        totalData: 17,
        key: 'jurnal_transaction',
        value: 'jurnal_transaction',
        className: 'brand',
    },
]
