import { FieldArray } from 'formik'
import { IFormLineCreateSales, ISOFormLines } from '../create-sales.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { ProductItem, TaxItem, UnitItem } from '../create-sales.dummy'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'

const SOLinePrice = ({
    formikProps,
    optionsDiscount,
    modalServiceProduct,
    modalServiceTax,
}: ISOFormLines) => {
    const { values } = formikProps
    const { linePrice } = values
    return (
        <FieldArray
            name="linePrice"
            render={(arrayHelpers) => {
                return (
                    <div>
                        <div className="overflow-x-scroll form-line-price-table">
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr className="text-muted">
                                            <td>Product</td>
                                            <td>Description</td>
                                            <td>Qty</td>
                                            <td>Units</td>
                                            <td>Unit Price</td>
                                            <td>Discount</td>
                                            <td>Tax</td>
                                            <td>Amount</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linePrice.map(
                                            (linePrice: IFormLineCreateSales, index: number) => {
                                                const prefixName = `linePrice.${index}.`
                                                return (
                                                    <tr key={`form-line-so-` + index}>
                                                        <td>
                                                            <FormDropdown
                                                                name={prefixName + 'product'}
                                                                placeholder="Select Product"
                                                                options={ProductItem}
                                                                className='w-[250px]'
                                                                additionalButton={{
                                                                    title: '+ Add New Product',
                                                                    className: "themes-bg-white themes-text-gray-v6 themes-bg-hover-brand-v3 text-size-S flex justify-center py-2 cursor-pointer border-[1px] rounded-md mt-1",
                                                                    onClick: () => { modalServiceProduct.openModalHandling(); }
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "description"}
                                                                placeholder="Description"
                                                                parentDivClassName='w-[250px]'
                                                                value={linePrice.description}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "qty"}
                                                                type='number'
                                                                placeholder="00"
                                                                parentDivClassName='w-[60px]'
                                                                min={0}
                                                                value={linePrice.qty}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormDropdown
                                                                name={prefixName + 'unit'}
                                                                placeholder="Unit"
                                                                options={UnitItem}
                                                                isClearable
                                                                className='w-[130px]'
                                                                isSearchable
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "unit_price"}
                                                                placeholder="0,00"
                                                                type='number'
                                                                parentDivClassName='w-[250px]'
                                                                value={linePrice.unit_price}
                                                                descriptionValue={values.currency}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormMeasurementInput
                                                                options={optionsDiscount}
                                                                name={prefixName + 'discount'}
                                                                parentDivClassName="mb-3 w-[250px]"
                                                                placeholder={'0,00'}
                                                                type='number'
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormDropdown
                                                                name={prefixName + 'tax'}
                                                                placeholder="Tax"
                                                                options={TaxItem}
                                                                isClearable
                                                                isSearchable
                                                                className='w-[150px]'
                                                                additionalButton={{
                                                                    title: '+ Add New Tax',
                                                                    className: "themes-bg-white themes-text-gray-v6 themes-bg-hover-brand-v3 text-size-S flex justify-center py-2 cursor-pointer border-[1px] rounded-md mt-1",
                                                                    onClick: () => {
                                                                        modalServiceTax.openModalHandling()
                                                                    }
                                                                }}
                                                            />

                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "amount"}
                                                                type='number'
                                                                parentDivClassName='w-[250px]'
                                                                placeholder="0,00"
                                                                value={linePrice.amount}
                                                                descriptionValue={values.currency}
                                                            />
                                                        </td>
                                                        <td className='flex items-center justify-end'>
                                                            {values.linePrice.length !== 1 && (
                                                                <i
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    className="ri-delete-bin-line themes-text-red-v3  themes-border-red-v3  themes-text-hover-white themes-bg-hover-red-v3  the border-[1px] text-size-L p-2   rounded-md cursor-pointer" />

                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default SOLinePrice
