import { ITableColumn } from '@components/table/table.interface'
import { formatDate, numberWithCommas } from '@services/common.service'
import { IAddFiles } from './add-files.interface'


export const addFilesHeader: ITableColumn<IAddFiles>[] = [
    {
        label: 'Date',
        accessor: 'date',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return <div>{formatDate(data)}</div>
        },
    },
    {
        label: 'File Number',
        accessor: 'file_number',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return <div>{data ? data : '-'}</div>
        },
    },
    {
        accessor: 'invoice_number',
        label: 'Invoice Number',
        width: 200,
        sort: true,
        customBuild: (data) => {
            return <div>{data ? data : '-'}</div>
        },
    },

    {
        label: 'Customer',
        accessor: 'customer',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return <div>{data ? data : '-'}</div>
        },
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus}`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        accessor: 'total',
        label: 'Total',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.total !== undefined
                    ? numberWithCommas(rowData.total.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        label: 'Attachments',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]