import { useFormik } from 'formik'
import { useEffect } from 'react'
import { IDropdownFilter } from './tab-master2.interface'

const useTabMaster = ({
    handleSearch,
    initialSearch,
    minSearchChar = 3,
}: {
    // eslint-disable-next-line no-unused-vars
    handleSearch?: (values: string) => void
    initialSearch?: string
    minSearchChar?: number
    handleDropdownFilter?: (values: string) => void
}) => {
    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            handleSearch && handleSearch(values.searchTerm)
        },
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < minSearchChar &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = 'Search term must be at least 3 characters'
            }
            return errors
        },
    })

    const formikDropdownFilter = useFormik<IDropdownFilter>({
        initialValues: {
            label: '',
            options: [],
            placeholder: '',
            name: '',
        },
        onSubmit: (values) => {},
    })

    useEffect(() => {
        if (!initialSearch) return
        formik.setValues({ searchTerm: initialSearch })
    }, [initialSearch])

    useEffect(() => {
        formikDropdownFilter.setFieldValue('item_category_1', 'Finished Good')
        formikDropdownFilter.setFieldValue('item_category_2', 'Commercial')
    }, [])

    return {
        formik,
        formikDropdownFilter,
    }
}

export default useTabMaster
