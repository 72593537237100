import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { tabItemsStatic } from './payment-request/payment-request.static'
import { IPaymentRequest, ITitipan, ITitipanDetails } from './titipan.interface'

interface ITitipanSlice {
    data: ITitipan[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    isLoading: boolean
    detail:{
        data: ITitipanDetails[]
        fetchParams: IFetchParams
    }
    paymentRequest: {
        data: IPaymentRequest[]
        meta: IMeta2
        fetchParams: IFetchParams
        exportFetchParams: IFetchParams
        isLoading: boolean
        tabItems: ITabItem[]
        tabItemSelected: ITabItem
    }
}
export const initialState: ITitipanSlice = {
    paymentRequest: {
        data: [],
        meta: {
            start: 1,
            size: 50,
            page: 1,
            data_count: 50,
            total_data_count: 50,
            total_page: 1,
        },
        fetchParams: {},
        exportFetchParams: {},
        isLoading: false,
        tabItemSelected: tabItemsStatic[0],
        tabItems: tabItemsStatic,
    },
    detail:{
        data: [],
        fetchParams: {},
    },
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {},
    exportFetchParams: {},
    isLoading: false,
}

const titipanSlice = createSlice({
    name: 'titipan',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<ITitipan[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    status: data.value,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
        setIsLoadingPaymentRequest(state, action: PayloadAction<boolean>) {
            state.paymentRequest.isLoading = action.payload
        },
        setFetchParamPaymentRequest(state, action: PayloadAction<IFetchParams>) {
            state.paymentRequest.fetchParams = action.payload
        },
        setExportFetchParamPaymentRequest(state, action: PayloadAction<IFetchParams>) {
            state.paymentRequest.exportFetchParams = action.payload
        },
        setDataPaymentRequest(state, action: PayloadAction<IResponseData<IPaymentRequest[]>>) {
            state.paymentRequest.data = action.payload.data
            state.paymentRequest.meta = action.payload.meta
        },
        setTabItemsPaymentRequest(state, action: PayloadAction<ITabItem[]>) {
            state.paymentRequest.tabItems = action.payload
        },
        setSelectedTabItemPaymentRequest(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            state.paymentRequest.fetchParams.transport = data.value === 'all' ? undefined : data.value
            state.paymentRequest.fetchParams.recountingData = false
            state.paymentRequest.tabItemSelected = data
        },
    },
})

// these all the variables used for selector
export const titipanFetchParamSelector = (state: RootState) =>
    state.titipan.fetchParams || {}
export const titipanExportFetchParamSelector = (state: RootState) =>
    state.titipan.exportFetchParams || {}
export const titipanDataSelector = (state: RootState) =>
    state.titipan.data || []
export const titipanMetaSelector = (state: RootState) =>
    state.titipan.meta || {}
export const titipanIsLoading = (state: RootState) => state.titipan.isLoading

export const paymentRequestFetchParamSelector = (state: RootState) =>
    state.titipan.paymentRequest.fetchParams || {}
export const paymentRequestTabItemsSelector = (state: RootState) =>
    state.titipan.paymentRequest.tabItems || {}
export const paymentRequestSelectedTabItemSelector = (state: RootState) =>
    state.titipan.paymentRequest.tabItemSelected || {}




// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
    setIsLoadingPaymentRequest,
    setFetchParamPaymentRequest,
    setExportFetchParamPaymentRequest,
    setDataPaymentRequest,
    setTabItemsPaymentRequest,
    setSelectedTabItemPaymentRequest,
} = titipanSlice.actions

export default titipanSlice.reducer
