import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormCreateCashAndBank, IFormLineCreateCashAndBank } from './create-cash-and-bank.interface'

export const formLineInitial: IFormLineCreateCashAndBank = {
    payment: '',
    description: '',
    tax: '',
    amount: '',
}

export const useCreateCashAndBankValidation = () => {
    const schema = Yup.object<IFormCreateCashAndBank>().shape({
        pay_from: Yup.string().required(requiredMessage('Pay From')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormCreateCashAndBank = {
            deposit_or_pay: '',
            payee_contact: '',
            transaction_date: '',
            transaction_no: '',
            currency: 'Rp',
            price_include_tax: false,
            withholding_tax: {
                name_price: '',
                withholding_data: false,
                withholding_discount: { qty: '', unit: '', unitLabel: '' },
                total_withholding_discount: 0
            },
            memo: '',
            total: 0,
            sub_total: 0,
            total_tax: 0,
            tax_per_line: [],
            balance_due: 0,
            linePrice: [formLineInitial],
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
