import { FormikProvider } from "formik"
import FormDropdown from "@components/form-dropdown/form-dropdown.component"
import FormInput from "@components/form-input/form-input.component"
import FormTextarea from "@components/form-textarea/form-textarea.component"
import FormDatePicker from "@components/form-date-picker/form-date-picker.component"
import Button from "@components/button/button.component"
import { CurrencyItem } from "../create-cash-and-bank.dummy"
import useCreateCashAndBankInternal from "./create-cash-and-bank-internal.service"
import Modal from "@components/modal/modal.component"
import FormTransactionNo from "../form-transaction-no/form-transaction-no.component"
import FileUpload from "component/file-uploud/file-uploud.component"

const CreateCashAndBankInternal = () => {
    const {
        formik,
        rate,
        modalServiceTransactionNo,
        navigate,
    } = useCreateCashAndBankInternal()
    const { values } = formik

    const formatNumber = (value: string | number) => {
        const number =
            typeof value === "string"
                ? parseFloat(value.replace(/,/g, "").replace(/\./g, ".")) || 0
                : value || 0;

        return new Intl.NumberFormat("id-ID", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    };

    const currency = values.currency
    const ExchangeRate = formatNumber(rate)
    const amount = formatNumber(values.amount)

    return (
        <div className="container-global content-full-height">
            <FormikProvider value={formik}>
                <div className="px-4 py-2">
                    <div className="themes-text-gray-v6 text-size-XS font-bold mb-1">Transaction</div>
                    <div className="themes-text-brand-v6 text-size-L font-bold">BANK INTERNAL TRANSFER</div>
                </div>
                <div className=" h-[calc(100vh-14.2rem)] overflow-auto border-t-[1px]">
                    <div className="flex gap-2 px-4 pt-4">
                        <FormDropdown
                            disabled={false}
                            label="CURRENCY"
                            placeholder="Select Currency"
                            name={'currency'}
                            required
                            parentDivClassName="w-[230px]"
                            options={CurrencyItem}
                        />
                        {currency !== 'Rp' &&
                            <div>
                                <div className="text-size-M font-semibold">Exchange Rate</div>
                                <div className="text-size-S font-semibold themes-text-gray-v5">
                                    Bank Indonesia <span className=" ml-5 themes-text-gray-v9 font-normal">1 {currency} = Rp.{ExchangeRate}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="grid grid-cols-4 gap-2 px-4 pt-5 themes-bg-brand-v1">
                        <FormDropdown
                            disabled={false}
                            label={"TRANSFER FROM"}
                            placeholder="Select Payment Account"
                            name={'transfer_form'}
                            required
                            options={[]}
                        />
                        <FormDropdown
                            disabled={false}
                            label={"DEPOSIT TO"}
                            placeholder="Select Payment Account"
                            name={'deposit_or_pay'}
                            required
                            options={[]}
                        />
                        <FormInput
                            name={"amount"}
                            type='number'
                            placeholder="0,00"
                            value={values.amount}
                            descriptionValue={values.currency}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2 px-4 pt-2">
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Payer Information</div>
                            <div>
                                <FormDatePicker
                                    name={'transaction_date'}
                                    isRange={false}
                                    label="TRANSACTION DATE"
                                    useUppercaseLabel
                                    placeholder="Select Date"
                                    parentDivClassName="mb-3"
                                />
                               <FormInput
                                    label="Transaction No"
                                    name={"transaction_no"}
                                    placeholder="Auto Generated"
                                    icon="ri-settings-3-line"
                                    useUppercaseLabel
                                    onIconClick={() => modalServiceTransactionNo.openModalHandling()}
                                    tooltipIcon={{
                                        text: 'tooltip',
                                        customText: (
                                          <div className='w-[239px] text-size-S'>
                                            <div className='mb-2'>
                                              Transaction number will be generated automatically by system. Click here to setup transaction number.
                                            </div>
                                            <div>Estimated number: 10010</div>
                                          </div>
                                        ),
                                      }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-size-M font-semibold">Attach Document</div>
                            <div>
                                <FileUpload onFileUpload={() => { }} />
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mb-4 mt-5">
                        <div className="border-[1px] rounded-md themes-bg-brand-v1">
                            <div className="flex justify-between  themes-bg-white border-t-[1px] rounded-md p-4">
                                <div className="w-[300px]">
                                    <div className="text-size-M font-semibold mb-4">Additional Information</div>
                                    <div className="flex flex-col gap-3">
                                        <FormTextarea
                                            name={'memo'}
                                            label={'MEMO'}
                                            placeholder={'Additional Notes'}
                                            additionalInfo="Internal Only"
                                        />
                                    </div>
                                </div>
                                <div className="w-[400px] flex flex-col gap-2">
                                    <div className="flex justify-between">
                                        <div className="text-size-S font-semibold themes-text-gray-v4">Amount</div>
                                        <div className="text-size-M font-semibold">{currency}. {amount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { navigate('/cash-bank')}}
                    />

                    <Button
                        type="submit"
                        label="CREATE TRANSACTION"
                        variant="brand"
                        className="w-[200px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </FormikProvider >
            <Modal isModalOpen={modalServiceTransactionNo.isModalOpen}
                className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                        Transaction Number Setting
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceTransactionNo.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormTransactionNo modalService={modalServiceTransactionNo} />
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default CreateCashAndBankInternal
