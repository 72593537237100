import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'

export const header: ITableColumn[] = [
    { accessor: 'hs_code', label: 'HS Code', width: 200 },
    { accessor: 'name', label: 'Name' },
]

export const tabItem: ITabItem[] = [
    { label: 'All HS Code', value: 'all', key: 'status' },
]
