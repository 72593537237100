import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IShipment } from 'repository/interface/shipment.interface'
import {
    attachshipmentStatusHeader,
    consolidationShipmentStatusHeader,
    shipmentStatusHeader,
} from './shipments.static'
import { ShipmentType } from './shipments.interface'
import { IConsolidation } from 'repository/interface/consolidation.interface'

interface IShipmentSlice {
    dataAir: IShipment[]
    dataSea: IShipment[]
    dataConsolidation: IConsolidation[]
    dataAttachShipment: IShipment[] // for attach shipment
    metaAir: IMeta2
    metaSea: IMeta2
    metaAttachShipment: IMeta2
    metaConsolidation: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItemsAir: ITabItem[]
    tabItemAirSelected: ITabItem
    tabItemsConsolidation: ITabItem[]
    tabItemConsolidationSelected: ITabItem
    tabItemsSea: ITabItem[]
    tabItemSeaSelected: ITabItem
    tabItemsAttachShipment: ITabItem[]
    tabItemAttachSelected: ITabItem
    isLoading: boolean
}

export const initialState: IShipmentSlice = {
    tabItemAirSelected: shipmentStatusHeader[0],
    tabItemsAir: shipmentStatusHeader,
    metaAir: {
        start: 0,
        size: 0,
        page: 0,
        data_count: 0,
        total_data_count: 0,
        total_page: 0,
    },
    metaSea: {
        start: 0,
        size: 0,
        page: 0,
        data_count: 0,
        total_data_count: 0,
        total_page: 0,
    },
    dataConsolidation: [],
    metaConsolidation: {
        start: 0,
        size: 0,
        page: 0,
        data_count: 0,
        total_data_count: 0,
        total_page: 0,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
    tabItemsConsolidation: consolidationShipmentStatusHeader,
    tabItemConsolidationSelected: consolidationShipmentStatusHeader[0],
    tabItemsSea: shipmentStatusHeader,
    tabItemSeaSelected: shipmentStatusHeader[0],
    dataAir: [],
    dataSea: [],
    dataAttachShipment: [],
    metaAttachShipment: {
        start: 0,
        size: 0,
        page: 0,
        data_count: 0,
        total_data_count: 0,
        total_page: 0,
    },
    tabItemsAttachShipment: attachshipmentStatusHeader,
    tabItemAttachSelected: shipmentStatusHeader[0],
}

const shipmentSlice = createSlice({
    name: 'shipment',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(
            state,
            action: PayloadAction<{
                response: IResponseData<IShipment[]>
                type: ShipmentType
            }>,
        ) {
            const payload = action.payload
            const data = payload.response
            if (payload.type === 'AIR') {
                return { ...state, dataAir: data.data, metaAir: data.meta }
            } else {
                return { ...state, dataSea: data.data, metaSea: data.meta }
            }
        },
        setDataAttachShipment(
            state,
            action: PayloadAction<IResponseData<IShipment[]>>,
        ) {
            const data = action.payload
            return {
                ...state,
                dataAttachShipment: data.data,
                metaAttachShipment: data.meta,
            }
        },
        setDataConsolidation(
            state,
            action: PayloadAction<IResponseData<IConsolidation[]>>,
        ) {
            const data = action.payload
            return {
                ...state,
                dataConsolidation: data.data,
                metaConsolidation: data.meta,
            }
        },
        setTabItems(
            state,
            action: PayloadAction<{ type: ShipmentType; tabs: ITabItem[] }>,
        ) {
            const { type, tabs } = action.payload
            if (type === 'CONSOLIDATION') {
                return { ...state, tabItemsConsolidation: tabs }
            } else if (type === 'SEA') {
                return { ...state, tabItemsSea: tabs }
            } else if (type === 'AIR') {
                return { ...state, tabItemsAir: tabs }
            } else if (type === 'ATTACH') {
                return { ...state, tabItemsAttachShipment: tabs }
            }
        },
        setSelectedTabItem(
            state,
            action: PayloadAction<{ type: ShipmentType; tab: ITabItem }>,
        ) {
            const { type, tab } = action.payload

            const fetchParam = state.fetchParams

            if (type === 'CONSOLIDATION') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        console_category: tab.value,
                        recountingData: false,
                    },
                    tabItemConsolidationSelected: tab,
                }
            } else if (type === 'SEA') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemSeaSelected: tab,
                }
            } else if (type === 'AIR') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemAirSelected: tab,
                }
            } else if (type === 'ATTACH') {
                return {
                    ...state,
                    fetchParams: {
                        ...fetchParam,
                        status: tab.value,
                        recountingData: false,
                    },
                    tabItemAttachSelected: tab,
                }
            }
        },
    },
})

// these all the variables used for selector
export const shipmentFetchParamSelector = (state: RootState) =>
    state.shipment.fetchParams || {}
export const shipmentExportFetchParamSelector = (state: RootState) =>
    state.shipment.exportFetchParams || {}
export const shipmentDataSelector = (state: RootState, type: ShipmentType) => {
    if (type === 'AIR') {
        return state.shipment.dataAir
    } else if (type === 'SEA') {
        return state.shipment.dataSea
    } else {
        return state.shipment.dataAttachShipment
    }
}
export const shipmentMetaSelector = (state: RootState, type: ShipmentType) => {
    if (type === 'AIR') {
        return state.shipment.metaAir
    } else if (type === 'SEA') {
        return state.shipment.metaSea
    } else {
        return state.shipment.metaAttachShipment
    }
}
export const consolidationDataSelector = (state: RootState) =>
    state.shipment.dataConsolidation || []
export const consolidationMetaSelector = (state: RootState) =>
    state.shipment.metaConsolidation || {}
export const shipmentIsLoading = (state: RootState) => state.shipment.isLoading
export const shipmentTabItemsSelector = (
    state: RootState,
    type: ShipmentType,
) => {
    if (type === 'CONSOLIDATION') {
        return state.shipment.tabItemsConsolidation
    } else if (type === 'SEA') {
        return state.shipment.tabItemsSea
    } else if (type === 'AIR') {
        return state.shipment.tabItemsAir
    } else if (type === 'ATTACH') {
        return state.shipment.tabItemsAttachShipment
    }
    return []
}
export const shipmentSelectedTabItemSelector = (
    state: RootState,
    type: ShipmentType,
) => {
    if (type === 'CONSOLIDATION') {
        return state.shipment.tabItemConsolidationSelected
    } else if (type === 'SEA') {
        return state.shipment.tabItemSeaSelected
    } else if (type === 'AIR') {
        return state.shipment.tabItemAirSelected
    } else if (type === 'ATTACH') {
        return state.shipment.tabItemAttachSelected
    }
    return {}
}

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
    setDataConsolidation,
    setExportFetchParam,
    setDataAttachShipment,
} = shipmentSlice.actions

export default shipmentSlice.reducer
