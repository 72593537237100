import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface ISettings {
    openAccordion2: boolean
    openAccordion1: boolean
    openAccordion3: boolean
}

export const initialState: ISettings = {
    openAccordion2: false,
    openAccordion1: false,
    openAccordion3: false,
    
}

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setAccordion1(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                openAccordion1: action.payload,
            }
        },
        setAccordion2(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                openAccordion2: action.payload,
            }
        },
        setAccordion3(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                openAccordion3: action.payload,
            }
        },
    },
})

export const { setAccordion1, setAccordion2, setAccordion3 } = settingSlice.actions

export const accordion1OpenSelector = (state: RootState) =>
    state.settings.openAccordion1
export const accordion2OpenSelector = (state: RootState) =>
    state.settings.openAccordion2
export const accordion3OpenSelector = (state: RootState) =>
    state.settings.openAccordion3

export default settingSlice.reducer
