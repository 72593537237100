import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'

// Define the interface
export interface IFChargeCode {
    code: string
    name: string
    allow_override: boolean
    charge_code: string
    gl_accrual: string
    gl_cost: string
    gl_revenue: string
    gl_wip: string
    department_filters: string
    price: number
    unit: {
        code: string
    }
}

// Validation schema
export const useChargeCodeValidation = () => {
    const schema = Yup.object<IFChargeCode>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        allow_override: Yup.boolean().required(
            requiredMessage('Allow Override'),
        ),
        charge_code: Yup.string().required(requiredMessage('Charge Code')),
        gl_accrual: Yup.string().required(requiredMessage('GL Accrual')),
        gl_cost: Yup.string().required(requiredMessage('GL Cost')),
        gl_revenue: Yup.string().required(requiredMessage('GL Revenue')),
        gl_wip: Yup.string().required(requiredMessage('GL WIP')),
        price: Yup.string()
            .typeError('Price Per Unit must be a number')
            .required(requiredMessage('Price Per Unit')),
        unit: Yup.object()
            .shape({
                code: Yup.string().required(requiredMessage('Unit Code')),
            })
            .required(requiredMessage('Unit')),
    })

    // Initial values
    const initialValue: IFChargeCode = {
        code: '',
        name: '',
        allow_override: false,
        charge_code: '',
        gl_accrual: '',
        gl_cost: '',
        gl_revenue: '',
        gl_wip: '',
        department_filters: '',
        price: 0,
        unit: {
            code: '',
        },
    }

    return {
        initialValue,
        schema,
    }
}
