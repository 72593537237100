import { ITableColumn } from '@components/table/table.interface'
import { numberWithCommas } from '@services/common.service'
import { IChartOfAccountDetails } from './details.interface'
import { IMeta } from '@components/pagination/pagination.interface'

export const ChartOfAccountDetailsHeader: ITableColumn<IChartOfAccountDetails>[] = [
    {
        label: 'Date',
        accessor: 'date',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        label: 'Person',
        accessor: 'person',
        sort: true,
        width: 400,
        customBuild: (data) => {
            return (
                <div className="flex gap-2">
                    {data}
                    <i className="ri-external-link-line themes-hover-brand-v5" />
                </div>
            )
        },
    },
    {
        accessor: 'number',
        label: 'Number',
        customBuild(_, rowData) {
            return (
                <div className="flex !flex-col">
                    <div>{rowData?.number}</div>
                    <div className='text-size-XS  italic'>{rowData?.type}</div>
                </div>
            )
        },
        width: 200,
    },
    {
        accessor: 'Received (in IDR)',
        label: 'received',
        customBuild(_, rowData) {
            const totalAmount =
                rowData?.received !== undefined
                    ? numberWithCommas(rowData.received.toString())
                    : '-'
            return 'Rp. ' + totalAmount
        },
    },
    {
        accessor: 'Spent (in IDR)',
        label: 'spent',
        customBuild(_, rowData) {
            const totalAmount =
                rowData?.spent !== undefined
                    ? numberWithCommas(rowData.spent.toString())
                    : '-'
            return 'Rp. ' + totalAmount
        },
    },
    {
        accessor: 'Balance (in IDR)',
        label: 'balance',
        customBuild(_, rowData) {
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return 'Rp. ' + totalAmount
        },
    },
    {
        label: 'Message',
        accessor: 'message',
        sort: true,
        width: 400,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        label: 'Attachment',
        accessor: 'attachments',
        customBuild: (_, rowData) => {
            return rowData?.attachments ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap !h-[30px]">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attachments}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]



export const metaDummyDetails: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

