import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'

export const header: ITableColumn[] = [
    { accessor: 'code', label: 'Code', width: 200 },
    { accessor: 'name', label: 'Name' },
]

export const tabItem: ITabItem[] = [
    { label: 'All Product Category', value: 'all', key: 'status' },
]
