import { ITableColumn } from '@components/table/table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { formatDate, numberWithCommas } from '@services/common.service'
import {
    IExpenses,
    IOrder,
    IInvoice,
} from 'repository/interface/expenses.interface'

export const ExpensesHeader: ITableColumn<IExpenses>[] = [
    {
        label: 'Date',
        accessor: 'created',
        sort: true,
        width: 200,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Number',
        accessor: 'number',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'category',
        label: 'Category',
        customBuild(_, rowData) {
            return rowData?.category || '-'
        },
        width: 200,
        ellipsis: true,
    },

    {
        label: 'Beneficiary',
        accessor: 'beneficiary',
        sort: true,
        customBuild(_, rowData) {
            return rowData?.beneficiary || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus} !h-[30px]`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        accessor: 'Balanced',
        label: 'balance',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'total',
        label: 'Total',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.total !== undefined
                    ? numberWithCommas(rowData.total.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'message',
        label: 'Message',
        customBuild(_, rowData) {
            return rowData?.message || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        label: 'Attachments',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap !h-[30px]">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]
export const OrderHeader: ITableColumn<IOrder>[] = [
    {
        label: 'Date',
        accessor: 'created',
        sort: true,
        width: 200,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Number',
        accessor: 'number',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'vendor',
        label: 'Vendor',
        customBuild(_, rowData) {
            return rowData?.vendor || '-'
        },
        width: 200,
        ellipsis: true,
    },

    {
        label: 'Due Date',
        accessor: 'due_date',
        sort: true,
        width: 200,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus} !h-[30px]`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        accessor: 'Balanced',
        label: 'balance',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'total',
        label: 'Total',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.total !== undefined
                    ? numberWithCommas(rowData.total.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'message',
        label: 'Message',
        customBuild(_, rowData) {
            return rowData?.message || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        label: 'Attachments',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap  !h-[30px]">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]
export const InvoiceHeader: ITableColumn<IInvoice>[] = [
    {
        label: 'Date',
        accessor: 'created',
        sort: true,
        width: 200,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Number',
        accessor: 'number',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'vendor',
        label: 'Vendor',
        customBuild(_, rowData) {
            return rowData?.vendor || '-'
        },
        width: 200,
        ellipsis: true,
    },

    {
        label: 'Due Date',
        accessor: 'due_date',
        sort: true,
        width: 200,
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Status',
        accessor: 'status',
        customBuild: (data, row) => {
            let classStatus = ''
            if (data === 'open')
                classStatus =
                    'themes-bg-extra-violet-v1 themes-text-extra-violet-v2'
            if (data === 'paid')
                classStatus =
                    'themes-bg-extra-green-v3 themes-text-extra-green-v4'
            if (data === 'partially paid')
                classStatus =
                    'themes-bg-extra-blue-v3 themes-text-extra-blue-v4'
            if (data === 'overdue')
                classStatus = 'themes-bg-red-v1 themes-text-red-v4'
            if (data === 'close')
                classStatus = 'themes-bg-gray-v3 themes-text-gray-v7'

            const capitalizeText = (text: string) => {
                return text
                    .split(' ')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase(),
                    )
                    .join(' ')
            }
            return (
                <div
                    className={`text-size-XS font-semibold px-2 flex items-center rounded-md ${classStatus}  !h-[30px]`}
                >
                    {capitalizeText(data)}
                </div>
            )
        },
    },
    {
        accessor: 'Balanced',
        label: 'balance',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.balance !== undefined
                    ? numberWithCommas(rowData.balance.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'total',
        label: 'Total',
        customBuild(_, rowData) {
            const currencySymbol = 'Rp. '
            const totalAmount =
                rowData?.total !== undefined
                    ? numberWithCommas(rowData.total.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'message',
        label: 'Message',
        customBuild(_, rowData) {
            return rowData?.message || '-'
        },
        width: 200,
        ellipsis: true,
    },
    {
        label: 'Attachments',
        accessor: 'attach',
        customBuild: (_, rowData) => {
            return rowData?.attach ? (
                <div className="border-[1px] rounded-md px-2 flex justify-start text-size-XS items-center w-[130px] overflow-hidden text-ellipsis whitespace-nowrap  !h-[30px]">
                    <i className="ri-file-line themes-text-brand-v5 mr-2 text-lg" />
                    <span className="truncate">{rowData?.attach}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
]

export const tabItemStatic: ITabItem[] = [
    {
        label: 'Purchases Invoice',
        totalData: 17,
        key: 'purchases_invoice',
        value: 'purchases_invoice',
        className: 'brand',
    },
    {
        label: 'Purchases Order',
        totalData: 12,
        key: 'purchases_order',
        value: 'purchases_order',
        className: 'brand',
    },
    {
        label: 'Expenses',
        totalData: 5,
        key: 'expenses',
        value: 'expenses',
        className: 'brand',
    },
]
