import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@components/modal/modal.service'

interface ICreateAccountBalance {
    name: string
    number: string
    billing_address: string
    category: string
    currency: string
    details: string

}

export const initialBookingFilter: ICreateAccountBalance = {
    name: '',
    number: '',
    billing_address: '',
    category: '',
    currency: '',
    details: '',
}

const useOppeningBalance = () => {
    const navigate = useNavigate()
    const modalServiceAccount = useModal()
    const formik = useFormik<ICreateAccountBalance>({
        initialValues: initialBookingFilter,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
        navigate,
        modalServiceAccount,
    }
}

export default useOppeningBalance
