import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'
import { IContactList } from 'pages/organisation/organisation-details/organisation-details.interface'

export const contactFormValidation: YUP.Schema<any> = YUP.object().shape({
    name: YUP.string().required(requiredMessage('Name')),
    first_name: YUP.string().required(requiredMessage('First Name')),
    last_name: YUP.string().required(requiredMessage('Last Name')),
    contact_type: YUP.string().required(requiredMessage('Contact Type')),
    job_title: YUP.string().required(requiredMessage('Job Title')),
    job_category: YUP.object().shape({
        code: YUP.string().required(requiredMessage('Job Category')),
    }),
    phones: YUP.object().shape({
        country_code: YUP.string()
            .required(requiredMessage('Country Code'))
            .matches(
                /^\+\d+$/,
                'Country code must start with a "+" followed by digits',
            ),
        number: YUP.string()
            .required(requiredMessage('Number'))
            .matches(/^\d+$/, 'Phone number must contain only digits'),
    }),
    emails: YUP.string()
        .email('Invalid email format')
        .required(requiredMessage('Email')),
    birth_date: YUP.string(),
    working_address: YUP.string(),
})

export interface IFormContact {
    code?: string
    name?: string
    first_name?: string
    last_name?: string
    contact_type?: string
    job_title?: string
    job_category?: {
        code?: string
    }
    phones: {
        country_code: string
        number: string
    }
    emails: string
    birth_date?: string
    working_address?: string
}
export interface IFormContactPayload {
    name?: string
    first_name?: string
    last_name?: string
    contact_type?: string
    job_title?: string
    job_category?: {
        code?: string
    }
    phones: {
        country_code: string
        number: string
    }[]
    emails: string[]
    birth_date?: string
    working_address?: string
}

// form contactr initial data
export const contactFormInitial: IFormContact = {
    name: '',
    first_name: '',
    last_name: '',
    contact_type: '',
    job_title: '',
    job_category: { code: '' },
    phones: { country_code: '', number: '' },
    emails: '',
    birth_date: '',
    working_address: '',
}
