import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IShipment } from 'repository/interface/shipment.interface'
import { IPackLines } from 'repository/interface/packlines.interface'
import { IContainers } from 'repository/interface/containers.interface'
import { INotes } from 'repository/interface/notes.interface'
import { IEDoc } from 'repository/interface/edocs.interface'
import { IConsolidation } from 'repository/interface/consolidation.interface'
interface IConsolidationDetailSlice {
    detail: IConsolidation
    shipments: { data: IShipment[]; isFetchNew: boolean }
    packLines: { data: IPackLines[]; isFetchNew: boolean }
    containers: { data: IContainers[]; isFetchNew: boolean }
    notes: { data: INotes[]; isFetchNew: boolean }
    edocs: { data: IEDoc[]; isFetchNew: boolean }
}

export const initialState: IConsolidationDetailSlice = {
    detail: {},
    shipments: {
        data: [],
        isFetchNew: true,
    },
    packLines: {
        data: [],
        isFetchNew: true,
    },
    containers: {
        data: [],
        isFetchNew: true,
    },
    notes: {
        data: [],
        isFetchNew: true,
    },
    edocs: {
        data: [],
        isFetchNew: true,
    },
}

const consolidationDetailSlice = createSlice({
    name: 'consolidationDetail',
    initialState,
    reducers: {
        setDetailData(state, action: PayloadAction<IConsolidation>) {
            const data = action.payload
            return { ...state, detail: data }
        },
        setShipmentData(state, action: PayloadAction<IShipment[]>) {
            const data = action.payload
            return {
                ...state,
                packLines: {
                    isFetchNew: state.packLines.isFetchNew,
                    data: data,
                },
            }
        },
        setPackLinesData(state, action: PayloadAction<IPackLines[]>) {
            const data = action.payload
            return {
                ...state,
                packLines: {
                    isFetchNew: state.packLines.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchPackLines(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                packLines: {
                    isFetchNew: data,
                    data: state.packLines.data,
                },
            }
        },
        setContainersData(state, action: PayloadAction<IContainers[]>) {
            const data = action.payload
            return {
                ...state,
                containers: {
                    isFetchNew: state.packLines.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchContainers(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                containers: {
                    isFetchNew: data,
                    data: state.containers.data,
                },
            }
        },
        setNotesData(state, action: PayloadAction<INotes[]>) {
            const data = action.payload
            return {
                ...state,
                notes: {
                    isFetchNew: state.notes.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchNotes(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                notes: {
                    isFetchNew: data,
                    data: state.notes.data,
                },
            }
        },
        setEdocsData(state, action: PayloadAction<IEDoc[]>) {
            const data = action.payload
            return {
                ...state,
                edocs: {
                    isFetchNew: state.edocs.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchEdocs(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                edocs: {
                    isFetchNew: data,
                    data: state.edocs.data,
                },
            }
        },
    },
})

// these all the variables used for selector
export const consolidationDetailSelector = (state: RootState) =>
    state.consolidationDetail.detail || {}
export const packLinesSelector = (state: RootState) =>
    state.consolidationDetail.packLines || {}
export const containersSelector = (state: RootState) =>
    state.consolidationDetail.containers || {}
export const notesSelector = (state: RootState) =>
    state.consolidationDetail.notes || {}
export const eDocsSelector = (state: RootState) =>
    state.consolidationDetail.edocs || {}

// all actions
export const {
    setDetailData,
    setPackLinesData,
    setFetchPackLines,
    setContainersData,
    setFetchContainers,
    setFetchNotes,
    setNotesData,
    setEdocsData,
    setFetchEdocs,
    setShipmentData,
} = consolidationDetailSlice.actions

export default consolidationDetailSlice.reducer
