import { FormikProvider } from "formik"
import useCreateJurnal from "./create-jurnal.service"
import FormInput from "@components/form-input/form-input.component"
import FormTextarea from "@components/form-textarea/form-textarea.component"
import FormDatePicker from "@components/form-date-picker/form-date-picker.component"
import FileUpload from "../../../component/file-uploud/file-uploud.component"
import SOLinePrice from "./line-price/line-price.component"
import Button from "@components/button/button.component"
import Modal from "@components/modal/modal.component"
import FormTransactionNo from "./form-transaction-no/form-transaction-no.component"

const CreateJurnal = () => {
    const {
        formik,
        modalServiceTransactionNo,
        navigate,
    } = useCreateJurnal()

    const { values } = formik

    return (
        <div className="container-global content-full-height">
            <FormikProvider value={formik}>
                <div className="px-4 py-2">
                    <div className="themes-text-gray-v6 text-size-XS font-bold mb-1">Transaction</div>
                    <div className="themes-text-brand-v6 text-size-L font-bold">JOURNAL ENTRY</div>
                </div>
                <div className=" h-[calc(100vh-14.2rem)] overflow-auto">
                    <div className="grid grid-cols-4 gap-2 px-4 pt-3 themes-bg-brand-v1">
                        <FormInput
                            name="transaction_no"
                            label="TRANSACTION NO"
                            placeholder="Auto Generated"
                            disabled={false}
                            icon="ri-settings-3-line"
                            onIconClick={() => modalServiceTransactionNo.openModalHandling()}
                            tooltipIcon={{
                                text: 'tooltip',
                                customText: (
                                    <div className='w-[230px] text-size-S'>
                                        <div className='mb-2'>
                                            Transaction number will be generated automatically by system. Click here to setup transaction number.
                                        </div>
                                        <div>Estimated number: 10010</div>
                                    </div>
                                ),
                            }}
                        />
                        <FormDatePicker
                            name={'transaction_date'}
                            isRange={false}
                            label="TRANSACTION DATE"
                            useUppercaseLabel
                            placeholder="Select Date"
                            parentDivClassName="mb-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-2 px-4 pt-2 mb-3">
                        <div className="flex flex-col gap-3 col-span-2">
                            <div className="text-size-M font-semibold">Attach eDocs <span className="themes-text-gray-v4 font-normal">(Max 10 mb)</span></div>
                            <div>
                                <FileUpload onFileUpload={() => { }} />
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mb-4">
                        <div className="border-[1px] rounded-md themes-bg-brand-v1">
                            <SOLinePrice
                                formikProps={formik}
                            />
                            <div className="flex justify-between  themes-bg-white border-t-[1px] rounded-md p-4">
                                <div className="w-[300px]">
                                    <div className="text-size-M font-semibold mb-4">Additional Information</div>
                                    <div className="flex flex-col gap-3">
                                        <FormTextarea
                                            name={'memo'}
                                            label={'MEMO'}
                                            placeholder={'Additional Notes'}
                                            additionalInfo="Internal Only"
                                        />
                                    </div>
                                </div>
                                <div className="flex  gap-2">
                                    <div className="w-[200px] ">
                                        <div className="text-size-S font-semibold themes-text-gray-v4">Total Debit</div>
                                        <div className="text-size-M ">Rp. {values.total_debit}</div>
                                    </div>
                                    <div className="w-[200px] ">
                                        <div className="text-size-S font-semibold themes-text-gray-v4">Total Credit</div>
                                        <div className="text-size-M ">Rp. {values.total_credit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CANCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { navigate(-1)}}
                    />

                    <Button
                        type="submit"
                        label={`CREATE JOURNAL`}
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </FormikProvider>
            <Modal isModalOpen={modalServiceTransactionNo.isModalOpen}
                className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                            Transaction Number Setting
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceTransactionNo.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormTransactionNo modalService={modalServiceTransactionNo} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CreateJurnal
