import { ChartOfAccountSummaryHeader } from './chart-of-accounts.static'
import useChartOfAccount from './chart-of-accounts.service'
import Table from '@components/table/table.component'
import { metaDummy, ChartOfAccountSummaryData } from './chart-of-accounts.dummy'
import TabMaster2 from '../components/tab-master2/tab-master2.component'
import { IChartOfAccountSummary } from './chart-of-accounts.interface'
import Button from '@components/button/button.component'

const ChartOfAccount = () => {
    const {
        loading,
        fetchParam,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
    } = useChartOfAccount()


    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster2
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search (min 3 char)'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    additionalButton1={
                        <>
                            <Button
                                type="submit"
                                label="New General Journal"
                                variant="brand-inverse"
                                className="w-[200px]"
                                useUpperCase={true}
                                onClick={() => { navigate('/chart-of-accounts/journal-entry') }}
                            />
                        </>
                    }
                    additionalButton2={
                        <>
                            <Button
                                type="submit"
                                label="Add New Account"
                                variant="brand"
                                className="ml-3 w-[200px]"
                                useUpperCase={true}
                                onClick={() => { navigate('/chart-of-accounts/new-account') }}
                            />
                        </>
                    }
                />
                <Table<IChartOfAccountSummary>
                    headers={ChartOfAccountSummaryHeader}
                    data={ChartOfAccountSummaryData}
                    checkboxVisible={true}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta3={metaDummy}
                    moduleTitle={'Cash Bank Summary'}
                    onRowClick={(data: IChartOfAccountSummary) => {
                        const id = data.account_code
                        navigate(`/chart-of-accounts/details/${id}`)
                    }}
                    additionalButtonBottom={
                        <>
                            <Button
                                type="submit"
                                label="Delete"
                                variant="red-inverse"
                                className="ml-3 w-[200px]"
                                useUpperCase={true}
                                onClick={() => { }}
                            />
                            <Button
                                type="submit"
                                label="Action"
                                variant="brand"
                                className="ml-3 w-[200px]"
                                useUpperCase={true}
                                onClick={() => { }}
                            />
                        </>
                    }
                />
            </div>
        </div>
    )
}

export default ChartOfAccount
