import { useFormik } from 'formik'
import { formLineInitial, useCreatePurchasesAndExpensesValidation } from './create-purchases-and-expenses.validation'
import { IFormCreatePurchasesAndExpenses, ITaxPerLine } from './create-purchases-and-expenses.interface'
import { useEffect, useState } from 'react';
import { IDropdownItem } from '@components/dropdown/dropdown.interface';
import { useModal } from '@components/modal/modal.service';
import { useLocation, useNavigate } from 'react-router-dom';

const useCreatePurchasesAndExpenses = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const getTypeCreate = location.pathname.replace(/^\/?pruchases-and-expenses\/create-/, "");
    const [togglePriceIncludeTax, setTogglePriceIncludeTax] = useState<boolean>(false);
    const [rate, setRate] = useState(16000);
    const [optionsDiscount, setOptionsDiscount] = useState<IDropdownItem[]>([
        { label: '%', value: '%' },
        { label: 'Rp', value: 'Rp' },
    ])

    //Modal
    const modalServiceProduct = useModal()
    const modalServiceTax = useModal()
    const modalServiceContact = useModal()
    const modalServiceTransactionNo = useModal()
    const modalServiceTerm = useModal()
    const modalServiceAddFiles = useModal()

    // formik
    const salesValidation = useCreatePurchasesAndExpensesValidation()
    const formik = useFormik<IFormCreatePurchasesAndExpenses>({
        validationSchema: salesValidation.schema,
        validateOnChange: true,
        initialValues: salesValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })
    const { values, setFieldValue } = formik
    const { linePrice } = values

    // function to update exchange rate based on currency
    const updateExchangeRate = () => {
        switch (values.currency) {
            case '$':
                setRate(16000);
                break;
            case 'CN¥':
                setRate(2400);
                break;
            case '€':
                setRate(18000);
                break;
            case 'JP¥':
                setRate(110);
                break;
            case 'AU$':
                setRate(12000);
                break;
            default:
                setRate(16000);
        }
    };

    // Use Effect for updating exchange rate and optionsDiscount based on currency
    useEffect(() => {
        updateExchangeRate();
        setOptionsDiscount([
            { label: "%", value: "%" },
            { label: values.currency, value: values.currency },
        ]);
    }, [values.currency]);

    // Add new line if all required fields are filled
    useEffect(() => {
        const lastLine = linePrice[linePrice.length - 1];
        if (
            lastLine &&
            lastLine.product &&
            lastLine.qty &&
            lastLine.unit &&
            lastLine.unit_price &&
            lastLine.amount
        ) {
            formik.setFieldValue('linePrice', [
                ...linePrice,
                formLineInitial,
            ]);
        }
    }, [linePrice, formik]);

    // Effect for updating price item in line
    useEffect(() => {
        // Exit early if there are no line prices
        if (!values.linePrice?.length) return;

        // Initialize calculation variables
        let subtotal = 0;
        let totalTax = 0;
        let total_withholding_discount = 0;
        const taxPerLine: ITaxPerLine[] = [];

        // Process each line item
        const updatedLinePrice = values.linePrice.map((line) => {
            const qty = parseFloat(line.qty) || 0;
            const unitPrice = parseFloat(line.unit_price) || 0;
            const taxRate = parseFloat(line.tax?.match(/\d+(\.\d+)?/g)?.[0] || "0");

            let taxAmount = 0;
            let amount = 0;

            if (togglePriceIncludeTax) {
                // Calculate subtotal excluding tax
                const unitPriceExcludingTax = unitPrice / (1 + taxRate / 100);
                const lineSubtotal = qty * unitPriceExcludingTax;
                taxAmount = qty * unitPrice - lineSubtotal;
                amount = qty * unitPrice 
                subtotal += lineSubtotal;
            } else {
                // Calculate subtotal including tax
                const lineSubtotal = qty * unitPrice ;
                taxAmount = lineSubtotal * (taxRate / 100);
                amount = lineSubtotal + taxAmount;

                subtotal += lineSubtotal;
            }

            totalTax += taxAmount;

            // Store tax details for each line
            if (line.tax) {
                taxPerLine.push({
                    name: line.tax || "Tax",
                    price: taxAmount.toFixed(2).toString(),
                });
            }

            return { ...line, amount };
        });

        // Calculate total price after applying all discounts and taxes
        const total = subtotal   + totalTax;

        // Calculate withholding tax discount if applicable
        if (values.withholding_tax.withholding_data) {
            if (values.withholding_tax.withholding_discount.unit === "%") {
                total_withholding_discount = total * (parseFloat(values.withholding_tax.withholding_discount.qty) || 0) / 100;
            } else {
                total_withholding_discount = parseFloat(values.withholding_tax.withholding_discount.qty) || 0;
            }
        }

        // Calculate final balance due
        const balanceDue = total - total_withholding_discount;

        // Update form values only if there are changes
        if (JSON.stringify(updatedLinePrice) !== JSON.stringify(values.linePrice)) {
            setFieldValue("linePrice", updatedLinePrice);
        }

        // Update form fields with calculated values
        setFieldValue("sub_total", subtotal);
        setFieldValue("total_tax", totalTax);
        setFieldValue("tax_per_line", taxPerLine);
        setFieldValue("total", total);
        setFieldValue("withholding_tax.total_withholding_discount", total_withholding_discount);
        setFieldValue("balance_due", balanceDue);
        setFieldValue("price_include_tax", togglePriceIncludeTax);

    }, [values.linePrice, setFieldValue, togglePriceIncludeTax, values.withholding_tax]);


    return {
        formik,
        rate,
        togglePriceIncludeTax,
        optionsDiscount,
        getTypeCreate,
        modalServiceProduct,
        modalServiceTax,
        modalServiceContact,
        modalServiceTerm,
        modalServiceTransactionNo,
        modalServiceAddFiles,
        navigate,
        setTogglePriceIncludeTax,
    }
}

export default useCreatePurchasesAndExpenses
