import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    ChartOfAccountDataSelector,
    ChartOfAccountFetchParamSelector,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './chart-of-accounts.slice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'

const useChartOfAccount = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const fetchParam = useSelector(ChartOfAccountFetchParamSelector)
    const ChartOfAccountsData = useSelector(ChartOfAccountDataSelector)

    const dropdownService = useDropdown()

    // states
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        console.log(fParams)
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    return {
        setTabItems,
        ChartOfAccountsData,
        loading,
        fetchParam,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
    }
}

export default useChartOfAccount
