import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { formatDate } from '@services/common.service'
import { IConsolidation } from 'repository/interface/consolidation.interface'
import { IShipment } from 'repository/interface/shipment.interface'

export const shipmentStatusHeader: ITabItem[] = [
    {
        label: 'Open Shipments',
        key: 'status',
        value: 'OPEN',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Confirmed',
        key: 'status',
        value: 'CONFIRMED',
        className: 'gray',
        totalData: 0,
    },
    {
        label: 'Delivered',
        key: 'status',
        value: 'DELIVERED',
        className: 'green',
        totalData: 0,
    },
    {
        label: 'Closed',
        key: 'status',
        value: 'CLOSED',
        className: 'gray',
        totalData: 0,
    },
    {
        label: 'Canceled',
        key: 'status',
        value: 'CANCELED',
        className: 'red',
        totalData: 0,
    },
]
export const attachshipmentStatusHeader: ITabItem[] = [
    {
        label: 'Open Shipments',
        key: 'status',
        value: 'OPEN',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Confirmed',
        key: 'status',
        value: 'CONFIRMED',
        className: 'gray',
        totalData: 0,
    },
    {
        label: 'Delivered',
        key: 'status',
        value: 'DELIVERED',
        className: 'green',
        totalData: 0,
    },
]

export const consolidationShipmentStatusHeader: ITabItem[] = [
    {
        label: 'All Consolidation',
        key: 'status',
        value: 'all',
        className: 'brand',
        totalData: 0,
    },
]

export const seaShipmentSummaryHeader: ITableColumn<IShipment>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            if (data === 'SEA') {
                return (
                    <div className="mr-2 items-center">
                        <i className="ri-ship-2-fill mr-2"></i> SEA
                    </div>
                )
            }
            return <>{data}</>
        },
    },
    {
        label: 'Shipment ID',
        accessor: 'shipment_id',
        customBuild: (data) => {
            return (
                <div className="px-2 py-1 themes-bg-brand-v1 rounded justify-center items-center gap-4 inline-flex !h-[30px]">
                    <div className="text-center themes-text-brand-v6">
                        {data}
                    </div>
                </div>
            )
        },
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'Carrier',
        accessor: 'carrier.name',
        customBuild: (data, row) => {
            return data ?? '-'
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'ETA',
        accessor: 'eta',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Container Type',
        accessor: 'container_type',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Consolidation No',
        accessor: 'consolidation.console_id',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Created By',
        accessor: 'created_by.name',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            return formatDate(data, 'DD/MM/YY, HH:mm')
        },
    },
]

export const airShipmentSummaryHeader: ITableColumn<IShipment>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            if (data === 'AIR') {
                return (
                    <div className="mr-2 items-center">
                        <i className="ri-plane-fill mr-2"></i> AIR
                    </div>
                )
            } else if (data === 'SEA') {
                return (
                    <div className="mr-2 items-center">
                        <i className="ri-ship-2-fill mr-2"></i> SEA
                    </div>
                )
            }
            return <></>
        },
    },
    {
        label: 'Shipment ID',
        accessor: 'shipment_id',
        customBuild: (data) => {
            return (
                <div className="px-2 py-1 themes-bg-brand-v1 rounded justify-center items-center gap-4 inline-flex !h-[30px]">
                    <div className="text-center themes-text-brand-v6">
                        {data}
                    </div>
                </div>
            )
        },
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'EMKL',
        accessor: 'emkl',
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    // {
    //     label: 'Warehouse',
    //     accessor: 'site.name',
    // },
    // {
    //     label: 'AWB',
    //     accessor: 'hawb_number',
    //     customBuild: (data) => {
    //         return data ?? '-'
    //     },
    // },
    // {
    //     label: 'Pack List No',
    //     accessor: 'pack_list_no', //
    //     customBuild: (data) => {
    //         return data ?? '-'
    //     },
    // },
    {
        label: 'Chargable Weight (kg)',
        accessor: 'weight',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Created By',
        accessor: 'created_by.name',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            return formatDate(data, 'DD/MM/YY, HH:mm')
        },
    },
]

export const consolidationShipmentSummaryHeader: ITableColumn<IConsolidation>[] =
    [
        {
            label: 'Consolidation',
            accessor: 'console_id',
        },
        {
            label: 'Category',
            accessor: 'console_category.name',
        },
        {
            label: 'Carrier',
            accessor: 'carrier',
        },
        {
            label: 'Destination',
            accessor: 'destination',
        },
        {
            label: 'ETD',
            accessor: 'load_port_etd',
            customBuild: (data) => {
                const date = formatDate(data, 'DD/MM/YYYY')
                return date || '-'
            },
            exportValue: (rowData) => {
                const date = formatDate(rowData.load_port_etd, 'DD/MM/YYYY')
                return date || '-'
            },
        },
        {
            label: 'ETA',
            accessor: 'load_port_eta',
            customBuild: (data) => {
                const date = formatDate(data, 'DD/MM/YYYY')
                return date || '-'
            },
            exportValue: (rowData) => {
                const date = formatDate(rowData.load_port_eta, 'DD/MM/YYYY')
                return date || '-'
            },
        },
        {
            label: 'Container Type',
            accessor: 'container_type.name',
        },
        // {
        //     label: 'Total m2',
        //     accessor: 'total_m2',
        //     customBuild: (_) => {
        //         return '0'
        //     },
        // },
        {
            label: 'Total File',
            accessor: 'total_file',
            customBuild: (_, rowData) => {
                const data = rowData?.total_file ?? []
                const length = data.length
                return length.toString()
            },
            exportValue: (rowData) => {
                const data = rowData?.total_file ?? []
                const length = data.length
                return length.toString() || '0'
            },
        },
        {
            label: 'Warehouse',
            accessor: 'warehouse',
        },
        {
            label: 'Docs',
            accessor: 'docs',
            customBuild: (_, rowData) => {
                const data = rowData?.docs ?? []
                const length = data.length
                return length.toString()
            },
            exportValue: (rowData) => {
                const data = rowData?.docs ?? []
                const length = data.length
                return length.toString() || '0'
            },
        },
        {
            label: 'Created By',
            accessor: 'created_by',
            customBuild: (_, rowData) => {
                const data = rowData?.created_by?.name ?? '-'
                return data
            },
            exportValue: (rowData) => {
                const data = rowData?.created_by?.name ?? '-'
                return data
            },
        },
        {
            label: 'Last Updated',
            accessor: 'updated',
            customBuild: (data) => {
                const date = formatDate(data, 'DD/MM/YY, HH:mm')
                return date
            },
        },
    ]
