import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import useOverlay from '@components/overlay/overlay.service'
import {
    fetchOrganisationsAddress,
    fetchOrganisationsContact,
} from 'repository/dropdown.repository'
import { IAddress } from 'repository/interface/address.interface'
import {
    IFConsolidation,
    IFConsolidationPayload,
    useConsolidationValidation,
} from 'form-validation/consolidation.validation'
import { IShipment } from 'repository/interface/shipment.interface'
import { ISteps } from '@components/progression-step/progression-step.interface'
import { steps } from './create-consolidation.static'
import { IContact } from 'repository/interface/contact.interface'
import { useModal } from '@components/modal/modal.service'
import { IResponseData } from 'common/common.interface'
import { submitConsolidation } from 'repository/shipment.repository'

const useCreateConsolidation = () => {
    const navigate = useNavigate()
    const serviceRatesOverlay = useOverlay()
    const dropdownService = useDropdown()
    const attachShipmentModal = useModal()

    // states
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)

    const [selectedStep, setSelectedStep] = useState<ISteps>(steps[0])
    const [sendingAgenAddressDropdown, setSendingAgentAddressDropdown] =
        useState<IDropdownItem<IAddress>[]>([])
    const [receivingAgentAddressDropdown, setReceivingAgentAddressDropdown] =
        useState<IDropdownItem<IAddress>[]>([])
    const [sendingAgentContactDropdown, setSendingAgentContactDropdown] =
        useState<IDropdownItem<IContact>[]>([])
    const [receivingAgentContactDropdown, setReceivingAgentContactDropdown] =
        useState<IDropdownItem<IContact>[]>([])

    // formik
    const consolidationValidation = useConsolidationValidation()
    const formik = useFormik<IFConsolidation>({
        validationSchema: consolidationValidation.schema,
        validateOnChange: true,
        initialValues: consolidationValidation.generateInitialValue(),
        onSubmit: (values) => {
            if (selectedStep.value === 'attach_shipment') {
                submitForm(values)
            }
        },
    })
    const { transport_mode, sending_agent, receiving_agent, shipments } =
        formik.values

    const submitForm = async (value: IFConsolidation) => {
        setIsSubmitLoading(true)
        const {
            consol_id,
            weight,
            volume,
            prepaid,
            chargable,
            collect,
            sending_agent_address,
            receiving_agent_address,
            sending_agent_contact,
            receiving_agent_contact,
            shipments,
            created_by,
            creation_date,
            ...dt
        } = value

        const payload: IFConsolidationPayload = {
            ...dt,
            weight: weight.qty ?? '0',
            weight_unit: {
                code: weight.unit ?? '',
            },
            volume: volume.qty ?? '0',
            volume_unit: {
                code: volume.unit ?? '',
            },
            prepaid: prepaid.qty ?? '0',
            prepaid_currency: {
                code: prepaid.unit ?? '0',
            },
            chargable: chargable.qty ?? '0',
            chargable_unit: {
                code: chargable.unit ?? '0',
            },
            collect: collect.qty ?? '',
            collect_currency: {
                code: collect.unit ?? '',
            },
            sending_agent_address:
                sending_agent_address.code === ''
                    ? undefined
                    : sending_agent_address.code,
            sending_agent_contact:
                sending_agent_contact.code === ''
                    ? undefined
                    : {
                          code: sending_agent_contact.code,
                      },
            receiving_agent_address:
                receiving_agent_address.code === ''
                    ? undefined
                    : receiving_agent_address.code,
            receiving_agent_contact:
                receiving_agent_contact.code === ''
                    ? undefined
                    : {
                          code: receiving_agent_contact.code,
                      },
            shipments: shipments.map((d) => {
                return {
                    code: d.code ?? '',
                }
            }),
        }

        try {
            let response: IResponseData<any> | undefined
            response = await submitConsolidation(payload)
            setIsSubmitLoading(false)
            if (response) {
                // const consoleCode = response.message ?? ''
                // navigate('/freight-forwarding/details/' + shipmenCode)
                navigate(-1)
                formik.resetForm()
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const addNewShipment = (data: IShipment) => {
        const shipmentData = formik.values.shipments
        formik.setValues((state) => ({
            ...state,
            notify_contacts: [...shipmentData, data],
        }))
    }

    const deleteShipmentByIndex = (index: number) => {
        const shipmentData = formik.values.shipments
        const updateData = shipmentData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, shipments: updateData }))
    }

    const addressHandling = async (
        type: 'sending' | 'receiving',
        selectedCode: string,
    ) => {
        const response = await fetchOrganisationsAddress({
            organisationCode: selectedCode,
        })
        if (response) {
            const dropdown: IDropdownItem<IAddress>[] = response.data.map(
                (d) => {
                    return {
                        label: d.name,
                        value: d.code,
                        additionalData: d,
                    }
                },
            )
            if (type === 'receiving') setReceivingAgentAddressDropdown(dropdown)
            if (type === 'sending') setSendingAgentAddressDropdown(dropdown)
            return
        }
    }

    const contactHandling = async (
        type: 'sending' | 'receiving',
        selectedCode: string,
    ) => {
        const response = await fetchOrganisationsContact({
            organisationCode: selectedCode,
        })
        if (response) {
            const dropdown: IDropdownItem<IContact>[] = response.data.map(
                (d) => {
                    return {
                        label: d.name ?? '',
                        value: d.code ?? '',
                        additionalData: d,
                    }
                },
            )
            if (type === 'receiving') setReceivingAgentContactDropdown(dropdown)
            if (type === 'sending') setSendingAgentContactDropdown(dropdown)
            return
        }
    }

    const attachShipmentHandling = (data: IShipment[]) => {
        const currentShipment = shipments
        const currentShipmentCode = currentShipment.map((d) => d.code)
        const filteredData = data.filter((d) => {
            if (currentShipmentCode.includes(d.code)) return false
            return true
        })
        const newData = [...currentShipment, ...filteredData]
        formik.setFieldValue('shipments', newData)
    }

    const removeShipmentHandling = async (code: string) => {
        const { shipments } = formik.values
        const newShipment = shipments.filter((d) => {
            return d.code !== code
        })
        formik.setFieldValue('shipments', newShipment)
    }

    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getUnlocos()
        dropdownService.getContainerType()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getAgentOrganisations()
        dropdownService.getIncoterms()
        dropdownService.getServiceLevel()
        dropdownService.getPaymentCategory()
        dropdownService.getCurrency()
        dropdownService.getConsoleCategory()
    }, [])

    useEffect(() => {
        if (!transport_mode) return
        dropdownService.getContainerModes(transport_mode)
    }, [transport_mode])

    useEffect(() => {
        if (!sending_agent.code) return
        addressHandling('sending', sending_agent.code)
        contactHandling('sending', sending_agent.code)
    }, [sending_agent.code])

    useEffect(() => {
        if (!receiving_agent.code) return
        addressHandling('receiving', receiving_agent.code)
        contactHandling('receiving', receiving_agent.code)
    }, [receiving_agent.code])

    return {
        navigate,
        submitForm,
        addNewShipment,
        deleteShipmentByIndex,
        setSelectedStep,
        attachShipmentHandling,
        removeShipmentHandling,
        selectedStep,
        formik,
        dropdownService,
        serviceRatesOverlay,
        isSubmitLoading,
        sendingAgenAddressDropdown,
        receivingAgentAddressDropdown,
        sendingAgentContactDropdown,
        receivingAgentContactDropdown,
        attachShipmentModal,
    }
}

export default useCreateConsolidation
