import { ITableColumn } from '@components/table/table.interface'
import { IItemListsTable } from './item-lists.interface'

export const tableHeader: ITableColumn<IItemListsTable>[] = [
    {
        accessor: 'no',
        label: 'No.',
        customBuild(_, rowData) {
            return rowData?.no ? (
                <div className="flex flex-row gap-1">
                    <i
                        className={`text-[1rem] ${
                            rowData.priority
                                ? 'ri-star-fill text-[#FFBA1B]'
                                : 'ri-star-line text-[#8D99A5]'
                        }`}
                    ></i>
                    <span>{rowData.no.toString()}</span>
                </div>
            ) : (
                '-'
            )
        },
    },
    {
        label: 'Name',
        accessor: 'name',
    },
    {
        label: 'Total',
        accessor: 'total',
    },
    {
        label: 'Unit',
        accessor: 'unit',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'HS Code',
        accessor: 'hs_code',
    },
    {
        label: 'Notes',
        accessor: 'notes',
    },
]

export const dummyItemLists: IItemListsTable[] = [
    {
        no: '1',
        name: 'Electronic Components',
        total: '150',
        unit: 'Pieces',
        category: 'Electronics',
        hs_code: '85423900',
        notes: 'Fragile, handle with care',
        priority: true,
    },
    {
        no: '2',
        name: 'Steel Rods',
        total: '500',
        unit: 'Kg',
        category: 'Construction',
        hs_code: '72149900',
        notes: 'Requires dry storage',
        priority: true,
    },
    {
        no: '3',
        name: 'Cotton Fabric',
        total: '200',
        unit: 'Meters',
        category: 'Textiles',
        hs_code: '52085100',
        notes: 'Organic cotton, no chemicals',
        priority: false,
    },
    {
        no: '4',
        name: 'Wooden Furniture',
        total: '50',
        unit: 'Sets',
        category: 'Furniture',
        hs_code: '94036090',
        notes: 'Requires assembly',
        priority: false,
    },
    {
        no: '5',
        name: 'Bottled Water',
        total: '1000',
        unit: 'Liters',
        category: 'Beverages',
        hs_code: '22011000',
        notes: 'Keep in cool storage',
        priority: false,
    },
]
