import Button from '@components/button/button.component'
import {
    IExpenses,
    IInvoice,
    IOrder,
} from 'repository/interface/expenses.interface'

export const RowOptionExpenses = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IExpenses
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)
export const RowOptionInvoice = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IInvoice
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)
export const RowOptionOrder = ({
    rowData,
    closeHandling,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IOrder
    closeHandling: () => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Delete"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)
