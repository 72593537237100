import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

// Define the interface
interface IPUnit {
    code: string
}

export interface IFItemLists {
    order_code?: string
    order_id?: string
    order_status?: string
    supplier: string
    name: string
    discount?: IMeasurement
    total_amount?: IMeasurement
    date?: string
    note?: string
    amount?: IMeasurement
    file?: string
    description?: string
    document_type?: string
}
export interface IFItemListsPayload {
    supplier: string
    name: string
    note?: string
    currency?: string
    total_amount?: string
    discount?: string
    discount_amount?: string
    date?: string
    order_status?: string
    files?: string
    document_type?: string
    description?: string
}

// Validation schema
export const useItemListsValidation = () => {
    const schema = Yup.object<IFItemLists>().shape({
        order_id: Yup.string(),
        supplier: Yup.string().required(requiredMessage('Supplier')),
        name: Yup.string().required(requiredMessage('Order Name')),
        note: Yup.string().optional(),
        total_amount: Yup.object<IMeasurement>()
            .shape({
                qty: Yup.string().optional(),
                unit: Yup.string().optional(),
            })
            .optional(),
        discount: Yup.object<IMeasurement>()
            .shape({
                qty: Yup.string().optional(),
                unit: Yup.string().optional(),
            })
            .optional(),
        date: Yup.date().optional(),
        order_status: Yup.string().optional(),
        file: Yup.mixed().optional(),
        description: Yup.string().optional(),
        document_type: Yup.string().optional(),
    })

    // Initial values
    const initialValue: IFItemLists = {
        order_id: '(Auto Generated)',
        supplier: '',
        name: '',
        note: '',
        total_amount: { qty: '0', unit: '', unitLabel: 'Rp' },
        discount: { qty: '', unit: '', unitLabel: '' },
        date: '',
        amount: { qty: '0', unit: '', unitLabel: 'Rp' },
        order_status: '',
    }

    return {
        initialValue,
        schema,
    }
}
