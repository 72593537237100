import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { Toast } from '@components/toast/toast.component'
import { IFetchParams } from 'common/common.interface'
import {
    setDataAttachShipment,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
    shipmentDataSelector,
    shipmentFetchParamSelector,
    shipmentMetaSelector,
    shipmentSelectedTabItemSelector,
    shipmentTabItemsSelector,
} from 'pages/shipments/shipments.slice'
import {
    airShipmentSummaryHeader,
    shipmentStatusHeader,
} from 'pages/shipments/shipments.static'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IShipment } from 'repository/interface/shipment.interface'
import { getShipments } from 'repository/shipment.repository'
import { RootState, useAppDispatch } from 'store'

const useAttachShipment = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const filterOverlay = useOverlay()

    // selectors
    const fetchParam = useSelector(shipmentFetchParamSelector)
    const shipmentData = useSelector((state: RootState) =>
        shipmentDataSelector(state, 'ATTACH'),
    )
    const shipmentMeta = useSelector((state: RootState) =>
        shipmentMetaSelector(state, 'ATTACH'),
    )
    const tabItems = useSelector((state: RootState) =>
        shipmentTabItemsSelector(state, 'ATTACH'),
    )
    const selectedTabItem = useSelector((state: RootState) =>
        shipmentSelectedTabItemSelector(state, 'ATTACH'),
    )

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedShipment, setSelectedShipment] = useState<IShipment[]>([])

    useEffect(() => {
        loadData({
            ...fetchParam,
            page: 1,
            size: 50,
        })
    }, [fetchParam])

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)

            const response = await getShipments(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setDataAttachShipment({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setDataAttachShipment(response))

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalOpen = 0
        let totalClosed = 0
        let totalConfirmed = 0
        let totalCanceled = 0
        let totalDelivered = 0

        const rOpen = await getShipments({
            ...fParams,
            status: 'OPEN',
            size: 1,
        })
        const rConfirmed = await getShipments({
            ...fParams,
            status: 'CONFIRMED',
            size: 1,
        })
        const rDelivered = await getShipments({
            ...fParams,
            status: 'DELIVERED',
            size: 1,
        })
        const rClosed = await getShipments({
            ...fParams,
            status: 'CLOSED',
            size: 1,
        })
        const rCanceled = await getShipments({
            ...fParams,
            status: 'CANCELED',
            size: 1,
        })

        if (!rOpen || !rConfirmed || !rClosed || !rCanceled || !rDelivered) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalOpen = rOpen?.meta.total_data_count
        totalClosed = rClosed?.meta.total_data_count
        totalConfirmed = rConfirmed?.meta.total_data_count
        totalCanceled = rCanceled?.meta.total_data_count
        totalDelivered = rDelivered?.meta.total_data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'OPEN') count = totalOpen
            else if (d.value === 'CLOSED') count = totalClosed
            else if (d.value === 'CANCELED') count = totalCanceled
            else if (d.value === 'CONFIRMED') count = totalConfirmed
            else if (d.value === 'DELIVERED') count = totalDelivered
            else count = 0

            return { ...d, totalData: count }
        })

        dispatch(setTabItems({ type: 'ATTACH', tabs: tItems }))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem({ type: 'ATTACH', tab: data }))
    }

    const getShipmentColumn = (): ITableColumn<IShipment>[] => {
        return airShipmentSummaryHeader
    }

    const getShipmentTab = (): ITabItem[] => {
        return shipmentStatusHeader
    }

    const data = () => {
        return shipmentData
    }
    const meta = () => {
        return shipmentMeta
    }

    return {
        setTabItems,
        tabItems,
        loading,
        selectedTabItem,
        fetchParam,
        filterOverlay,
        selectedShipment,
        setSelectedShipment,
        data,
        meta,
        loadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        handleFilter,
        getShipmentColumn,
        getShipmentTab,
    }
}

export default useAttachShipment
