import { header, tabItem } from './hs-code.static'
import ModalSidebar from '@components/modal-sidebar/modal-sidebar.component'
import Table from '@components/table/table.component'
import TabMaster from '@components/tab-master/tab-master.component'
import { useHsCode } from './hs-code.service'
import { IHSCode } from 'repository/interface/hs.-code.interface'
import HSCodeForm from './hs-code-form.component'

const HSCode = () => {
    const {
        data,
        isLoading,
        formik,
        modalService,
        isNew,
        submitLoading,
        meta,
        deleteLoading,
        fetchParams,
        deleteItem,
        handleSearch,
        selectRow,
        setIsNew,
        resetForm,
    } = useHsCode()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster
                    items={tabItem}
                    tabFilter={tabItem[0]}
                    onChange={() => {}}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParams.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    actionButton1={{
                        onClick: () => {
                            resetForm()
                            setIsNew(true)
                            modalService.openModalHandling()
                        },
                        label: 'CREATE',
                        className: '!text-size-S',
                    }}
                />

                <Table<IHSCode>
                    headers={header}
                    data={data}
                    loading={isLoading}
                    nextHandling={(page) => {}}
                    previousHandling={(page) => {}}
                    meta2={meta}
                    moduleTitle={'HS Code'}
                    enableExport={false}
                    onRowClick={(value) => {
                        selectRow(value)
                    }}
                />
            </div>

            <ModalSidebar
                content={
                    <HSCodeForm
                        formik={formik}
                        submitLoading={submitLoading}
                        deleteHandling={() => deleteItem()}
                        deleteLoading={deleteLoading}
                        isNew={isNew}
                    />
                }
                modalService={modalService}
                title={isNew ? 'Create New HS Code' : 'Update HS Code'}
            />
        </div>
    )
}

export default HSCode
