import TabMaster from '@components/tab-master/tab-master.component'
import useAttachShipment from './attach-shipment.service'
import ShipmentsFilterOverlay from 'pages/shipments/components/filter/shipments-filter-overlay.component'
import Table from '@components/table/table.component'
import { IShipment } from 'repository/interface/shipment.interface'

export const AttachShipment = ({
    onAttach,
}: {
    onAttach: (data: IShipment[]) => void
}) => {
    const {
        tabItems,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        selectedShipment,
        setSelectedShipment,
        meta,
        data,
        setTabFilter,
        handleSearch,
        handlePagination,
        getShipmentColumn,
        handleFilter,
    } = useAttachShipment()

    return (
        <div className="p-4 h-[calc(100vh-170px)] flex-col">
            <div className="flex-grow h-[calc(100vh-310px)]">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Shipment ID'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ShipmentsFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    console.log(data)
                                    handleFilter(data)
                                }}
                            />
                        ),
                    }}
                />

                <Table<IShipment>
                    headers={getShipmentColumn()}
                    data={data()}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta2={meta()}
                    moduleTitle={'Inquiry'}
                    checkboxVisible={true}
                    checkboxDataHandling={(data) => {
                        setSelectedShipment(data)
                    }}
                    enableExport={true}
                    labelButtonExport="Attach Shipment"
                    exportButtonOnClick={() => {
                        onAttach(selectedShipment)
                    }}
                    variantButtonExport="brand"
                />
            </div>
        </div>
    )
}
