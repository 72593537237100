import { FormikProvider } from "formik"
import useFormTransactionNo from "./form-transaction-no.service"
import FormInput from "@components/form-input/form-input.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"
import FormRadio from '@components/form-radio/form-radio.component'
import { IRadioOption } from "@components/form-radio/form-radio.interface"

const FormTransactionNo = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormTransactionNo()
    const { values } = formik
    const optionsTransaction: IRadioOption[] = [
        { value: 'auto', label: 'Automatic created' },
        { value: 'custom', label: 'Custom format' },
        { value: 'add_new', label: 'Add New' },
    ]

    return (
        <FormikProvider value={formik}>
            <div className=" flex flex-col justify-between">
                <div className="p-4">
                    <div className="mb-3">Tax Details</div>
                    <div className="flex flex-col gap-2 mb-2">
                        <FormRadio
                            name="typeTranscionNo"
                            label="Automatic created" options={optionsTransaction}
                            parentDivClassName="flex flex-col gap-2"
                            classNameLabel="!text-size-s font-normal"
                        />
                    </div>
                    {values.typeTranscionNo === 'add_new' && (
                        <>
                            <div className="grid grid-cols-3 gap-2 mb-2">
                                <FormInput
                                    name="prefix"
                                    label="Prefix"
                                    placeholder="Example: /INV"
                                    required
                                />
                                <FormInput
                                    name="number"
                                    label="Number"
                                    placeholder="Number"
                                    required
                                    disabled={true}
                                />
                                <FormInput
                                    name="suffix"
                                    label="Suffix"
                                    placeholder="Example: /XV"
                                    required
                                />
                            </div>
                            <FormInput
                                name="number_from"
                                label="Start number from"
                                placeholder="1"
                                required
                            />
                            <div className="text-size-S">Transaction number will increase automatically</div>
                        </>
                    )}
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CANCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { modalService.closeModalHandling() }}
                    />

                    <Button
                        type="submit"
                        label="SAVE CHANGES"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </div>
        </FormikProvider>
    )
}
export default FormTransactionNo