import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'

interface IAddFilesSlice {
    data: any[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    isLoading: boolean
}
export const initialState: IAddFilesSlice = {
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
}

const expensesSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setData(state, action: PayloadAction<IResponseData<any[]>>) {
            console.log(state, 'testtt')
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        }
    },
})

// these all the variables used for selector
export const expensesFetchParamSelector = (state: RootState) =>
    state.expense.fetchParams || {}

// all actions
export const {
    setData,
    setFetchParam,
    setIsLoading,
} = expensesSlice.actions

export default expensesSlice.reducer
