import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFConsolidation } from 'form-validation/consolidation.validation'
import { FormikProps } from 'formik'
import { IAddress } from 'repository/interface/address.interface'
import { IContact } from 'repository/interface/contact.interface'
import { IOrganisation } from 'repository/interface/organisation.interface'

const Step1 = ({
    isNew = true,
    formik,
    receivingAgentAddress,
    receivingAgentContact,
    sendingAgentAddress,
    sendingAgentContact,

    // Additional helper
    firstLoadSendingAgent,
    firstLoadReceivingAgent,
    setFirstLoadReceivingAgent,
    setFirstLoadSendingAgent,
}: {
    isNew?: boolean
    formik: FormikProps<IFConsolidation>
    sendingAgentAddress: IDropdownItem<IAddress>[]
    sendingAgentContact: IDropdownItem<IContact>[]
    receivingAgentAddress: IDropdownItem<IAddress>[]
    receivingAgentContact: IDropdownItem<IContact>[]

    // additional
    firstLoadSendingAgent?: boolean
    firstLoadReceivingAgent?: boolean
    setFirstLoadReceivingAgent?: () => void
    setFirstLoadSendingAgent?: () => void
}) => {
    const dropdownService = useDropdown()
    return (
        <div className="">
            {!isNew && (
                <div className="flex-1 flex mobile:flex-wrap gap-4 mobile:gap-0 w-full border-b mb-4  mt-5">
                    <FormInput
                        disabled
                        parentDivClassName="w-full md:flex-grow"
                        name="consol_id"
                        label="CONSOL ID"
                        placeholder="Input Consol Id"
                        useUppercaseLabel={true}
                        className="w-full"
                    />
                    <FormInput
                        disabled
                        parentDivClassName="w-full md:w-1/2"
                        name="created_by"
                        label="CREATED BY"
                        useUppercaseLabel={true}
                        className="w-full"
                    />

                    <FormInput
                        disabled
                        parentDivClassName="w-full  md:w-1/2"
                        name="creation_date"
                        label="CREATION DATE"
                        useUppercaseLabel={true}
                        className="w-full"
                    />
                </div>
            )}

            <div className="flex sm:flex-wrap gap-4 h-full w-full">
                <div className="md:flex-1">
                    <div className="text-size-S font-bold">
                        Consolidation Details
                    </div>

                    <div className="flex sm:flex-wrap gap-4 sm:gap-0 mt-5">
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.transportModesData}
                            parentDivClassName="w-full"
                            name="transport_mode"
                            label="transport mode"
                            placeholder="Select Transport Mode"
                            useUppercaseLabel={true}
                        />
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.containerModeData}
                            parentDivClassName="w-full "
                            name="container_mode"
                            label="container mode"
                            placeholder="Select Container Mode"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0  sm:gap-0">
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.containerTypeData}
                            parentDivClassName="w-full "
                            name="container_type.code"
                            label="container type"
                            placeholder="Select Container Type"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full "
                            name="container_number"
                            label="container number"
                            placeholder="Input Container Number"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0">
                        <FormInput
                            parentDivClassName="w-full "
                            name="container_booking_reference"
                            label="carrier booking reference"
                            placeholder="Input Carrier Booking Reference"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full "
                            name="master_bill"
                            label="master bill"
                            placeholder="Input Master Bill"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0">
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.incotermsData}
                            parentDivClassName="w-full "
                            name="incoterm"
                            label="Incoterms"
                            placeholder="Select Incoterm"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full "
                            name="carrier"
                            label="Carrier"
                            placeholder="Select Carrier"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0">
                        <FormInput
                            parentDivClassName="w-full "
                            name="voyage"
                            label="voyage"
                            placeholder="Input Voyage"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full "
                            name="flight_number"
                            label="flight number"
                            placeholder="Input Flight Number"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 border-b mb-4">
                        <FormInput
                            parentDivClassName="w-full "
                            name="creditor"
                            label="Creditor"
                            placeholder="Select Creditor"
                            useUppercaseLabel={true}
                        />
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.consoleCategoryData}
                            parentDivClassName="w-full "
                            name="console_category.code"
                            label="Consol Category"
                            placeholder="Select Category"
                            useUppercaseLabel={true}
                            className="w-full"
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 mb-4">
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Load Port
                            </div>
                            <FormDropdown
                                isSearchable={true}
                                options={dropdownService.unlocodesData}
                                parentDivClassName="w-full "
                                name="load_port.code"
                                label="Load Port"
                                placeholder="Select Port"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <div className="flex sm:flex-wrap gap-4">
                                <FormDatePicker
                                    parentDivClassName="w-full "
                                    name="load_port_etd"
                                    label="ETD (Optional)"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                    isRange={false}
                                />
                                <FormDatePicker
                                    parentDivClassName="w-full "
                                    name="load_port_eta"
                                    label="ETA (Optional)"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                    isRange={false}
                                />
                            </div>
                        </div>
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Discharge Port
                            </div>
                            <FormDropdown
                                isSearchable={true}
                                options={dropdownService.unlocodesData}
                                parentDivClassName="w-full "
                                name="discharge_port.code"
                                label="Load Port"
                                placeholder="Select Port"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <div className="flex sm:flex-wrap gap-4">
                                <FormDatePicker
                                    parentDivClassName="w-full "
                                    name="discharge_port_etd"
                                    label="ETD (Optional)"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                    isRange={false}
                                />
                                <FormDatePicker
                                    parentDivClassName="w-full "
                                    name="discharge_port_eta"
                                    label="ETA (Optional)"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                    isRange={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:flex-1">
                    <div className="text-size-S font-bold mb-5">
                        Shipment Details
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0 sm:gap-0">
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.serviceLevelData}
                            parentDivClassName="w-full "
                            name="service_level"
                            label="Service Level"
                            placeholder="Select Service Level"
                            useUppercaseLabel={true}
                        />
                        <FormDropdown
                            isSearchable={true}
                            options={dropdownService.paymentCategoryData}
                            parentDivClassName="w-full "
                            name="payment_category"
                            label="payment category"
                            placeholder="Select Payment Category"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0 sm:gap-0">
                        <FormInput
                            parentDivClassName="w-full "
                            name="ship_count"
                            label="Ship Count"
                            placeholder="Select Ship Count"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full "
                            name="total_packs"
                            label="Total Packs"
                            placeholder="Select Total Packs"
                            useUppercaseLabel={true}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 mb-3">
                        <div className="flex-1 sm:flex-grow flex sm:flex-wrap gap-2">
                            <FormMeasurementInput
                                parentDivClassName="w-full "
                                name="weight"
                                label="Weight"
                                placeholder="Input Weight"
                                useUppercaseLabel={true}
                                className="w-full"
                                options={dropdownService.weightUnitsData}
                            />
                            <FormMeasurementInput
                                parentDivClassName="w-full "
                                name="volume"
                                label="Volume"
                                placeholder="Input Volume"
                                useUppercaseLabel={true}
                                className="w-full"
                                options={dropdownService.volumeUnitsData}
                            />
                        </div>
                        <FormMeasurementInput
                            parentDivClassName="w-full flex-1"
                            name="chargable"
                            label="chargable"
                            placeholder="Input Chargable"
                            useUppercaseLabel={true}
                            options={dropdownService.currencyShortData}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-0 border-b mb-3 pb-3">
                        <FormMeasurementInput
                            parentDivClassName="w-full flex-1"
                            name="prepaid"
                            label="prepaid"
                            placeholder="Input Prepaid"
                            useUppercaseLabel={true}
                            options={dropdownService.currencyShortData}
                        />
                        <FormMeasurementInput
                            parentDivClassName="w-full flex-1"
                            name="collect"
                            label="Collect"
                            placeholder="Input Collect"
                            useUppercaseLabel={true}
                            options={dropdownService.currencyShortData}
                        />
                    </div>
                    <div className="flex sm:flex-wrap gap-4 mb-4 border-b">
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Sending Agent (Optional)
                            </div>
                            <FormDropdown<IOrganisation>
                                isSearchable={true}
                                isClearable={true}
                                options={dropdownService.agentOrganisationData}
                                parentDivClassName="w-full "
                                name="sending_agent.code"
                                label="Sending Agent"
                                placeholder="Sending Agent"
                                useUppercaseLabel={true}
                                className="w-full"
                                additionalOnClick={() => {
                                    if (firstLoadSendingAgent) {
                                        setFirstLoadSendingAgent &&
                                            setFirstLoadSendingAgent()
                                        return
                                    }
                                    formik.setFieldValue(
                                        'sending_agent_contact.email',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'sending_agent_contact.phone',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'sending_agent_address.address',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'sending_agent_address.code',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'sending_agent_contact.code',
                                        '',
                                    )
                                }}
                            />
                            <div className="flex sm:flex-wrap gap-4">
                                <div className="flex-1">
                                    <FormDropdown
                                        isSearchable={true}
                                        options={sendingAgentAddress}
                                        parentDivClassName="w-full"
                                        name="sending_agent_address.code"
                                        label="Address"
                                        placeholder="Address Name"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                        additionalOnClick={(data) => {
                                            if (firstLoadSendingAgent) {
                                                setFirstLoadSendingAgent &&
                                                    setFirstLoadSendingAgent()
                                                return
                                            }
                                            const d = (
                                                data as IDropdownItem<IAddress>
                                            ).additionalData
                                            const label = `${d?.street}, ${d?.street_2}, ${d?.city.name}, ${d?.state.name}, ${d?.country.name}`
                                            formik.setFieldValue(
                                                'sending_agent_address.address',
                                                label,
                                            )
                                        }}
                                    />
                                    <FormTextarea
                                        name={'sending_agent_address.address'}
                                        label="Address 1"
                                        disabled
                                        rows={3}
                                    />
                                </div>
                                <div className="flex-1">
                                    <FormDropdown
                                        isSearchable={true}
                                        options={sendingAgentContact}
                                        parentDivClassName="w-full"
                                        name="sending_agent_contact.code"
                                        label="Contact"
                                        placeholder="Select Contact"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                        additionalOnClick={(data) => {
                                            const d = (
                                                data as IDropdownItem<IContact>
                                            ).additionalData
                                            const emails = d?.emails ?? []
                                            const email =
                                                emails.length > 0
                                                    ? emails[0]
                                                    : ''
                                            const phones = d?.phones ?? []
                                            const phone =
                                                phones.length > 0
                                                    ? phones[0].country_code +
                                                      phones[0].number
                                                    : ''
                                            formik.setFieldValue(
                                                'sending_agent_contact.email',
                                                email,
                                            )
                                            formik.setFieldValue(
                                                'sending_agent_contact.phone',
                                                phone,
                                            )
                                        }}
                                    />
                                    <FormInput
                                        parentDivClassName="w-full"
                                        name="sending_agent_contact.email"
                                        placeholder="Email"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                    />
                                    <FormInput
                                        parentDivClassName="w-full"
                                        name="sending_agent_contact.phone"
                                        placeholder="Phone Number"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Receiving Agent (Optional)
                            </div>
                            <FormDropdown<IOrganisation>
                                isSearchable={true}
                                isClearable={true}
                                options={dropdownService.agentOrganisationData}
                                parentDivClassName="w-full "
                                name="receiving_agent.code"
                                label="Receiving Agent"
                                placeholder="Receiving Agent"
                                useUppercaseLabel={true}
                                className="w-full"
                                additionalOnClick={(value) => {
                                    if (firstLoadReceivingAgent) {
                                        setFirstLoadReceivingAgent &&
                                            setFirstLoadReceivingAgent()
                                        return
                                    }

                                    formik.setFieldValue(
                                        'receiving_agent_contact.email',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'receiving_agent_contact.phone',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'receiving_agent_address.address',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'receiving_agent_address.code',
                                        '',
                                    )
                                    formik.setFieldValue(
                                        'receiving_agent_contact.code',
                                        '',
                                    )
                                }}
                            />
                            <div className="flex sm:flex-wrap gap-4">
                                <div className="flex-1">
                                    <FormDropdown
                                        isSearchable={true}
                                        options={receivingAgentAddress}
                                        parentDivClassName="w-full"
                                        name="receiving_agent_address.code"
                                        label="Address"
                                        placeholder="Address Name"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                        additionalOnClick={(data) => {
                                            if (firstLoadReceivingAgent) {
                                                setFirstLoadReceivingAgent &&
                                                    setFirstLoadReceivingAgent()
                                                return
                                            }
                                            const d = (
                                                data as IDropdownItem<IAddress>
                                            ).additionalData
                                            const label = `${d?.street}, ${d?.street_2}, ${d?.city.name}, ${d?.state.name}, ${d?.country.name}`
                                            formik.setFieldValue(
                                                'receiving_agent_address.address',
                                                label,
                                            )
                                        }}
                                    />
                                    <FormTextarea
                                        name={'receiving_agent_address.address'}
                                        label="Address 1"
                                        disabled
                                        rows={3}
                                    />
                                </div>
                                <div className="flex-1">
                                    <FormDropdown
                                        isSearchable={true}
                                        options={receivingAgentContact}
                                        parentDivClassName="w-full"
                                        name="receiving_agent_contact.code"
                                        label="Contact"
                                        placeholder="Select Contact"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                        additionalOnClick={(data) => {
                                            const d = (
                                                data as IDropdownItem<IContact>
                                            ).additionalData
                                            const emails = d?.emails ?? []
                                            const email =
                                                emails.length > 0
                                                    ? emails[0]
                                                    : ''
                                            const phones = d?.phones ?? []
                                            const phone =
                                                phones.length > 0
                                                    ? phones[0].country_code +
                                                      phones[0].number
                                                    : ''
                                            formik.setFieldValue(
                                                'receiving_agent_contact.email',
                                                email,
                                            )
                                            formik.setFieldValue(
                                                'receiving_agent_contact.phone',
                                                phone,
                                            )
                                        }}
                                    />
                                    <FormInput
                                        parentDivClassName="w-full"
                                        name="receiving_agent_contact.email"
                                        placeholder="Email"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                    />
                                    <FormInput
                                        parentDivClassName="w-full"
                                        name="receiving_agent_contact.phone"
                                        placeholder="Phone Number"
                                        useUppercaseLabel={true}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex sm:flex-wrap gap-4 mb-4 b-4 mb-4">
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Departure
                            </div>
                            <FormInput
                                parentDivClassName="w-full "
                                name="departure_cto_address"
                                label="CTO Address"
                                placeholder="Add Address"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <FormInput
                                parentDivClassName="w-full "
                                name="departure_cfs_address"
                                label="CFS Address"
                                placeholder="Add Address"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <div className="flex sm:flex-wrap gap-4 sm:gap-0">
                                <FormInput
                                    parentDivClassName="w-full "
                                    name="departure_container_yard"
                                    label="Container Yard"
                                    placeholder="Add Container Yard"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                />
                                <FormInput
                                    parentDivClassName="w-full "
                                    name="departure_port_transport"
                                    label="Port Transport"
                                    placeholder="Add Port Transport"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <div className="w-full md:flex-1">
                            <div className="text-size-S font-bold mb-5">
                                Arrival
                            </div>
                            <FormInput
                                parentDivClassName="w-full "
                                name="arrival_cto_address"
                                label="CTO Address"
                                placeholder="Add Address"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <FormInput
                                parentDivClassName="w-full "
                                name="arrival_cfs_address"
                                label="CFS Address"
                                placeholder="Add Address"
                                useUppercaseLabel={true}
                                className="w-full"
                            />
                            <div className="flex sm:flex-wrap gap-4 sm:gap-0">
                                <FormInput
                                    parentDivClassName="w-full "
                                    name="arrival_container_yard"
                                    label="Container Yard"
                                    placeholder="Add Container Yard"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                />
                                <FormInput
                                    parentDivClassName="w-full "
                                    name="arrival_port_transport"
                                    label="Port Transport"
                                    placeholder="Add Port Transport"
                                    useUppercaseLabel={true}
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step1
