import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormCreateJurnal, IFormLineCreateJurnal } from './create-jurnal.interface'

export const formLineInitial: IFormLineCreateJurnal = {
    account: '',
    description: '',
    debit: '',
    credit: '',
}

export const useCreateJurnalValidation = () => {
    const schema = Yup.object<IFormCreateJurnal>().shape({
        customer: Yup.string().required(requiredMessage('Customer')),
        email: Yup.string().required(requiredMessage('Email')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormCreateJurnal = {
            transaction_date: '',
            transaction_no: '',
            total_debit: '',
            total_credit: '',
            linePrice: [formLineInitial],
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
