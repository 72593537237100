import { IDropdownItem } from "@components/dropdown/dropdown.interface";

export const CurrencyItem: IDropdownItem[] = [
    { label: 'US Dollar (USD)', value: '$' },
    { label: 'Euro (EUR)', value: '€' },
    { label: 'Japanese Yen (JPY)', value: 'JP¥' },
    { label: 'Australian Dollar (AUD)', value: 'AU$' },
    { label: 'Chinese Yuan (CNY)', value: 'CN¥' },
    { label: 'Indonesian Rupiah (IDR)', value: 'Rp' },
];

export const ProductItem: IDropdownItem[] = [
    { label: 'Laptop', value: 'PROD-001' },
    { label: 'Smartphone', value: 'PROD-002' },
    { label: 'Headphones', value: 'PROD-003' },
    { label: 'Wireless Mouse', value: 'PROD-004' },
    { label: 'Keyboard', value: 'PROD-005' },
    { label: 'Monitor', value: 'PROD-006' },
    { label: 'Printer', value: 'PROD-007' },
    { label: 'Tablet', value: 'PROD-008' },
];

export const UnitItem: IDropdownItem[] = [
    { label: 'Piece', value: 'PCS' },
    { label: 'Box', value: 'BOX' },
    { label: 'Kilogram', value: 'KG' },
    { label: 'Liter', value: 'L' },
    { label: 'Dozen', value: 'DOZEN' },
    { label: 'Pack', value: 'PACK' },
];

export const TaxItem: IDropdownItem[] = [
    { label: 'VAT 5%', value: 'VAT 5%' },
    { label: 'VAT 10%', value: 'VAT 10%' },
    { label: 'Jurnal Tax 12%', value: 'Jurnal Tax 12%' },
    { label: 'Excise Duty 15%', value: 'Excise Duty 15%' },
    { label: 'Luxury Tax 8%', value: 'Luxury Tax 8%' },
    { label: 'Service Tax 18%', value: 'Service Tax 18%' },
    { label: 'Custom Duty 20%', value: 'Custom Duty 20%' },
];





