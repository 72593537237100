import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import ProgressionStepSmall from '@components/progression-step-small/progression-step-small.component'
import Step1 from './component/step-1.component'
import Step2 from './component/step-2.component'
import { FormikProvider } from 'formik'
import { useNavigate } from 'react-router-dom'
import { steps } from './create-consolidation.static'
import useCreateConsolidation from './create-consolidation.service'

const CreateConsolidation = () => {
    const navigate = useNavigate()
    const {
        formik,
        isSubmitLoading,
        selectedStep,
        sendingAgenAddressDropdown,
        sendingAgentContactDropdown,
        receivingAgentAddressDropdown,
        receivingAgentContactDropdown,
        attachShipmentModal,
        setSelectedStep,
        removeShipmentHandling,
        attachShipmentHandling,
    } = useCreateConsolidation()

    return (
        <div className="container-global sm:overflow-auto content-full-height flex-col w-full">
            <div className="flex justify-between items-center w-full border-b px-4 py-2 ">
                <div className="font-bold">CREATE CONSOLIDATION</div>
                <div className="flex sm:hidden">
                    <ProgressionStepSmall
                        steps={steps}
                        onChange={(selected) => {
                            setSelectedStep(selected)
                        }}
                        selectedItem={selectedStep}
                    />
                </div>
                <div>
                    <Button
                        onClick={() => {
                            formik.submitForm()
                        }}
                        isDisabled={selectedStep.value === 'consol_details'}
                        isLoading={isSubmitLoading}
                        label={'SAVE AND CREATE'}
                        variant="brand"
                    />
                </div>
            </div>

            <div
                className={`p-4 h-[calc(100vh-235px)] sm:h-[calc(100vh-215px)]  overflow-auto`}
            >
                <FormikProvider value={formik}>
                    <div className="flex mobile:flex-wrap gap-4 mobile:gap-0 w-full border-b mb-4">
                        <FormInput
                            disabled
                            parentDivClassName="w-full md:flex-grow"
                            name="consol_id"
                            label="CONSOL ID"
                            placeholder="Input Consol Id"
                            useUppercaseLabel={true}
                            className="w-full"
                        />
                        <FormInput
                            disabled
                            parentDivClassName="w-full md:w-1/2"
                            name="created_by"
                            label="CREATED BY"
                            useUppercaseLabel={true}
                            className="w-full"
                        />

                        <FormInput
                            disabled
                            parentDivClassName="w-full  md:w-1/2"
                            name="creation_date"
                            label="CREATION DATE"
                            useUppercaseLabel={true}
                            className="w-full"
                        />
                    </div>
                    {selectedStep.value === 'consol_details' ? (
                        <Step1
                            formik={formik}
                            sendingAgentAddress={sendingAgenAddressDropdown}
                            sendingAgentContact={sendingAgentContactDropdown}
                            receivingAgentAddress={
                                receivingAgentAddressDropdown
                            }
                            receivingAgentContact={
                                receivingAgentContactDropdown
                            }
                        />
                    ) : (
                        <Step2
                            formik={formik}
                            modalService={attachShipmentModal}
                            attachShipmentHandling={attachShipmentHandling}
                            deleteShipmentHandling={removeShipmentHandling}
                        />
                    )}
                </FormikProvider>
            </div>

            <div className="flex justify-between border-t p-4">
                <Button
                    label="BACK"
                    onClick={function (): void {
                        navigate('/inquiry')
                    }}
                />
                <div className="flex gap-4">
                    <Button
                        label={'Previous'}
                        icon="ri-arrow-left-line"
                        variant="brand-inverse"
                        onClick={() => {
                            setSelectedStep(steps[0])
                        }}
                        isDisabled={selectedStep.value === 'consol_details'}
                    />

                    <Button
                        label={'Next'}
                        iconSuffix="ri-arrow-right-line"
                        variant="brand-inverse"
                        onClick={async () => {
                            formik.submitForm()
                            const validate = await formik.validateForm()
                            if (Object.keys(validate).length === 0) {
                                setSelectedStep(steps[1])
                            }
                        }}
                        isDisabled={selectedStep.value === 'attach_shipment'}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateConsolidation
