import * as Yup from 'yup'

interface IFormCreateRole {
    role: string
}

export const useCreateRoleValidation = () => {
    const schema = Yup.object<IFormCreateRole>().shape({

    })

    const generateInitialValue = () => {
        const initialValue: IFormCreateRole = {
            role: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
