import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { IContactList } from '../../organisation-details.interface'
import {
    createOrganisationContact,
    deleteOrganisationContacts,
    getOrganisationContactData,
    updateOrganisationContact,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import {
    contactFormInitial,
    contactFormValidation,
    IFormContact,
} from 'form-validation/organisation-contact.validation'
import useDropdown from 'common/dropdown/dropdown.service'
import moment from 'moment'
import { useModal } from '@components/modal/modal.service'

export const useContactListService = () => {
    const { id = 'not found' } = useParams()

    const [contactListData, setContactListData] = useState<IContactList[]>([])
    const [contactDetail, setContactDetail] = useState<IContactList>()
    const [contactSelected, setContactSelected] = useState<IFormContact>()
    const [loading, setLoading] = useState<boolean>(false)
    const dropdownService = useDropdown()
    const createOrEditContactModal = useModal()

    useEffect(() => {
        loadContactList()

        return () => {
            setContactListData([])
        }
    }, [])

    const formikOrgContactDetail = useFormik<IFormContact>({
        validationSchema: contactFormValidation,
        initialValues: contactFormInitial,
        onSubmit: async (values) => {
            try {
                const formattedValues = {
                    ...values,
                    birth_date: moment(values.birth_date).format('YYYY-MM-DD'),
                    phones: [values.phones],
                    emails: [values.emails],
                }

                if (id) {
                    const res = await updateOrganisationContact(
                        id,
                        formattedValues,
                    )
                    if (res) {
                        await loadContactList()
                    }
                }
            } catch (error) {
                console.error('Error updating contact:', error)
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateContact = useFormik<IFormContact>({
        validationSchema: contactFormValidation,
        initialValues: contactFormInitial,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                birth_date: moment(values.birth_date).format('YYYY-MM-DD'),
                phones: [values.phones],
                emails: [values.emails],
            }
            if (id) {
                const res = await createOrganisationContact(id, formattedValues)
                if (res) {
                    loadContactList()
                }
            }
            createOrEditContactModal.closeModalHandling()
        },
        enableReinitialize: true,
    })

    const handleDuplicate = async () => {
        if (id && contactDetail) {
            const res = await createOrganisationContact(id, contactDetail)
            if (res) {
                loadContactList()
            }
        }
    }

    const handleContactDetail = (data: IContactList) => {
        if (data) {
            setContactDetail(data)
            const formData = {
                code: data.code,
                name: data.name,
                first_name: data.first_name,
                last_name: data.last_name,
                contact_type: data.contact_type,
                job_title: data.job_title,
                job_category: {
                    code: data.job_category?.code,
                },
                phones: data?.phones?.[0] || contactFormInitial.phones,
                emails: data.emails?.[0] || contactFormInitial.emails,
                birth_date: data.birth_date,
                working_address: data.working_address,
            }
            setContactSelected(formData)
            formikOrgContactDetail.setValues(formData)
        }
    }

    const loadContactList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationContactData(id)
            setContactListData(response.data as IContactList[])

            if (response?.data?.length > 0) {
                const formData = {
                    code: response.data?.[0].code,
                    name: response.data?.[0].name,
                    first_name: response.data?.[0].first_name,
                    last_name: response.data?.[0].last_name,
                    contact_type: response.data?.[0].contact_type,
                    job_title: response.data?.[0].job_title,
                    job_category: {
                        code: response.data?.[0].job_category?.code,
                    },
                    phones:
                        response.data?.[0]?.phones?.[0] ||
                        contactFormInitial.phones,
                    emails:
                        response.data?.[0].emails?.[0] ||
                        contactFormInitial.emails,
                    birth_date: response.data?.[0].birth_date,
                    working_address: response.data?.[0].working_address,
                }
                setContactDetail(response.data?.[0])
                setContactSelected(formData)
                formikOrgContactDetail.setValues(formData)
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteContact = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationContacts(id, dataId)
            const filterData = contactListData.filter(
                (data) => data.code !== dataId,
            )
            setContactListData(filterData)

            if (filterData?.length > 0) {
                formikOrgContactDetail.setValues({
                    code: filterData?.[0].code,
                    name: filterData?.[0].name,
                    first_name: filterData?.[0].first_name,
                    last_name: filterData?.[0].last_name,
                    contact_type: filterData?.[0].contact_type,
                    job_title: filterData?.[0].job_title,
                    job_category: {
                        code: filterData?.[0].job_category?.code,
                    },
                    phones:
                        filterData?.[0]?.phones?.[0] ||
                        contactFormInitial.phones,
                    emails:
                        filterData?.[0].emails?.[0] ||
                        contactFormInitial.emails,
                    birth_date: filterData?.[0].birth_date,
                    working_address: filterData?.[0].working_address,
                })
                setContactDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    useEffect(() => {
        dropdownService.getCountries()
        dropdownService.getJobCategories()
        dropdownService.getContactTypes()
    }, [])

    return {
        contactListData,
        useDropdown,
        dropdownService,
        handleContactDetail,
        contactSelected,
        formikOrgContactDetail,
        formikOrgCreateContact,
        contactDetail,
        deleteContact,
        createOrEditContactModal,
        handleDuplicate,
    }
}
