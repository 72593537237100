import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface ModuleAccessState {
    codes: string[]
    isLoaded: boolean
}

export const initialState: ModuleAccessState = {
    codes: [],
    isLoaded: false,
}

const moduleAccessSlice = createSlice({
    name: 'moduleAccess',
    initialState,
    reducers: {
        setModuleCodes(state, action: PayloadAction<string[]>) {
            action.payload.forEach((code) => {
                if (!state.codes.includes(code)) {
                    state.codes.push(code)
                }
            })
            state.isLoaded = true // Tandai bahwa data telah dimuat
        },
        resetModuleCodes(state) {
            state.codes = []
            state.isLoaded = false
        },
    },
})

export const { setModuleCodes, resetModuleCodes } = moduleAccessSlice.actions
export const modulesCodesSelector = (state: RootState) =>
    state.moduleCodes.codes
export const moduleAccessLoadedSelector = (state: RootState) =>
    state.moduleCodes.isLoaded
export default moduleAccessSlice.reducer
