import Modal from "@components/modal/modal.component"
import { IUseModal } from "@components/modal/modal.service"

const ModalRight = ({
    modalService,
    form,
    title,
}: {
    modalService: IUseModal
    title: string
    form: JSX.Element
}) => {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute md:!right-0 sticky !m-4 top-[70px]"
        >
            <div>
                <div className="flex flex-1 flex-col">
                    {/* Header */}
                    <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                        {title}
                        <div className="flex justify-center items-center pl-4">
                            <i
                                className="ri-close-fill cursor-pointer "
                                onClick={() => {
                                    modalService.closeModalHandling()
                                }}
                            ></i>
                        </div>
                    </div>

                    {/* Content */}
                    {form}
                </div>
            </div>
        </Modal>
    )
}
export default ModalRight