import { useFormik } from 'formik'
import { useAccountValidation } from './account-form.validation'
import { IFAccount } from './account-form.interface'

const useAccount = () => {
    const accountValidation = useAccountValidation()
    const formik = useFormik<IFAccount>({
        validationSchema: accountValidation.schema,
        validateOnChange: true,
        initialValues: accountValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useAccount
