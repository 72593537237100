import { FormikProvider } from "formik"
import useFormTerm from "./form-term.service"
import FormInput from "@components/form-input/form-input.component"
import Button from "@components/button/button.component"
import { IUseModal } from "@components/modal/modal.service"

const FormTerm = ({ modalService }: { modalService: IUseModal }) => {
    const { formik } = useFormTerm()
    return (
        <FormikProvider value={formik}>
            <div className=" flex flex-col justify-between">
                <div className="p-4">
                    <div className="mb-3">Nem New Term</div>
                    <div className="grid grid-cols-2 gap-2">
                        <FormInput
                            name="name"
                            label="NAME"
                            placeholder="Enter Name"
                        />
                        <FormInput
                            name="due_Date"
                            label="DUE DATE"
                            placeholder="Day"
                            type="number"
                        />
                    </div>
                </div>
                <div className="flex justify-between px-4 border-t-[1px] py-3">
                    <Button
                        label="CENCEL"
                        variant="default"
                        className="w-[150px]"
                        onClick={() => { modalService.closeModalHandling() }}
                    />

                    <Button
                        type="submit"
                        label="SAVE CHANGES"
                        variant="brand"
                        className="w-[150px]"
                        onClick={() => { formik.submitForm() }}
                    />
                </div>
            </div>
        </FormikProvider>
    )
}
export default FormTerm