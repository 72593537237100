import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IItemList } from 'repository/interface/item-list.interface'
import { initialState } from '../detail-shipment.slice'

const slice = createSlice({
    name: 'shipmentDetail',
    initialState:initialState.itemList,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setData(state, action: PayloadAction<IResponseData<IItemList[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta}
        },
    },
})

// these all the variables used for selector
export const fetchParamSelector = (state: RootState) =>
    state.shipmentDetail.itemList.fetchParams || {}
export const dataSelector = (state: RootState) => state.shipmentDetail.itemList.data || []
export const metaSelector = (state: RootState) => state.shipmentDetail.itemList.meta || {}
export const isLoadingSelector = (state: RootState) => state.shipmentDetail.itemList.isLoading

// all actions
export const { setData, setFetchParam, setIsLoading } = slice.actions

export default slice.reducer
