import { IOpeningBalance } from "./opening-balance.interface";

export const dummyOppeningBalance: IOpeningBalance[] = [
    {
        account: '1-10001 Cash IDR',
        debit: 'IDR 0.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-10002 Cash USD',
        debit: 'USD 1,000.00',
        credit: 'USD 0.00',
    },
    {
        account: '1-10003 Accounts Receivable',
        debit: 'IDR 5,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-10004 Inventory',
        debit: 'IDR 10,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-20001 Accounts Payable',
        debit: 'IDR 0.00',
        credit: 'IDR 3,000,000.00',
    },
    {
        account: '1-20002 Accrued Expenses',
        debit: 'IDR 0.00',
        credit: 'IDR 1,500,000.00',
    },
    {
        account: '1-30001 Retained Earnings',
        debit: 'IDR 0.00',
        credit: 'IDR 20,000,000.00',
    },
    {
        account: '1-40001 Sales Revenue',
        debit: 'IDR 0.00',
        credit: 'IDR 15,000,000.00',
    },
    {
        account: '1-50001 Cost of Goods Sold',
        debit: 'IDR 8,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-60001 Operating Expenses',
        debit: 'IDR 2,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-70001 Fixed Assets',
        debit: 'IDR 25,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-80001 Short-Term Loans',
        debit: 'IDR 0.00',
        credit: 'IDR 5,000,000.00',
    },
    {
        account: '1-90001 Long-Term Loans',
        debit: 'IDR 0.00',
        credit: 'IDR 10,000,000.00',
    },
    {
        account: '1-10005 Prepaid Expenses',
        debit: 'IDR 1,000,000.00',
        credit: 'IDR 0.00',
    },
    {
        account: '1-10006 Accumulated Depreciation',
        debit: 'IDR 0.00',
        credit: 'IDR 7,000,000.00',
    },
    {
        account: '1-10007 Owner’s Equity',
        debit: 'IDR 0.00',
        credit: 'IDR 30,000,000.00',
    },
]