import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import useFilter from './filter.service'
import { IFetchParams } from 'common/common.interface'
import FormInput from '@components/form-input/form-input.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'

const Filter = ({
    onFilterSubmit,
    isLoading,
}: {
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { formik, dropdownService } = useFilter(onFilterSubmit)
    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                <div className=" w-full">
                    <FormInput
                        name="keyword"
                        label={'KEYWORD'}
                        useUppercaseLabel={true}
                        placeholder={'Search Transaction'}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="column_option"
                        options={[
                            { label: 'Col 1', value: 'col_1' },
                            { label: 'Col 2', value: 'col_2' },
                        ]}
                        label={'COLUMN OPTION'}
                        useUppercaseLabel={true}
                        placeholder={'All Column'}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
                <div className="pb-4 font-bold text-size-M w-full">
                    Start Date
                </div>
                <div className="w-full">
                    <FormDatePicker
                        name="transaction_date"
                        isRange={true}
                        disableDays="future"
                    />
                </div>
                <div className="pb-4 font-bold text-size-M w-full mt-3">
                    Due Date
                </div>
                <div className="w-full">
                    <FormDatePicker
                        name="due_date"
                        isRange={true}
                        disableDays="future"
                    />
                </div>
                <div className="flex flex-col">
                    <div className="pb-4 font-bold text-size-M w-full mt-3">
                        Balance Due
                    </div>
                    <div className="grid grid-cols-3 gap-4 gap-y-0">
                        {['More than', 'In between', 'Less than'].map(
                            (item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2"
                                >
                                    <FormCheckbox
                                        key={index}
                                        name={'balance_due_cb'}
                                        label={item}
                                    />
                                </div>
                            ),
                        )}
                    </div>
                    <div className=" w-full">
                        <FormInput name="balance_due" type="number" />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="pb-4 font-bold text-size-M w-full">
                        Total
                    </div>
                    <div className="grid grid-cols-3 gap-4 gap-y-0">
                        {['More than', 'In between', 'Less than'].map(
                            (item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2"
                                >
                                    <FormCheckbox
                                        key={index}
                                        name={'total_cb'}
                                        label={item}
                                    />
                                </div>
                            ),
                        )}
                    </div>
                    <div className=" w-full">
                        <FormInput name="total" type="number" />
                    </div>
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="additional_tag"
                        options={[
                            { label: 'tag 1', value: 'tag_1' },
                            { label: 'tag 2', value: 'tag_2' },
                        ]}
                        label={'Additional Tag'}
                        useUppercaseLabel={true}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>

                <div className="-mx-4 border-b border-gray-40 my-1"></div>
                <div className="flex gap-2 mt-1">
                    <Button
                        onClick={() => {
                            formik.submitForm()
                        }}
                        isLoading={isLoading}
                        label={'RESET'}
                        useUpperCase={true}
                        className="w-full"
                        variant="default"
                    />
                    <Button
                        onClick={() => {
                            formik.resetForm()
                        }}
                        isLoading={isLoading}
                        label={'SAVE FILTER'}
                        useUpperCase={true}
                        className="w-full"
                        variant="brand"
                    />
                </div>
            </FormikProvider>
        </div>
    )
}

export default Filter
