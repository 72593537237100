import { useModal } from '@components/modal/modal.service'
import { IFetchParams } from 'common/common.interface'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getUserActivityLogs,
    getUserDetail,
    deactiveUser,
    activateUser,
} from 'repository/user.repository'
import {
    activityLogsSelector,
    setActivityLogs,
    setUserData,
    userDetailDataSelector,
} from './user-access-detail.slice'
import { useAppDispatch } from 'store'
import { Toast } from '@components/toast/toast.component'
import useUserAccess from '../user-access.service'
import { emptyUserPayload } from '../user-access-form/user-access-form.static'

const useUserAccessDetail = () => {
    const { refreshSummary } = useUserAccess()
    const modalConfirmationService = useModal()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const userDetailData = useSelector(userDetailDataSelector)
    const userActivityLogs = useSelector(activityLogsSelector)
    // const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadData(id, {})
        loadUserActivity(id)
    }, [])

    function sendEmail() {
        window.location.assign(`mailto:${userDetailData.email}`)
    }
    const loadUserActivity = async (id: string | undefined) => {
        // const _params = { ...params }

        try {
            // setLoading(true)
            dispatch(setActivityLogs([]))
            try {
                const response = await getUserActivityLogs(id, true)
                if (!response) {
                    // setLoading(false)
                    return
                }
                dispatch(setActivityLogs(response.data))
            } catch (e) {
                Toast({
                    header: 'Failed!',
                    message: 'Failed to get user activity logs',
                    type: 'error',
                })
            }
            // setLoading(false)
        } catch (e) {
            // setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            // setErrorMessage(errorMessage)
            console.error(errorMessage)
        }
    }

    const loadData = async (id: string | undefined, params: IFetchParams) => {
        const _params = { ...params }

        try {
            // setLoading(true)
            dispatch(setUserData(emptyUserPayload))
            try {
                const response = await getUserDetail(id, _params, true)
                if (!response) {
                    // setLoading(false)
                    return
                }
                dispatch(setUserData(response.data))
            } catch (e) {
                Toast({
                    header: 'Failed!',
                    message: 'Failed to get user data',
                    type: 'error',
                })
            }
            // setLoading(false)
        } catch (e) {
            // setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const extraAction = async (status: string) => {
        let response
        let message = ''
        try {
            if (status === 'ACTIVE') {
                response = await deactiveUser({ code: id })
                message = 'Success deactivate User'
            } else {
                response = await activateUser({ code: id })
                message = 'Success to activate User'
            }
            Toast({
                header: 'Success!',
                message,
                type: 'success',
            })

            refreshSummary()
        } catch (e) {
            Toast({
                header: 'Failed!',
                message: 'Failed 401 to update User',
                type: 'error',
            })
        }
    }
    return {
        modalConfirmationService,
        sendEmail,
        navigate,
        extraAction,
        userDetailData,
        userActivityLogs,
        id,
    }
}

export default useUserAccessDetail
