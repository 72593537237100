import { ITableColumn } from '@components/table/table.interface'
import { ITitipanDetails } from '../titipan.interface'
import { formatDate } from '@services/common.service'

export const titipanSummaryHeader: ITableColumn<ITitipanDetails>[] = [
    {
        label: 'Date',
        accessor: 'date',
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
    {
        label: 'Type',
        accessor: 'type',
    },
    {
        label: 'Bank Account',
        accessor: 'bankAccount',
    },
    {
        label: 'Notes',
        accessor: 'note',
    },
    {
        label: 'Debit',
        accessor: 'debit',
    },
    {
        label: 'Credit',
        accessor: 'credit',
    },
    {
        label: 'Balance',
        accessor: 'balance',
    },
]
