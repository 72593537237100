import { ITableColumn } from '@components/table/table.interface'
import { numberWithCommas } from '@services/common.service'
import { ICashBankSummary } from 'repository/interface/cash-bank.interface'

export const CashBankSummaryHeader: ITableColumn<ICashBankSummary>[] = [
    {
        label: 'Account Code',
        accessor: 'account_code',
        sort: true,
        width: 200,
        customBuild: (data) => {
            return (
                <div className="px-2 text-center themes-bg-brand-v1 rounded themes-text-brand-v5 text-sm font-bold  flex justify-center items-center !h-[30px]">
                    {data}
                </div>
            )
        },
    },
    {
        label: 'Account Name',
        accessor: 'account_name',
        sort: true,
        width: 400,
        customBuild: (data) => {
            return data ? data : '-'
        },
    },
    {
        accessor: 'currency',
        label: 'Currency',
        customBuild(_, rowData) {
            return rowData?.currency || '-'
        },
        width: 200,
    },
    {
        accessor: 'balance_statement',
        label: 'Statement Balance',
        customBuild(_, rowData) {
            const currencySymbol = rowData?.currency || ''
            const totalAmount =
                rowData?.balance_statement !== undefined
                    ? numberWithCommas(rowData.balance_statement.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
    {
        accessor: 'balance_jurnal',
        label: 'Balance in Jurnal',
        customBuild(_, rowData) {
            const currencySymbol = rowData?.currency || ''
            const totalAmount =
                rowData?.balance_jurnal !== undefined
                    ? numberWithCommas(rowData.balance_jurnal.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
    },
]
