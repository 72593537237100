import { useFormik } from 'formik'
import { useFormShareEmailValidation } from './share-email.validation'
import { IFormShareEmail } from './share-email.interface'

const useFormShareEmail = () => {
    const formShareEmailValidation = useFormShareEmailValidation()
    const formik = useFormik<IFormShareEmail>({
        validationSchema: formShareEmailValidation.schema,
        validateOnChange: true,
        initialValues: formShareEmailValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormShareEmail

