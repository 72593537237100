import Button from "@components/button/button.component"
import DatePicker from "@components/date-picker/date-picker.component"
import { dummyOppeningBalance } from "./opening-balance.dummy"
import { IOpeningBalance } from "./opening-balance.interface"
import Tooltip from "@components/tooltip/tooltip.component"
import Modal from "@components/modal/modal.component"
import useOppeningBalance from "./opening-balance.service"
import FormInput from "@components/form-input/form-input.component"
import FormTextarea from "@components/form-textarea/form-textarea.component"
import FormDropdown from "@components/form-dropdown/form-dropdown.component"
import { FormikProvider } from "formik"

const OppeningBalance = () => {

    const {
        formik,
        navigate,
        modalServiceAccount,
    } = useOppeningBalance()


    const Dropdown = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                >
                    SAVE AND PUBLISH
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute left-0 mt-2 w-[220px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                    >
                        SAVE AS DRAFT
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                    >
                        PUBLISH
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-full-height container-global flex flex-col">
            <div className='flex justify-start items-center'>
                <div className='flex items-center justify-between gap-2 p-4 border-b-[1px] w-full'>
                    <div className="flex items-center gap-2 ">
                        <div>
                            <div className='font-bold text-size-S'>ACCOUNT</div>
                            <div className='text-size-L font-bold themes-text-brand-v6'>OPENING BALANCE</div>
                        </div>
                        <div className="text-size-XS font-semibold themes-text-yellow-v2 themes-bg-yellow-v1 rounded-md p-1">DRAFT</div>
                    </div>
                    {Dropdown()}
                </div>
            </div>
            <div className="p-4 flex">
                <div className="w-[300px]" >
                    <DatePicker isRange={false} />
                </div>
            </div>
            <div className="flex-grow overflow-x-scroll form-line-price-table rounded-none">
                <div className="table-wrapper !max-h-[calc(100vh-21rem)]">
                    <table>
                        <thead>
                            <tr className="text-muted themes-text-gray-v5">
                                <td>Account</td>
                                <td>
                                    <div className="flex gap-2">
                                        Debit
                                        <Tooltip
                                            text={'Faded amount will indicate negative balance'}
                                            isShow
                                            isHover
                                        >
                                            <i className="ri-error-warning-line cursor-pointer themes-text-hover-brand-v5" />
                                        </Tooltip>
                                    </div>

                                </td>
                                <td>
                                    <div className="flex gap-2">
                                        Credit
                                        <Tooltip
                                            text={'Faded amount will indicate negative balance'}
                                            isShow
                                            isHover
                                        >
                                            <i className="ri-error-warning-line cursor-pointer themes-text-hover-brand-v5" />
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody className="">
                            <tr className="themes-bg-brand-v1">
                                <td className="themes-text-gray-v6 font-bold !py-4">
                                    Asset
                                </td>
                                <td />
                                <td />
                            </tr>
                            {dummyOppeningBalance.map(
                                (line: IOpeningBalance, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="!py-4 px-2 themes-text-gray-v8">
                                                {line.account}
                                            </td>
                                            <td className="!py-4 px-2 w-[200px] themes-text-gray-v8">
                                                {line.debit}
                                            </td>
                                            <td className="!py-4 px-2 w-[200px]">
                                                {line.credit}
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            <tr className="">
                                <td className="!py-4">
                                    <div
                                        onClick={() => modalServiceAccount.openModalHandling()}
                                        className="themes-bg-gray-v1 rounded-lg p-2 max-w-max cursor-pointer themes-bg-hover-brand-v5 themes-text-hover-brand-v1"
                                    >
                                        <i className="ri-add-line" />
                                        ADD NEW ACCOUNT
                                    </div>
                                </td>
                                <td />
                                <td />
                            </tr>
                            <tr>
                                <td className="text-right font-bold themes-text-gray-v9 !pr-[100px]">
                                    Total
                                </td>
                                <td className="font-bold themes-text-gray-v9">IDR 0.00</td>
                                <td className="font-bold themes-text-gray-v9">IDR 0,00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex justify-between px-4 border-t-[1px] py-3">
                <Button
                    label="CANCEL"
                    variant="default"
                    className="w-[150px]"
                    onClick={() => { navigate(-1) }}
                />
                <Button
                    type="submit"
                    label={`reset to last saved`}
                    icon="ri-reset-left-line"
                    variant="brand-inverse"
                    className="w-[200px]"
                    useUpperCase
                    onClick={() => { }}
                />
            </div>
            <Modal isModalOpen={modalServiceAccount.isModalOpen}
                className="md:w-1/4 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div>
                    <div className="flex flex-1 flex-col">
                        {/* Header */}
                        <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                            Opening Balances
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalServiceAccount.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                        <FormikProvider value={formik}>
                            <div className='themes-bg-white flex flex-col items-center p-3'>
                                <div className='w-full'>
                                    <FormInput
                                        label="NAME"
                                        name={"name"}
                                        placeholder="Enter Name"
                                        useUppercaseLabel
                                    />
                                    <FormInput
                                        label="Number"
                                        name={"number"}
                                        placeholder="Enter Account number"
                                        useUppercaseLabel
                                    />
                                    <FormTextarea
                                        name={'billing_address'}
                                        label={'Billing Address'}
                                        placeholder={'Add Billing Address'}
                                        className='!h-[100px] mb-2'
                                    />
                                    <FormDropdown
                                        disabled={false}
                                        label="category"
                                        placeholder="Select Category"
                                        name={'category'}
                                        useUppercaseLabel
                                        options={[]}
                                    />
                                    <FormDropdown
                                        disabled={true}
                                        label="CURRENCY"
                                        placeholder="Select currency"
                                        name={'currency'}
                                        useUppercaseLabel
                                        options={[]}
                                    />
                                    <FormDropdown
                                        disabled={false}
                                        label="details"
                                        placeholder="Select details"
                                        name={'details'}
                                        useUppercaseLabel
                                        options={[]}
                                    />
                                </div>
                            </div>
                        </FormikProvider>
                        <div className="flex font-bold gap-2 p-4 items-center border-t">
                            <Button
                                onClick={() => { modalServiceAccount.closeModalHandling() }}
                                className="w-full"
                                label="BANK"
                                variant='default'
                            />
                            <Button
                                onClick={() => { modalServiceAccount.closeModalHandling();}}
                                className="w-full"
                                label="Save Change"
                                variant='brand'
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default OppeningBalance
