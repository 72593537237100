import usePaymentRequestFilter, { initialPaymentRequestFilter } from './payment-request-filter.service'
import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { IFetchParams } from 'common/common.interface'

const PaymentRequestFilter = ({
    onFilterSubmit,
    isLoading,
}: {
    isGenerateReport: boolean
    onFilterSubmit: (data: IFetchParams) => void
    isLoading?: boolean
}) => {
    const { t } = useTranslation()
    const { dropdown, formik } = usePaymentRequestFilter(
        onFilterSubmit,
    )
    const customerOptions = [
        { value: 'customer1', label: 'Customer 1' },
        { value: 'customer2', label: 'Customer 2' },
        { value: 'customer3', label: 'Customer 3' },
    ]

    const typeOptions = [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
        { value: 'type3', label: 'Type 3' },
    ]

    const depositOptions = [
        { value: 'deposit1', label: 'Deposit 1' },
        { value: 'deposit2', label: 'Deposit 2' },
        { value: 'deposit3', label: 'Deposit 3' },
    ]

    const bankOptions = [
        { value: 'bank1', label: 'Bank 1' },
        { value: 'bank2', label: 'Bank 2' },
        { value: 'bank3', label: 'Bank 3' },
    ]
    return (
        <div className={`flex flex-col p-4 pb-2 overflow-auto`}>
            <FormikProvider value={formik}>
                <div className=" w-full">
                    <FormDropdown
                        name="customer"
                        options={customerOptions}
                        label={'Customer'}
                        useUppercaseLabel={true}
                        placeholder={'All Customer'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="currency"
                        options={dropdown.currencyData}
                        label={'Currency'}
                        useUppercaseLabel={true}
                        placeholder={'All Currency'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="type"
                        options={typeOptions}
                        label={'Type'}
                        useUppercaseLabel={true}
                        placeholder={'All Type'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="deposit"
                        options={depositOptions}
                        label={'Deposit Account'}
                        useUppercaseLabel={true}
                        placeholder={'All Deposit Account'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="payment"
                        options={dropdown.unlocodesData}
                        label={'Payment Method'}
                        useUppercaseLabel={true}
                        placeholder={'All Payment Method'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>
                <div className=" w-full">
                    <FormDropdown
                        name="bank"
                        options={bankOptions}
                        label={'Bank Account'}
                        useUppercaseLabel={true}
                        placeholder={'All Bank Account'}
                        isClearable={true}
                        isMultiSelect={true}
                        isSearchable={true}
                    />
                </div>

                {/* Button For Filter */}
                <div>
                    <div className="border-b border-gray-40 mt-2"></div>
                    <div className="w-full flex gap-4 pt-4">
                        <Button
                            onClick={async () => {
                                formik.setValues(initialPaymentRequestFilter)
                            }}
                            label={t('action.reset')}
                            useUpperCase={true}
                            className="w-1/4"
                            variant="default"
                        />
                        <Button
                            onClick={() => {
                                formik.submitForm()
                            }}
                            label={t('action.apply', {
                                value: t('action.filter'),
                            })}
                            useUpperCase={true}
                            className="w-3/4"
                            variant="brand"
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default PaymentRequestFilter
