import { IMeta } from '@components/pagination/pagination.interface'
import { Toast } from '@components/toast/toast.component'
import { ITableColumn } from '@components/table/table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IPaymentRequest } from '../titipan.interface'

export const tabItemsStatic: ITabItem[] = [
    {
        label: 'Open',
        key: 'status',
        value: 'open',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Close',
        key: 'status',
        value: 'close',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Cancelled',
        key: 'status',
        value: 'cancelled',
        className: 'brand',
        totalData: 0,
    },
]

export const paymentRequestHeaders: ITableColumn<IPaymentRequest>[] = [
    {
        label: 'Request Payment No.',
        accessor: 'requestPaymentNo',
    },
    {
        label: 'Customer Name',
        accessor: 'customerName',
    },
    {
        label: 'File No.',
        accessor: 'fileNo',
    },
    {
        label: 'Currency',
        accessor: 'currency',
    },
    {
        label: 'Total Payment',
        accessor: 'totalPayment',
    },
    {
        label: 'Type',
        accessor: 'type',
    },
    {
        label: 'Payment Method',
        accessor: 'paymentMethod',
    },
    {
        label: 'Bank Account',
        accessor: 'bankAccount',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })
