import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IItemList } from './interface/item-list.interface'
import { IFItemListsPayload } from 'form-validation/item-list.validation'

export const getItemList = async (
    params: IFetchParams,
    shipmentCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `shipments/${shipmentCode}/${endpoints.order}/`
        const response = await fetch<IResponseData<IItemList[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Orders',
            })
        }
        return undefined
    }
}

export const submitItemList = async (code: string, payload: FormData) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/orders`
        await post<IResponseData<any>, IFItemListsPayload>({
            endpoint: endpoint,
            payload,
            isFormData: true,
        })

        Toast({
            header: 'Submit Success',
            message: 'Order created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Order',
        })
        return undefined
    }
}

export const updateItemList = async (
    code: string,
    orderCode: string,
    payload: FormData,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/orders/${orderCode}`
        await put<IResponseData<any>, IFItemListsPayload>({
            endpoint: endpoint,
            payload,
            isFormData: true,
        })

        Toast({
            header: 'Submit Success',
            message: 'Order is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Order',
        })
        return undefined
    }
}

export const deleteItemList = async (code: string, orderId: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/orders/${orderId}`
        console.log(endpoint)
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Order is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Order',
        })
        return undefined
    }
}
