import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITableColumn } from '@components/table/table.interface'
import {
    capitalizeFirstLetter,
    formatDate,
    numberWithCommas,
    removeUnderscores,
} from '@services/common.service'
import { ICommonDropdown } from 'repository/interface/common.interface'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'

export const headerParent: ITableColumn<IItemList>[] = [
    { accessor: 'order_id', label: 'Order ID.', width: 100 },
    {
        accessor: 'supplier.name',
        label: 'Supplier',
    },
    { accessor: 'name', label: 'Order Name' },
    { accessor: 'note', label: 'Note', width: 200, ellipsis: true },
    {
        accessor: 'currency',
        label: 'Currency',
        customBuild(_, rowData) {
            return rowData?.currency?.short_code || '-'
        },
    },
    {
        accessor: 'total_amount',
        label: 'Total Amount',
        customBuild(_, rowData) {
            if (!rowData?.items || !Array.isArray(rowData.items)) {
                return '-'
            }
            const totalAmount = rowData.items.reduce(
                (sum, item) => sum + (item.total_amount || 0),
                0,
            )
            const totalAfterDiscount =
                rowData.discount > 0
                    ? totalAmount * (1 - rowData.discount)
                    : totalAmount - rowData.discount_amount
            const currencySymbol = rowData?.currency?.short_code || ''
            return `${currencySymbol || ''} ${numberWithCommas(totalAfterDiscount.toString()) || '-'}`
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'discount',
        label: 'Discount',
        customBuild(_, rowData) {
            const discount =
                rowData?.discount !== undefined
                    ? rowData.discount
                    : rowData?.discount_amount
            return discount !== undefined
                ? rowData?.discount !== undefined
                    ? `${discount * 100}%`
                    : `${numberWithCommas(discount.toString())}`
                : '-'
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'date',
        label: 'Date',
        customBuild: (data) => {
            if (!data || isNaN(new Date(data).getTime())) {
                return '-'
            }
            return formatDate(data)
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'e_doc',
        label: 'Attach eDoc',
        customBuild(_, rowData) {
            return rowData?.e_doc?.[0]?.filename ? (
                <button
                    onClick={() => console.log('')}
                    className="flex flex-row gap-1"
                >
                    <i className="ri-file-2-line text-[#00B0F2]"></i>
                    <span className="truncate max-w-[150px]">
                        {rowData.e_doc?.[0]?.filename}
                    </span>
                </button>
            ) : (
                '-'
            )
        },
        width: 200,
        ellipsis: true,
    },
    {
        accessor: 'order_status',
        label: 'Status',
        customBuild(_, rowData) {
            return rowData?.order_status !== undefined
                ? capitalizeFirstLetter(removeUnderscores(rowData.order_status))
                : '-'
        },
        width: 120,
        ellipsis: true,
    },
]

export const headerChild: ITableColumn<IOrderItem>[] = [
    {
        accessor: 'number',
        label: 'No.',
        customBuild(_, rowData) {
            return rowData?.number ? (
                <div className="flex flex-row gap-1">
                    <i
                        className={`text-[1rem] ${
                            rowData.priority
                                ? 'ri-star-fill text-[#FFBA1B]'
                                : 'ri-star-line text-[#8D99A5]'
                        }`}
                    ></i>
                    <span>{rowData.number.toString()}</span>
                </div>
            ) : (
                '-'
            )
        },
        width: 50,
    },
    { accessor: 'name', label: 'Item Name', width: 200, ellipsis: true },
    { accessor: 'total_qty', label: 'Total' },
    { accessor: 'ready_qty', label: 'Ready' },
    { accessor: 'unit', label: 'Unit' },
    {
        accessor: 'price',
        label: 'Price per unit',
        customBuild(_, rowData) {
            const currencySymbol = ''
            const totalAmount =
                rowData?.price !== undefined
                    ? numberWithCommas(rowData.price.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'total_amount',
        label: 'Total Amount',
        customBuild(_, rowData) {
            const currencySymbol = ''
            const totalAmount =
                rowData?.total_amount !== undefined
                    ? numberWithCommas(rowData.total_amount.toString())
                    : '-'
            return currencySymbol
                ? `${currencySymbol} ${totalAmount}`
                : totalAmount
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'discount',
        label: 'Discount',
        customBuild(_, rowData) {
            const discount =
                rowData?.discount !== undefined
                    ? rowData.discount
                    : rowData?.discount_amount
            return discount !== undefined
                ? rowData?.discount !== undefined
                    ? `${discount * 100}%`
                    : `${numberWithCommas(discount.toString())}`
                : '-'
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'weight',
        label: 'Weight',
        customBuild(_, rowData) {
            const qty = rowData?.weight || '-'
            const unit = rowData?.weight_unit?.symbol.toUpperCase() || '-'
            return unit ? `${qty} ${unit}` : unit
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'volume',
        label: 'Volumetric Weight',
        customBuild(_, rowData) {
            const qty = rowData?.volume || '-'
            const unit = rowData?.volume_unit?.symbol.toUpperCase() || '-'
            return unit ? `${qty} ${unit}` : unit
        },
        width: 150,
        ellipsis: true,
    },
    {
        accessor: 'unit.label',
        label: 'Product Category',
        width: 150,
        ellipsis: true,
    },
    { accessor: 'hs_code.name', label: 'Hs Code', width: 150, ellipsis: true },
    { accessor: 'note', label: 'Note', width: 150, ellipsis: true },
]

export const discountTypes: IDropdownItem<ICommonDropdown>[] = [
    {
        value: 'PERCENTAGE',
        label: 'Percentage',
    },
    {
        value: 'AMOUNT',
        label: 'Amount',
    },
]
