import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import { contactTypeHeaders } from '../../organisation-details.static'
import useDetailsService from './details.service'
import useOrganisationDetail from '../../organisation-details.service'
import Button from '@components/button/button.component'
import useCreateOrganisation from 'pages/organisation/create-organisation/create-organisation.service'
import { Toast } from '@components/toast/toast.component'

const borderComponent = <div className="border-b mt-3"></div>
const contactTypeBorder: string[] = ['payables', 'warehouse', 'storeOrShop']

const OrganisationDetailsForm = ({
    isEdit,
    showAddressForm,
    formik,
}: {
    isEdit: boolean
    showAddressForm?: boolean
    formik: any
}) => {
    const {
        organisationTypesData,
        taxData,
        languagesData,
        organisationDetailData,
    } = useDetailsService()

    const { countries, states, cities, unlocodes } = useCreateOrganisation()
    const { removeModalService: modalService } = useOrganisationDetail()

    return (
        <>
            <div className="flex gap-x-2">
                <div className="w-3/4 border rounded  h-[calc(100vh-19rem)] flex flex-col">
                    <div className="pt-3 px-4 pb-0">Organisation Details</div>
                    {borderComponent}

                    <div className="p-4 flex flex-col gap-3 overflow-auto">
                        <div className="flex gap-x-2">
                            <div className="w-1/4">
                                <FormInput
                                    label="Organisation Code"
                                    name="code"
                                    disabled={true}
                                    useUppercaseLabel
                                />
                            </div>
                            <div className="w-3/4">
                                <FormInput
                                    label="Full Name"
                                    placeholder="Enter Full Name"
                                    name="name"
                                    disabled={!isEdit}
                                    useUppercaseLabel
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="w-1/4">
                                <FormDropdown
                                    label="Category"
                                    disabled={!isEdit}
                                    name={`category`}
                                    placeholder={'Select Category'}
                                    parentDivClassName="!mb-0 col-span-2"
                                    options={organisationTypesData}
                                    useUppercaseLabel
                                />
                            </div>
                            <div className="w-3/4">
                                <FormInput
                                    label=""
                                    name="category"
                                    disabled={true}
                                    useUppercaseLabel
                                />
                            </div>
                        </div>

                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormInput
                                    label="Email (Optional)"
                                    name="email_1"
                                    placeholder="Enter Email"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                    useUppercaseLabel
                                    onIconClick={() => {
                                        const email1 =
                                            formik.values.email_1 || ''
                                        navigator.clipboard.writeText(email1)
                                        Toast({
                                            header: 'Copy Successful!',
                                            message:
                                                'Successfully copied the email ',
                                            type: 'info',
                                        })
                                    }}
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Email Accounting (Optional)"
                                    name="email_2"
                                    placeholder="Enter Email"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                    useUppercaseLabel
                                    onIconClick={() => {
                                        const email2 =
                                            formik.values.email_2 || ''
                                        navigator.clipboard.writeText(email2)
                                        Toast({
                                            header: 'Copy Successful!',
                                            message:
                                                'Successfully copied the email accounting',
                                            type: 'info',
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormInput
                                    label="Phone Number (Optional)"
                                    name="phone_no"
                                    placeholder="Enter Phone Number"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                    useUppercaseLabel
                                    onIconClick={() => {
                                        const phoneNumber =
                                            formik.values.phone_no || ''
                                        navigator.clipboard.writeText(
                                            phoneNumber,
                                        )
                                        Toast({
                                            header: 'Copy Successful!',
                                            message:
                                                'Successfully copied the phone number',
                                            type: 'info',
                                        })
                                    }}
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Mobile Number (Optional)"
                                    name="mobile_no"
                                    placeholder="Enter Phone Number"
                                    disabled={!isEdit}
                                    icon="ri-file-copy-line"
                                    parentDivClassName="!mb-0"
                                    parentInputClassName="w-full"
                                    useUppercaseLabel
                                    onIconClick={() => {
                                        const mobileNumber =
                                            formik.values.mobile_no || ''
                                        navigator.clipboard.writeText(
                                            mobileNumber,
                                        )
                                        Toast({
                                            header: 'Copy Successful!',
                                            message:
                                                'Successfully copied the mobile number',
                                            type: 'info',
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormInput
                                    label="Fax (Optional)"
                                    name="fax"
                                    placeholder="Enter Fax"
                                    disabled={!isEdit}
                                    useUppercaseLabel
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Website Url (Optional)"
                                    name="website_url"
                                    placeholder="Enter Url"
                                    disabled={!isEdit}
                                    useUppercaseLabel
                                />
                            </div>
                        </div>
                        <FormDropdown
                            label="Language"
                            name="language"
                            disabled={!isEdit}
                            placeholder="Select Language"
                            options={languagesData}
                            useUppercaseLabel
                        />
                        <FormTextarea
                            disabled={!isEdit}
                            label="Remark (Optional)"
                            name="remark"
                            placeholder="For Carrier"
                            useUppercaseLabel
                        />
                        {borderComponent}
                        <div className="pb-2">Tax Info</div>
                        <div className="flex gap-x-2">
                            <div className="w-1/2">
                                <FormDropdown
                                    label="Government tax type"
                                    name="tax_type"
                                    disabled={!isEdit}
                                    placeholder="Select Tax Type"
                                    options={taxData}
                                    useUppercaseLabel
                                />
                            </div>
                            <div className="w-1/2">
                                <FormInput
                                    label="Tax Number"
                                    name="tax_number"
                                    placeholder="Enter Tax Number"
                                    disabled={!isEdit}
                                    useUppercaseLabel
                                />
                            </div>
                        </div>
                        {showAddressForm && (
                            <div className="flex gap-4 flex-col">
                                {borderComponent}
                                <div className="pb-2">Main Address</div>
                                <div className="flex gap-3">
                                    <div className="w-1/2">
                                        <FormInput
                                            label="Address Name"
                                            name="address_name"
                                            placeholder="Enter Address Name"
                                            parentDivClassName="!mb-0"
                                            parentInputClassName="w-full"
                                            useUppercaseLabel
                                        />
                                    </div>
                                    <div className="w-1/2 flex items-center gap-2 font-thin">
                                        <FormCheckbox
                                            name={`consignment_delivery_address`}
                                            label="Consigment Delivery Address"
                                            useUppercaseLabel
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-x-2">
                                    <div className="w-1/2">
                                        <FormTextarea
                                            label="Address 1"
                                            useUppercaseLabel
                                            name="street"
                                            placeholder="Enter Address 1"
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <FormTextarea
                                            useUppercaseLabel
                                            label="Address 2 (Optional)"
                                            name="street_2"
                                            placeholder="Enter Address 2"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-x-2">
                                    <div className="w-1/4">
                                        <FormDropdown
                                            useUppercaseLabel
                                            name="country.code"
                                            label="Country/Region"
                                            placeholder="Select Country/Region"
                                            options={countries}
                                        />
                                    </div>
                                    <div className="w-1/4">
                                        <FormDropdown
                                            useUppercaseLabel
                                            name="state.code"
                                            label="State"
                                            placeholder="Select State"
                                            disabled={states?.length === 0}
                                            options={states}
                                        />
                                    </div>
                                    <div className="w-1/4">
                                        <FormDropdown
                                            useUppercaseLabel
                                            name="city.code"
                                            label="City"
                                            placeholder="Select City"
                                            disabled={cities?.length === 0}
                                            options={cities}
                                        />
                                    </div>
                                    <div className="w-1/4">
                                        <FormInput
                                            useUppercaseLabel
                                            label="Post Code"
                                            name="post_code"
                                            placeholder="Enter Post Code"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-x-2">
                                    <div className="w-1/2">
                                        <FormDropdown
                                            useUppercaseLabel
                                            name={'unlocode'}
                                            label="UNLOCO"
                                            placeholder="Select UNLOCO"
                                            options={unlocodes}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <FormInput
                                            useUppercaseLabel
                                            label="Related City/Port"
                                            name="unlocode"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-1/4 border rounded h-[calc(100vh-19rem)] flex flex-col">
                    <div className="p-3 pb-0">Type</div>
                    {borderComponent}
                    <div className="p-3 overflow-auto ">
                        <FormToggleSwitch
                            name="is_active"
                            label="Active"
                            handleClick={() => {
                                modalService.openModalHandling()
                            }}
                        />
                        {borderComponent}
                        <div className="grid grid-cols-1 mt-1">
                            {contactTypeHeaders.map((data, idx) => (
                                <div key={`${data.selected}-${idx}`}>
                                    <div className="flex justify-between w-full mb-2">
                                        <p className="text-[0.75rem]">
                                            {data.label}
                                        </p>
                                        <div>
                                            <FormCheckbox
                                                name={`${data.selected}`}
                                            />
                                        </div>
                                    </div>
                                    {/* Optional border rendering logic */}
                                    {contactTypeBorder.includes(
                                        data.selected,
                                    ) && (
                                        <div className="col-span-2 border-b my-1"></div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {!showAddressForm && (
                        <div className="p-3 flex flex-col gap-2">
                            <Button
                                label="SAVE CHANGES"
                                variant="brand-inverse"
                                className="w-full"
                                icon="ri-save-line"
                                onClick={() => {
                                    formik.submitForm()
                                }}
                            />
                            <Button
                                label="DISCARD CHANGES"
                                variant="red-inverse"
                                className="w-full"
                                icon="ri-save-line"
                                onClick={() => {
                                    formik.resetForm({
                                        values: { ...organisationDetailData },
                                    })
                                }}
                            />
                        </div>
                    )}
                </div>
                {/* <RemoveModalAddress
                modalService={modalService}
                type={
                    entityStatus === 'ACTJVE' ? 'nonActiveOrg' : 'reactivateOrg'
                }
                data={data.fullName}
                onClick={() => {
                    const toggle =
                        data.status === 'active' ? 'nonActive' : 'active'
                    setData({ ...data, status: toggle })
                }}
            /> */}
            </div>
        </>
    )
}

export default OrganisationDetailsForm
