import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormContact } from './form-contact.interface'

export const useFormContactValidation = () => {
    const schema = Yup.object<IFormContact>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        email: Yup.string().required(requiredMessage('Email')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormContact = {
            name: '',
            email: '',
            telp: '',
            billing_address: '',
            shipping_address: '',
            same_address:false
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
