import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { FormikContextType, FormikProvider } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormAccount } from 'form-validation/organisation-account.validation'
type DropdownProps = ReturnType<typeof useDropdown>

const AccountsListForm = ({
    dropdownService,
    formik,
}: {
    dropdownService: DropdownProps
    formik?: FormikContextType<IFormAccount>
}) => {
    return (
        <div className="overflow-auto">
            <div className="flex flex-col p-3 gap-3">
                <FormInput
                    label="Account Name"
                    placeholder="Enter Account Name"
                    name="name"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                    useUppercaseLabel={true}
                />
                <FormInput
                    label="Account Number"
                    placeholder="Enter Account Number"
                    name="account_number"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                    useUppercaseLabel={true}
                />
                <FormInput
                    label="Routing Number"
                    placeholder="Enter Routing Number"
                    name="routing_number"
                    parentDivClassName="col-span-2"
                    className="!mb-0"
                    useUppercaseLabel={true}
                />
                <FormDropdown
                    name="currency.code"
                    label="Currency"
                    placeholder="Select Currency Category"
                    options={dropdownService.currencyData}
                    useUppercaseLabel={true}
                />
                <FormTextarea
                    label="Remark (Optional)"
                    name="remark"
                    placeholder="Enter Remark"
                    useUppercaseLabel={true}
                />
            </div>
        </div>
    )
}

export default AccountsListForm
