import * as Yup from 'yup'
import {
    requiredArrayMessage,
    requiredMessage,
} from './validation-message.static'

// Define the interface
export interface IFServiceRates {
    code: string
    name: string
    charge_codes: { code: string; price: number }[]
}

// Validation schema
export const useServiceRatesValidation = () => {
    const schema = Yup.object<IFServiceRates>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        charge_codes: Yup.array()
            .of(
                Yup.object().shape({
                    code: Yup.string().required(requiredMessage('Charge Code')),
                }),
            )
            .required(requiredArrayMessage('Charge Codes')),
    })

    // Initial values
    const initialValue: IFServiceRates = {
        code: '',
        name: '',
        charge_codes: [{ code: '', price: 0 }],
    }

    return {
        initialValue,
        schema,
    }
}
