import Table from '@components/table/table.component'
import TabMaster2 from 'pages/components/tab-master2/tab-master2.component'
import {
    RowOption,
} from './components/option/option.component'
import { ITitipan } from './titipan.interface'
import { dummyPaymentRequest, dummyTitipan, metaDummy } from './titipan.dummy'
import useTitipan from './titipan.service'
import { titipanSummaryHeader } from './titipan.static'
import Input from '@components/input/input.component'
import Button from '@components/button/button.component'
import FilterOverlay from './components/filter/filter-overlay.component'
import './titipan.style.css'


const Titipan = () => {
    const {
        loading,
        fetchParam,
        exportModalService,
        filterOverlay,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
    } = useTitipan()

    const TitipanDropdown = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                >
                    CREATE TRANSACTION
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute left-0 mt-2 w-[200px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/titipan/top-up')}
                    >
                        Top Up
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => navigate('/titipan/withdraw')}
                    >
                        Withdraw
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='grid grid-cols-10 gap-2'>
            <div className="content-full-height lg:col-span-7 md:col-span-6 sm:col-span-5 mobile:col-span-10">
                <div className='flex justify-between items-center py-2'>
                    <div className='text-size-L font-bold themes-text-brand-v6'>TITIPAN ACCOUNTS</div>
                    <div>{TitipanDropdown()}</div>
                </div>
                <div className='flex container-global  h-[calc(100vh-10rem)] '>
                    <div className="flex flex-grow flex-col pt-0 w-full">
                        <TabMaster2
                            onChange={(item) => setTabFilter(item)}
                            iconButton1={{
                                onClick: () => filterOverlay.toggleOverlayHandling(),
                                icon: 'ri-filter-2-line',
                                filterOverlayComponent: (
                                    <FilterOverlay
                                        filterOverlayService={filterOverlay}
                                        onFilterSubmit={(data) => {
                                            return
                                        }}
                                    />
                                ),
                            }}
                            //  search ----------------------------------------------------------------
                            useSearch={true}
                            placeHolderSearch={'Search Titipan ID '}
                            containerSearchClassName={'flex-1'}
                            initialSearch={fetchParam.search}
                            onSearchSubmit={(values) => handleSearch(values)}
                        />
                        <Table<ITitipan>
                            headers={titipanSummaryHeader}
                            data={dummyTitipan}
                            loading={loading}
                            nextHandling={(page) => {
                                handlePagination(page)
                            }}
                            previousHandling={(page) => {
                                handlePagination(page)
                            }}
                            meta={metaDummy}
                            moduleTitle={'Titipan'}
                            exportModalService={exportModalService}
                            onRowClick={(item) => {
                                navigate(`/titipan/${item.accountNumber}`)
                            }}
                            rowOptionComponent={(rowData, closeHandling) => (
                                <RowOption
                                    closeHandling={closeHandling}
                                    rowData={rowData}

                                />
                            )}
                        />
                    </div>
                </div>

            </div>
            <div className='container-global content-full-height lg:col-span-3 md:col-span-4 sm:col-span-5 mobile:col-span-10 flex flex-col'>
                <div className='flex justify-between items-center border-b p-3'>
                    <div className='text-size-L font-bold themes-text-brand-v6'>PAYMENT REQUEST</div>
                    <div className='text-size-M themes-text-brand-v5 cursor-pointer hover:underline' onClick={() => navigate('/titipan/payment-request')}>
                        VIEW ALL
                    </div>
                </div>
                <div className='themes-bg-gray-v1 p-3 flex-grow rounded-b-xl'>
                    <Input
                        autoComplete="auto-off"
                        placeholder={'Search (min 3 char)'}
                        icon="ri-search-line"
                        name="searchTerm"
                    />
                    <div className='mt-2 flex flex-col gap-2 overflow-auto h-[calc(100vh-14rem)]'>
                        {dummyPaymentRequest.map((data, index) => {
                            return (
                                <div className='bg-white rounded-xl' key={`payment-request-${index}`}>
                                    <div className='flex justify-between items-center px-3 pt-3'>
                                        <div>{data.requestPaymentNo}</div>
                                        <div className='themes-bg-yellow-v1 themes-text-yellow-v4 rounded-md p-1 text-size-XS'>NEW</div>
                                    </div>
                                    <div className='text-size-L font-semibold px-3'>{data.fileNo} - {data.customerName}</div>
                                    <div className='px-3 pt-2'>
                                        <div className='flex text-size-S mb-2'>
                                            <div className='w-[120px] themes-text-gray-v5'>Total Payment</div>
                                            <div className='themes-text-gray-v6'>{data.totalPayment}</div>
                                        </div>
                                        <div className='flex text-size-S mb-2'>
                                            <div className='w-[120px] themes-text-gray-v5'>Type</div>
                                            <div className='themes-text-gray-v6'>{data.type}</div>
                                        </div>
                                        <div className='flex text-size-S mb-2'>
                                            <div className='w-[120px] themes-text-gray-v5'>Curr. Balance</div>
                                            <div className='themes-text-gray-v6'>IDR 1.000.000</div>
                                        </div>
                                        <div className='flex text-size-S mb-2'>
                                            <div className='w-[120px] themes-text-gray-v5'>Created Date</div>
                                            <div className='themes-text-gray-v6'>Sun, 12/01/25</div>
                                        </div>
                                    </div>
                                    <div className='border-t flex justify-end item-center p-2'>
                                        <Button
                                            onClick={() => { }}
                                            label='Create Payment'
                                            className={`h-8`}
                                            variant="brand-inverse"
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Titipan
