var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Select, { components, } from 'react-select';
import './dropdown.style.css';
import AsyncSelect from 'react-select/async';
function Dropdown(_a) {
    var _b;
    var _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.isClearable, isClearable = _d === void 0 ? false : _d, _e = _a.isSearchable, isSearchable = _e === void 0 ? false : _e, _f = _a.isMultiSelect, isMultiSelect = _f === void 0 ? false : _f, _g = _a.required, required = _g === void 0 ? false : _g, _h = _a.dropDownIndicator, dropDownIndicator = _h === void 0 ? false : _h, _j = _a.disabled, disabled = _j === void 0 ? false : _j, _k = _a.useBorder, useBorder = _k === void 0 ? true : _k, _l = _a.isAsync, isAsync = _l === void 0 ? false : _l, options = _a.options, _m = _a.useUppercaseLabel, useUppercaseLabel = _m === void 0 ? false : _m, _o = _a.disabledVariant, disabledVariant = _o === void 0 ? 'gray' : _o, borderColorClassName = _a.borderColorClassName, _p = _a.heightInput, heightInput = _p === void 0 ? 30 : _p, props = __rest(_a, ["isLoading", "isClearable", "isSearchable", "isMultiSelect", "required", "dropDownIndicator", "disabled", "useBorder", "isAsync", "options", "useUppercaseLabel", "disabledVariant", "borderColorClassName", "heightInput"]);
    var _q = useState(false), isOpen = _q[0], setIsOpen = _q[1];
    var _r = useState(false), focus = _r[0], setFocus = _r[1];
    var showLabelClass = !props.label || props.label === '' ? 'hidden' : '';
    var isRequired = required ? 'required' : '';
    var labelFocus = focus ? 'themes-text-brand-v5' : '';
    var borderFocus = focus ? 'themes-border-brand-v5' : '';
    var dropdownValue = (props === null || props === void 0 ? void 0 : props.value) || props.defaultValue ? props === null || props === void 0 ? void 0 : props.value : null;
    var dataTestIdComponent = props.dataTestId
        ? "".concat(props.dataTestId, "_DROPDOWN")
        : '';
    var overflowOption = isMultiSelect ? "overflow-option" : '';
    // control label color
    var labelBgGolor = disabled ? 'themes-bg-gray-v1' : 'bg-white';
    // isloading
    if (isLoading) {
        options = [{ value: 'loading', label: 'Loading Data' }];
    }
    var defaultOptionComponent = function (optionProps) {
        var _a, _b;
        var dataTestId = '';
        if (dataTestIdComponent) {
            var sequenceNumber = Number((_b = (_a = optionProps === null || optionProps === void 0 ? void 0 : optionProps.innerProps) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.split('-').pop()) + 1 || 0;
            dataTestId =
                sequenceNumber <= 5
                    ? "".concat(dataTestIdComponent, "_OPTION_").concat(sequenceNumber)
                    : '';
        }
        return (_jsx("div", __assign({ "data-testid": dataTestId }, { children: _jsx(components.Option, __assign({}, optionProps)) })));
    };
    var defaultInputComponent = function (optionProps) {
        var dataTestId = dataTestIdComponent
            ? "".concat(dataTestIdComponent, "_INPUT")
            : '';
        return _jsx(components.Input, __assign({}, optionProps, { "data-testid": dataTestId }));
    };
    var customStyles = {
        control: function (base) { return (__assign(__assign({}, base), { border: 'none', boxShadow: 'none', '&:hover': {
                border: 'none',
            }, height: heightInput ? heightInput : '100%', display: 'flex', alignItems: 'center', overflow: 'hidden', backgroundColor: disabled && disabledVariant === 'gray'
                ? 'var(--loglines-gray-v1)'
                : 'var(--loglines-white)', color: disabled && disabledVariant === 'gray'
                ? 'var(--loglines-gray-v6)'
                : 'var(--loglines-gray-v5)' })); },
        placeholder: function (base) { return (__assign(__assign({}, base), { color: 'var(--loglines-gray-v4)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' })); },
        menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: '99' })); },
        menuList: function (base) { return (__assign(__assign({}, base), { '::-webkit-scrollbar': {
                width: '12px',
                height: '15px',
                marginBottom: '50px',
            }, '::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
            }, '::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--loglines-gray-v2)',
                border: '3px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                borderRadius: '9px',
                backgroundClip: 'content-box',
            }, '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'var(--loglines-gray-v3)',
                borderRadius: '15px',
            }, marginTop: useBorder ? '' : '-16px', backgroundColor: 'var(--loglines-white)', fontSize: '12px', lineHeight: '20px', borderRadius: '5px', border: '1px', borderColor: 'var(--loglines-brand-v5)', borderStyle: 'solid', position: 'relative' })); },
        dropdownIndicator: function (base, state) { return (__assign(__assign({}, base), { transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '', transition: 'transform 0.3s ease-in-out' })); },
        clearIndicator: function (base) { return (__assign(__assign({}, base), { cursor: 'pointer' })); },
        option: function (base, _a) {
            var isSelected = _a.isSelected, isDisabled = _a.isDisabled;
            return (__assign(__assign({}, base), { textAlign: isLoading ? 'center' : 'left', backgroundColor: isSelected
                    ? 'var(--loglines-brand-v2)'
                    : 'inherit', '&:hover': {
                    backgroundColor: 'var(--loglines-gray-v1)',
                }, color: isDisabled
                    ? 'var(--loglines-gray-v2)'
                    : 'var(--loglines-gray-v6)', position: 'relative', cursor: 'pointer', paddingLeft: '8px', paddingTop: '4px', paddingBottom: '4px' }));
        },
        valueContainer: function (base) { return (__assign(__assign({}, base), { display: 'flex', flexWrap: 'wrap', maxHeight: heightInput ? heightInput : '100%', overflowY: 'auto', '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--loglines-gray-v3)',
                borderRadius: '4px',
            }, scrollbarWidth: 'none', '&::-webkit-scrollbar': {
                display: 'none',
            } })); },
        multiValue: function (base) { return (__assign(__assign({}, base), { backgroundColor: '#BEEDFF', marginLeft: '4px', marginTop: '4px', marginBottom: '4px', color: '#0075A1', height: 24, fontSize: '12px', borderRadius: '5px' })); },
        multiValueLabel: function (base) { return (__assign(__assign({}, base), { color: '#0075A1', fontSize: '12px', padding: '2px 6px' })); },
        multiValueRemove: function (base) { return (__assign(__assign({}, base), { borderRadius: '5px', cursor: 'pointer', '&:hover': {
                backgroundColor: 'unset',
                color: 'unset',
            } })); },
        singleValue: function (base) { return (__assign(__assign({}, base), { color: 'var(--loglines-gray-v7)' })); },
    };
    return (_jsxs("div", __assign({ className: "dropdownParent-style ".concat(props.parentDivClassname, " ").concat(borderColorClassName), "data-testid": props === null || props === void 0 ? void 0 : props.dataTestId }, { children: [_jsx("label", __assign({ className: "".concat(showLabelClass) }, { children: _jsx("div", __assign({ className: "dropdownLabelDiv-style ".concat(labelBgGolor) }, { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(labelFocus, " ").concat(props.labelDivClassname) }, { children: useUppercaseLabel
                            ? (_b = props.label) === null || _b === void 0 ? void 0 : _b.toUpperCase()
                            : props.label })) })) })), _jsx("div", __assign({ className: " ".concat(focus ? '' : props.parentDivClassname, " ").concat(overflowOption, " ").concat(borderFocus, " ").concat(useBorder ? '' : 'border-none', " dropDown") }, { children: !isAsync ? (_jsx(Select, { className: "".concat(props.className), classNamePrefix: 'select', components: __assign(__assign({}, ((props === null || props === void 0 ? void 0 : props.customMenuComponent) && {
                        MenuList: props.customMenuComponent,
                    })), { Option: (props === null || props === void 0 ? void 0 : props.customOptionComponent)
                            ? props === null || props === void 0 ? void 0 : props.customOptionComponent
                            : defaultOptionComponent, Input: defaultInputComponent }), 
                    // custom label option
                    formatOptionLabel: function (option, _a) {
                        var context = _a.context;
                        return context === 'menu' &&
                            option.customLabelOption
                            ? option.customLabelOption
                            : option.label;
                    }, closeMenuOnSelect: isMultiSelect === true ? false : true, defaultValue: props === null || props === void 0 ? void 0 : props.defaultValue, placeholder: props.placeholder, value: dropdownValue, isMulti: isMultiSelect, isDisabled: disabled, isLoading: isLoading, isClearable: isClearable, isSearchable: isSearchable, options: options, isOptionDisabled: function (options) {
                        return (options === null || options === void 0 ? void 0 : options.value) === 'loading';
                    }, menuPosition: "fixed", menuIsOpen: isOpen, onMenuOpen: function () { return setIsOpen(true); }, onMenuClose: function () { return setIsOpen(false); }, maxMenuHeight: 115, onFocus: function () {
                        setFocus(true);
                    }, onBlur: function () {
                        setFocus(false);
                    }, onChange: function (e) {
                        var _a;
                        if (props.onClick)
                            props.onClick(e);
                        if (props.additionalDataHandling)
                            props.additionalDataHandling((_a = e === null || e === void 0 ? void 0 : e.additionalData) !== null && _a !== void 0 ? _a : null);
                    }, onInputChange: function (value) {
                        if (props.onInputChange)
                            return props.onInputChange(value);
                    }, filterOption: function (option, inputValue) {
                        if (isLoading) {
                            return true;
                        }
                        return option.label
                            .toUpperCase()
                            .includes(inputValue.toUpperCase());
                    }, unstyled: false, styles: customStyles, theme: function (theme) { return (__assign(__assign({}, theme), { borderRadius: 4 })); } })) : (_jsx(AsyncSelect, { loadOptions: (props === null || props === void 0 ? void 0 : props.loadOptions) && (props === null || props === void 0 ? void 0 : props.loadOptions), className: "".concat(props.className), classNamePrefix: 'select', components: __assign(__assign({}, ((props === null || props === void 0 ? void 0 : props.customOptionComponent) && {
                        Option: props === null || props === void 0 ? void 0 : props.customOptionComponent,
                    })), ((props === null || props === void 0 ? void 0 : props.customMenuComponent) && {
                        MenuList: props.customMenuComponent,
                    })), closeMenuOnSelect: isMultiSelect === true ? false : true, placeholder: props.placeholder, value: dropdownValue, isMulti: isMultiSelect, isDisabled: disabled, isLoading: isLoading, isClearable: isClearable, isSearchable: isSearchable, options: options, isOptionDisabled: function (options) {
                        return (options === null || options === void 0 ? void 0 : options.value) === 'loading';
                    }, menuPosition: "fixed", menuIsOpen: isOpen, onMenuOpen: function () { return setIsOpen(true); }, onMenuClose: function () { return setIsOpen(false); }, maxMenuHeight: 115, onFocus: function () {
                        setFocus(true);
                    }, onBlur: function () {
                        setFocus(false);
                    }, onChange: function (e) {
                        var _a;
                        if (props.onClick)
                            props.onClick(e);
                        if (props.additionalDataHandling)
                            props.additionalDataHandling((_a = e === null || e === void 0 ? void 0 : e.additionalData) !== null && _a !== void 0 ? _a : null);
                    }, defaultOptions: props.defaultOptions || (props === null || props === void 0 ? void 0 : props.defaultValue), unstyled: false, styles: customStyles, theme: function (theme) { return (__assign(__assign({}, theme), { borderRadius: 4 })); } })) }))] })));
}
export default Dropdown;
