import { FormikProvider } from 'formik'
import useCreateAccount from './account-form.service'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import Button from '@components/button/button.component'
import FormRadio from '@components/form-radio/form-radio.component'
import {
    AccountAccessOptions,
    AccountTaxItem,
    BankNameItem,
    CategoryItem,
    CurrencyItem,
    DetailsItem,
} from './account-form.dummy'

const CreateAccount = () => {
    const { formik } = useCreateAccount()

    return (
        <div className="container-global content-full-height themes-bg-brand-v1">
            <FormikProvider value={formik}>
                <div className="px-4 py-2">
                    <div className="themes-text-gray-v6 text-size-XS font-bold mb-1">
                        REGISTER
                    </div>
                    <div className="themes-text-brand-v6 text-size-L font-bold">
                        EDIT ACCOUNT
                    </div>
                </div>
                <div className="h-[calc(100vh-15.2rem)] overflow-auto bg-white flex flex-col items-center mx-4 rounded-t-lg">
                    <div className="flex flex-col gap-2 px-4 pt-2 lg:w-[36rem] w-full py-5">
                        <div className="text-size-M font-semibold mb-2">
                            Account Information
                        </div>
                        <FormInput
                            name="account_name"
                            label="ACCOUNT NAME"
                            placeholder="Enter account name"
                        />
                        <FormInput
                            name="account_code"
                            label="NUMBER ACCOUNT CODE"
                            placeholder="Enter Account Code"
                        />
                        <FormDropdown
                            disabled={false}
                            label="CATEGORY"
                            placeholder="Select Category"
                            name={'category'}
                            options={CategoryItem}
                        />
                        <FormDropdown
                            disabled={false}
                            label="CURRENCY"
                            placeholder="Select Currency"
                            name={'currency'}
                            options={CurrencyItem}
                        />
                        <FormDropdown
                            disabled={false}
                            label="DETAILS"
                            placeholder="Select Details"
                            name={'details'}
                            options={DetailsItem}
                        />
                        <FormDropdown
                            disabled={false}
                            label="DEFAULT ACCOUNT TAX"
                            placeholder="Select Tax"
                            name={'account_tax'}
                            options={AccountTaxItem}
                        />
                        <FormDropdown
                            disabled={false}
                            label="BANK NAME"
                            placeholder="Select Bank"
                            name={'bank_name'}
                            options={BankNameItem}
                        />
                        <FormInput
                            name="bank_number"
                            label="BANK NUMBER"
                            placeholder="Enter Bank Number"
                            type="number"
                        />
                        <div className="flex flex-row justify-between">
                            <div className="text-size-S mb-2 font-normal">
                                Start Balance
                            </div>
                            <div className="text-size-M mb-2 themes-text-brand-v6">
                                RP 0,0{' '}
                                <span className="font-semibold">EDIT</span>
                            </div>
                        </div>
                        <FormTextarea
                            name={'description'}
                            label={'DESCRIPTION'}
                            placeholder={'Enter Description'}
                            className="!h-[94px]"
                        />
                        <div className="flex flex-col gap-1">
                            <div className="text-size-M font-semibold">
                                Account Access
                            </div>
                            <div className="text-size-S font-semibold">
                                Who Can Access
                            </div>
                            <FormRadio
                                name="account_access"
                                label="All User"
                                options={AccountAccessOptions}
                                parentDivClassName="grid grid-cols-3 w-full"
                                classNameLabel="text-size-XS"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex rounded-b-lg flex-col items-center bg-white mx-4 border-t-[1px]">
                    <div className="flex justify-between px-4 py-3 lg:w-[36rem] w-full">
                        <Button
                            label="CANCEL"
                            variant="default"
                            className="w-[150px]"
                            onClick={() => {}}
                        />

                        <Button
                            type="submit"
                            label="CREATE ACCOUNT"
                            variant="brand"
                            className="w-[150px]"
                            onClick={() => {
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default CreateAccount
