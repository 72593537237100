import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { IEDocs } from '../../organisation-details.interface'
import {
    createOrganisationEDocs,
    deleteOrganisationEDoc,
    getOrganisationEDocs,
    updateOrganisationEDoc,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import {
    edocFormInitial,
    edocFormValidation,
    IFormEdoc,
} from 'form-validation/organisation-edocs.validation'
import useDropdown from 'common/dropdown/dropdown.service'
import { useModal } from '@components/modal/modal.service'

export const useEDocumentationListService = () => {
    const { id = 'not found' } = useParams()

    const [docListData, setDocListData] = useState<IEDocs[]>([])
    const [docDetail, setDocDetail] = useState<IEDocs>()
    const [edocSelected, setEdocSelected] = useState<IFormEdoc>()
    const [loading, setLoading] = useState<boolean>(false)
    const dropdownService = useDropdown()
    const createOrEditEdocModal = useModal()

    useEffect(() => {
        loadDocList()
        return () => {
            setDocListData([])
        }
    }, [])

    const formikOrgDocDetail = useFormik<IFormEdoc>({
        initialValues: edocFormInitial,
        validationSchema: edocFormValidation,
        onSubmit: async (values) => {
            if (id) {
                const res = await updateOrganisationEDoc(id, values)
                if (res) {
                    loadDocList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateDoc = useFormik<IFormEdoc>({
        initialValues: edocFormInitial,
        validationSchema: edocFormValidation,
        onSubmit: async (values) => {
            if (id) {
                const payload = {
                    document_type: values?.document_type,
                    description: values?.description,
                    filename: values?.filename,
                    path: values?.path,
                }
                const res = await createOrganisationEDocs(id, payload)
                if (res) {
                    loadDocList()
                }
            }
            createOrEditEdocModal.closeModalHandling()
        },
        enableReinitialize: true,
    })

    const loadDocList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationEDocs(id)
            setDocListData(response.data)

            if (response?.data?.length > 0) {
                formikOrgDocDetail.setValues(response.data?.[0])
                setDocDetail(response.data?.[0])
                setEdocSelected(response.data?.[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleDocumentationDetail = (data: IEDocs) => {
        setDocDetail(data)
        setEdocSelected(data)
        formikOrgDocDetail.setValues(data)
    }

    const handleDuplicate = async () => {
        if (id && docDetail) {
            const res = await createOrganisationEDocs(id, docDetail)
            if (res) {
                loadDocList()
            }
        }
    }

    const deleteDoc = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationEDoc(id, dataId)
            const filterData = docListData.filter(
                (data) => data.code !== dataId,
            )
            setDocListData(filterData)

            if (filterData?.length > 0) {
                formikOrgDocDetail.setValues(filterData[0])
                setDocDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    useEffect(() => {
        dropdownService.getDocumentType()
    }, [])

    return {
        docListData,
        docDetail,
        formikOrgDocDetail,
        formikOrgCreateDoc,
        handleDocumentationDetail,
        deleteDoc,
        handleDuplicate,
        useDropdown,
        dropdownService,
        edocSelected,
        createOrEditEdocModal,
    }
}
