import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'

export const useInnerPacks = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Variable
    // const { data, isFetchNew } = innerPacks
    const modalFormInnerPack = useModal()

    // Formik
    const formikDetail = useFormik({
        validateOnChange: true,
        initialValues: {
            volume: { qty: '0' },
            weight: { qty: '0' },
            total: '0',
        },
        onSubmit: (values) => {},
    })

    const getInnerPacks = async () => {}

    const selectRow = (value: any) => {
        const newValue: any = {
            count: 0,
            weight: {
                qty: value.weight?.toString() ?? '0',
                unit: value.weight_unit?.code,
                unitLabel: value.weight_unit?.symbol,
            },
            volume: {
                qty: value.volume?.toString() ?? '0',
                unit: value.volume_unit?.code,
                unitLabel: value.volume_unit?.symbol,
            },
        }
        formikDetail.setValues(newValue)
    }

    const deleteItem = async (id: string) => {
        getInnerPacks()
    }

    useEffect(() => {
        dropdownService.getVolumeUnit()
        dropdownService.getWeightUnit()
    }, [])

    return {
        dropdownService,
        isLoading,
        formikDetail,
        isNew,
        deleteLoading,
        modalFormInnerPack,
        deleteItem,
        setIsNew,
        selectRow,
    }
}
