import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { Toast } from '@components/toast/toast.component'
import { IOrganisation } from 'repository/interface/organisation.interface'
import { contactTypeHeaders } from './organisation-details/organisation-details.static'
import { formatDate } from '@services/common.service'

export const organisationEndpoints = {
    organisations: 'organisations',
    categories: 'organisation_categories',
    languages: 'languages',
    tax_types: 'tax_types',
    countries: 'countries',
    states: 'states',
    cities: 'cities',
    unlocodes: 'unlocodes',
    document_types: 'document_types',
    address_capabilities: 'address_capabilities',
}
export const organisationStatusHeaders: ITabItem[] = [
    {
        label: 'Active',
        totalData: 0,
        key: 'status',
        value: 'true',
        className: 'brand',
    },
    {
        label: 'Inactive',
        totalData: 0,
        key: 'status',
        value: 'false',
        className: 'red',
    },
]

export const organisationSummaryHeaders: ITableColumn<IOrganisation>[] = [
    {
        label: 'Code',
        accessor: 'short_code',
    },
    {
        label: 'Full Name',
        accessor: 'name',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'City',
        accessor: 'addresses?.[0]?.city?.name',
        customBuild: (_, rowData) => {
            if (rowData?.addresses?.[0]?.city?.name) {
                const name = rowData?.addresses?.[0]?.city?.name
                return <>{name}</>
            } else return <>-</>
        },
        exportValue: (rowData) => rowData?.addresses?.[0]?.city?.name,
    },
    {
        label: 'Country',
        accessor: 'addresses?.[0]?.country?.name',
        customBuild: (_, rowData) => {
            if (rowData?.addresses?.[0]?.country?.name) {
                const name = rowData?.addresses?.[0]?.country?.name
                return <>{name}</>
            } else return <>-</>
        },
        exportValue: (rowData) => rowData?.addresses?.[0]?.country?.name,
    },
    {
        label: 'Email',
        accessor: 'email_1',
    },
    {
        label: 'Number',
        accessor: 'mobile_no',
    },
    {
        label: 'Org Type',
        accessor: 'category',
        customBuild: (data: any, rowData) => {
            return <>{getActiveLabels(rowData)}</>
        },
        exportValue: (rowData) => getActiveLabelsText(rowData),
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            const date = formatDate(data, 'DD/MM/YY, HH:mm')
            return date
        },
    },
]

const getActiveLabels = (rowData: any) => {
    return contactTypeHeaders
        .filter((header) => rowData[header.selected])
        .map((header) => (
            <span
                key={header.selected}
                className={`text-size-XS mr-1 font-semibold px-2 py-1 flex items-center rounded-md ${header.classStatus}`}
            >
                {header.label}
            </span>
        ))
}

const getActiveLabelsText = (rowData: any) => {
    return contactTypeHeaders
        .filter((header) => rowData[header.selected])
        .map((header) => header.label)
        .join(', ')
}

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })

export const organisationType: IDropdownItem[] = [
    { label: 'Shipper', value: 'shipper' },
    { label: 'Consignee', value: 'consignee' },
    { label: 'Agent', value: 'agent' },
    { label: 'Customer', value: 'customer' },
    { label: 'Transport', value: 'transport' },
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Carrier', value: 'carrier' },
    { label: 'Supplier', value: 'supplier' },
    { label: 'Notify', value: 'notify' },
    { label: 'VIP', value: 'vip' },
    { label: 'Services', value: 'services' },
    { label: 'Sales', value: 'sales' },
    { label: 'Store', value: 'store' },
]
