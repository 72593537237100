import { useEffect, useState } from 'react'
import { expensesFetchParamSelector, setFetchParam } from './add-files.slice'
import { useSelector } from 'react-redux'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'

const useAddFiles = () => {
    const dispatch = useAppDispatch()
    // selectors
    const fetchParam = useSelector(expensesFetchParamSelector)
    const dropdownService = useDropdown()


    // states
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)

    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            console.log(values.searchTerm)
        },
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = 'Search term must be at least 3 characters'
            }
            return errors
        },
    })

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])


    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        console.log(fParams)
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const deleteData = async (_orderId?: string) => {
        try {
            setDeleteLoading(true)
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    return {
        loading,
        fetchParam,
        handlePagination,
        deleteLoading,
        formik,
        deleteData,
        handleSearch,
        setLoading
    }
}

export default useAddFiles