import Button from '@components/button/button.component'
import usePaymentDetails from './payment-details.service'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import Checkbox from '@components/checkbox/checkbox.component'
import '../titipan.style.css'
import Modal from '@components/modal/modal.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FileUpload from 'component/file-uploud/file-uploud.component'

const PaymentDetails = () => {
    const {
        formik,
        currency,
        modalExchange,
        setCurrency,
        navigate,
    } = usePaymentDetails()


    const idr = `https://flagcdn.com/id.svg`
    const usd = `https://flagcdn.com/us.svg`

    const currencyOptions = [
        { label: 'IDR', value: 'IDR' },
        { label: 'USD', value: 'USD' },
    ]

    return (
        <div className="content-full-height">
            <div className='flex items-center gap-4  p-3'>
                <i className="ri-arrow-left-line text-[20px] cursor-pointer" onClick={() => navigate(-1)} />
                <div>
                    <div className='font-bold text-size-S'>TITIPAN</div>
                    <div className='text-size-L font-bold themes-text-brand-v6'>PAYMENT REQUEST DETAILS #REQ001123</div>
                </div>
            </div>
            <div className='flex flex-col container-global !border-0 !rounded-t-none'>
                <div className=" overflow-auto h-[calc(100vh-14rem)] grid grid-cols-10">
                    <div className='col-span-3 themes-bg-gray-v1'>
                        <div className='flex flex-col gap-2 px-11 pt-11'>
                            <div className='themes-text-gray-v6 font-semibold'>Account Name</div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>Customer</div>
                                <div>Sae</div>
                            </div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>Email</div>
                                <div>saesanti@gmail.com</div>
                            </div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>Billing Address</div>
                                <div>Jalan Pamulang</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 px-11 pt-4'>
                            <div className='themes-text-gray-v6 font-semibold'>File Information</div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>File Type</div>
                                <div>Shipment File</div>
                            </div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>File Number</div>
                                <div>S12242025 <i className="ri-external-link-line cursor-pointer themes-text-hover-brand-v5" /></div>
                            </div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>Organisation</div>
                                <div>JEmily Cooper <i className="ri-external-link-line cursor-pointer themes-text-hover-brand-v5" /></div>
                            </div>
                            <div className='grid grid-cols-2 text-[14px]'>
                                <div className='themes-text-gray-v5 font-semibold'>Invoice Number</div>
                                <div>INV20139135 <i className="ri-external-link-line cursor-pointer themes-text-hover-brand-v5" /></div>
                            </div>
                            <div className='flex gap-2'>
                                <Checkbox
                                    name={'mark_as_deposit'}
                                    onChecked={(e) => { console.log(e) }}
                                />
                                <span className='themes-text-gray-v7'>Mark as Deposit Transaction</span>
                            </div>
                        </div>
                    </div>
                    <div className='themes-bg-white flex flex-col items-center px-3 py-11 col-span-7'>
                        <FormikProvider value={formik}>
                            <div className='flex text-size-S gap-2'>
                                <div className='themes-text-gray-v5'>TOP UP BALANCE IN</div>
                                <div className="relative inline-block ml-4 group">
                                    <div className=" cursor-pointer themes-text-brand-v5">
                                        {currency}
                                        <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />                                    </div>

                                    <div
                                        className="absolute left-0 w-[100px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                                    >
                                        {currencyOptions.map((item, idx) => {
                                            return (
                                                <div
                                                    key={`item-${idx}`}
                                                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                                    onClick={() => { setCurrency(item.value) }}
                                                >
                                                    {item.label}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='flex border-b-[1px] items-start gap-1'>
                                <div className='themes-text-brand-v7 text-[32px] font-semibold'>Rp</div>
                                <FormInput
                                    name={"top_up"}
                                    placeholder="0,00"
                                    parentDivClassName='top-up-input'
                                />
                            </div>
                            <div className='flex gap-2 text-size-S py-3'>
                                <span className='themes-text-gray-v5 font-semibold'> Exchange Rate</span>
                                1$ = Rp.16.000,00
                                <i className="ri-edit-fill themes-text-hover-brand-v5 cursor-pointer" />
                            </div>
                            <div className='border-[1px] w-full mb-3' />
                            <div className='w-[50%]'>
                                <div className='themes-bg-gray-v1 rounded-lg p-3 mb-5 px-14'>
                                    <div className='flex justify-center mb-3 font-bold'><span className='themes-text-brand-v5 mr-1'>See</span> Titipan Balance</div>
                                    <div className='flex justify-between mb-3'>
                                        <img
                                            src={idr}
                                            className="h-5 w-7 sm:hidden"
                                            alt={idr}
                                        />
                                        <div>Rp 6.000.000,00</div>
                                    </div>
                                    <div className='flex justify-between mb-3'>
                                        <img
                                            src={usd}
                                            className="h-5 w-7 sm:hidden"
                                            alt={usd}
                                        />
                                        <div>$500.000</div>
                                    </div>
                                </div>
                                <div className='themes-bg-red-v1 rounded-lg py-3 mb-5 px-3  gap-3 flex items-center'>
                                    <i className="ri-error-warning-fill text-[24px] themes-text-red-v3" />
                                    <div className='text-size-S flex flex-col gap-2'>
                                        <div className='font-semibold'>Insufficient funds</div>
                                        <div className='themes-text-gray-v5'>Top up your balance or exchange from another available balance to continue. Proceeding will result in a negative balance.</div>
                                        <div className='text-size-M themes-text-red-v3 cursor-pointer hover:underline' onClick={() => modalExchange.openModalHandling()}>
                                            EXCHANGE MONEY
                                        </div>
                                    </div>
                                </div>
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-3'>Top Up Information</div>
                                <FormDatePicker
                                    isRange={false}
                                    name="transaction_date"
                                    placeholder="DD/MM/YYYY"
                                    parentDivClassName="mb-4"
                                    label="transaction date"
                                    useUppercaseLabel
                                />
                                <FormInput
                                    label="Transaction No"
                                    name={"transaction_no"}
                                    placeholder="Auto Generated"
                                    useUppercaseLabel

                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Top Up Method"
                                    placeholder="Select Top Up Method"
                                    name={'top_up_method'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Bank Account"
                                    placeholder="Select Bank Account"
                                    name={'bank_account'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <FileUpload onFileUpload={() => { }} />
                            </div>
                        </FormikProvider>
                    </div>
                </div>
                <div className="flex justify-end px-4 border-t-[1px] gap-2 py-3">
                    <Button
                        onClick={() => { navigate(-1) }}
                        className="w-[220px] "
                        label="CANCEL REQUEST"
                        variant='red'
                    />
                    <Button
                        onClick={() => {
                        }}
                        className="w-[220px]"
                        label="CREATE PAYMENT"
                        variant='brand'
                    />
                </div>
            </div>
            <Modal
                isModalOpen={modalExchange.isModalOpen}
                className="md:w-1/4 mobile:min-w-0 min-w-[500px] right-0  !p-0 md:absolute sticky"
            >
                <div className="flex flex-1 flex-col">
                    {/* Header */}
                    <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                        Money Exchange
                        <div className="flex justify-center items-center pl-4">
                            <i
                                className="ri-close-fill cursor-pointer "
                                onClick={() => {
                                    modalExchange.closeModalHandling()
                                }}
                            ></i>
                        </div>
                    </div>
                    <div className='themes-bg-white flex flex-col items-center px-3 py-5 col-span-7'>
                        <FormikProvider value={formik}>
                            <div className='themes-bg-gray-v1 rounded-lg p-3 mb-5 w-full px-10'>
                                <div className='flex justify-center mb-3 font-bold'><span className='themes-text-brand-v5 mr-1'>See</span> Titipan Balance</div>
                                <div className='flex justify-between mb-3'>
                                    <img
                                        src={idr}
                                        className="h-5 w-7 sm:hidden"
                                        alt={idr}
                                    />
                                    <div>Rp 6.000.000,00</div>
                                </div>
                                <div className='flex justify-between mb-3'>
                                    <img
                                        src={usd}
                                        className="h-5 w-7 sm:hidden"
                                        alt={usd}
                                    />
                                    <div>$500.000</div>
                                </div>
                            </div>
                            <div className='flex text-size-S gap-2'>
                                <div className='themes-text-gray-v5'>TOP UP BALANCE IN</div>
                                <div className="relative inline-block ml-4 group">
                                    <div className=" cursor-pointer themes-text-brand-v5">
                                        {currency}
                                        <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />                                    </div>

                                    <div
                                        className="absolute left-0 w-[100px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                                    >
                                        {currencyOptions.map((item, idx) => {
                                            return (
                                                <div
                                                    key={`item-${idx}`}
                                                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                                    onClick={() => { setCurrency(item.value) }}
                                                >
                                                    {item.label}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-start gap-1 mb-2'>
                                <div className='themes-text-brand-v7 text-[32px] font-semibold'>Rp</div>
                                <FormInput
                                    name={"top_up"}
                                    placeholder="0,00"
                                    parentDivClassName='top-up-input'
                                />
                            </div>
                            <div className='flex items-start gap-3'>
                                <FormDropdown
                                    disabled={false}
                                    label="Top Up Method"
                                    placeholder="Select Top Up Method"
                                    name={'top_up_method'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                                <i className="ri-arrow-left-right-line mt-2 text-[24px]" />
                                <FormDropdown
                                    disabled={false}
                                    label="Top Up Method"
                                    placeholder="Select Top Up Method"
                                    name={'top_up_method'}
                                    useUppercaseLabel
                                    required
                                    options={[]}
                                />
                            </div>
                            <div className='flex gap-2 text-size-S py-3'>
                                <span className='themes-text-gray-v5 font-semibold'> Exchange Rate</span>
                                1$ = Rp.16.000,00
                                <i className="ri-edit-fill themes-text-hover-brand-v5 cursor-pointer" />
                            </div>
                            <div className='border-[1px] w-full mb-3' />
                            <div className='w-full px-2'>
                                <div className='themes-text-grayv-v6 text-size-M font-semibold mb-3'>Top Up Information</div>
                                <FormDatePicker
                                    isRange={false}
                                    name="transaction_date"
                                    placeholder="DD/MM/YYYY"
                                    parentDivClassName="mb-4"
                                    label="transaction date"
                                    useUppercaseLabel
                                />
                                <FormInput
                                    label="EXCHANGE BALANCE"
                                    name={"transaction_no"}
                                    placeholder="Auto Generated"
                                    useUppercaseLabel
                                />
                                <FormInput
                                    label="ADDITIONAL FEE"
                                    name={"transaction_no"}
                                    placeholder="Auto Generated"
                                    useUppercaseLabel
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="EXCHANGE Method"
                                    placeholder="Select Top Up Method"
                                    name={'exchange_method'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormDropdown
                                    disabled={false}
                                    label="Bank Account"
                                    placeholder="Select Bank Account"
                                    name={'bank_account'}
                                    useUppercaseLabel
                                    options={[]}
                                />
                                <FormTextarea
                                    name={'note'}
                                    label={'Note'}
                                    placeholder={'Additional Notes'}
                                />
                            </div>
                        </FormikProvider>
                    </div>
                    <div className="w-full px-4 border-t-[1px] py-3">
                        <Button
                            type="submit"
                            label="CONFIRM MONEY EXCHANGE"
                            variant="brand"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default PaymentDetails
