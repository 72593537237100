import { headerChild, headerParent, tabItem } from './service-rate.static'
import ModalSidebar from '@components/modal-sidebar/modal-sidebar.component'
import TableExpandable from '@components/table-expandable/table-expandable.component'
import TabMaster from '@components/tab-master/tab-master.component'
import { useServiceRates } from './service-rate.service'
import ServiceRateForm from './service-rate-form.component'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IChargeCode } from 'repository/interface/charge-code.interface'
import { ParentOption } from './component/option.component'
const ServiceRate = () => {
    const {
        data,
        formik,
        modalService,
        isNew,
        submitLoading,
        deleteLoading,
        fetchParams,
        isLoading,
        selectRow,
        deleteItem,
        handleSearch,
        setIsNew,
        resetForm,
        addChargeCode,
        removeChargeCode,
    } = useServiceRates()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster
                    items={tabItem}
                    tabFilter={tabItem[0]}
                    onChange={() => {}}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParams.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    actionButton1={{
                        onClick: () => {
                            resetForm()
                            setIsNew(true)
                            modalService.openModalHandling()
                        },
                        label: 'CREATE',
                        className: '!text-size-S',
                    }}
                />

                <TableExpandable<IServiceRate, IChargeCode>
                    childAccessor="charge_codes"
                    data={data}
                    headerChild={headerChild}
                    headerParent={headerParent}
                    moduleTitle={'Service Rate'}
                    loading={isLoading}
                    rowOptionComponent={(rowData, closeHandling) => (
                        <ParentOption
                            closeHandling={closeHandling}
                            rowData={rowData}
                            openModalEdit={selectRow}
                        />
                    )}
                    addItemHandling={() => {
                        resetForm()
                        setIsNew(true)
                        modalService.openModalHandling()
                    }}
                />
            </div>

            <ModalSidebar
                content={
                    <ServiceRateForm
                        formik={formik}
                        submitLoading={submitLoading}
                        deleteHandling={() => deleteItem()}
                        deleteLoading={deleteLoading}
                        isNew={isNew}
                        addChargeCodeHandling={() => addChargeCode()}
                        removeChargeCodeHandling={(index) => {
                            removeChargeCode(index)
                        }}
                    />
                }
                modalService={modalService}
                title={isNew ? 'Create Service Rate' : 'Update  Service Rate:'}
            />
        </div>
    )
}

export default ServiceRate
