import { IMeta3 } from '@components/pagination/pagination.interface'
import { IChartOfAccountSummary } from './chart-of-accounts.interface'

export const ChartOfAccountSummaryData: IChartOfAccountSummary[] = [
    {
        account_code: 'ACC-001',
        account_name: 'Cash',
        category: 'Cash & Bank',
        default_tax: 'PPN',
        balance: 9800,
    },
    {
        account_code: 'ACC-002',
        account_name: 'Accounts Receivable',
        category: 'Current Assets',
        default_tax: 'PPN',
        balance: 15000,
    },
    {
        account_code: 'ACC-003',
        account_name: 'Inventory',
        category: 'Current Assets',
        default_tax: 'PPN',
        balance: 20000,
    },
    {
        account_code: 'ACC-004',
        account_name: 'Prepaid Expenses',
        category: 'Current Assets',
        default_tax: 'PPN',
        balance: 5000,
    },
    {
        account_code: 'ACC-005',
        account_name: 'Fixed Assets',
        category: 'Non-Current Assets',
        default_tax: 'PPN',
        balance: 30000,
    },
    {
        account_code: 'ACC-006',
        account_name: 'Accounts Payable',
        category: 'Current Liabilities',
        default_tax: 'PPN',
        balance: 12000,
    },
    {
        account_code: 'ACC-007',
        account_name: 'Accrued Expenses',
        category: 'Current Liabilities',
        default_tax: 'PPN',
        balance: 8000,
    },
    {
        account_code: 'ACC-008',
        account_name: 'Short-Term Loans',
        category: 'Current Liabilities',
        default_tax: 'PPN',
        balance: 10000,
    },
    {
        account_code: 'ACC-009',
        account_name: 'Long-Term Loans',
        category: 'Non-Current Liabilities',
        default_tax: 'PPN',
        balance: 25000,
    },
    {
        account_code: 'ACC-010',
        account_name: 'Owner’s Equity',
        category: 'Equity',
        default_tax: 'PPN',
        balance: 40000,
    },
    {
        account_code: 'ACC-011',
        account_name: 'Retained Earnings',
        category: 'Equity',
        default_tax: 'PPN',
        balance: 35000,
    },
    {
        account_code: 'ACC-012',
        account_name: 'Sales Revenue',
        category: 'Revenue',
        default_tax: 'PPN',
        balance: 45000,
    },
    {
        account_code: 'ACC-013',
        account_name: 'Service Revenue',
        category: 'Revenue',
        default_tax: 'PPN',
        balance: 30000,
    },
    {
        account_code: 'ACC-014',
        account_name: 'Cost of Goods Sold',
        category: 'Expenses',
        default_tax: 'PPN',
        balance: 25000,
    },
    {
        account_code: 'ACC-015',
        account_name: 'Operating Expenses',
        category: 'Expenses',
        default_tax: 'PPN',
        balance: 20000,
    },
    {
        account_code: 'ACC-016',
        account_name: 'Interest Expense',
        category: 'Expenses',
        default_tax: 'PPN',
        balance: 5000,
    },
]

export const metaDummy: IMeta3 = {
    page: 1,
    size: 16,
    total_data: 10,
    data_count: 10,
    total_page: 1,
    is_first: true,
    is_last: true,
    start: 1,
    end: 10,
}
