import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IServiceRate } from './interface/service-rate.interface'
import { IFServiceRates } from 'form-validation/service-rate.validation'

export const getServiceRate = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.serviceRate}`
        const response = await fetch<
            IResponseData<IServiceRate[]>,
            IFetchParams
        >({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Service Rate',
            })
        }
        return undefined
    }
}

export const submitServiceRate = async (values: IFServiceRates) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.serviceRate}`
        await post<IResponseData<any>, IFServiceRates>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Service Rate is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Service Rate',
        })
        return undefined
    }
}

export const updateServiceRate = async (values: IFServiceRates) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.serviceRate}/${code}`
        await put<IResponseData<any>, IFServiceRates>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Service Rate is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Service Rate',
        })
        return undefined
    }
}

export const deleteServiceRate = async (code: string) => {
    try {
        const endpoint = `${endpoints.serviceRate}/${code}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Service Rate is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Service Rate',
        })
        return undefined
    }
}
