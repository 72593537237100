import { useFormik } from 'formik'
import { useFormTransactionNoValidation } from './form-transaction-no.validation'
import { IFormTransactionNo } from './form-transaction-no.interface'

const useFormTransactionNo = () => {
    const formTransactionNoValidation = useFormTransactionNoValidation()
    const formik = useFormik<IFormTransactionNo>({
        validationSchema: formTransactionNoValidation.schema,
        validateOnChange: true,
        initialValues: formTransactionNoValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormTransactionNo

