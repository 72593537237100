import { useState, useEffect } from 'react'
import { FormikContextType, useFormik } from 'formik'
import { IAddress, ICapabilities } from '../../organisation-details.interface'
import {
    createOrganisationAddress,
    deleteOrganisationAddress,
    getAddressCapabilitiesData,
    getOrganisationAddressData,
    updateOrganisationAddress,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
    citiesDataSelector,
    citiesIsLoadingSelector,
    countriesDataSelector,
    countriesIsLoadingSelector,
    statesDataSelector,
    statesIsLoadingSelector,
    unlocodesDataSelector,
    unlocodesIsLoadingSelector,
} from 'pages/organisation/organisation.slice'
import {
    addressFormInitial,
    addressFormValidation,
    IFormAddress,
} from 'form-validation/organisation-address.validation'
import useDropdown from 'common/dropdown/dropdown.service'
import { useModal } from '@components/modal/modal.service'
import {
    fetchCities,
    fetchCountries,
    fetchStates,
} from 'repository/dropdown.repository'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'

const useAddressListService = () => {
    const { id = 'not found' } = useParams()

    const unlocodes = useSelector(unlocodesDataSelector)
    const isLoadingCountries = useSelector(countriesIsLoadingSelector)
    const isLoadingStates = useSelector(statesIsLoadingSelector)
    const isLoadingCities = useSelector(citiesIsLoadingSelector)
    const isLoadingUnlocodes = useSelector(unlocodesIsLoadingSelector)
    const [addressSelected, setAddressSelected] = useState<IFormAddress>()

    const [addressListData, setAddressListData] = useState<IAddress[]>([])
    const [capabilitiesData, setCapabilitiesData] = useState<ICapabilities[]>(
        [],
    )
    const [addressDetail, setAddressDetail] = useState<IFormAddress>()
    const [loading, setLoading] = useState<boolean>(false)
    const [countries, setCountries] = useState<IDropdownItem<ICountries>[]>()
    const [cities, setCities] = useState<IDropdownItem<ICities>[]>()
    const [states, setStates] = useState<IDropdownItem<IStates>[]>()
    const dropdownService = useDropdown()
    const createOrEditAddressModal = useModal()
    const [activeTab, setActiveTab] = useState('addressDetail')

    const formikOrgAddressDetail = useFormik<IFormAddress>({
        initialValues: addressFormInitial,
        validationSchema: addressFormValidation,
        onSubmit: async (values) => {
            if (id) {
                const formatedAddress = {
                    ...values,
                    capabilities: convertCapabilitiesToArray(
                        values?.capabilities,
                    ),
                }
                const res = await updateOrganisationAddress(id, formatedAddress)

                if (res) {
                    await loadAddressList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateAddress = useFormik<IFormAddress>({
        initialValues: addressFormInitial,
        validationSchema: addressFormValidation,
        onSubmit: async (values) => {
            if (id) {
                const formatedAddress = {
                    ...values,
                    capabilities: convertCapabilitiesToArray(
                        values?.capabilities,
                    ),
                }
                const res = await createOrganisationAddress(id, formatedAddress)
                if (res) {
                    await loadAddressList()
                }
            }
            createOrEditAddressModal.closeModalHandling()
        },
        enableReinitialize: true,
    })

    const convertCapabilitiesToArray = (capabilitiesObj?: {
        [key: string]: boolean
    }): string[] => {
        return Object.entries(capabilitiesObj || {})
            .filter(([_, value]) => value)
            .map(([key]) => key)
    }

    const convertArrayToCapabilitiesObject = (
        capabilitiesArray?: string[],
    ): { [key: string]: boolean } => {
        return (capabilitiesArray || []).reduce(
            (acc, key) => {
                acc[key] = true
                return acc
            },
            {} as { [key: string]: boolean },
        )
    }

    // useEffect
    useEffect(() => {
        loadAddressList()
        return () => {
            setAddressListData([])
        }
    }, [])

    const handleAddressDetail = (data: IAddress) => {
        const formatedAddress = {
            ...data,
            capabilities: convertArrayToCapabilitiesObject(data?.capabilities),
        }
        setAddressDetail(formatedAddress)
        setAddressSelected(formatedAddress)
        formikOrgAddressDetail.setValues(formatedAddress)
    }

    const handleDuplicate = async () => {
        if (id && addressDetail) {
            const formatedAddress = {
                ...addressDetail,
                capabilities: convertCapabilitiesToArray(
                    addressDetail?.capabilities,
                ),
            }
            const res = await createOrganisationAddress(id, formatedAddress)
            if (res) {
                loadAddressList()
            }
        }
    }
    const loadAddressList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationAddressData(id)
            const responseCapabilities = await getAddressCapabilitiesData()

            setAddressListData(response.data as IAddress[])
            setCapabilitiesData(responseCapabilities.data as ICapabilities[])

            if (response?.data?.length > 0) {
                const formatedAddress = {
                    ...response.data?.[0],
                    capabilities: convertArrayToCapabilitiesObject(
                        response.data?.[0]?.capabilities,
                    ),
                }
                formikOrgAddressDetail.setValues(formatedAddress)
                setAddressDetail(formatedAddress)
                setAddressSelected(formatedAddress)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteAddress = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationAddress(id, dataId)
            const filterData = addressListData.filter(
                (data) => data?.code !== dataId,
            )
            setAddressListData(filterData)

            if (filterData?.length > 0) {
                const formatedAddress = {
                    ...filterData[0],
                    capabilities: convertArrayToCapabilitiesObject(
                        filterData[0]?.capabilities,
                    ),
                }
                formikOrgAddressDetail.setValues(formatedAddress)
                setAddressDetail(formatedAddress)
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const getCountries = async () => {
        const response = await fetchCountries()
        if (!response) return
        const dropdown: IDropdownItem<ICountries>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setCountries(dropdown)
        return dropdown
    }

    const getStates = async (country: string) => {
        const response = await fetchStates(country)
        if (!response) return
        const dropdown: IDropdownItem<IStates>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setStates(dropdown)
    }

    const getCities = async (state?: string) => {
        const response = await fetchCities(state)
        if (!response) return
        const dropdown: IDropdownItem<ICities>[] = response.data.map(
            (d: ICities) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setCities(dropdown)
    }

    useEffect(() => {
        getCountries()
        dropdownService.getUnlocos()
    }, [])

    return {
        addressDetail,
        setAddressDetail,
        handleAddressDetail,
        useDropdown,
        dropdownService,
        addressListData,
        deleteAddress,
        setAddressListData,
        activeTab,
        setActiveTab,
        capabilitiesData,
        loading,
        formikOrgAddressDetail,
        formikOrgCreateAddress,
        countries,
        states,
        createOrEditAddressModal,
        addressSelected,
        cities,
        unlocodes,
        isLoadingCities,
        isLoadingCountries,
        isLoadingStates,
        isLoadingUnlocodes,
        handleDuplicate,
        getCountries,
        setCountries,
        getCities,
        getStates,
    }
}

export default useAddressListService
