import { FieldArray } from 'formik'
import { IFormLineCreateCashAndBank, ICBFormLines } from '../create-cash-and-bank.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { ProductItem, TaxItem } from '../create-cash-and-bank.dummy'

const CBLinePrice = ({
    formikProps,
    modalServiceTax,
}: ICBFormLines) => {
    const { values } = formikProps
    const { linePrice } = values
    return (
        <FieldArray
            name="linePrice"
            render={(arrayHelpers) => {
                return (
                    <div>
                        <div className="overflow-x-scroll form-line-price-table">
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr className="text-muted">
                                            <td>Payment For Expense</td>
                                            <td>Description</td>
                                            <td>Tax</td>
                                            <td>Amount</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linePrice.map(
                                            (linePrice: IFormLineCreateCashAndBank, index: number) => {
                                                const prefixName = `linePrice.${index}.`
                                                return (
                                                    <tr key={`form-line-so-` + index}>
                                                        <td>
                                                            <FormDropdown
                                                                name={prefixName + 'payment'}
                                                                placeholder="Select Expense Account"
                                                                options={ProductItem}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "description"}
                                                                placeholder="Description"
                                                                value={linePrice.description}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormDropdown
                                                                name={prefixName + 'tax'}
                                                                placeholder="Tax"
                                                                options={TaxItem}
                                                                isClearable
                                                                isSearchable
                                                                additionalButton={{
                                                                    title: ' + Add New Tax',
                                                                    className: "themes-bg-white themes-text-gray-v6 themes-bg-hover-brand-v3 text-size-S flex justify-center py-2 cursor-pointer border-[1px] rounded-md mt-1",
                                                                    onClick: () => {
                                                                        modalServiceTax.openModalHandling()
                                                                    }
                                                                }}
                                                            />

                                                        </td>
                                                        <td>
                                                            <FormInput
                                                                name={prefixName + "amount"}
                                                                type='number'
                                                                placeholder="0,00"
                                                                value={linePrice.amount}
                                                                descriptionValue={values.currency}
                                                            />
                                                        </td>
                                                        <td className='flex items-center justify-end'>
                                                            {values.linePrice.length !== 1 && (
                                                                <i
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    className="ri-delete-bin-line themes-text-red-v3  themes-border-red-v3  themes-text-hover-white themes-bg-hover-red-v3  the border-[1px] text-size-L p-2   rounded-md cursor-pointer" />

                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default CBLinePrice
