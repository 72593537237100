import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormCreateCashAndBankInternal } from './create-cash-and-bank-internal.interface'

export const useCreateCashAndBankInternalValidation = () => {
    const schema = Yup.object<IFormCreateCashAndBankInternal>().shape({
        pay_from: Yup.string().required(requiredMessage('Pay From')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormCreateCashAndBankInternal = {
            transaction_date: '',
            transaction_no: '',
            transfer_form: '',
            deposit_to: '',
            amount: 0,
            currency: '',
            memo: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
