import Button from '@components/button/button.component'
import { IServiceRate } from 'repository/interface/service-rate.interface'

export const ParentOption = ({
    rowData,
    closeHandling,
    openModalEdit,
}: {
    rowData: IServiceRate
    closeHandling: () => void
    openModalEdit: (data: IServiceRate) => void
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
                openModalEdit(rowData)
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit"
            icon="ri-pencil-line"
        />
    </div>
)
