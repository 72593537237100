import { ILoginValidation } from 'form-validation/login.validation'
import {
    IUser,
    IUserActivities,
    IUserDetail,
    IUserUpdate,
} from './interface/user.interface'
// import json from './static-data/dummy-user.json'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { errorAxiosToast, fetch, post, put } from 'common/common.service'

export const postLogin = async (payload: ILoginValidation) => {}

export const getUserData = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.users.data}`
        const response = await fetch<IResponseData<IUser[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Users Data',
            })
        }
        return undefined
    }
}

// export const changePassword = async (payload: any) => {
//     try {
//         await put<IResponseData<any>, IChangePasswordValidation>({
//             endpoint: endpoints.common.staff_changePassword,
//             payload,
//         })
//         Toast({
//             header: 'Success',
//             message: 'Success Update Password',
//             type: 'success',
//         })
//         return 'success'
//     } catch (error: any) {
//         Toast({
//             header: 'Failed!',
//             message: error?.response?.data ?? 'Failed Update Password',
//             type: 'error',
//         })
//         return error.response.data as string
//     }
// }

export const createUser = async (
    payload: IUserUpdate,
    showErrorToast?: boolean,
) => {
    try {
        const response = await post<IResponseData<any>, IUserUpdate>({
            endpoint: endpoints.users.data,
            payload: payload,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed!',
                message: 'Failed Create Users ',
            })
        }
        return undefined
    }
}

export const updateUser = async (
    id: string | undefined,
    payload: IUserUpdate,
    showErrorToast?: boolean,
) => {
    try {
        const response = await put<IResponseData<any>, IUserUpdate>({
            endpoint: endpoints.users.data + '/' + id,
            payload: payload,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed!',
                message: 'Failed Update Users Data',
            })
        }
        return undefined
    }
}

export const getUserDetail = async (
    id: string | undefined,
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<IResponseData<IUserDetail>, IFetchParams>({
            endpoint: endpoints.users.data + '/' + id,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Users Data',
            })
        }
        return undefined
    }
}

export const getUserSelfDetail = async (showErrorToast?: boolean) => {
    try {
        const response = await fetch<IResponseData<IUserDetail>, IFetchParams>({
            endpoint: endpoints.users.data + '/' + endpoints.users.self,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Users Data',
            })
        }
        return undefined
    }
}

export const getUserActivityLogs = async (
    id: string | undefined,
    showErrorToast?: boolean,
) => {
    const url: string = endpoints.users.data + '/' + id + '/activity_logs'
    try {
        const response = await fetch<IResponseData<IUserActivities[]>, null>({
            endpoint: url,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Users Activity Logs',
            })
        }
        return undefined
    }
}

export const deactiveUser = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await put<IResponseData<any>, IFetchParams>({
            endpoint:
                endpoints.users.data +
                '/' +
                params.code +
                '/' +
                endpoints.users.deactive,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed!',
                message: 'Failed to Deactivate User',
            })
        }
        return undefined
    }
}

export const activateUser = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await put<IResponseData<any>, IFetchParams>({
            endpoint:
                endpoints.users.data +
                '/' +
                params.code +
                '/' +
                endpoints.users.activate,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed!',
                message: 'Failed to Activate User',
            })
        }
        return undefined
    }
}
// export const updateModuleUser = async (uuid: string, payload: IFRoleModule) => {
//     try {
//         const response = await put<IResponseData<any>, IFRoleModule>({
//             endpoint: endpoints.common.staff_updateModule + '/' + uuid,
//             payload: payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const unSuspendUser = async (uuid: string) => {
//     try {
//         const response = await put<IResponseData<any>, { uuid: string }>({
//             endpoint: endpoints.common.staff_unsuspend + '/' + uuid,
//             params: { uuid },
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const forgotPassword = async (payload: IForgotPassword) => {
//     try {
//         const response = await post<IResponseData<any>, IForgotPassword>({
//             endpoint: endpoints.common.staff_forgotPassword,
//             params: payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const postResetPassword = async (payload: IResetPasswordValidation) => {
//     try {
//         const response = await post<
//             IResponseData<any>,
//             IResetPasswordValidation
//         >({
//             endpoint: endpoints.common.staff_resetPassword,
//             payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const getUserCurrent = async () => {
//     try {
//         const response = await fetch<IResponseData<any>, IUserAccessFilter>({
//             endpoint: endpoints.common.staff_current,
//         })
//         return response
//     } catch (error: any) {
//         console.log(error?.message)
//     }
// }
