import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormCreatePurchasesAndExpenses, IFormLineCreatePurchasesAndExpenses } from './create-purchases-and-expenses.interface'

export const formLineInitial: IFormLineCreatePurchasesAndExpenses = {
    product: '',
    description: '',
    qty: '',
    unit: '',
    unit_price: '',
    tax: '',
    amount: '',
}

export const useCreatePurchasesAndExpensesValidation = () => {
    const schema = Yup.object<IFormCreatePurchasesAndExpenses>().shape({
        customer: Yup.string().required(requiredMessage('Customer')),
        email: Yup.string().required(requiredMessage('Email')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormCreatePurchasesAndExpenses = {
            vendor: '',
            email: '',
            billing_address: '',
            file_number: '',
            transaction_date: '',
            due_date: '',
            term: '',
            transaction_no: '',
            warehouse: '',
            customer_reference_no: '',
            currency: 'Rp',
            price_include_tax: false,
            withholding_tax: {
                name_price: '',
                withholding_data: false,
                withholding_discount: { qty: '', unit: '%', unitLabel: '%' },
                total_withholding_discount: 0,
            },
            massage: '',
            memo: '',
            total: 0,
            sub_total: 0,
            total_tax: 0,
            balance_due: 0,
            linePrice: [formLineInitial],
            tax_per_line: [],
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
