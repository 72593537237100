import { useFormik } from 'formik'
import { useEffect, useState } from 'react';
import { IFormCreateCashAndBankInternal } from './create-cash-and-bank-internal.interface';
import { useCreateCashAndBankInternalValidation } from './create-cash-and-bank-internal.validation';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@components/modal/modal.service';

const useCreateCashAndBankInternal = () => {
    const [rate, setRate] = useState(16000);

    const navigate = useNavigate()
    const modalServiceTransactionNo = useModal()
    // formik
    const salesValidation = useCreateCashAndBankInternalValidation()
    const formik = useFormik<IFormCreateCashAndBankInternal>({
        validationSchema: salesValidation.schema,
        validateOnChange: true,
        initialValues: salesValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })
    const { values } = formik

    // function to update exchange rate based on currency
    const updateExchangeRate = () => {
        switch (values.currency) {
            case '$':
                setRate(16000);
                break;
            case 'CN¥':
                setRate(2400);
                break;
            case '€':
                setRate(18000);
                break;
            case 'JP¥':
                setRate(110);
                break;
            case 'AU$':
                setRate(12000);
                break;
            default:
                setRate(16000);
        }
    };

    // Use Effect for updating exchange rate and optionsDiscount based on currency
    useEffect(() => {
        updateExchangeRate();
    }, [values.currency]);

    return {
        modalServiceTransactionNo,
        navigate,
        formik,
        rate,
    }
}

export default useCreateCashAndBankInternal
