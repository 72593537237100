import Button from '@components/button/button.component'
import SOLinePriceDetails from './line-price-details/line-price-details.component'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@components/modal/modal.service'
import Modal from '@components/modal/modal.component'
import JurnalReport from './jurnal-report/jurnal-report.component'
import FormShareEmail from './share-email/share-email.component'
import useDetailCashAndBackForm from './details.sales.service'
import { ReceiveMoneyDoc } from 'pages/cash-bank/docs/receive-money/receive-money-doc.component'

const DetailCashAndBackForm = () => {
    const navigate = useNavigate()
    const modalViewJurnal = useModal()
    const modalShareEmail = useModal()

    const { componentRef, reactToPrintContent, handlePrint } =
        useDetailCashAndBackForm()

    const MoreOptions = () => {
        return (
            <div className="relative inline-block ml-4 group">
                <div
                    className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                        w-[200px] h-[38px] rounded-md flex justify-center items-center text-size-M font-semibold 
                        cursor-pointer"
                >
                    MORE OPTION
                    <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                </div>

                <div
                    className="absolute bottom-full left-0 mb-2 w-[200px] bg-white shadow-lg rounded-md overflow-hidden 
                        opacity-0 invisible translate-y-[10px] transition-all duration-300 ease-in-out 
                        group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                >
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {}}
                    >
                        Set as Recurring
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                            handlePrint(reactToPrintContent)
                        }}
                    >
                        Print and Preview
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {}}
                    >
                        Edit
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => modalShareEmail.openModalHandling()}
                    >
                        Share via Email
                    </div>
                </div>
                {/* TODO between receive money doc or bit doc */}
                <div className="hidden">
                    <ReceiveMoneyDoc ref={componentRef} />
                </div>
            </div>
        )
    }
    return (
        <div className="container-global content-full-height">
            <div className="px-4 py-2">
                <div className="flex items-start">
                    <div>
                        <div className="themes-text-gray-v6 text-size-XS font-bold mb-1">
                            Transaction
                        </div>
                        <div className="themes-text-brand-v6 text-size-L font-bold">
                            RECEIVE MONEY
                        </div>
                    </div>
                </div>
            </div>
            <div className=" h-[calc(100vh-14.2rem)] overflow-auto">
                <div className="flex justify-between px-4 py-4 themes-bg-brand-v1">
                    <div className="flex flex-col">
                        <div className="grid grid-cols-1 gap-2">
                            <div className="text-size-S grid grid-cols-3 gap-2">
                                <div className="font-semibold themes-text-gray-v5">
                                    Deposit To
                                </div>
                                <div>(1-10001) Cash (Cash & Bank)</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[410px]">
                        <div className="font-semibold flex text-size-XL pl-2">
                            Total
                            <span className="ml-3 themes-text-brand-v7">
                                Rp. 0,00
                            </span>
                        </div>
                        <div
                            onClick={() => modalViewJurnal.openModalHandling()}
                            className="themes-text-brand-v5 cursor-pointer hover:underline ml-3 font-semibold"
                        >
                            View Jurnal Entry
                        </div>
                    </div>
                </div>
                <div className="flex justify-between gap-2 px-4 py-4">
                    <div className="flex flex-col gap-3">
                        <div className="text-size-M font-semibold">
                            Payer Information
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-size-S grid grid-cols-3 gap-2">
                                <div className="font-semibold themes-text-gray-v5">
                                    Payer Contact
                                </div>
                                <div>Emily Cooper</div>
                            </div>
                            <div className="text-size-S grid grid-cols-3 gap-2">
                                <div className="font-semibold themes-text-gray-v5">
                                    Transaction Date
                                </div>
                                <div>14/01/25</div>
                            </div>
                            <div className="text-size-S grid grid-cols-3 gap-2">
                                <div className="font-semibold themes-text-gray-v5">
                                    Transaction No
                                </div>
                                <div>10010</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 w-[400px]">
                        <div className="text-size-M font-semibold">
                            Attach Document
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                                <i className="ri-image-line themes-text-brand-v5 text-[40px]" />
                                <div>
                                    <div>Product_Image1.jpg</div>
                                    <div>500 KB</div>
                                </div>
                            </div>
                            <i className="ri-download-2-line text-[20px]" />
                        </div>
                    </div>
                </div>
                <div className="px-4 mb-4">
                    <div className="border-[1px] rounded-md themes-bg-brand-v1">
                        <SOLinePriceDetails />
                        <div className="flex justify-between  themes-bg-white border-t-[1px] rounded-md p-4">
                            <div className="w-[300px]">
                                <div className="flex flex-col gap-3">
                                    <div className="text-size-S grid grid-cols-3 gap-2">
                                        <div className="font-semibold themes-text-gray-v5">
                                            Memo
                                        </div>
                                        <div>
                                            Please check this file Admin A
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[400px] flex flex-col gap-2">
                                <div className="flex justify-between">
                                    <div className="text-size-S font-semibold themes-text-gray-v4">
                                        Sub Total
                                    </div>
                                    <div className="text-size-M font-semibold">
                                        Rp. 0,00
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className="text-size-S font-semibold themes-text-gray-v4">
                                        PPN
                                    </div>
                                    <div className="text-size-S">Rp. 0,00</div>
                                </div>
                                <div className="flex justify-between">
                                    <div className="text-[18px] font-semibold themes-text-gray-v4">
                                        Total
                                    </div>
                                    <div className="text-[18px] font-semibold">
                                        Rp. 0,00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between px-4 border-t-[1px] py-3">
                <Button
                    label="CENCEL"
                    variant="default"
                    className="w-[150px]"
                    onClick={() => {
                        navigate('/cash-bank')
                    }}
                />

                <div>
                    <Button
                        label="DELETE"
                        variant="brand-inverse"
                        className="w-[150px]"
                        onClick={() => {}}
                    />
                    {MoreOptions()}
                </div>
            </div>
            <Modal
                isModalOpen={modalViewJurnal.isModalOpen}
                className=" mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div className="flex flex-1 flex-col">
                    {/* Header */}
                    <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                        Jurnal Report Bank Internal Transfer #10004
                        <div className="flex justify-center items-center pl-4">
                            <i
                                className="ri-close-fill cursor-pointer "
                                onClick={() => {
                                    modalViewJurnal.closeModalHandling()
                                }}
                            ></i>
                        </div>
                    </div>
                    <JurnalReport />
                </div>
            </Modal>
            <Modal
                isModalOpen={modalShareEmail.isModalOpen}
                className="md:w-1/2 mobile:min-w-0 min-w-[500px] !p-0 md:absolute sticky"
            >
                <div className="flex flex-1 flex-col">
                    {/* Header */}
                    <div className="flex font-bold text-size-L justify-between p-4 items-center border-b">
                        Share Via Email
                        <div className="flex justify-center items-center pl-4">
                            <i
                                className="ri-close-fill cursor-pointer "
                                onClick={() => {
                                    modalShareEmail.closeModalHandling()
                                }}
                            ></i>
                        </div>
                    </div>
                    <FormShareEmail modalService={modalShareEmail} />
                </div>
            </Modal>
        </div>
    )
}
export default DetailCashAndBackForm
