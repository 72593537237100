import { jsx as _jsx } from "react/jsx-runtime";
import './radio.style.css';
function Radio(_a) {
    var _b = _a.useFormik, useFormik = _b === void 0 ? false : _b, name = _a.name, value = _a.value, onChecked = _a.onChecked, disabled = _a.disabled, className = _a.className, formikOnChange = _a.formikOnChange;
    var handleChange = function (e) {
        var val = e.target.value;
        if (useFormik && formikOnChange) {
            // If it's a Formik radio , trigger Formik's onChange
            formikOnChange(e);
        }
        else {
            // Otherwise, handle local state change
            onChecked && onChecked(val);
        }
    };
    return (_jsx("input", { className: "cursor-pointer loglines-radio themes-border-color ".concat(className), value: value, type: "radio", name: name, disabled: disabled, onChange: handleChange }));
}
export default Radio;
