import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormCreateSales, IFormLineCreateSales } from './create-sales.interface'

export const formLineInitial: IFormLineCreateSales = {
    product: '',
    description: '',
    qty: '',
    unit: '',
    unit_price: '',
    discount: { qty: '', unit: '', unitLabel: '' },
    tax: '',
    amount: '',
}

export const useCreateSalesValidation = () => {
    const schema = Yup.object<IFormCreateSales>().shape({
        customer: Yup.string().required(requiredMessage('Customer')),
        email: Yup.string().required(requiredMessage('Email')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormCreateSales = {
            customer: '',
            email: '',
            billing_address: '',
            type_number: '',
            file_number: '',
            transaction_date: '',
            due_date: '',
            term: '',
            transaction_no: '',
            warehouse:'',
            customer_reference_no: '',
            currency: 'Rp',
            additional_discount: { qty: '', unit: '%', unitLabel: '%' },
            total_additional_discount: 0,
            mark_as_deposit: false,
            price_include_tax: false,
            withholding_tax: {
                name_price:'',
                withholding_data: false,
                withholding_discount:  { qty: '', unit: '%', unitLabel: '%' },
                total_withholding_discount: 0,
            },
            massage: '',
            memo: '',
            total: 0,
            sub_total: 0,
            discount_per_lines: '',
            total_tax: 0,
            balance_due: 0,
            linePrice: [formLineInitial],
            tax_per_line: []
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
