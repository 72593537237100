import Button from '@components/button/button.component'
import { ITitipan } from '../../titipan.interface'

export const RowOption = ({
    rowData,
    closeHandling,
}: {
    rowData: ITitipan
    closeHandling: () => void
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Genereate Report"
            icon="ri-upload-2-line"
        />
    </div>
)