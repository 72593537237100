var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DayPicker } from 'react-day-picker';
import DatePickerHeader from './date-picker-header.component';
import { useDatePicker } from './date-picker.service';
import './date-picker.style.css';
import DatePickerInput from './date-picker-input.component';
import { useState, useEffect } from 'react';
var DatePicker = function (_a) {
    var _b = _a.isRange, isRange = _b === void 0 ? false : _b, props = __rest(_a, ["isRange"]);
    var _c = useDatePicker({
        selectedRange: props === null || props === void 0 ? void 0 : props.selectedRange,
        selectedDate: props === null || props === void 0 ? void 0 : props.selectedDate,
        isRange: isRange,
    }), dropdownRef = _c.dropdownRef, showDatePicker = _c.showDatePicker, date = _c.date, rangeTemporaryValue = _c.rangeTemporaryValue, isFutureDate = _c.isFutureDate, isPastDate = _c.isPastDate, setShowDatePicker = _c.setShowDatePicker, changeDateHandler = _c.changeDateHandler, adjustDateRange = _c.adjustDateRange;
    var _d = useState(false), isAbove = _d[0], setIsAbove = _d[1];
    var disabledBackground = props.disable
        ? 'themes-bg-gray-v5'
        : 'themes-bg-white';
    useEffect(function () {
        if (dropdownRef.current) {
            var rect = dropdownRef.current.getBoundingClientRect();
            var spaceBelow = window.innerHeight - rect.bottom;
            var spaceAbove = rect.top;
            setIsAbove(spaceBelow < 300 && spaceAbove > spaceBelow);
        }
    }, [showDatePicker]);
    return (_jsx("div", __assign({ className: "w-full" }, { children: _jsxs("div", __assign({ ref: dropdownRef, className: "w-full ".concat(isAbove ? 'relative' : '') }, { children: [_jsx(DatePickerInput, __assign({ isRange: isRange, setShowDatePicker: setShowDatePicker, rangeTemporaryValue: rangeTemporaryValue }, props)), showDatePicker && (_jsx("div", __assign({ className: "".concat(isRange && 'flex justify-center', " w-fit") }, { children: _jsx("div", __assign({ className: "pickerStyle ".concat(disabledBackground, " ").concat(props.classNamesDisplayDate, " absolute ").concat(!isRange ? '' : 'left-[50%] -translate-x-1/2', " ").concat(isAbove ? 'bottom-full mb-2' : '') }, { children: !isRange ? (_jsx(DayPicker, { month: new Date(date), disabled: !props.disableDays
                                ? undefined
                                : props.disableDays === 'future'
                                    ? isFutureDate
                                    : isPastDate, onDayClick: function (date) {
                                (props === null || props === void 0 ? void 0 : props.setSelectedDate) &&
                                    props.setSelectedDate(date);
                            }, selected: props.selectedDate, components: {
                                Caption: function (props) {
                                    return DatePickerHeader(__assign(__assign({}, props), { changeDateHandler: changeDateHandler }));
                                },
                            } })) : (_jsx(DayPicker, { month: new Date(date), mode: 'range', disabled: !props.disableDays
                                ? undefined
                                : props.disableDays === 'future'
                                    ? isFutureDate
                                    : isPastDate, components: {
                                Caption: function (props) {
                                    return DatePickerHeader(__assign(__assign({}, props), { changeDateHandler: changeDateHandler }));
                                },
                            }, selected: rangeTemporaryValue, onSelect: function (range) {
                                var adjusted = adjustDateRange(range);
                                if (!adjusted)
                                    return;
                                props.setSelectedRange &&
                                    props.setSelectedRange(adjusted);
                            } })) })) })))] })) })));
};
export default DatePicker;
