import { IMeta } from '@components/pagination/pagination.interface'
import { IPaymentRequest, ITitipan, ITitipanDetails } from './titipan.interface'

export const dummyTitipan: ITitipan[] = [
    {
        accountName: 'John Stark',
        accountNumber: '1234 5612 8920',
        lastTransaction: '19/01/2025, 15:00',
        balanceUsd: '$500,000',
        balanceIdr: 'Rp. 60.520.000,00',
    },
    {
        accountName: 'Jane Doe',
        accountNumber: '5678 1234 5678',
        lastTransaction: '20/01/2025, 10:30',
        balanceUsd: '$300,000',
        balanceIdr: 'Rp. 36.312.000,00',
    },
    {
        accountName: 'Alice Johnson',
        accountNumber: '8765 4321 8765',
        lastTransaction: '21/01/2025, 12:45',
        balanceUsd: '$150,000',
        balanceIdr: 'Rp. 18.156.000,00',
    },
    {
        accountName: 'Bob Smith',
        accountNumber: '4321 8765 4321',
        lastTransaction: '22/01/2025, 14:20',
        balanceUsd: '$250,000',
        balanceIdr: 'Rp. 30.260.000,00',
    },
    {
        accountName: 'Charlie Brown',
        accountNumber: '1234 5678 1234',
        lastTransaction: '23/01/2025, 09:15',
        balanceUsd: '$400,000',
        balanceIdr: 'Rp. 48.416.000,00',
    },
    {
        accountName: 'David Wilson',
        accountNumber: '5678 8765 5678',
        lastTransaction: '24/01/2025, 11:50',
        balanceUsd: '$350,000',
        balanceIdr: 'Rp. 42.468.000,00',
    },
    {
        accountName: 'Eve Davis',
        accountNumber: '8765 1234 8765',
        lastTransaction: '25/01/2025, 13:35',
        balanceUsd: '$200,000',
        balanceIdr: 'Rp. 24.208.000,00',
    },
    {
        accountName: 'Frank Miller',
        accountNumber: '4321 5678 4321',
        lastTransaction: '26/01/2025, 16:10',
        balanceUsd: '$450,000',
        balanceIdr: 'Rp. 54.468.000,00',
    },
    {
        accountName: 'Grace Lee',
        accountNumber: '1234 8765 1234',
        lastTransaction: '27/01/2025, 08:25',
        balanceUsd: '$600,000',
        balanceIdr: 'Rp. 72.624.000,00',
    },
    {
        accountName: 'Hank Green',
        accountNumber: '5678 4321 5678',
        lastTransaction: '28/01/2025, 10:40',
        balanceUsd: '$100,000',
        balanceIdr: 'Rp. 12.104.000,00',
    },
    {
        accountName: 'Ivy White',
        accountNumber: '8765 8765 8765',
        lastTransaction: '29/01/2025, 12:55',
        balanceUsd: '$700,000',
        balanceIdr: 'Rp. 84.728.000,00',
    },
    {
        accountName: 'Jack Black',
        accountNumber: '4321 1234 4321',
        lastTransaction: '30/01/2025, 14:05',
        balanceUsd: '$800,000',
        balanceIdr: 'Rp. 96.832.000,00',
    },
    {
        accountName: 'Karen Brown',
        accountNumber: '1234 4321 1234',
        lastTransaction: '31/01/2025, 09:45',
        balanceUsd: '$900,000',
        balanceIdr: 'Rp. 108.936.000,00',
    },
    {
        accountName: 'Leo King',
        accountNumber: '5678 1234 5678',
        lastTransaction: '01/02/2025, 11:30',
        balanceUsd: '$1,000,000',
        balanceIdr: 'Rp. 121.040.000,00',
    },
    {
        accountName: 'Mia Queen',
        accountNumber: '8765 4321 8765',
        lastTransaction: '02/02/2025, 13:20',
        balanceUsd: '$1,100,000',
        balanceIdr: 'Rp. 133.144.000,00',
    },
]

export const dummyPaymentRequest: IPaymentRequest[] = [
    {
        requestPaymentNo: 'REQ0099',
        customerName: 'John Stark',
        fileNo: 'S032410401',
        currency: 'USD',
        totalPayment: '$ 50,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 414',
        paymentStatus: 'open', // open, close, cancelled
    },
    {
        requestPaymentNo: 'REQ0100',
        customerName: 'Jane Doe',
        fileNo: 'S032410402',
        currency: 'USD',
        totalPayment: '$ 30,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 415',
        paymentStatus: 'close',
    },
    {
        requestPaymentNo: 'REQ0101',
        customerName: 'Alice Johnson',
        fileNo: 'S032410403',
        currency: 'USD',
        totalPayment: '$ 20,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 416',
        paymentStatus: 'cancelled',
    },
    {
        requestPaymentNo: 'REQ0102',
        customerName: 'Bob Smith',
        fileNo: 'S032410404',
        currency: 'USD',
        totalPayment: '$ 40,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 417',
        paymentStatus: 'open',
    },
    {
        requestPaymentNo: 'REQ0103',
        customerName: 'Charlie Brown',
        fileNo: 'S032410405',
        currency: 'USD',
        totalPayment: '$ 60,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 418',
        paymentStatus: 'close',
    },
    {
        requestPaymentNo: 'REQ0104',
        customerName: 'David Wilson',
        fileNo: 'S032410406',
        currency: 'USD',
        totalPayment: '$ 70,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 419',
        paymentStatus: 'cancelled',
    },
    {
        requestPaymentNo: 'REQ0105',
        customerName: 'Eve Davis',
        fileNo: 'S032410407',
        currency: 'USD',
        totalPayment: '$ 80,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 420',
        paymentStatus: 'open',
    },
    {
        requestPaymentNo: 'REQ0106',
        customerName: 'Frank Miller',
        fileNo: 'S032410408',
        currency: 'USD',
        totalPayment: '$ 90,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 421',
        paymentStatus: 'close',
    },
    {
        requestPaymentNo: 'REQ0107',
        customerName: 'Grace Lee',
        fileNo: 'S032410409',
        currency: 'USD',
        totalPayment: '$ 100,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 422',
        paymentStatus: 'cancelled',
    },
    {
        requestPaymentNo: 'REQ0108',
        customerName: 'Hank Green',
        fileNo: 'S032410410',
        currency: 'USD',
        totalPayment: '$ 110,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 423',
        paymentStatus: 'open',
    },
    {
        requestPaymentNo: 'REQ0109',
        customerName: 'Ivy White',
        fileNo: 'S032410411',
        currency: 'USD',
        totalPayment: '$ 120,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 424',
        paymentStatus: 'close',
    },
    {
        requestPaymentNo: 'REQ0110',
        customerName: 'Jack Black',
        fileNo: 'S032410412',
        currency: 'USD',
        totalPayment: '$ 130,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 425',
        paymentStatus: 'cancelled',
    },
    {
        requestPaymentNo: 'REQ0111',
        customerName: 'Karen Brown',
        fileNo: 'S032410413',
        currency: 'USD',
        totalPayment: '$ 140,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 426',
        paymentStatus: 'open',
    },
    {
        requestPaymentNo: 'REQ0112',
        customerName: 'Leo King',
        fileNo: 'S032410414',
        currency: 'USD',
        totalPayment: '$ 150,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 427',
        paymentStatus: 'close',
    },
    {
        requestPaymentNo: 'REQ0113',
        customerName: 'Mia Queen',
        fileNo: 'S032410415',
        currency: 'USD',
        totalPayment: '$ 160,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 428',
        paymentStatus: 'cancelled',
    },
    {
        requestPaymentNo: 'REQ0114',
        customerName: 'Nina Brown',
        fileNo: 'S032410416',
        currency: 'USD',
        totalPayment: '$ 170,000',
        type: 'Pay to Limajari',
        paymentMethod: 'Bank Transfer',
        bankAccount: 'BNI LIM RP 429',
        paymentStatus: 'open',
    },
]

export const dummyTitipanDetails: ITitipanDetails[] = [
    {
        date: '02/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 677',
        note: 'Send to Carla',
        debit: '$0',
        credit: '$100,000',
        balance: '$100,000',
    },
    {
        date: '03/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 678',
        note: 'Withdraw by John',
        debit: '$50,000',
        credit: '$0',
        balance: '$50,000',
    },
    {
        date: '04/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 679',
        note: 'Send to Alice',
        debit: '$0',
        credit: '$200,000',
        balance: '$250,000',
    },
    {
        date: '05/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 680',
        note: 'Withdraw by Bob',
        debit: '$100,000',
        credit: '$0',
        balance: '$150,000',
    },
    {
        date: '06/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 681',
        note: 'Send to Charlie',
        debit: '$0',
        credit: '$300,000',
        balance: '$450,000',
    },
    {
        date: '07/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 682',
        note: 'Withdraw by David',
        debit: '$150,000',
        credit: '$0',
        balance: '$300,000',
    },
    {
        date: '08/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 683',
        note: 'Send to Eve',
        debit: '$0',
        credit: '$400,000',
        balance: '$700,000',
    },
    {
        date: '09/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 684',
        note: 'Withdraw by Frank',
        debit: '$200,000',
        credit: '$0',
        balance: '$500,000',
    },
    {
        date: '10/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 685',
        note: 'Send to Grace',
        debit: '$0',
        credit: '$500,000',
        balance: '$1,000,000',
    },
    {
        date: '11/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 686',
        note: 'Withdraw by Hank',
        debit: '$250,000',
        credit: '$0',
        balance: '$750,000',
    },
    {
        date: '12/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 687',
        note: 'Send to Ivy',
        debit: '$0',
        credit: '$600,000',
        balance: '$1,350,000',
    },
    {
        date: '13/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 688',
        note: 'Withdraw by Jack',
        debit: '$300,000',
        credit: '$0',
        balance: '$1,050,000',
    },
    {
        date: '14/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 689',
        note: 'Send to Karen',
        debit: '$0',
        credit: '$700,000',
        balance: '$1,750,000',
    },
    {
        date: '15/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 690',
        note: 'Withdraw by Leo',
        debit: '$350,000',
        credit: '$0',
        balance: '$1,400,000',
    },
    {
        date: '16/01/25',
        type: 'Top Up',
        bankAccount: 'BCA LIM USD 691',
        note: 'Send to Mia',
        debit: '$0',
        credit: '$800,000',
        balance: '$2,200,000',
    },
    {
        date: '17/01/25',
        type: 'Withdrawal',
        bankAccount: 'BCA LIM USD 692',
        note: 'Withdraw by Nina',
        debit: '$400,000',
        credit: '$0',
        balance: '$1,800,000',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
