import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { FormikContextType, FormikProvider } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import { IFormNote } from 'form-validation/organisation-note.validation'
type DropdownProps = ReturnType<typeof useDropdown>

const NotesListForm = ({
    dropdownService,
    formik,
}: {
    dropdownService: DropdownProps
    formik?: FormikContextType<IFormNote>
}) => {
    return (
        <div className="overflow-auto">
            <div className="flex flex-col p-3 gap-3">
                <FormDropdown
                    name="category"
                    label="Note Category"
                    placeholder="Select Note Category"
                    options={dropdownService.noteCategoryData}
                    useUppercaseLabel={true}
                />
                <FormTextarea
                    label="Notes Text"
                    name="text"
                    placeholder="Enter Notes Text"
                    useUppercaseLabel={true}
                />
            </div>
        </div>
    )
}

export default NotesListForm
