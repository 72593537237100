import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { IPackLines } from 'repository/interface/packlines.interface'

export const packLinesHeader: ISTColumn<IPackLines>[] = [
    {
        accessor: 'pack_id',
        label: 'Pack Id',
        minWidth: 100,
    },
    {
        accessor: 'pack_type',
        label: 'Pack Type',
        minWidth: 100,
    },
    {
        accessor: 'total_packs',
        label: 'Inner Packs',
        minWidth: 100,
    },
    {
        accessor: 'product_categories',
        label: 'Product Category',
        customBuild: (dt: any) => {
            if (!dt) return '-'
            const data = dt
            const totalData = data.length
            if (totalData === 1) {
                const value = data[0].name
                return value
            } else if (totalData > 1) {
                const value = data[0].name
                const totalOthers = totalData - 1
                return `${value}, +${totalOthers}`
            } else {
                return '-'
            }
        },
        minWidth: 100,
    },
    {
        accessor: 'hs_codes',
        label: 'HS Code',
        customBuild: (dt: any) => {
            if (!dt) return '-'
            const data = dt
            const totalData = data.length
            if (totalData === 1) {
                const value = data[0].name
                return value
            } else if (totalData > 1) {
                const value = data[0].name
                const totalOthers = totalData - 1
                return `${value}, +${totalOthers}`
            } else {
                return '-'
            }
        },
        minWidth: 100,
    },
    {
        accessor: 'length',
        label: 'Length',
        customBuild: (dt, a, b, c, rowData) => {
            const value = dt ?? 0
            const unit = rowData?.length_unit?.symbol ?? ''
            return <>{`${value} ${unit}`}</>
        },
        minWidth: 100,
    },
    {
        accessor: 'width',
        label: 'Width',
        customBuild: (dt, a, b, c, rowData) => {
            const value = dt ?? 0
            const unit = rowData?.width_unit?.symbol ?? ''
            return <>{`${value} ${unit}`}</>
        },
        minWidth: 100,
    },
    {
        accessor: 'height',
        label: 'Height',
        customBuild: (dt, a, b, c, rowData) => {
            const value = dt ?? 0
            const unit = rowData?.height_unit?.symbol ?? ''
            return <>{`${value} ${unit}`}</>
        },
        minWidth: 100,
    },
    {
        accessor: 'weight',
        label: 'Weight',
        customBuild: (dt, a, b, c, rowData) => {
            const value = dt ?? 0
            const unit = rowData?.weight_unit?.symbol ?? ''
            return <>{`${value} ${unit}`}</>
        },
        minWidth: 100,
    },
    {
        accessor: 'volume',
        label: 'Volume',
        customBuild: (dt, a, b, c, rowData) => {
            const value = dt ?? 0
            const unit = rowData?.volume_unit?.symbol ?? ''
            return <>{`${value} ${unit}`}</>
        },
        minWidth: 100,
    },
    {
        accessor: 'goods_description',
        label: 'Goods Description',
        minWidth: 100,
    },
]
