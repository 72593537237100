import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './item-list.slice'
import { IFetchParams } from 'common/common.interface'
import {
    IFItemLists,
    IFItemListsPayload,
    useItemListsValidation,
} from 'form-validation/item-list.validation'
import {
    deleteItemList,
    getItemList,
    submitItemList,
    updateItemList,
} from 'repository/item-list.repository'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'
import { shipmentDetailSelector } from '../detail-shipment.slice'
import moment from 'moment'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

export const useItemLists = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()
    const modalService = useModal()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const data = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Formik
    const validation = useItemListsValidation()
    const formik = useFormik<IFItemLists>({
        initialValues: validation.initialValue,
        validationSchema: validation.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const calculateTotalAmount = (
        amount: IMeasurement | undefined,
        discount: IMeasurement | undefined,
    ) => {
        if (!discount?.qty) return amount?.qty
        if (!amount?.qty || !discount) return amount?.qty || 0

        let calculatedTotalAmount = 0

        if (discount?.unit === 'PERCENTAGE') {
            const discountRate = discount?.qty ? Number(discount.qty) / 100 : 0
            const calculatedDiscount = Number(amount.qty) * discountRate
            calculatedTotalAmount = Number(amount.qty) - calculatedDiscount
        } else if (discount?.unit === 'AMOUNT') {
            const fixedDiscount = discount?.qty ? Number(discount.qty) : 0
            if (Number(amount.qty) === 0) {
                return (calculatedTotalAmount = 0)
            }
            calculatedTotalAmount = Number(amount.qty) - fixedDiscount
        }

        return calculatedTotalAmount
    }

    const getData = async (params: IFetchParams, shipmentCode: string) => {
        setIsLoading(true)
        const response = await getItemList(params, shipmentCode)
        if (!response || !response.data) {
            setIsLoading(false)
            return []
        }
        const updatedData = response.data.map(
            (order: IItemList, index: number) => {
                const sortedItems = order.items.sort(
                    (a, b) => Number(b.priority) - Number(a.priority),
                )
                const updatedItems = sortedItems.map((item, itemIndex) => ({
                    ...item,
                    number: itemIndex + 1,
                }))
                return {
                    ...order,
                    number: index + 1,
                    items: updatedItems,
                }
            },
        )
        const updatedResponse = {
            ...response,
            data: updatedData,
        }
        dispatch(setData(updatedResponse))
        setIsLoading(false)
    }

    const resetForm = () => {
        formik.setValues(validation.initialValue)
    }

    const submit = async (value: IFItemLists) => {
        let response: true | undefined
        const code = shipmentDetailData.code ?? ''
        const orderCode = formik.getFieldProps('order_code').value

        const formData = new FormData()

        const formDataEntries: IFItemListsPayload = {
            supplier: value.supplier,
            name: value.name,
            note: value.note || undefined,
            discount:
                value.discount?.unit === 'PERCENTAGE' && value.discount?.qty
                    ? String(Number(value.discount.qty) / 100)
                    : '',
            discount_amount:
                value.discount?.unit === 'AMOUNT' && value.discount?.qty
                    ? String(Number(value.discount.qty))
                    : '',
            date: value.date
                ? moment(value.date).format('YYYY-MM-DD HH:mm:ss')
                : '',
            order_status: value.order_status,
            total_amount: value.total_amount?.qty
                ? String(Number(value.total_amount.qty))
                : '',
            currency: 'CUR_1000',
            files: value.file,
            description: value.description,
            document_type: value.document_type,
        }

        Object.entries(formDataEntries).forEach(([key, val]) => {
            if (val) formData.append(key, val)
        })

        if (isNew) {
            response = await submitItemList(code, formData)
        } else {
            response = await updateItemList(code, orderCode, formData)
        }

        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getData(fetchParams, shipmentDetailData.code || '')
    }

    const selectRow = (value: IItemList) => {
        const totalAmount: number = value.items.reduce(
            (sum, item) => sum + item.total_amount,
            0,
        )

        const newValue: IFItemLists = {
            order_id: value.order_id,
            supplier: value.supplier.code,
            name: value?.name,
            note: value?.note,
            total_amount: {
                qty: value.total_amount?.toString() ?? '0',
                unit: value?.currency?.code,
                unitLabel: value?.currency?.symbol,
            },
            amount: {
                qty: totalAmount?.toString() ?? '0',
                unit: value?.currency?.code,
                unitLabel: value?.currency?.symbol,
            },
            discount: {
                qty: value?.discount
                    ? (Number(value.discount) * 100).toString()
                    : value?.discount_amount
                      ? value.discount_amount.toString()
                      : undefined,
                unit: value?.discount
                    ? 'PERCENTAGE'
                    : value?.discount_amount
                      ? 'AMOUNT'
                      : undefined,
                unitLabel: value?.discount
                    ? 'Percentage'
                    : value?.discount_amount
                      ? 'Amount'
                      : undefined,
            },
            date: value?.date ?? '',
            order_status: value?.order_status,
            order_code: value.code ?? '',
            description: value.e_doc?.[0]?.description,
            document_type: value.e_doc?.[0]?.document_type,
        }

        formik.setValues(newValue)
        setIsNew(false)
        modalService.openModalHandling()
    }

    const deleteData = async (orderId?: string) => {
        const code = shipmentDetailData.code ?? ''
        const resolvedOrderId =
            orderId || formik.getFieldProps('order_code').value

        try {
            setDeleteLoading(true)
            await deleteItemList(code, resolvedOrderId)
            resetForm()
            modalService.closeModalHandling()
            getData(fetchParams, shipmentDetailData.code || '')
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    const handleSearch = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    useEffect(() => {
        getData(fetchParams, shipmentDetailData.code || '')
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getOrderStatus()
        dropdownService.getOrganisationSupplier()
        dropdownService.getCurrency()
        dropdownService.getDocumentType()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formik,
        isNew,
        submitLoading,
        deleteLoading,
        meta,
        fetchParams,
        deleteData,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        handleSearch,
        calculateTotalAmount,
    }
}
