import {
    CashBankSummaryHeader,
    tabItemStatic,
} from './cash-bank-details.static'
import useCashBank from './cash-bank-details.service'
import { ICashBankDetailSummary } from 'repository/interface/cash-bank-details.interface'
import Table from '@components/table/table.component'
import { metaDummy, cashBankSummaryData } from './cash-bank-details.dummy'
import FilterOverlay from './components/filter/filter-overlay.component'
import { RowOption } from './components/option/option.component'
import TabMaster2 from 'pages/components/tab-master2/tab-master2.component'

const CashBank = () => {
    const {
        selectedTabItem,
        loading,
        fetchParam,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        filterOverlay,
        deleteLoading,
        deleteData,
    } = useCashBank()

    const CashBankDropdown = () => {
        return (
            <div className="flex flex-row">
                <div className="relative inline-block ml-4 group">
                    <div
                        className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[200px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                    >
                        CREATE TRANSACTION
                        <i className="ri-arrow-down-s-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                    </div>

                    <div
                        className="absolute left-0 mt-2 w-[220px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                    >
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => navigate('/sales/create-invoice')}
                        >
                            Bank Internal Transfer
                        </div>
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => navigate('/sales/create-invoice')}
                        >
                            Receives Money
                        </div>
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => navigate('/sales/create-invoice')}
                        >
                            Pay Money
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const CashBankActionDropdown = () => {
        return (
            <div className="flex flex-row">
                <div className="relative inline-block ml-2 group">
                    <div
                        className="themes-bg-brand-v5 themes-text-white themes-bg-hover-white border-[1px] themes-text-hover-brand-v5 
                            w-[150px] py-3 rounded-md flex justify-center items-center text-size-S font-semibold 
                            cursor-pointer"
                    >
                        Action
                        <i className="ri-menu-line ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" />
                    </div>

                    <div
                        className="absolute left-0 mt-2 w-[150px] bg-white shadow-lg rounded-md overflow-hidden 
                            opacity-0 invisible translate-y-[-10px] transition-all duration-300 ease-in-out 
                            group-hover:opacity-100 group-hover:visible group-hover:translate-y-0 z-50"
                    >
                        <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => navigate('/cash-bank/detail/4/edit')}
                        >
                            Edit Account
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster2
                    items={tabItemStatic || []}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <FilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={() => { }}
                            />
                        ),
                    }}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search (min 3 char)'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    additionalButton1={<>{CashBankDropdown()}</>}
                    additionalButton2={<>{CashBankActionDropdown()}</>}
                />
                <Table<ICashBankDetailSummary>
                    headers={CashBankSummaryHeader}
                    data={cashBankSummaryData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta3={metaDummy}
                    onRowClick={(data) => {
                        navigate(`/cash-bank-form/detail/${data.spent_balance}`)
                    }}
                    moduleTitle={'Cash Bank Details'}
                    rowOptionComponent={(rowData, closeHandling) => (
                        <RowOption
                            closeHandling={closeHandling}
                            rowData={rowData}
                            deleteHandling={() => deleteData(rowData.message)}
                            deleteLoading={deleteLoading}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default CashBank
