import * as React from 'react'
import './../expenses-doc.style.css'
import Logo from './../lima-jari-logo.png'
import { IQuotation } from 'repository/interface/quotations.interface'
import moment from 'moment'

interface ComponentToPrintProps {
    text?: string
    data?: IQuotation
}

export const PurchaseInvoiceDoc = React.forwardRef<
    HTMLDivElement | null,
    ComponentToPrintProps
>((props, ref) => {
    const { data, text } = props
    return (
        <div
            ref={ref}
            className="mx-[50px] flex flex-col justify-center min-h-screen"
        >
            <div className="min-h-[calc(100vh-5rem)] flex flex-col">
                {/* header */}
                <div className="flex flex-col">
                    <img src={Logo} alt="Logo" className="h-20 w-fit mb-3" />
                    <tbody className="max-w-[24rem]">
                        <tr className="base-text">
                            <td className="text w-32">COMPANY PROFILE</td>
                            <td className="text-dark">LIMAJARI CARGO</td>
                        </tr>
                        <tr className="base-text">
                            <td className="text w-32">ADDRESS</td>
                            <td className="text-dark">
                                Jl. Raya Kerobokan 100X, Kerobokan, Badung,
                                80361 Bali
                            </td>
                        </tr>
                    </tbody>
                </div>
                {/* body */}

                <div className="flex flex-col flex-grow mt-6">
                    <p className="heading w-full text-center">
                        PURCHASE INVOICE
                    </p>
                    <div className="sub-heading custom-border-bottom-bold text-start w-full mt-4">
                        PURCHASES DETAILS
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <div>
                            <table className="w-full border-collapse col-span-1">
                                <tbody>
                                    <tr>
                                        <td className="text custom-border-bottom-light min-w-24">
                                            Vendor
                                        </td>
                                        <td className="text-dark custom-border-bottom-light w-full">
                                            PT UNGGUL MANDIRI
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table className="w-full border-collapse col-span-1">
                            <tbody>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-40">
                                        Transaction No
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10004
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-40">
                                        Transaction Date
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10/02/2025
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-40">
                                        Due Date
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10/02/2025
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text custom-border-bottom-light min-w-40">
                                        Customer Reference No
                                    </td>
                                    <td className="text-dark custom-border-bottom-light w-full">
                                        10/02/2025
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="sub-heading custom-border-bottom-bold text-start w-full mt-4">
                        FILE DETAILS
                    </div>
                    <div className="flex flex-row gap-6 mt-6 custom-border-bottom-light">
                        <div>
                            <p className="text">File Number</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-dark">
                                SS12242025 - INV01001, INV01002
                            </p>
                            <p className="text-dark">
                                SS12242025 - INV01005, INV01020
                            </p>
                            <p className="text-dark">SS12242025 - INV01011</p>
                            <p className="text-dark">SS12242025 - INV01011</p>
                            <p className="text-dark">SS12242025 - INV01011</p>
                        </div>
                    </div>
                    <div className="mt-6">
                        <table className="w-full mt-2 rounded-2xl custom-border-light">
                            <tbody>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[20px]">
                                        No
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[110px]">
                                        ACCOUNT
                                    </td>

                                    <td className="text-dark-sm px-2 py-2 bg-secondary">
                                        DESCRIPTION
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[30px]">
                                        QTY
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[130px] text-right">
                                        UNIT PRICE
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[30px]">
                                        TAX
                                    </td>
                                    <td className="text-dark-sm px-2 py-2 bg-secondary w-[130px] text-right">
                                        AMOUNT
                                    </td>
                                </tr>
                                <tr className="base-text custom-border-light-bottom">
                                    <td className="text-sm px-2 py-2">1</td>
                                    <td className="text-sm px-2 py-2">
                                        Shipment Fee
                                    </td>
                                    <td className="text-sm px-2 py-2">-</td>
                                    <td className="text-sm px-2 py-2">100</td>
                                    <td className="text-sm px-2 py-2 text-right">
                                        Rp. 1.000.000,00
                                    </td>
                                    <td className="text-sm px-2 py-2">PPN</td>
                                    <td className="text-sm px-2 py-2 text-right">
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={4}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        SUB TOTAL
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2 " colSpan={4}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        PPN 11%
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 110.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text bg-secondary">
                                    <td className="px-2 py-2 " colSpan={4}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        TOTAL
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        RP. 1.100.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2 " colSpan={4}></td>
                                    <td
                                        className="text-dark px-2 py-2"
                                        colSpan={1}
                                    >
                                        PAYMENT PAID
                                    </td>
                                    <td
                                        colSpan={2}
                                        className="text-dark px-2 py-2 text-right"
                                    >
                                        Rp. 110.000,00
                                    </td>
                                </tr>

                                <tr className="custom-border-light-bottom base-text">
                                    <td
                                        className="px-2 py-2 bg-secondary"
                                        colSpan={4}
                                    ></td>
                                    <td
                                        className="text-dark text-xl px-2 py-2 bg-secondary"
                                        colSpan={1}
                                    >
                                        BALANCE DUE
                                    </td>
                                    <td
                                        className="text-dark px-2 py-2 bg-secondary text-right"
                                        colSpan={2}
                                    >
                                        RP. 1.000.000,00
                                    </td>
                                </tr>
                                <tr className="custom-border-light-bottom base-text">
                                    <td className="px-2 py-2" colSpan={7}>
                                        <div className="w-full flex flex-row rounded-b-md justify-end">
                                            <p className="text-dark mr-1 text-[11px]">
                                                AMOUNT in words:
                                            </p>
                                            <p className="text-dark text-[10px] font-semibold">
                                                ONE MILLION ONE HUNDRED TEN
                                                THOUSAND RUPIAH
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full mt-4">
                        <p className="text custom-border-bottom-bold text-start">
                            Message
                        </p>
                        <p className="text text-start py-1">
                            Is there any additional information that we need to
                            know, please kindly inform us in the field shown
                            below
                        </p>
                    </div>
                    <div className="flex mt-6">
                        <p className="big-heading mr-6">TOTAL</p>
                        <p className="big-heading font-bold">
                            Rp. 1.110.000,00
                        </p>
                    </div>
                </div>
                {/* footer */}
                {/* <div className="flex flex-row justify-between items-center w-full custom-border-top-light self-end">
                    <img
                        src={LogisticalSquare}
                        alt="Logo"
                        className="w-9 h-9"
                    />
                    <p className="text mb-0">
                        ©OneByOne Logistical Pty Ltd 2024. Connect and control
                        with digital precision™
                    </p>
                </div> */}
            </div>
        </div>
    )
})
