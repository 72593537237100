import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

interface IStorageUnitFilter extends Pick<IFetchParams, 'type' | 'bank'> {}

export const initialQuotesFilter: IStorageUnitFilter = {
    type: [],
    bank: [],
}

const useFilter = (onFilterSubmit: (data: IFetchParams) => void) => {
    const dropdownService = useDropdown()
    const formik = useFormik<IStorageUnitFilter>({
        initialValues: initialQuotesFilter,
        onSubmit: (values) => {
            generateFilter(values)
        },
    })

    const generateFilter = async (values: IStorageUnitFilter) => {
        let params: IFetchParams = { ...values }
        onFilterSubmit(params)
    }

    useEffect(() => {
        dropdownService.getOrganisationCarrier()
    }, [])

    return { formik, dropdownService }
}

export default useFilter
