import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    expensesDataSelector,
    expensesFetchParamSelector,
    expensesTabItemSelector,
    setFetchParam,
    setTabItems,
} from './expenses.slice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import useOverlay from '@components/overlay/overlay.service'

const useExpenses = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const fetchParam = useSelector(expensesFetchParamSelector)
    const expensesData = useSelector(expensesDataSelector)
    const selectedTabItem = useSelector(expensesTabItemSelector)

    const dropdownService = useDropdown()

    const filterOverlay = useOverlay()

    // states
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [tabFilter, setTabFilter] = useState<ITabItem>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        console.log(fParams)
    }

    const deleteData = async (orderId?: string) => {
        try {
            setDeleteLoading(true)
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    return {
        setTabItems,
        expensesData,
        loading,
        selectedTabItem,
        fetchParam,
        filterOverlay,
        deleteLoading,
        tabFilter,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        deleteData,
    }
}

export default useExpenses
