import * as Yup from 'yup'
import { requiredMessage } from 'form-validation/validation-message.static'
import { IFormTax } from './form-tax.interface'

export const useFormTaxValidation = () => {
    const schema = Yup.object<IFormTax>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        email: Yup.string().required(requiredMessage('Email')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormTax = {
            tax_name: '',
            effective_rate: '',
            sell_account: '',
            buy_account: '',
            multiplier: false,
            third_party:false
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}
