import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IFHSCode } from 'form-validation/hs-code.validation'
import { IHSCode } from './interface/hs.-code.interface'

export const getHSCode = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.hsCode}`
        const response = await fetch<IResponseData<IHSCode[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Hs Code',
            })
        }
        return undefined
    }
}

export const submitHSCode = async (values: IFHSCode) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.hsCode}`
        await post<IResponseData<any>, IFHSCode>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Hs Code is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Hs Code',
        })
        return undefined
    }
}

export const updateHSCode = async (values: IFHSCode) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.hsCode}/${code}`
        await put<IResponseData<any>, IFHSCode>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Hs Code is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Hs Code',
        })
        return undefined
    }
}

export const deleteHSCode = async (code: string) => {
    try {
        const endpoint = `${endpoints.hsCode}/${code}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Hs Code is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Hs Code',
        })
        return undefined
    }
}
