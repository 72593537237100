import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'

const PreviewPdfModal = ({
    title,
    modalService,
    form,
    position = 'right',
    className,
    zIndex,
}: {
    title: string
    modalService: IUseModal
    form: JSX.Element
    position?: 'right' | 'middle'
    className?: string
    zIndex?: number
}) => {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            zIndex={zIndex}
            className={`${position === 'right' ? '!right-0' : ''} ${className} w-[850px] sm:!w-[95vw] sm:!right-[unset] mobile:min-w-0 md:min-w-[900px] !p-0 absolute !m-4 top-[30px]`}
        >
            <div className="flex flex-1 flex-col">
                {/* Header */}
                <div className="flex font-bold text-size-L justify-between p-4 items-center themes-border-gray border-b">
                    <div>{title}</div>
                    <div className="flex flex-row">
                        <div className="flex justify-center items-center pl-4">
                            <i
                                className="ri-close-fill cursor-pointer"
                                onClick={() => {
                                    modalService.closeModalHandling()
                                }}
                            ></i>
                        </div>
                    </div>
                </div>

                {/* Content dengan scroll */}
                <div className="overflow-y-auto max-h-[90vh]">{form}</div>
            </div>
        </Modal>
    )
}

export default PreviewPdfModal
