import { IFetchParams, IResponseData } from 'common/common.interface'
import { IShipment } from './interface/shipment.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { IFormCreateShipmentPayload } from 'form-validation/shipment-create.validation'
import { Toast } from '@components/toast/toast.component'
import { IFormShipmentDetailPayload } from 'form-validation/shipment-details.validation'
import { IConsolidation } from './interface/consolidation.interface'
import { IFConsolidationPayload } from 'form-validation/consolidation.validation'

export const getShipments = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<IResponseData<IShipment[]>, IFetchParams>({
            endpoint: endpoints.shipments,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const getConsolidationDetail = async (code: string) => {
    try {
        const endpoint = `${endpoints.consolidation}/${code}`
        const response = await fetch<
            IResponseData<IConsolidation>,
            IFetchParams
        >({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Consolidation Detail',
        })
        return undefined
    }
}

export const getConsolidationShipment = async (code: string) => {
    try {
        const endpoint = `${endpoints.consolidation}/${code}/shipments`
        const response = await fetch<IResponseData<IShipment[]>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Shipment data',
        })
        return undefined
    }
}

export const addConsolidationShipment = async (
    shipment: IShipment,
    code: string,
) => {
    const payload = { code: shipment.code }
    try {
        const endpoint = `${endpoints.consolidation}/${code}/shipments`
        const response = await post<IResponseData<any>, IFetchParams>({
            endpoint: endpoint,
            payload,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed add Shipment ' + shipment.shipment_id,
        })
        return undefined
    }
}

export const removeConsolidationShipment = async (
    shipmentCode: string,
    code: string,
) => {
    try {
        const endpoint = `${endpoints.consolidation}/${code}/shipments/${shipmentCode}`
        await deleteRequest<IResponseData<any>, IFetchParams>({
            endpoint: endpoint,
        })
        return 1
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed remove Shipment',
        })
        return undefined
    }
}

export const getShipmentDetail = async (code: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}`
        const response = await fetch<IResponseData<IShipment>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Shipment Detail',
        })
        return undefined
    }
}

export const submitShipment = async (payload: IFormCreateShipmentPayload) => {
    try {
        const endpoint = `${endpoints.shipments}`
        const response: IResponseData<any> = await post<
            IResponseData<any>,
            IFormCreateShipmentPayload
        >({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New shipment is created',
            type: 'success',
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Shipment Data',
        })
        return undefined
    }
}

export const submitConsolidation = async (payload: IFConsolidationPayload) => {
    try {
        const endpoint = `${endpoints.consolidation}`
        const response: IResponseData<any> = await post<
            IResponseData<any>,
            IFormCreateShipmentPayload
        >({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New Consolidation is created',
            type: 'success',
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Consolidation Data',
        })
        return undefined
    }
}

export const updateConsolidation = async (payload: IFConsolidationPayload) => {
    const {
        code,
        shipments,
        sending_agent_address,
        receiving_agent_address,
        ...newPayload
    } = payload

    // rehaping again because of payload structure create / update not match
    const reshapingPayload = {
        ...newPayload,
        receiving_agent_address: { code: receiving_agent_address },
        sending_agent_address: { code: sending_agent_address },
    }

    try {
        const endpoint = `${endpoints.consolidation}/${code}`
        const response: IResponseData<any> = await put<
            IResponseData<any>,
            IFormCreateShipmentPayload
        >({
            endpoint: endpoint,
            payload: reshapingPayload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Consolidation is updated',
            type: 'success',
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Consolidation Data',
        })
        return undefined
    }
}

export const updateShipment = async (
    shipmentCode: string,
    payload: IFormShipmentDetailPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}`
        await put<IResponseData<any>, IFormShipmentDetailPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Success',
            message: 'Shipment is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Shipment Data',
        })
        return undefined
    }
}

export const updateShipmentStatus = async (
    shipmentCode: string,
    statusShipment: string,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}/change_status?status=${statusShipment}`
        await put<IResponseData<any>, IFormShipmentDetailPayload>({
            endpoint: endpoint,
        })

        Toast({
            header: 'Update Success',
            message: 'Success update shipment status',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Shipment status Data',
        })
        return undefined
    }
}

export const getConsolidation = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    let newParam = params
    if (params.console_category === 'all') {
        newParam = {
            ...params,
            console_category: undefined,
        }
    }

    try {
        const response = await fetch<
            IResponseData<IConsolidation[]>,
            IFetchParams
        >({
            endpoint: endpoints.consolidation,
            params: newParam,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}
