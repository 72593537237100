import { useFormik } from 'formik'
import { useFormTaxValidation } from './form-tax.validation'
import { IFormTax } from './form-tax.interface'

const useFormTax = () => {
    const formTaxValidation = useFormTaxValidation()
    const formik = useFormik<IFormTax>({
        validationSchema: formTaxValidation.schema,
        validateOnChange: true,
        initialValues: formTaxValidation.generateInitialValue(),
        onSubmit: (values) => {
            console.log(values)
        },
    })

    return {
        formik,
    }
}

export default useFormTax

