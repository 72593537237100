import { IFetchParams, IResponseData } from 'common/common.interface'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { Toast } from '@components/toast/toast.component'
import { IEDoc } from './interface/edocs.interface'
import { IFormEdocsPayload } from 'form-validation/edocs.validation'

export const fetchEdocs = async (
    shipmentCode: string,
    type: 'shipments' | 'consolidations',
    showErrorToast?: boolean,
) => {
    try {
        const url =
            type === 'consolidations'
                ? endpoints.consolidation
                : endpoints.shipments
        const response = await fetch<IResponseData<IEDoc[]>, IFetchParams>({
            endpoint: `${url}/${shipmentCode}/edocs`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const submitEdocs = async (
    code: string,
    payload: FormData,
    type: 'shipments' | 'consolidations',
) => {
    try {
        const url =
            type === 'consolidations'
                ? endpoints.consolidation
                : endpoints.shipments
        const endpoint = `${url}/${code}/edocs`
        await post<IResponseData<any>, IFormEdocsPayload>({
            endpoint: endpoint,
            payload,
            isFormData: true,
        })

        Toast({
            header: 'Submit Success',
            message: 'New Edocs is uploaded',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Upload Edocs',
        })
        return undefined
    }
}

export const updateEdocs = async (
    code: string,
    edocsCode: string,
    payload: FormData,
    type: 'shipments' | 'consolidations',
) => {
    try {
        const url =
            type === 'consolidations'
                ? endpoints.consolidation
                : endpoints.shipments
        const endpoint = `${url}/${code}/edocs/${edocsCode}`
        await put<IResponseData<any>, IFormEdocsPayload>({
            endpoint: endpoint,
            payload,
            isFormData: true,
        })

        Toast({
            header: 'Submit Success',
            message: 'Edocs is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Edocs',
        })
        return undefined
    }
}

export const deleteEdocs = async (
    code: string,
    edocsCode: string,
    type: 'shipments' | 'consolidations',
) => {
    try {
        const url =
            type === 'consolidations'
                ? endpoints.consolidation
                : endpoints.shipments
        const endpoint = `${url}/${code}/edocs/${edocsCode}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Edocs is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Edocs',
        })
        return undefined
    }
}
